import React, { createContext, useContext } from 'react'
import { useState } from 'react'

const WinningBoxContext = createContext()

export function useWinningBox() {
  return useContext(WinningBoxContext)
}

export default function WinningBoxProvider({ children }) {
  const [isWinningBoxOpen, setIsWinningBoxOpen] = useState('')
  const [winningBoxData, setWinningBoxData] = useState([])

  const onSubmitWinningBoxOpen = (flag) => {
    setIsWinningBoxOpen(flag)
    if (!flag) {
      document.body.classList.remove('winning_box_open')
    }
  }
  const onSubmitWinningBoxData = (data) => {
    setWinningBoxData(data)
  }

  return (
    <WinningBoxContext.Provider
      value={{
        isWinningBoxOpen,
        winningBoxData,
        onSubmitWinningBoxOpen,
        onSubmitWinningBoxData,
      }}
    >
      {children}
    </WinningBoxContext.Provider>
  )
}
