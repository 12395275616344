import axios from 'axios'
import React, { useEffect, useState } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { Col, Nav, Row, Tab } from 'react-bootstrap'

const WagersAndPrizePot = () => {
  const [date, setDate] = useState([null, new Date()])
  const [topWagersList, setTopWagersList] = useState([])
  const [show, setShow] = useState(true)
  const [key, setKey] = useState('tab1')

  const onDateChange = (date) => {
    setDate(date)
  }
  const getAllWaggers = () => {
    axios
      .get(`tron/data/topwagers/${Date.now()}`)
      .then((res) => {
        if (res.data) {
        }
      })
      .catch(() => {})
  }

  useEffect(() => {
    getAllWaggers()
  }, [])

  return (
    <>
      <div className="wagers_prize_pot_wrapper">
        <h5 className="title">
          {key === 'tab1' ? 'Top Wagers' : 'Prize Pots'}{' '}
        </h5>

        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="tab1"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="tab1">Top Wagers</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab2">Prize Pots</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="tab1">
              <div className="card mb-3 transaction_card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <DateRangePicker onChange={onDateChange} value={date} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                {topWagersList.length > 0 ? (
                  <div>
                    <table className="table table-striped table-dark referral_table_tb">
                      <thead>
                        <tr>
                          <th scope="col">S.N</th>
                          <th scope="col">SignUP</th>
                          <th scope="col">Hash</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {topWagersList.map((tw, index) => (
                          <tr></tr>
                        ))}
                      </tbody>
                    </table>
                    {show ? (
                      <div style={{ textAlign: 'center' }}>
                        <button
                          style={{
                            marginBottom: '10px',
                            marginTop: 10,
                          }}
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                        >
                          Load more
                        </button>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="card mb-3 transaction_card">
                    <h4 className="no_h4">No data available</h4>
                  </div>
                )}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab2">
              <div className="card mb-3 transaction_card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <DateRangePicker onChange={onDateChange} value={date} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                {topWagersList.length > 0 ? (
                  <div>
                    <table className="table table-striped table-dark referral_table_tb">
                      <thead>
                        <tr>
                          <th scope="col">S.N</th>
                          <th scope="col">SignUP</th>
                          <th scope="col">Hash</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {topWagersList.map((tw, index) => (
                          <tr></tr>
                        ))}
                      </tbody>
                    </table>
                    {show ? (
                      <div style={{ textAlign: 'center' }}>
                        <button
                          style={{
                            marginBottom: '10px',
                            marginTop: 10,
                          }}
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                        >
                          Load more
                        </button>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="card mb-3 transaction_card">
                    <h4 className="no_h4">No data available</h4>
                  </div>
                )}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  )
}

export default WagersAndPrizePot
