import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import App from './App'
import './assets/css/style.css'

import './assets/css/responsive.css'
import 'react-loading-skeleton/dist/skeleton.css'
import './assets/styles/app.scss'
import { AppProvider } from './app/context'
import AccountProvider from './app/context/AccountProvider'
import LotteryProvider from './app/context/LotteryProvider'
import TSPTokenProvider from './app/context/TSPProvider'
import WinningBoxProvider from './app/context/WinningBoxProvider'
import SidebarToggleProvider from './app/context/SidebarToggleProvider'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use(
  (reqConfig) => {
    const reqConfiglocal = reqConfig
    const token = localStorage.getItem('token')
    if (token) {
      reqConfiglocal.headers.Authorization = `${token}`
    }
    return reqConfiglocal
  },
  (error) => {
    Promise.reject(error)
  },
)

window.consoleClearHandler = (...args) => {
  // console.log()
}

ReactDOM.render(
  <AppProvider>
    <AccountProvider>
      <LotteryProvider>
        <TSPTokenProvider>
          <WinningBoxProvider>
            <SidebarToggleProvider>
              <App />
            </SidebarToggleProvider>
          </WinningBoxProvider>
        </TSPTokenProvider>
      </LotteryProvider>
    </AccountProvider>
  </AppProvider>,
  document.getElementById('root'),
)
