// Lottery
const contractAddress = process.env.REACT_APP_LOTTO_GAME_CONTRACT
// Lotto Token
const tokencontractAddress = process.env.REACT_APP_LOTO_TOKEN_CONTRACT
// TSP
const tspcontractAddress = process.env.REACT_APP_TSP_CONTRACT
// BSC Admin Address
const bscadminAddress = process.env.REACT_APP_BSC_ADMIN_ADDRESS
// BSC Admin Address
const tronadminAddress = process.env.REACT_APP_TRON_ADMIN_ADDRESS

export {
  contractAddress,
  tokencontractAddress,
  tspcontractAddress,
  bscadminAddress,
}