import isEmpty from 'is-empty'
import React, { useEffect, useState } from 'react'
import { ToastsStore } from 'react-toasts'
import { changePassword } from '../components/Auth/Service'

export default function ChangePassword() {
    const [oldPassword, setOldPassword] = useState()
    const [newPassword, setNewPassword] = useState()
    const changePasswordHandler = async() => {
        const data = {
            oldPassword,
            newPassword
        }
        const res = await changePassword(data)
        console.log(res, "res")
        if (res.status == 200) {
            ToastsStore.success(res.message)
        } else {
            ToastsStore.info("Somthing Went Wrong")
        }
    }
    // useEffect(() => {
    //   }, [])
    return (
        <>
            <h5 className="title mt-5">Change Password</h5>
            <div className="card mb-3 transaction_card">
                <div className="card-body pb-5">
                    <div className='w-50' style={{ width: '300px' }}>
                        <div class="form-group">
                            <label for="formGroupExampleInput">Old Password</label>
                            <input type="text" className="form-control" id="formGroupExampleInput" onChange={(e) => setOldPassword(e.target.value)} placeholder="Old Password" />
                        </div>
                        <div class="form-group">
                            <label for="formGroupExampleInput2">New Passowrd</label>
                            <input type="text" className="form-control" id="formGroupExampleInput2" onChange={(e) => setNewPassword(e.target.value)} placeholder="New Passowrd" />
                        </div>
                        <button type="submit" className="btn btn-primary" onClick={() => !isEmpty(newPassword) ? changePasswordHandler() : null}>Change Passowrd</button>
                    </div>
                </div>
            </div>
        </>
    )
}


