import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import { BsFillDice6Fill, BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import { useSidebarToggle } from '../context/SidebarToggleProvider'
import axios from 'axios'
import { useAccount } from '../context/AccountProvider'
import Referral from '../referral'
import config from '../../config.json'

const menuItemList = [
  {
    name: 'Home',
    path: '/',
    icon: 'tsp',
    subMenu: [],
  },
  {
    name: 'Games',
    path: '#',
    icon: 'lotto',
    iconClosed: <BsChevronDown />,
    iconOpen: <BsChevronUp />,
    subMenu: [
      {
        name: 'Lotto TRX',
        path: '/dapp/lotto',
        icon: <i className={`icon lotto`}></i>,
      },
      {
        name: 'Lotto MTW',
        path: '/partner/mtw/lotto/2',
        icon: <i className={`icon lotto`}></i>,
      },
      {
        name: 'Dice',
        path: '/inhouse/dice',
        icon: <i className={`icon dapp`}></i>,
      },
      {
        name: 'Color Prediction',
        path: '/inhouse/color-prediction',
        icon: <i className={`icon color`}></i>,
      },
      {
        name: 'Wheel of Fortune',
        path: '/inhouse/fortune-wheel',
        icon: <i className={`icon wheel`}></i>,
      },
      {
        name: 'Roulette',
        path: '/inhouse/roulette',
        icon: <i className={`icon roulette-icon`}></i>,
      },
    ],
  },
  {
    name: 'Dividend',
    path: '/dividend',
    icon: 'tsp',
    subMenu: [],
  },
  {
    name: 'Referral',
    path: '#!',
    icon: 'referral',
    subMenu: [],
  },
  {
    name: 'Buy TSP',
    path: '/tronsweep-token',
    icon: 'buy_tsp',
    subMenu: [],
  },
  {
    name: 'Stake & Earn',
    path: '/dapp/stake/lotto',
    icon: 'stake',
    subMenu: [],
  },
  {
    name: 'FAQs',
    path: '/faq',
    icon: 'faq',
    subMenu: [],
  },
  {
    name: 'Tutorials',
    path: '/learn',
    icon: 'tutorials',
    subMenu: [],
  },
]

const Sidebar = () => {
  const { accountAddress, refferalCode } = useAccount()
  const { isOpen } = useSidebarToggle()
  const [subnav, setSubnav] = useState(false)
  const [referralModalOpen, setReferralModalOpen] = useState(false)
  const [afterGeneratedRefCode, setAfterGeneratedRefCode] = useState('')
  const [generateReferralCode, setGenerateReferralCode] = useState('')

  const showSubnav = () => setSubnav(!subnav)

  const referralOpenModalHandler = () => setReferralModalOpen(true)
  const referralCloseModalHandler = () => setReferralModalOpen(false)

  const handleChange = (event) => {
    setGenerateReferralCode(event.target.value)
  }

  const getgeneratedReferalCode = async (code = null) => {
    try {
      let refTemp = refferalCode
      if (code) {
        refTemp = code
      }
      const refC = `${config.production.domain}?ref=${refTemp}`
      setAfterGeneratedRefCode(refC)
      setGenerateReferralCode(refC)
    } catch (error) {
      console.log(error, 'Error')
    }
  }

  const generateRefferal = async () => {
    const refCode = `?ref=${generateReferralCode.toUpperCase()}`
    if (generateReferralCode === '') {
      ToastsStore.success('Please enter your referral code')
    } else {
      try {
        const res = await axios.post(`tron/user/createReferral`, {
          public_address: accountAddress,
          refCode: generateReferralCode,
        })
        if (res.data.status) {
          setAfterGeneratedRefCode(refCode)
          setGenerateReferralCode(refCode)
          ToastsStore.success(res.data.message)
          getgeneratedReferalCode(generateReferralCode.toUpperCase())
          referralCloseModalHandler()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (refferalCode) {
      getgeneratedReferalCode()
    }
  }, [refferalCode])

  useEffect(() => {
    if (isOpen) {
      setSubnav(true)
    }
  }, [isOpen])
  return (
    <>
      <div className="sidebar sticky-top">
        <ul>
          {menuItemList.map((menu, i) => {
            return (
                <li onClick={menu.subMenu.length > 0 && showSubnav} key={i}>
                  {menu.name === 'Games' ? (
                    <Link className={menu.subMenu.length > 0 ? 'top_link' : ''}>
                      <div>
                        <BsFillDice6Fill />

                        <span>{menu.name}</span>
                      </div>
                      {menu.subMenu.length > 0 && (
                        <div className="arrow_box">
                          {menu.subMenu.length > 0 && subnav
                            ? menu.iconOpen
                            : menu.subMenu.length > 0
                            ? menu.iconClosed
                            : null}
                        </div>
                      )}
                    </Link>
                  ) : menu.name === 'Referral' ? (
                    <>
                      <Link
                        className={menu.subMenu.length > 0 ? 'top_link' : ''}
                        onClick={referralOpenModalHandler}
                      >
                        <div>
                          <i className={`icon ${menu.icon}`}></i>

                          <span>{menu.name}</span>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      <NavLink
                        className={menu.subMenu.length > 0 ? 'top_link' : ''}
                        to={menu.path}
                        onClick={menu.path === '#!' && referralOpenModalHandler}
                      >
                        <div>
                          <i className={`icon ${menu.icon}`}></i>

                          <span>{menu.name}</span>
                        </div>
                      </NavLink>
                    </>
                  )}

                  {subnav && (
                    <ul>
                      {menu.subMenu.map((submenu, i) => (
                        <li key={i}>
                          <NavLink activeClassName="active" to={submenu.path}>
                            {submenu.icon}
                            <span>{submenu.name}</span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
            )
          })}

          {/* <li>
            <NavLink activeClassName="active" to="/dividend">
              <i className="icon tsp"></i> <span>Dividend</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/referral">
              <i className="icon referral"></i> <span>Referral</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/tronsweep-token">
              <i className="icon buy_tsp"></i> <span>Buy TSP</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/dapp/stake/lotto">
              <i className="icon stake"></i> <span>Stake & Earn</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/faq">
              <i className="icon faq"></i> <span>FAQ</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/learn">
              <i className="icon tutorials"></i> <span>Tutorials</span>
            </NavLink>
          </li> */}
          {/* <li>
            <Link to="/">
              <i className="icon buy_energy"></i> <span>Buy Energy</span>
            </Link>
          </li> */}
          {/* <li>
            <Link to="/">
              <i className="icon live_support"></i> <span>Live Support</span>
            </Link>
          </li> */}
        </ul>
      </div>

      <Modal
        show={referralModalOpen}
        onHide={referralCloseModalHandler}
        dialogClassName=" "
        className="referral_modal "
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {' '}
            Get rewarded just by referring your friends!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Referral
            accountAddress={accountAddress}
            generatedReferalCode={generateReferralCode}
            afterGeneratedRefCode={afterGeneratedRefCode}
            onClick={generateRefferal}
            changed={handleChange}
          />
        </Modal.Body>
      </Modal>
      <ToastsContainer store={ToastsStore} />
    </>
  )
}

export default Sidebar
