/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React from 'react'

export const AppContext = React.createContext()

export class AppProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAccountVisible: true,
      isGamingHistoryVisible: false,
      isReferralHistoryVisible: false,
      isDealerReferralHistoryVisible: false,
      isTronsweepHistoryVisible: false,
      isCashbackVisible: false,
      isDailyTaskVisible: false,
      isRankVisible: false,
      isDividendVisible: false,
      accountAddress: '',
      dividend: 0,
      totalMyticket: 0,
      gameWalt: 0,
      tspWalt: 0,
      level: 0,
      stage: 0,
      dividendtimer: 0,
      currentdifficulty: null,
      sweepToken: 0,
      tspsweepToken: 0,
      DistributionTime: 0,
      totalmineyet: 0,
      totalfrozen: 0,
      Pot1: 0,
      Pot2: 0,
      Pot3: 0,
      Pot4: 0,
      Pot5: 0,
      Pot6: 0,
      frezeeamount: 0,
      tspfrezeeamount: 0,

      odometerValue: 0,
      isIcoVisible: true,
      boxshare1: 0,
      boxshare2: 0,
      boxshare3: 0,
      boxshare4: 0,
      boxshare5: 0,
      boxshare6: 0,
      isDataLoaded: false,
      unfreezed: false,
      unfreezeclick: false,
      unfreezeTimmer: '',
      disabled: false,
      unfreezeTime: '',
      current_bar: 0,
      toFreezAmount: 0,
      toUnFreezAmount: 0,
      tspTotalFrozen: 0,
      tspholdershare: 0,
      tspDividend: 0,
      tspReceive: 0,
      tspTotalmineyet: 0,
      tspUnfreezeclick: false,
      tspUnfreezeTimmer: '',
      tspDisabled: false,
      tspUnfreezeTime: '',
      updatedSweepToken: 0,
      testing: 'this is just for testing',
      innerFullLoad: true,
      accountBalance: 0,
      buyTicket: 0,
      isDealer: false,
      unfreezedice: 0,
      receive: 0,
      isWinningVisible: false,
      winningBoxData: [],
      allNotifications: [],
      isNotificationAvailable: false,
      notificationCounter: 0,
      isLottoDividendOpenContext: false,
      tspModalOpen: false,
      lottoModalOpen: false,
    }
  }

  lottoModalOpenHandler = () => {
    this.setState({
      lottoModalOpen: true,
    })
  }

  lottoModalCloseHandler = () => {
    this.setState({
      lottoModalOpen: false,
    })
  }

  tspModalOpenHandler = () => {
    this.setState({
      tspModalOpen: true,
    })
  }

  tspModalCloseHandler = () => {
    this.setState({
      tspModalOpen: false,
    })
  }

  setAllNotifications = (data) => {
    this.setState({ allNotifications: data })
  }

  handleOpenLottoDividend = () => {
    this.setState({ isLottoDividendOpenContext: true })
  }

  // this function is for profile navigations
  setVisibleState = (
    flagAccount,
    flagDividend,
    flagReferralHistory,
    flagTronsweepToken,
    gameWalletBalance,
  ) => {
    // if (flagDealerReferralHistory) {
    //   this.setState({
    //     isAccountVisible: flagAccount,
    //     isReferralHistoryVisible: flagReferralHistory,
    //     isDealerReferralHistoryVisible: true,
    //     isDividendVisible: flagDividend,
    //     isTronsweepHistoryVisible: flagTronsweepToken,
    //   });
    // } else {
    //   this.setState({
    //     isAccountVisible: flagAccount,
    //     isReferralHistoryVisible: flagReferralHistory,
    //     isDividendVisible: flagDividend,
    //     isTronsweepHistoryVisible: flagTronsweepToken,
    //   });
    // }
    this.setState({
      isAccountVisible: flagAccount,
      isReferralHistoryVisible: flagReferralHistory,
      isDividendVisible: flagDividend,
      isTronsweepHistoryVisible: flagTronsweepToken,
      gameWalt: +gameWalletBalance,
    })
  }

  increaseNotifications = () => {
    this.setState({ notificationCounter: this.state.notificationCounter + 1 })
  }

  setIsNotificationAvailable = (value) => {
    this.setState({ isNotificationAvailable: value })
  }

  setDealerVisible = (
    flagAccount,
    flagDividend,
    flagDealerReferral,
    flagTronsweepToken,
    gameWalletBalance,
  ) => {
    this.setState({
      isAccountVisible: flagAccount,
      isDividendVisible: flagDividend,
      isDealerReferralHistoryVisible: flagDealerReferral,
      isTronsweepHistoryVisible: flagTronsweepToken,
      gameWalt: +gameWalletBalance,
    })
  }

  setIcoVisible = (flag) => {
    this.setstage({
      isIcoVisible: flag,
    })
  }

  setAccountAddress = (address) => {
    this.setState({ accountAddress: address })
  }

  setPoolBal = (pollbal) => {
    this.setState({
      dividend: pollbal,
    })
  }

  setTicketPurchase = (tickets) => {
    this.setState({
      totalMyticket: tickets,
    })
  }

  setUpdateTicketPurchase = (tickets) => {
    this.setState({
      totalMyticket: tickets,
    })
  }

  setGamebalance = (gamebal) => {
    this.setState({
      gameWalt: gamebal,
    })
  }

  setUpdateGameWallet = (value) => {
    this.setState({
      gameWalt: value,
    })
  }

  setTSPbalance = (tspbal) => {
    this.setState({
      tspWalt: tspbal,
    })
  }

  setlevel = (level) => {
    this.setState({
      level,
    })
  }

  setstage = (stage) => {
    this.setState({
      stage,
    })
  }

  setdividentTimer = (time) => {
    this.setState({
      dividendtimer: time,
    })
  }

  setdifficulty = (difficulty) => {
    this.setState({
      currentdifficulty: difficulty,
    })
  }

  setsweepToken = (token) => {
    this.setState({
      sweepToken: token,
    })
  }

  settspsweepToken = (tsptoken) => {
    this.setState({
      tspsweepToken: tsptoken,
    })
  }

  setprizeDistributionTime = (prizetime) => {
    this.setState({
      DistributionTime: prizetime,
    })
  }

  setTotalMine = (mine) => {
    this.setState({
      totalmineyet: mine,
    })
  }

  setTotalFrozen = (frozen) => {
    this.setState({
      totalfrozen: frozen,
    })
  }

  setprizeDistributionTime = (prizetime) => {
    this.setState({
      DistributionTime: prizetime,
    })
  }

  setboxWinnerValue = (boxWinner) => {
    for (const i in boxWinner) {
      boxWinner[i] = parseInt(boxWinner[i], 10)
      this.setState({
        Pot6: boxWinner[5],
        Pot5: boxWinner[4],
        Pot4: boxWinner[3],
        Pot3: boxWinner[2],
        Pot2: boxWinner[1],
        Pot1: boxWinner[0],
      })
    }
  }

  setUserFreezeToken = (freezed) => {
    this.setState({
      frezeeamount: freezed,
    })
  }

  setUsertspFreezeToken = (freezed) => {
    this.setState({
      tspfrezeeamount: freezed,
    })
  }

  setTspTotalFrozen = (token) => {
    this.setState({
      tspTotalFrozen: token,
    })
  }

  setTspHolderShare = (share) => {
    this.setState({
      tspholdershare: share,
    })
  }

  setTsptspDividend = (dividend) => {
    this.setState({
      tspDividend: dividend,
    })
  }

  setTspRecieve = (revieve) => {
    this.setState({
      tspReceive: revieve,
    })
  }

  setTspTotalmineyet = (token) => {
    this.setState({
      tspTotalmineyet: token,
    })
  }

  setTspUnfreezeReqData = (data) => {
    this.setState({
      tspUnfreezeclick: data.data.status,
      tspUnfreezeTimmer: data.data.unfreezeTimmer,
      tspDisabled: data.data.status,
      tspUnfreezeTime: data.data.created_at,
    })
  }

  setTicketIssued = (totalticket) => {
    this.setState({
      odometerValue: totalticket,
    })
  }

  setDataLoaded = (flag) => {
    this.setState({
      isDataLoaded: flag,
    })
  }

  setboxShareValue = (boxShare) => {
    for (const i in boxShare) {
      boxShare[i] = parseInt(boxShare[i], 10)
    }
    this.setState({
      boxshare6: boxShare[5],
      boxshare5: boxShare[4],
      boxshare4: boxShare[3],
      boxshare3: boxShare[2],
      boxshare2: boxShare[1],
      boxshare1: boxShare[0],
    })
  }

  setUpdateBoxShareValue = (boxShare) => {
    for (const i in boxShare) {
      boxShare[i] = parseInt(boxShare[i], 10)
    }
    this.setState({
      boxshare6: boxShare[5],
      boxshare5: boxShare[4],
      boxshare4: boxShare[3],
      boxshare3: boxShare[2],
      boxshare2: boxShare[1],
      boxshare1: boxShare[0],
    })
  }

  setUnfreeze = () => {
    this.setState({
      unfreezed: true,
    })
  }

  setUnfreezeData = (data) => {
    this.setState({
      unfreezeclick: data.data.status,
      unfreezeTimmer: data.data.unfreezeTimmer,
      disabled: data.data.status,
      unfreezeTime: data.data.created_at,
    })
  }

  setCurrentBar = (value) => {
    this.setState({
      current_bar: value,
    })
  }

  freezeSweepTokenData = (data) => {
    this.setState({
      toFreezAmount: data,
    })
  }

  unFreezeSweepTokenData = (data) => {
    this.setState({
      toUnFreezAmount: data,
    })
  }

  setUpdatedSweepToken = (token) => {
    this.setState({
      sweepToken: token,
    })
  }

  setUpdatedFrezeeAmount = (token) => {
    this.setState({
      frezeeamount: token,
    })
  }

  setUpdateTspSweepToken = (token) => {
    this.setState({
      tspsweepToken: token,
    })
  }

  setUpdateTspFrezeeamount = (token) => {
    this.setState({
      tspfrezeeamount: token,
    })
  }

  setUpdateUnfreezeData = (data) => {
    this.setState({
      unfreezeclick: data.data.status,
      unfreezeTimmer: data.data.unfreezeTimmer,
      disabled: data.data.status,
      unfreezeTime: data.data.created_at,
    })
  }

  setUpdateUnfreezeFlag = (flag) => {
    this.setState({
      unfreezeclick: flag,
    })
  }

  setUpdateTSPUnfreezeFlag = (flag) => {
    this.setState({
      tspUnfreezeclick: flag,
    })
  }

  setTestingCheck = (data) => {
    this.setState({
      testing: data,
    })
  }

  setInnerFullLoad = (flag) => {
    this.setState({
      innerFullLoad: flag,
    })
  }

  setAccountBalance = (amount) => {
    this.setState({
      accountBalance: amount,
    })
  }

  setBuyTicket = (tickets) => {
    this.setState({
      buyTicket: tickets,
    })
  }

  setIsDealer = (dealer) => {
    this.setState({
      isDealer: dealer,
    })
  }

  setUnfreezeDice = (amount) => {
    this.setState({
      unfreezedice: amount,
    })
  }

  setLottoModalCloseHandler = (flag) => {
    this.setState({
      lottoModalOpen: flag,
    })
  }

  setReceive = (amount) => {
    this.setState({
      receive: amount,
    })
  }

  setWinningBoxData = (flag, data) => {
    this.setState({
      isWinningVisible: flag,
      winningBoxData: data,
    })
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setTestingCheck: this.setTestingCheck,
          setVisibleState: this.setVisibleState,
          setAccountAddress: this.setAccountAddress,
          setPoolBal: this.setPoolBal,
          setTicketPurchase: this.setTicketPurchase,
          setGamebalance: this.setGamebalance,
          setTSPbalance: this.setTSPbalance,
          setlevel: this.setlevel,
          setstage: this.setstage,
          setdividentTimer: this.setdividentTimer,
          setdifficulty: this.setdifficulty,
          setsweepToken: this.setsweepToken,
          setUserFreezeToken: this.setUserFreezeToken,
          setTotalMine: this.setTotalMine,
          setTotalFrozen: this.setTotalFrozen,
          setprizeDistributionTime: this.setprizeDistributionTime,
          setboxWinnerValue: this.setboxWinnerValue,
          setIcoVisible: this.setIcoVisible,
          setTicketIssued: this.setTicketIssued,
          settspsweepToken: this.settspsweepToken,
          setUsertspFreezeToken: this.setUsertspFreezeToken,
          setboxShareValue: this.setboxShareValue,
          setDataLoaded: this.setDataLoaded,
          setUnfreezeData: this.setUnfreezeData,
          freezeSweepTokenData: this.freezeSweepTokenData,
          unFreezeSweepTokenData: this.unFreezeSweepTokenData,
          setTspTotalFrozen: this.setTspTotalFrozen,
          setTspHolderShare: this.setTspHolderShare,
          setTsptspDividend: this.setTsptspDividend,
          setTspUnfreezeReqData: this.setTspUnfreezeReqData,
          setCurrentBar: this.setCurrentBar,
          setTspRecieve: this.setTspRecieve,
          setDealerVisible: this.setDealerVisible,
          setUpdatedSweepToken: this.setUpdatedSweepToken,
          setUpdatedFrezeeAmount: this.setUpdatedFrezeeAmount,
          setUpdateTspSweepToken: this.setUpdateTspSweepToken,
          setUpdateTspFrezeeamount: this.setUpdateTspFrezeeamount,
          setUpdateGameWallet: this.setUpdateGameWallet,
          setUpdateUnfreezeData: this.setUpdateUnfreezeData,
          setUpdateBoxShareValue: this.setUpdateBoxShareValue,
          setUpdateTicketPurchase: this.setUpdateTicketPurchase,
          setInnerFullLoad: this.setInnerFullLoad,
          setAccountBalance: this.setAccountBalance,
          setBuyTicket: this.setBuyTicket,
          setIsDealer: this.setIsDealer,
          setUpdateUnfreezeFlag: this.setUpdateUnfreezeFlag,
          setUnfreezeDice: this.setUnfreezeDice,
          setUpdateTSPUnfreezeFlag: this.setUpdateTSPUnfreezeFlag,
          setLottoModalCloseHandler: this.setLottoModalCloseHandler,
          setReceive: this.setReceive,
          setWinningBoxData: this.setWinningBoxData,
          setTspTotalmineyet: this.setTspTotalmineyet,
          setAllNotifications: this.setAllNotifications,
          setIsNotificationAvailable: this.setIsNotificationAvailable,
          increaseNotifications: this.increaseNotifications,
          handleOpenLottoDividend: this.handleOpenLottoDividend,
          tspModalOpenHandler: this.tspModalOpenHandler,
          tspModalCloseHandler: this.tspModalCloseHandler,
          lottoModalOpenHandler: this.lottoModalOpenHandler,
          lottoModalCloseHandler: this.lottoModalCloseHandler,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    )
  }
}
