/* eslint-disable react/require-default-props */
import React, { useState, useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

// import lotteryLight from "../public/images/icons/tickets_light.svg";
import axios from 'axios'
import smartTruncate from 'smart-truncate'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import { Modal, InputGroup, Form, Tabs, Tab } from 'react-bootstrap'
import logo from '../../assets/img/tronLogo.png'
import logoMobile from '../../assets/img/logo_mobile.png'
import userDP from '../public/images/icons/avatar.svg'
import Referral from '../referral'
import LoginPopup from '../login'
import './navbar.css'
import TSPdividend from '../pages/TSPdividend'
import config from '../../config.json'
import Utils from '../utils/index'
import { contractAddress } from '../utils/ContractAddress'

import FireBase from '../../firebase'
import { useAccount } from '../context/AccountProvider'

// import tokencontractAddress from "../../tokencontractAddress.js";

import { AppContext } from '../context'
import { useLottery } from '../context/LotteryProvider'
import { useTSPToken } from '../context/TSPProvider'
import { TOKENGOODIES_API_KEY } from '../utils/keys'
import EnergyBuy from '../lottery/components/EnergyBuy'
import { Button } from 'react-bootstrap'
import { useSidebarToggle } from '../context/SidebarToggleProvider'
import ModalComponent from '../components/Modal'
import Login from '../components/Auth/Login'
import Signup from '../components/Auth/Signup'
import Wallet from './Wallet'
import { getTokenUsd, getUserBalance } from './Service'
import SelectInput from '../components/SelectInput'
import isEmpty from 'is-empty'
import { initiateSocketForUser, subscribeToUserBalance } from '../helper/socket'

const Navbar = (props) => {
  const { username, onLoginTronWallet } = props
  const context = useContext(AppContext)
  const {
    setAllNotifications,
    setIsNotificationAvailable,
    increaseNotifications,
    isIcoVisible,
    isNotificationAvailable,
    notificationCounter,
    tspModalOpenHandler,
    tspModalCloseHandler,
    tspModalOpen,
  } = context
  const {
    accountAddress,
    accountBalance,
    refferalCode,
    isUserNotloggedIn,
    totalTRX,
    resultTimer,
    onSubmitWhlBalance,
    onSubmitUserBalanceData,
    setTotalTRX,
    setTokenInUsd,
    selectedToken,
    onSetSelectedToken
  } = useAccount()

  const { lottoTokens } = useLottery()
  const { tspToken } = useTSPToken()
  const { setIsOpen, isOpen } = useSidebarToggle()
  const [afterGeneratedRefCode, setAfterGeneratedRefCode] = useState('')
  const [generateReferralCode, setGenerateReferralCode] = useState('')

  const [referralModalOpen, setReferralModalOpen] = useState(false)
  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [showEnergyModal, setShowEnergyModal] = useState(false)
  const [creatOrderValues, setCreatOrderValues] = useState({})
  const [amountAdded, setAmountAdded] = useState('')

  const [balanceOptions, setBalanceOptions] = useState([
    {
      value: 'CRD',
      text: '0.000000',
      icon: <i className="icon cred_icon"></i>,
    },
    {
      value: 'TRX',
      text: '0.000000',
      icon: <i className="icon tron_red"></i>,
    },
    {
      value: 'PLAY',
      text: '0.000000',
      icon: <i className="icon play"></i>,
    },
    {
      value: 'LOTTO',
      text: lottoTokens.toFixed(6),
      icon: <i className="icon lotto_logo"></i>,
    },
    {
      value: 'MTW',
      text: '0.000000',
      icon: <i className="icon mtw"></i>,
    },
    {
      value: 'WHL',
      text: '0.000000',
      icon: <i className="icon mtw"></i>,
    }
  ])
  const [selectedOption, setSelectedOption] = useState(balanceOptions[0])

  const [loginShow, setLoginShow] = useState(false)
  const [key, setKey] = useState('login')
  const [walletModalShow, setWalletModalShow] = useState(false)

  const handleCloseWalletModal = () => setWalletModalShow(false)
  const handleOpenWalletModal = () => setWalletModalShow(true)

  const handleCloseLogin = () => setLoginShow(false)
  const handleOpenLogin = () => setLoginShow(true)

  const loginModalOpenHandler = () => setLoginModalOpen(true)
  const loginModalCloseHandler = () => setLoginModalOpen(false)

  const referralOpenModalHandler = () => setReferralModalOpen(true)
  const referralCloseModalHandler = () => setReferralModalOpen(false)

  const handleCloseEnergyModal = () => setShowEnergyModal(false)
  const handleOpenEnergyModal = () => {
    setShowEnergyModal(true)
    CreateAPIOrder()
  }

  const getNotificationsFirebase = () => {
    var collection = FireBase.firestore().collection('notifications')
    var query = collection.where('publicAddrerss', '==', accountAddress)

    query.onSnapshot((querySnapshot) => {
      const items = []
      querySnapshot.forEach((doc) => {
        items.push(doc.data())
      })
      setAllNotifications(items)
      items.forEach((item) => {
        if (!item.read) {
          increaseNotifications()
          setIsNotificationAvailable(true)
        }
      })
    })
  }

  const getNotifications = () => {
    axios
      .get(`tron/user/notification/${accountAddress}`)
      .then((res) => {
        let items = res.data.data
        setAllNotifications(items)
        items.forEach((item) => {
          if (!item.read) {
            increaseNotifications()
            setIsNotificationAvailable(true)
          }
        })
      })
      .catch(() => {
        // console.log(res, 'unfreezeRes')
      })
  }

  const getgeneratedReferalCode = async (code = null) => {
    try {
      let refTemp = refferalCode
      if (code) {
        refTemp = code
      }
      const refC = `${config.production.domain}?ref=${refTemp}`
      setAfterGeneratedRefCode(refC)
      setGenerateReferralCode(refC)
    } catch (error) {
      console.log(error, 'Error')
    }
  }

  useEffect(() => {
    if (refferalCode) {
      getgeneratedReferalCode()
    }
  }, [refferalCode])

  const getUserBalanceHanlder = async () => {
    const res = await getUserBalance()
    // if (res.response.status !== 200) {
    //   return
    // }
    onSubmitUserBalanceData(res?.User_info || {})
    const tempData = [...balanceOptions]

    const userBalanceList = [
      ...res?.User_info?.user_balance,
      ...res?.User_info?.token_balance,
    ]

    for (const data of tempData) {
      for (const innerData of userBalanceList) {
        if (data.value === innerData.token) {
          data.text = innerData.balance.toFixed(6)
        }

        if (innerData.token === 'WHL') {
          onSubmitWhlBalance(innerData.balance)
        }
        if (innerData.token === 'TRX') {
          setTotalTRX(innerData.balance)
        }
        if (innerData.token === 'CRD') {
          setTotalTRX(innerData.balance)
        }
      }
    }
    setBalanceOptions(tempData)
  }

  useEffect(() => {
    const tempData = [...balanceOptions]
    for (const data of tempData) {
      if (data.value === 'LOTTO') {
        data.text = lottoTokens.toFixed(6)
      }
    }

    setBalanceOptions(tempData)
  }, [lottoTokens])

  const getTokenUsdHandler = async () => {
    const res = await getTokenUsd()
    if (res.data) {
      setTokenInUsd(res.data.coin_prices.find((t) => t.token === 'TRX').price)
    }
  }

  useEffect(() => {
    onSetSelectedToken(balanceOptions[0])
    getNotificationsFirebase()
    getNotifications()
    getUserBalanceHanlder()
    getTokenUsdHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event) => {
    setGenerateReferralCode(event.target.value)
  }

  const Logout = () => {
    localStorage.clear()
    window.location.reload()
  }

  const generateRefferal = async () => {
    const refCode = `?ref=${generateReferralCode.toUpperCase()}`
    if (generateReferralCode === '') {
      ToastsStore.success('Please enter your referral code')
    } else {
      try {
        const contract = await window.tronWeb.contract().at(contractAddress)
        const feePaid = await contract
          .referralFee()
          .send({ callValue: 10000000 })
        if (feePaid) {
          const res = await axios.post(`tron/user/createReferral`, {
            public_address: accountAddress,
            refCode: generateReferralCode,
          })
          if (res.data.status) {
            setAfterGeneratedRefCode(refCode)
            setGenerateReferralCode(refCode)
            ToastsStore.success(res.data.message)
            getgeneratedReferalCode(generateReferralCode.toUpperCase())
            referralCloseModalHandler()
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  // const truncate = (text, startChars, endChars, maxLength) => {
  //   if (text.length > maxLength) {
  //     const start = text.substring(0, startChars)
  //     const end = text.substring(text.length - endChars, text.length)

  //     return `${start}...${end}`
  //   }

  //   return text
  // }

  async function CreateAPIOrder() {
    try {
      var url_to_post =
        'https://www.tokengoodies.com/tronresourceexchange/exchange' // Base URL for the API

      // ==========================================================================================
      // Get contract address and other values needed to create an order

      var requestbody = new Object()
      requestbody.action = 'utils'
      requestbody.type = 'api_get_create_order_values'
      requestbody.api_key = TOKENGOODIES_API_KEY
      var create_order_values_obj = await FetchAPIData(
        url_to_post,
        requestbody,
        5000,
      )

      setCreatOrderValues(create_order_values_obj)
      // The create_order_values_obj variable is an object with the following structure.
      // { "order_fees_address": "TAeXoCEpGTeTruxwN19pc6TpTPfeAk1E5E", "min_order_amount_in_sun": 9000000, "min_energy_price_in_sun": 30, "min_bandwidth_price_in_sun": 540 }
    } catch (e) {
      console.log(e)
    }
  }

  async function FetchAPIData(url_to_post, requestbody, timeout_in_ms) {
    try {
      var result = await axios.post(url_to_post, requestbody)
      return result.data
    } catch (e) {
      console.log(e)
    }
    return null
  }

  const address = smartTruncate(accountAddress, 18, {
    position: 8,
  })

  const handleSelectChange = (e) => {
    onSetSelectedToken(e)
    setSelectedOption(e)
  }

  const updateBalances =(tempData, data)=>{
    for (const options of tempData) {
      if (options.value === 'TRX' && !isEmpty(data.user_info.user_balance.find((p) => p.token === 'TRX'))) {
        options.text = data.user_info.user_balance.find((p) => p.token === 'TRX').balance
      }
      if (options.value === 'PLAY' && !isEmpty(data.user_info.token_balance.find((p) => p.token === 'PLAY'))) {
        options.text = data.user_info.token_balance.find((p) => p.token === 'PLAY').balance.toFixed(6)
      }
      if (options.value === 'CRD' && !isEmpty(data.user_info.user_balance.find((p) => p.token === 'CRD'))) {
        options.text = data.user_info.user_balance.find((p) => p.token === 'CRD').balance
      }
    }
  }

  useEffect(() => {
    initiateSocketForUser()
    const tempData = [...balanceOptions]
    subscribeToUserBalance((err, data) => {
      let resultTime = parseInt(localStorage.getItem("resultTime"))

      if (data['amount-added']) {
        setTimeout(() => {
          console.log(data['amount-added'], "Amount to be added")
          setAmountAdded(data['amount-added'])
          setTimeout(() => {
            setAmountAdded('')
          }, 500)
          
          updateBalances(tempData, data)
          setBalanceOptions(tempData)
        }, resultTime)

      } else {
        updateBalances(tempData, data)
        setBalanceOptions(tempData)
      }
    })
  }, [])

  return (
    <>
      <div>
        <a className="navbar-brand mobile_nav_logo" href="/">
          <img src={logo} alt="" style={{ height: 60 }} />
        </a>
        <nav className="navbar navbar-expand-lg fixed-top navbar-dark">
          <div className="navbar-left">
            {!isOpen && (
              <div className="games_toggle">
                <ul>
                  <li className="active">
                    <a href="/">
                      <span>
                        <i className="icon casino"></i>Casino
                      </span>
                    </a>
                  </li>
                  <li>
                    <div className="lock">
                      <i className="icon lock"></i>
                    </div>
                    <span>
                      <i className="icon sports"></i>Sports
                    </span>
                  </li>
                </ul>
              </div>
            )}

            <span
              className="sidebar_toggle_btn"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <i className={`icon ${isOpen ? 'toggle_right' : 'toggle'}`}></i>
            </span>
            <a className="navbar-brand" href="/">
              <img
                className="web_only"
                src={logo}
                alt=""
                style={{ height: 47 }}
              />
              <img
                className="mobile_only"
                src={logoMobile}
                alt=""
                style={{ height: 60 }}
              />
            </a>
            <div className="hex-button d-none d-lg-block d-xl-block web_only">
              <div className="navbar_winning_wrap ">
                <div>
                  <h5>
                    Congratulations,{' '}
                    <span className="name_text">OLMQ806SDBO</span>
                    🥳🎉
                  </h5>
                  <p>for winning 300,000 TRX on Ticket No. 98315</p>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar_right">
            <ul className="navbar-nav nav_right" data-tut="step-1" h="1">
              {accountAddress || !isEmpty(localStorage.getItem('token')) ? (
                <>
                  <li>
                    <InputGroup>
                      {/* <InputGroup.Text id="basic-addon1">
                        <i className="icon tron_red"></i>
                      </InputGroup.Text> */}
                      {/* <Form.Control value={totalTRX.toFixed(6)} /> */}
                      {amountAdded && (
                        <span className="amount_added">
                          {amountAdded?.toFixed(6)}
                        </span>
                      )}

                      <SelectInput
                        selectedOption={selectedOption}
                        handleChange={handleSelectChange}
                        data={balanceOptions}
                      />
                      <InputGroup.Text
                        id="basic-addon1"
                        onClick={handleOpenWalletModal}
                      >
                        <i className="icon-md wallet"></i>
                      </InputGroup.Text>
                    </InputGroup>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle dd_btn"
                      href="#!"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img className="avatar" src={userDP} alt="" />
                    </a>
                    <div
                      className="dropdown-menu animation slideDownIn"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <div className="wrap_one">
                        <a className="dropdown-item w_address" href="#!">
                          {username.toUpperCase()}
                          <br />
                          {address}
                        </a>

                        <a className="dropdown-item" href="#!">
                          <div className="two_col_row">
                            <div className="col_left">
                              <p>TSP</p>
                              <strong>{(tspToken / 10 ** 8).toFixed(2)}</strong>
                            </div>
                            <div className="col_right">
                              <p>LOTTO</p>
                              <strong>
                                {' '}
                                {(Math.round(lottoTokens * 100) / 100).toFixed(
                                  2,
                                )}
                              </strong>
                            </div>
                          </div>
                        </a>
                      </div>
                      <NavLink className="dropdown-item " to="/dividend">
                        Dividend
                      </NavLink>
                      <a
                        className="dropdown-item "
                        to="#!"
                        onClick={referralOpenModalHandler}
                      >
                        Referral
                      </a>
                      <NavLink className="dropdown-item " to="/tronsweep-token">
                        Buy TSP Token
                      </NavLink>
                      {/* <NavLink className="dropdown-item " to="/account-activity">
                        Account Activity
                      </NavLink> */}
                      <NavLink className="dropdown-item " to="/account-statement">
                        Account Statement
                      </NavLink>
                      <NavLink className="dropdown-item " to="/change-password">
                        Change Password
                      </NavLink>
                      <NavLink className="dropdown-item " to="/account">
                        My Account
                      </NavLink>
                      <NavLink
                        className="nphv dropdown-item"
                        to="/account"
                        onClick={Logout}
                      >
                        <span className="vp">Log Out</span>
                      </NavLink>
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Button variant="secondary" onClick={handleOpenLogin}>
                      Sign up
                    </Button>
                  </li>
                  <li>
                    <Button variant="primary" onClick={handleOpenLogin}>
                      Login
                    </Button>
                  </li>

                  {/* <li className="nav-item">
                  <a
                    href="#!"
                    onClick={loginModalOpenHandler}
                    className="nav-link btn btn-outline-success login_btn"
                  >
                    Login
                  </a>
                </li> */}
                </>
              )}
              {/* <li className="chat_li">
                <i className="icon-md chat"></i>
              </li> */}
            </ul>
          </div>
        </nav>

        <Modal
          show={referralModalOpen}
          onHide={referralCloseModalHandler}
          dialogClassName=" "
          className="referral_modal "
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {' '}
              Get rewarded just by referring your friends!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Referral
              accountAddress={accountAddress}
              generatedReferalCode={generateReferralCode}
              afterGeneratedRefCode={afterGeneratedRefCode}
              onClick={generateRefferal}
              changed={handleChange}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={tspModalOpen}
          onHide={tspModalCloseHandler}
          dialogClassName="dividend_modal "
        >
          <Modal.Header closeButton>
            <Modal.Title>TSP Dividend</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TSPdividend />
          </Modal.Body>
        </Modal>

        <Modal
          show={loginModalOpen}
          onHide={loginModalCloseHandler}
          dialogClassName="referral_modal  "
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>TSP Dividend</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <LoginPopup onClose={loginModalCloseHandler} />
          </Modal.Body>
        </Modal>

        <div
          className="modal fade fairness_modal"
          id="fairnessModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Fairness
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  The winning numbers are random and not known in advance.
                  Random Number Generator based on TRON hash block and operated
                  by smart contract in TRON.network You can use Player&apos;s
                  Address and Block Number to check winning numbers.
                </p>
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="content goes here..."
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="content goes here..."
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="content goes here..."
                    />
                  </div>
                  <div className="form-group">
                    <button type="button" className="btn btn-outline-primary">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore} />
      </div>

      <Modal
        show={showEnergyModal}
        onHide={handleCloseEnergyModal}
        className="energy_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Buy Energy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EnergyBuy
            creatOrderValues={creatOrderValues}
            handleCloseEnergyModal={handleCloseEnergyModal}
          />
        </Modal.Body>
      </Modal>

      <ModalComponent
        className="auth_modal"
        show={loginShow}
        handleClose={handleCloseLogin}
      >
        <div className="login_wrap">
          <div className="banner_wrap"></div>
          <div className="login_form">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="custom_tabs"
            >
              <Tab eventKey="login" title="Login">
                <Login
                  onClose={handleCloseLogin}
                  loginWithTronWallet={onLoginTronWallet}
                />
              </Tab>
              <Tab eventKey="signup" title="Signup">
                <Signup onClose={handleCloseLogin} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        className="wallet_modal"
        show={walletModalShow}
        handleClose={handleCloseWalletModal}
      >
        <Wallet />
      </ModalComponent>
    </>
  )
}

export default Navbar
