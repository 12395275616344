import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useAccount } from '../../context/AccountProvider'
import useQuery from '../../hooks/useQuery'
import { ascii_to_hex } from '../../utils/functions'
import Web3 from 'web3'
import CustomInput from '../Input'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import { userLoginWallet, getNonce, userEmailSignup } from './Service'


let web3 = undefined // Will hold the web3 instance
const Signup = ({ onClose }) => {
  const query = useQuery()
  const { onSubmitWalletAddress, onSubmitAccountAddress } = useAccount()

  const [accountAddress, setAccountAddress] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [referalCode, setReferalCode] = useState('')

  const getBalance = async () => {
    //if wallet installed and logged , getting TRX token balance
    if (window.tronWeb && window.tronWeb.ready) {
      let walletBalances = await window.tronWeb.trx.getAccount(
        window.tronWeb.defaultAddress.base58,
      )
      return walletBalances
    } else {
      return 0
    }
  }

  const getWalletDetails = async () => {
    if (window.tronWeb) {
      //checking if wallet injected
      if (window.tronWeb.ready) {
        let tempBalance = await getBalance()
        //  let tempFrozenBalance = 0

        if (!tempBalance.balance) {
          tempBalance.balance = 0
        }

        //we have wallet and we are logged in
        window.consoleClearHandler('WALLET CONNECTED')

        setAccountAddress(window.tronWeb.defaultAddress.base58)
      } else {
      }
    } else {
      window.consoleClearHandler('WALLET NOT DETECTED')
    }
  }

  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     getWalletDetails()
  //     //wallet checking interval 2sec
  //   }, 2000)
  //   return () => {
  //     clearInterval(interval)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    getWalletDetails()
  }, [])



  const handleSignMessageTronlink = (accountAddress) => {
    return new Promise(async (resolve, reject) => {
      try {
        const payload = {
          public_address: accountAddress,
        }
        let nonce = await getNonce(payload);
        let text = `I am signing my one-time nonce: ${nonce.nonce}`;
        const sign = window.tronWeb.trx.sign(ascii_to_hex(text));
        let data = {
          public_address: accountAddress,
          signature: "",
        };

        Promise.resolve(sign).then(
          function (value) {
            data.signature = value;
            axios
              .post(process.env.REACT_APP_AUTH + `/user/login-tron`, data)
              .then((res) => {
                resolve(res.data.data);
                if (res.data.status == 200) {
                  onSubmitAccountAddress(accountAddress)
                  localStorage.setItem('loginType', 'tronlink')
                  localStorage.setItem('token', res.data.data)
                  ToastsStore.success('Signed up Successfully')
                  onClose()
                }
              })
              .catch((err) => {
                reject(err);
              });
          },
          function (value) {
            return;
          }
        );
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleConnect = async () => {
    // Check if MetaMask is installed
    if (!window.ethereum) {
      window.alert('Please install MetaMask first.')
      return
    }

    if (!web3) {
      try {
        // Request account access if needed
        await window.ethereum.enable()

        // We don't know window.web3 version, so we use our own instance of Web3
        // with the injected provider given by MetaMask
        web3 = new Web3(window.ethereum)
      } catch (error) {
        window.alert('You need to allow MetaMask.')
        return
      }
    }

    const coinbase = await web3.eth.getCoinbase()
    if (!coinbase) {
      window.alert('Please activate MetaMask first.')
      return
    }

    onSubmitWalletAddress(coinbase)
    onClose()

    const publicAddress = coinbase.toLowerCase()
    handleSignMessageMetaMask(publicAddress)
    // getUserDetailsFromPublicAddressHandler(publicAddress);

    // Look if user with current publicAddress is already present on backend
  }
  const handleSignMessageMetaMask = async (publicAddress) => {
    try {
      const payload = {
        public_address: publicAddress,
      }
      const noncedata = await getNonce(payload)
      console.log(noncedata.nonce, 'noncedata')

      const signature = await web3.eth.personal.sign(
        `I am signing my one-time nonce: ${noncedata.nonce}`,
        publicAddress,
      )
      onLoggedIn(publicAddress, signature)
    } catch (err) {
      throw new Error('You need to sign the message to be able to log in.')
    }
  }

  const onLoggedIn = async (publicAddress, signature) => {
    const res = await userLoginWallet(publicAddress, signature)

    if (res.status === 200) {
      localStorage.setItem('token', res.data)
      onSubmitAccountAddress(publicAddress)
      ToastsStore.success('Signed up Successfully')
    }
  }

  const userEmailSignupHandler = async () => {
    let payload = {
      email,
      password,
      referalCode,
    }
    const res = await userEmailSignup(payload)

    console.log(res)

  }


  return (
    <>
      <div className="form_body">
        <form>
          <CustomInput
            type="text"
            iconLeft="email"
            placeholder="Enter Your Email"
            onChange={(e)=>setEmail(e.target.value)}
          />
          <CustomInput
            iconLeft="password_lock"
            placeholder="Enter Your Password"
            type="password"
            onChange={(e)=>setPassword(e.target.value)}
          />
          <CustomInput
            type="text"
            placeholder="Promo code (optional)"
            iconLeft="promo_code"
            onChange={(e)=>setReferalCode(e.target.value)}
          />
          <Form.Check
            type="checkbox"
            label="I confirm that I am 18 year old and I have read the the terms of services."
          />
          <Button variant="primary" className="primary w-100" onClick={userEmailSignupHandler}>
            REGISTER
          </Button>
        </form>

        <div className="other_login_box">
          <p className="or_login_text">or Sign up with</p>
          <ul>
            {/* <li>
              <span>
                <i className="icon google"></i>
              </span>
            </li> */}
            <li>
              <span
                onClick={() => {
                  handleSignMessageTronlink()
                }}
              >
                <i className="icon trx"></i>
              </span>
            </li>
            <li>
              <span
                onClick={() => {
                  handleConnect()
                }}
              >
                <i className="icon metamask"></i>
              </span>
            </li>
          </ul>

          <p className="have_account_text">
            Do you have an account <a href="">Login</a>
          </p>
        </div>
      </div>
      <ToastsContainer store={ToastsStore} />
    </>
  )
}

export default Signup
