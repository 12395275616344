/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unused-state */
/* eslint-disable camelcase */
import React, { useContext, useState, useEffect, useCallback } from 'react'
import TronWeb from 'tronweb'
import axios from 'axios'
import StickyBox from 'react-sticky-box'
import './lottery.css'
import Odometer from 'react-odometerjs'
import smartTruncate from 'smart-truncate'
import 'odometer/themes/odometer-theme-default.css'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import Modal from 'react-bootstrap/Modal'
import Countdown, { zeroPad } from 'react-countdown-now'
import TruncateMiddle from 'react-truncate-string'
import TopWaggersIcon from '../../public/images/icons/topwaggers.svg'
import WinnerIcon from '../../public/images/icons/winner.svg'
import moment from 'moment'
import { AppContext } from '../../context/'
import priceDistibutionIcon from '../../public/images/icons/distribution.svg'
import { AiFillThunderbolt } from 'react-icons/ai'

import Tron from '../../public/images/icons/tron.png'
import lottery from '../../public/images/icons/tickets_dark.svg'
// import config from '../../../config.json'
import {
  tokencontractAddress,
  contractAddress,
} from '../../utils/ContractAddress'

import Utils from '../../utils/index'
import WinningNumbers from '../WinningNumbers'
import ticketPrice from '../../utils/ticketPrice'
import LottoRules from './LottoRules'
import SidebarLottery from './sidebar-lottery'
import LottoDividend from './lottoDividend'
import LotteryHistory from './LotteryHistory'
import TopWager from './TopWager'
import { useLottery } from '../../context/LotteryProvider'
import { useAccount } from '../../context/AccountProvider'
import { useWinningBox } from '../../context/WinningBoxProvider'
import WinningBox from './winning_box'
import { Alert, Button, Form, Nav, Tab, Table, Tabs } from 'react-bootstrap'
import isEmpty from 'is-empty'
import { TOKENGOODIES_API_KEY } from '../../utils/keys'
import EnergyBuy from './EnergyBuy'
// import WinningSlider from "../WinningNumbers";
let contract
const levelToken = 100000

const tomorrow = moment(new Date()).add(2, 'days')
const timerTimestamp = new Date(tomorrow).getTime()

function Lottery(props) {
  const { username } = props
  const context = useContext(AppContext)
  const {
    onSubmitLottoTokens,
    onSubmitFreezeLottoTokens,
    freezeLottoTokens,
    onSubmitRecieveBalance,
    onSubmitTotalFrozenBalance,
    totalFrozenBalance,
  } = useLottery()
  const {
    accountAddress,
    isUserNotloggedIn,
    onSubmitWalletBalance,
    walletBalance,
  } = useAccount()
  const {
    onSubmitWinningBoxOpen,
    isWinningBoxOpen,
    onSubmitWinningBoxData,
    winningBoxData,
  } = useWinningBox()
  const {
    setUpdateUnfreezeFlag,
    setUpdateTicketPurchase,

    gameWalt,
    setWinningBoxData,
    setTotalFrozen,
    setUserFreezeToken,
    setUpdateGameWallet,
    setUpdatedSweepToken,
    buyTicket,
    setReceive,

    setUnfreezeDice,
    isDealer,
    totalfrozen,
    receive,

    lottoModalOpen,
    lottoModalOpenHandler,
    lottoModalCloseHandler,
  } = context
  const [minTicket, setMinTicket] = useState(0)

  const [dividend, setDividend] = useState(0)
  const [level, setLevel] = useState(0)
  const [stage, setStage] = useState(0)
  const [sweepToken, setSweepToken] = useState(0)
  const [totalmineyet, setTspTotalmineyet] = useState(0)

  const [pots, setPots] = useState({
    Pot1: 0,
    Pot2: 0,
    Pot3: 0,
    Pot4: 0,
    Pot5: 0,
    Pot6: 0,
  })
  const [odometerValue, setOdometerValue] = useState(0)
  const [dividendtimer, setDividendtimer] = useState(0)
  const [currentdifficulty, setCurrentdifficulty] = useState(0)
  const [isWagerTableVisible, setIsWagerTableVisible] = useState(false)
  const [mybought, setMyBought] = useState(0)
  const [topWager, setTopWager] = useState([])
  const [loading, setLoading] = useState(false)
  const [isTopwagersLoading, setIsTopwagersLoading] = useState(false)

  const [DistributionTime, setDistributionTime] = useState(0)
  const [topWagerData, setTopWagerData] = useState('')
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [boxshareValue, setboxShareValue] = useState({
    boxshare1: 0,
    boxshare2: 0,
    boxshare3: 0,
    boxshare4: 0,
    boxshare5: 0,
    boxshare6: 0,
  })
  const [topWaggersDate, setTopWaggersDate] = useState('')
  const [pastDate] = useState(new Date())
  const [isFuturVisible, setIsFuturVisible] = useState(false)
  const [disabledfreezebtn, setDisabledFreezeBtn] = useState(false)
  const [currentBar, setCurrentBar] = useState(0)

  const [unfreezeclick] = useState(false)
  const [unfreezeTime] = useState(0)
  const [unfreezediceLocal, setUnfreezeDiceLocal] = useState(0)
  const [unfreezeTimmer] = useState(0)
  const [isGameWalletLoaded, setIsGameWalletLoaded] = useState(false)
  const [isWinnerListVisible, setIsWinnerListVisible] = useState(false)
  const [afterBuyLottoToken, setAfterBuyLottoToken] = useState(0)
  const [afterBuyFlag, setAfterBuyFlag] = useState(0)
  const [isLimiteToBuyTickets, setIsLimiteToBuyTickets] = useState(false)

  const [showRuleModal, setShowRuleModal] = useState(false)
  const [showWaggerModal, setShowWaggerModal] = useState(false)
  const [showPrizeModal, setShowPrizeModal] = useState(false)
  const [disabled] = useState(false)
  const [min] = useState(0)
  const [publicData, setPublicData] = useState({})
  const [isPublicDataLoading, setIsPublicDataLoading] = useState(false)
  const [showEnergyModal, setShowEnergyModal] = useState(false)
  const [creatOrderValues, setCreatOrderValues] = useState({})

  const handleCloseWaggerModal = () => setShowWaggerModal(false)
  const handleClosePrizeModal = () => setShowPrizeModal(false)
  const handleCloseRuleModal = () => setShowRuleModal(false)

  const handleCloseEnergyModal = () => setShowEnergyModal(false)
  const handleOpenEnergyModal = () => {
    setShowEnergyModal(true)
    CreateAPIOrder()
  }

  // ticket increment methods
  const plusOne = (e) => {
    if (minTicket === '') {
      setMinTicket(0)
    }
    e.preventDefault()
    setMinTicket(+minTicket + 1)
  }

  const minusOne = (e) => {
    const minusBtn = document.getElementById('minus_btn')
    e.preventDefault()
    if (minTicket <= 1) {
      minusBtn.disabled = true
    } else {
      setMinTicket(+minTicket - 1)
      minusBtn.disabled = false
    }
  }

  const plusFive = () => {
    if (minTicket === '') {
      setMinTicket(0)
    }
    setMinTicket(+minTicket + 5)
  }

  const PlusTen = () => {
    if (minTicket === '') {
      setMinTicket(0)
    }
    setMinTicket(+minTicket + 10)
  }

  const Plusfifty = () => {
    if (minTicket === '') {
      setMinTicket(0)
    }
    setMinTicket(+minTicket + 50)
  }

  const minusFive = () => {
    if (minTicket > 5) {
      setMinTicket(+minTicket - 5)
    }
  }

  const minusTen = () => {
    if (minTicket > 10) {
      setMinTicket(+minTicket - 10)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    buyTicketHandler()
  }

  const handleTicket = (e) => {
    setMinTicket(e.target.value)
    if (e.target.value <= 10000000) {
      setMinTicket(e.target.value)
      setIsLimiteToBuyTickets(false)
    } else {
      setIsLimiteToBuyTickets(true)
    }
  }

  const setWagerVisible = () => {
    setIsTopwagersLoading(true)
    setIsWagerTableVisible(!isWagerTableVisible)
    const theDate = new Date()
    const todayFormat = theDate.toDateString()

    setTopWaggersDate(todayFormat)

    axios
      .get(`tron/data/topwagers/${Date.now()}`)
      .then((res) => {
        setTopWager(res.data.data)
        setTopWagerData(res.data.status)
        setIsTopwagersLoading(false)
      })
      .catch(() => { })
  }

  const getPastWaggers = () => {
    setIsTopwagersLoading(true)
    const prevDate = pastDate.setDate(pastDate.getDate() - 1)
    const theDate = new Date(prevDate)
    const dateString = theDate.toDateString()

    setTopWaggersDate(dateString)

    axios
      .get(`tron/data/topwagers/${prevDate}`)
      .then((res) => {
        setTopWager(res.data.data)
        setTopWagerData(res.data.status)
        setIsFuturVisible(true)
        setIsTopwagersLoading(false)
      })
      .catch(() => { })
  }
  const getFutureWaggers = () => {
    setIsTopwagersLoading(true)
    const prevDate = pastDate.setDate(pastDate.getDate() + 1)
    const theDate = new Date(prevDate)
    const dateString = theDate.toDateString()
    setTopWaggersDate(dateString)

    const currentDate = Date.now()
    const currtheDate = new Date(currentDate)
    const currentDateString = currtheDate.toDateString()

    const prevDateDate = new Date(prevDate)
    const prevDateString = prevDateDate.toDateString()

    if (currentDateString === prevDateString) {
      setIsFuturVisible(false)
    }
    axios
      .get(`tron/data/topwagers/${prevDate}`)
      .then((res) => {
        setTopWager(res.data.data)
        setTopWagerData(res.data.status)
        setIsTopwagersLoading(false)
      })
      .catch(() => { })
  }

  const setWinnerListVisible = () => {
    setIsWinnerListVisible(!isWinnerListVisible)
  }

  const cancelunfreeze = async () => {
    try {
      await Utils.tokencontract.cancelUnfreeze().send()
      ToastsStore.success('Cancel Unfreeze Successfully')
      setTimeout(() => {
        setUpdateUnfreezeFlag(false)
        freezeToken()
      }, 1000)
    } catch (error) {
      console.log(error)
    }
  }

  const chatBoxHandler = () => {
    setIsChatOpen(!isChatOpen)
  }
  useEffect(() => {
    if (isChatOpen) {
      document.body.classList.add('is-chat-open')
    } else {
      document.body.classList.remove('is-chat-open')
    }
    return () => document.body.classList.remove('is-chat-open')
  }, [isChatOpen])

  const totalmyticket = async () => {
    try {
      contract = await window.tronWeb.contract().at(contractAddress)
      const tickets = await contract.ticketBalance(accountAddress).call()
      const mypuchased = +tickets

      setMyBought(mypuchased)
      setUpdateTicketPurchase(mypuchased)
      // setTimeout(() => {
      // }, 1000)
    } catch (e) {
      console.log(e)
    }
  }

  const handleWalletTicket = () => {
    if (gameWalt > 0) {
      setMinTicket(Math.floor(+gameWalt / 100))
    }
  }

  const calcState = (level) => {
    let levelParam = level
    let stage = +Math.floor(levelParam / 10)
    levelParam %= 10
    if (levelParam === 0) {
      levelParam = 10
      stage -= 1
    }
    stage += 1

    return { stage, level }
  }

  const getWinningData = () => {
    axios
      .get(`tron/user/getWinningDataForWinningBox/${accountAddress}`)
      .then((res) => {
        // console.log(res, 'winningRes')
        const resData = res.data.winningData
        if (resData.length > 0) {
          setWinningBoxData(true, resData)
          onSubmitWinningBoxOpen(true)
          onSubmitWinningBoxData(resData)
        }
      })
      .catch(() => {
        // console.log(res, 'unfreezeRes')
      })
  }

  const fetchLevel = async () => {
    try {
      contract = await window.tronWeb.contract().at(tokencontractAddress)
      const Level = await contract.level().call()
      const levelobj = calcState(Level)

      setLevel(levelobj.level)
      setStage(levelobj.stage)
    } catch (error) {
      console.log(error)
    }
  }

  const dividendDistributionTime = async () => {
    try {
      let tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      let freezeblockTime = await tokencontract.FREEZE_TOKEN_BLOCK_TIME().call()
      freezeblockTime = +freezeblockTime
      let time = await tokencontract.dividendDistributionTime().call()
      time = +time * 1000
      let difficultylevel = await tokencontract.difficultyLevel().call()
      difficultylevel = +difficultylevel

      setDividendtimer(time)
      setCurrentdifficulty(50 + difficultylevel)

      const buttonfreezetime = time - freezeblockTime * 1000 - Date.now()

      if (buttonfreezetime < 0) {
        setDisabledFreezeBtn(true)
      } else {
        setDisabledFreezeBtn(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const totalMinedYet = async () => {
    try {
      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      const total = await tokencontract.totalTokenMinedYet().call()
      const totalmined = +total

      setTspTotalmineyet(totalmined)
    } catch (error) {
      console.log(error)
    }
  }

  const totalfrozenHandler = async () => {
    try {
      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      const totalfrozen = await tokencontract.frozenTotal().call()
      const total = +totalfrozen / 10 ** 8

      setTotalFrozen(total)
      onSubmitTotalFrozenBalance(total)
    } catch (error) {
      console.log(error)
    }
  }

  const boxWinnerValue = async () => {
    try {
      contract = await window.tronWeb.contract().at(contractAddress)
      const box6winner = contract.winnerCountBox6().call()
      const box5winner = contract.winnerCountBox5().call()
      const box4winner = contract.winnerCountBox4().call()
      const box3winner = contract.winnerCountBox3().call()
      const box2winner = contract.winnerCountBox2().call()
      const box1winner = contract.winnerCountBox1().call()
      const boxWinner = await Promise.all([
        box1winner,
        box2winner,
        box3winner,
        box4winner,
        box5winner,
        box6winner,
      ])

      // for (const i in boxWinner) {
      //   boxWinner[i] = parseInt(boxWinner[i], 10)
      //   console.log(boxWinner[i], 'indexof')
      // }

      boxWinner.forEach((element, i) => {
        boxWinner[i] = parseInt(element, 10)
      })

      setPots({
        Pot6: boxWinner[5],
        Pot5: boxWinner[4],
        Pot4: boxWinner[3],
        Pot3: boxWinner[2],
        Pot2: boxWinner[1],
        Pot1: boxWinner[0],
      })
    } catch (error) {
      console.log(error)
    }
  }

  const freezeToken = async () => {
    if (accountAddress) {
      try {
        const tokencontract = await window.tronWeb
          .contract()
          .at(tokencontractAddress)
        const freeze = await tokencontract.frozenBalance(accountAddress).call()
        const freezetoken = +freeze / 10 ** 8

        setUserFreezeToken(freezetoken)
        onSubmitFreezeLottoTokens(freezetoken)
      } catch (error) {
        console.log(error, 'error')
      }
    }
  }

  const ticketIssued = async () => {
    console.log(contractAddress, accountAddress, 'contractAddress')
    try {
      contract = await window.tronWeb.contract().at(contractAddress)
      const tickets = await contract.ticketsIssued().call()
      const ticketIssued = +tickets

      console.log(ticketIssued, 'ticketIssued')

      setOdometerValue(ticketIssued)
    } catch (error) {
      console.log(error, 'findError')
    }
  }

  const fetchDividentPoll = async () => {
    try {
      contract = await window.tronWeb.contract().at(contractAddress)
      const ticketbal = await contract.dividendPool().call()

      setDividend(TronWeb.fromSun(parseInt(ticketbal, 10)))
    } catch (error) {
      console.log(error)
    }
  }

  const prizeDistributionTime = async () => {
    try {
      contract = await window.tronWeb.contract().at(contractAddress)
      let pricetime = await contract.prizeDistributionTime().call()
      pricetime = +pricetime * 1000

      setDistributionTime(+pricetime)
    } catch (error) {
      console.log(error.response, 'errorData')
    }
  }

  const gameWaltbalance = async () => {
    try {
      contract = await window.tronWeb.contract().at(contractAddress)
      const valtBal = await contract.userVaultBalance(accountAddress).call()
      const gameWaltbal = TronWeb.fromSun(valtBal)
      setUpdateGameWallet(gameWaltbal)
      const walletBalanceTemp = Math.floor(gameWaltbal * 100) / 100
      onSubmitWalletBalance(walletBalanceTemp)

      setIsGameWalletLoaded(false)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const sweepTokenHandler = async () => {
    if (accountAddress && tokencontractAddress) {
      try {
        const tokencontract = await window.tronWeb
          .contract()
          .at(tokencontractAddress)
        const sweep = await tokencontract.balanceOf(accountAddress).call()
        const sweeptoken = +sweep / 10 ** 8

        setSweepToken(sweeptoken)
        if (sweeptoken < 1) {
          setDisabledFreezeBtn(true)
        }

        setAfterBuyFlag(true)
        setAfterBuyLottoToken(sweeptoken)
        setUpdatedSweepToken(sweeptoken)
        onSubmitLottoTokens(sweeptoken)
      } catch (error) {
        console.log(error, 'errorSweep')
      }
    }
  }

  const boxLevelShare = async () => {
    try {
      contract = await window.tronWeb.contract().at(contractAddress)
      const box6share = contract.boxLevel6Share().call()
      const box5share = contract.boxLevel5Share().call()
      const box4share = contract.boxLevel4Share().call()
      const box3share = contract.boxLevel3Share().call()
      const box2share = contract.boxLevel2Share().call()
      const box1share = contract.boxLevel1Share().call()
      const boxShare = await Promise.all([
        box1share,
        box2share,
        box3share,
        box4share,
        box5share,
        box6share,
      ])

      boxShare.forEach((element, i) => {
        boxShare[i] = parseInt(element, 10)
      })

      setboxShareValue({
        boxshare6: boxShare[5],
        boxshare5: boxShare[4],
        boxshare4: boxShare[3],
        boxshare3: boxShare[2],
        boxshare2: boxShare[1],
        boxshare1: boxShare[0],
      })
    } catch (error) {
      console.log()
    }
  }

  const logBoughtTicketInDb = () => {
    axios
      .get(`/tron/user/transactionInitiated`)
      .then(() => { })
      .catch((error) => { })
  }

  const buyTicketHandler = async () => {
    if (accountAddress) {
      const buyBtn = document.getElementById('buy_btn')
      buyBtn.disabled = true
      try {
        const ticketPrize = ticketPrice

        setLoading(true)
        const amount = minTicket * ticketPrize

        if (gameWalt < amount) {
          contract = await window.tronWeb.contract().at(contractAddress)
          await contract.buyTickets(Number(minTicket)).send({
            callValue: amount * 10 ** 6 - gameWalt * 10 ** 6,
          })
        } else {
          contract = await window.tronWeb.contract().at(contractAddress)
          await contract.buyTickets(Number(minTicket)).send({ callValue: 1 })
        }
        ToastsStore.success(`${minTicket}   Tickets Successfully Bought`)
        logBoughtTicketInDb()

        setMinTicket(10)

        setTimeout(() => {
          sweepTokenHandler()
        }, 1000)

        totalmyticket()
        gameWaltbalance()
        ticketIssued()
        getPublicLotteryDataHandler()
        // const ratio = amount / currentdifficulty
        //const transactAmount = ((ratio * 16666667) / 1000000) * 0.6
        ToastsStore.success('Lotto Token Added Successfully')

        if (!buyTicket) {
          axios
            .post(`tron/user/manageReferrals`, {
              public_address: accountAddress,
              refCode: 'admin',
            })
            .then(() => { })
            .catch((error) => {
              console.log(error)
            })
        }

        buyBtn.disabled = false

        setLoading(false)
      } catch (error) {
        ToastsStore.error('Transaction Failed')
        buyBtn.disabled = false

        setLoading(false)
      }
    } else {
      ToastsStore.error('Please login to your Tronlink wallet')
    }
  }

  // optimization
  const calCurrentbar = async () => {
    try {
      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      const levelInHex = await tokencontract.level().call()
      const totalTokenMinedInHex = tokencontract.totalTokenMinedYet().call()
      const result = await Promise.all([levelInHex, totalTokenMinedInHex])
      const level = +result[0]
      const totalTokenMined = +result[1]
      const stageObj = calcState(level)
      const completedStage = stageObj.stage - 1
      const completedLevel = stageObj.level - 1
      const previousStageTokens =
        (completedStage * 10 + completedLevel) * levelToken
      const currentToken =
        Math.round(totalTokenMined / 10 ** 8) - previousStageTokens
      const currentBar = (currentToken / levelToken) * 100

      setCurrentBar(currentBar)
    } catch (error) {
      console.log(error)
    }
  }

  // const receiveHandler = async () => {
  //   try {
  //     contract = await window.tronWeb.contract().at(contractAddress)
  //     const percentvalue = await contract.divDistributePercentage().call()
  //     const percent = parseInt(percentvalue._hex, 16)
  //     const receivebal =
  //       (((+dividend * percent) / 100 / totalFrozenBalance) *
  //         freezeLottoTokens *
  //         10 ** 8) /
  //       10 ** 8

  //     setReceive(receivebal)
  //     onSubmitRecieveBalance(receivebal)
  //   } catch (error) {
  //     console.log(error, 'error')
  //   }
  // }

  // const unfreezeSweepToken = async () => {
  //   try {
  //     setUloading(true)
  //     const tokencontract = await window.tronWeb
  //       .contract()
  //       .at(tokencontractAddress)
  //     await tokencontract.subscribeUnfreezeRequest().send({ callValue: 500000 })
  //     await ToastsStore.success('Unfrozen successfully')

  //     setUloading(false)
  //   } catch (error) {
  //     setUloading(false)
  //   }
  // }
  const getUnfreezeReq = () => {
    axios
      .get(`tron/user/getUnfreezeReq/${accountAddress}`)
      .then((res) => {
        //  setUnfreezeData(res)
      })
      .catch(() => { })
  }

  const unfreezeRequest = async () => {
    try {
      const tokencontract = await window.tronWeb.contract().at(accountAddress)
      let dice = await tokencontract.unfreezeRequest(accountAddress).call()
      dice = +dice

      setUnfreezeDiceLocal(dice)
      setUnfreezeDice(dice)
    } catch (error) {
      console.log(error)
    }
  }

  // eslint-disable-next-line consistent-return
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setTimeout(() => {
        getPublicLotteryDataHandler()
        prizeDistributionTime()
      }, 3000)
      setTimeout(() => {
        getWinningData()
      }, 60000)

      return true
    }

    return (
      <span>
        {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    )
  }

  useEffect(() => {
    //boxLevelShare()
    ticketIssued()
    calCurrentbar()
    fetchDividentPoll()
    totalfrozenHandler()
    totalMinedYet()
    fetchLevel()
    //receiveHandler()
    dividendDistributionTime()
    prizeDistributionTime()
    getUnfreezeReq()

    if (accountAddress) {
      boxWinnerValue()
      sweepTokenHandler()
      totalmyticket()
      freezeToken()
      unfreezeRequest()
      gameWaltbalance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFrozenBalance, dividend, freezeLottoTokens])

  const getPublicLotteryDataHandler = async () => {
    setIsPublicDataLoading(true)
    const res = await axios.get(`tron/data/getPublicLotteryData`)

    if (res.data.status) {
      setPublicData(res.data.data[0])
      setPots({
        Pot6: res.data.data[0].pot6Winners,
        Pot5: res.data.data[0].pot5Winners,
        Pot4: res.data.data[0].pot4Winners,
        Pot3: res.data.data[0].pot3Winners,
        Pot2: res.data.data[0].pot2Winners,
        Pot1: res.data.data[0].pot1Winners,
      })

      setboxShareValue({
        boxshare6: parseInt(
          (res.data.data[0].ticketsSold / 100 - res.data.data[0].pot6Winners) *
          1500,
        ),
        boxshare5: parseInt(
          (res.data.data[0].ticketsSold / 10000 -
            res.data.data[0].pot5Winners) *
          30000,
        ),
        boxshare4: parseInt(
          (res.data.data[0].ticketsSold / 100000 -
            res.data.data[0].pot4Winners) *
          300000,
        ),
        boxshare3: parseInt(
          (res.data.data[0].ticketsSold / 1000000 -
            res.data.data[0].pot3Winners) *
          3000000,
        ),
        boxshare2: parseInt(
          (res.data.data[0].ticketsSold / 10000000 -
            res.data.data[0].pot2Winners) *
          30000000,
        ),
        boxshare1: parseInt(
          (res.data.data[0].ticketsSold / 60000000 -
            res.data.data[0].pot1Winners) *
          300000000,
        ),
      })
      setIsPublicDataLoading(false)

      //odometerValue
    } else {
      setIsPublicDataLoading(false)
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    getPublicLotteryDataHandler()
  }, [])

  async function CreateAPIOrder() {
    try {
      var url_to_post =
        'https://www.tokengoodies.com/tronresourceexchange/exchange' // Base URL for the API

      // ==========================================================================================
      // Get contract address and other values needed to create an order

      var requestbody = new Object()
      requestbody.action = 'utils'
      requestbody.type = 'api_get_create_order_values'
      requestbody.api_key = TOKENGOODIES_API_KEY
      var create_order_values_obj = await FetchAPIData(
        url_to_post,
        requestbody,
        5000,
      )

      setCreatOrderValues(create_order_values_obj)
      console.log(create_order_values_obj, 'min_bandwidth_price_in_sun')
      // The create_order_values_obj variable is an object with the following structure.
      // { "order_fees_address": "TAeXoCEpGTeTruxwN19pc6TpTPfeAk1E5E", "min_order_amount_in_sun": 9000000, "min_energy_price_in_sun": 30, "min_bandwidth_price_in_sun": 540 }
    } catch (e) {
      console.log(e)
    }
  }

  async function FetchAPIData(url_to_post, requestbody, timeout_in_ms) {
    try {
      var result = await axios.post(url_to_post, requestbody)
      return result.data
    } catch (e) {
      console.log(e)
    }
    return null
  }
  return (
    <>
      <div className="main_container">
        <div class="hex-button">
          <div className="navbar_winning_wrap">
            <div>
              <h5>
                Congratulations, <span className="name_text">OLMQ806SDBO</span>
                🥳🎉
              </h5>
              <p>
                for winning 300,000 TRX on Ticket No.{' '}98315
              </p>
            </div>
          </div>
        </div>
        <div className="top_nav_lottery">
          <ul>
            <li>
              <span
                aria-hidden
                className="chat_icon_toggle"
                onClick={chatBoxHandler}
              >
                <i className="mdi mdi-forum" />
              </span>
            </li>
            <li>
              <a href="#!" onClick={() => setShowWaggerModal(true)}>
                <span aria-hidden className="chat_icon_toggle">
                  <i className="mdi mdi-trophy" />
                </span>
              </a>
            </li>
            <li>
              <a href="#!" onClick={() => setShowPrizeModal(true)}>
                <span aria-hidden className="chat_icon_toggle">
                  <i className="mdi mdi-timer" />
                </span>
              </a>
            </li>

            <li>
              <a href="#!" onClick={() => handleOpenEnergyModal(true)}>
                <span aria-hidden className="chat_icon_toggle">
                  <AiFillThunderbolt />
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className="row mt-4">
          {/* <div className="col-lg-3">
              <div
                className={`chat_sidebar_mobile ${
                  isChatOpen ? 'chap_open' : ''
                }`}
              >
                <SidebarLottery
                  address={accountAddress}
                  username={username}
                  onClose={chatBoxHandler}
                />
               
              </div>
            </div> */}

          <div className="col-lg-8">
            <div className="card middle_card" data-tut="step-2">
              <div className="card-body">
                <div className="top_options_menu">
                  <div className="top_bar">
                    <h4>Buy Ticket</h4>
                    <ul>
                      <li>
                        <a href="#" data-tut="step-6" h="6">
                          <span aria-hidden onClick={handleWalletTicket}>
                            Game Wallet:{' '}
                            {isGameWalletLoaded ? (
                              <span className="spinner">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                              </span>
                            ) : (
                              <> {walletBalance} </>
                            )}
                          </span>

                          {/* {this.state.gameWalt > 0 ? (
                                  <span onClick={this.handleWalletTicket}>
                                    Game Wallet:{" "}
                                    {parseInt(this.state.gameWalt * 100) / 100}
                                  </span>
                                ) : (
                                  <span class="spinner">
                                    <div class="bounce1"></div>
                                    <div class="bounce2"></div>
                                    <div class="bounce3"></div>
                                  </span> */}
                          {/* )} */}
                        </a>
                      </li>
                      <li>
                        <a
                          data-tut="step-4"
                          h="4"
                          href="#"
                          onClick={lottoModalOpenHandler}
                          id="lottoDividendBtn"
                        >
                          Stake Lotto
                        </a>
                      </li>
                      <li>
                        <a href="#!" onClick={() => setShowRuleModal(true)}>
                          Lotto Rules
                        </a>
                      </li>
                    </ul>
                  </div>
                  <Modal
                    show={lottoModalOpen}
                    onHide={lottoModalCloseHandler}
                    dialogClassName="dividend_modal "
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Lotto Dividend</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <LottoDividend
                        accountAddress={accountAddress}
                        sweepToken={sweepToken}
                        frezeeamount={freezeLottoTokens}
                        unfreezetimmer={unfreezeTimmer}
                        unfreezeClick={unfreezeclick}
                        disable={disabled}
                        unfreezeTime={unfreezeTime}
                        dividend={dividend}
                        totalmineyet={totalmineyet}
                        totalfrozen={totalFrozenBalance}
                        level={level}
                        stage={stage}
                        currentdifficulty={currentdifficulty}
                        currentBar={currentBar}
                        dividendtimer={dividendtimer}
                        receive={receive}
                        loading={loading}
                        disabledfreezebtn={disabledfreezebtn}
                        unfreezedice={unfreezediceLocal}
                        cancelunfreeze={cancelunfreeze}
                        // totalToken={toFreezAmount}
                        // sweepTokenFunc={this.sweepToken()}
                        afterBuyFlag={afterBuyFlag}
                        afterBuyLottoToken={afterBuyLottoToken}
                      />
                    </Modal.Body>
                  </Modal>

                  <Modal show={showRuleModal} onHide={handleCloseRuleModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Lotto Rules</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {' '}
                      <LottoRules />
                    </Modal.Body>
                  </Modal>
                </div>
                <div className="ticketBox">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-2 col-2 text-center">
                        <button
                          type="button"
                          id="minus_btn"
                          onClick={minusOne}
                          className="isIcon"
                        >
                          <span>&ndash;</span>
                        </button>
                      </div>
                      <div className="col-sm-8 col-8 text-center tickets_input_box">
                        <input
                          type="number"
                          value={minTicket}
                          onChange={handleTicket}
                          className="w-100 ticketNo"
                          min={min}
                          required
                        />
                        {isLimiteToBuyTickets ? (
                          <span className="text-danger">
                            Enter valid ticket count.
                          </span>
                        ) : null}
                        {/* button Group */}
                        <div
                          className="btn-group mt-2 lottery_btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={minusFive}
                          >
                            -5
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={minusTen}
                          >
                            -10
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={plusFive}
                          >
                            +5
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={PlusTen}
                          >
                            +10
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={Plusfifty}
                          >
                            +50
                          </button>
                        </div>
                      </div>
                      <div className="col-sm-2 col-2 text-center">
                        <button
                          type="button"
                          onClick={plusOne}
                          className="isIcon"
                        >
                          +
                        </button>
                        <br />
                      </div>
                    </div>
                    <br />
                    <div className="pay_btn_col" data-tut="step-3" h="3">
                      <span className="my_tickets_text">
                        (My Tickets: {mybought})
                      </span>
                      {!loading ? (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary btn-block pay_btn"
                            id="buy_btn"
                            disabled={
                              isDealer || isLimiteToBuyTickets || minTicket < 1
                            }
                          >
                            {`PAY ${minTicket * ticketPrice} TRX`}
                          </button>

                          <div
                            className="col text-center distribution_col"
                            id="mobile-distribution-col"
                          >
                           
                              <>
                                {!isPublicDataLoading ? (
                                  <>
                                    {!moment(
                                      new Date(publicData?.lotteryTime * 1000),
                                    ).isBefore(moment()) ? (
                                      <h4
                                        className="widget-game-result__score-result widget-game-result__score-result--winner"
                                        style={{
                                          marginBottom: '0px',
                                          fontSize: '3em',
                                        }}
                                      >
                                        <Countdown
                                          date={publicData?.lotteryTime * 1000}
                                          renderer={renderer}
                                        />
                                      </h4>
                                    ) : (
                                      <h5>Prize is being Distributed</h5>
                                    )}
                                  </>
                                ) : (
                                  <h4 className="no_top_waggers">
                                    <span className="spinner">
                                      <div className="bounce1" />
                                      <div className="bounce2" />
                                      <div className="bounce3" />
                                    </span>
                                  </h4>
                                )}
                              </>
                          </div>
                        </>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-block pay_btn"
                          id="buy_btn"
                        >
                          <span className="spinner">
                            <div className="bounce1" />
                            <div className="bounce2" />
                            <div className="bounce3" />
                          </span>
                        </button>
                      )}
                      {/* <div className="button_bottom_box">
                          <span
                            className="buy_energy_btn"
                            onClick={handleOpenEnergyModal}
                          >
                            Buy energy
                          </span>
                          {isDealer ? (
                            <span className=" my_tickets_text">
                              (Dealers are not eligible to buy tickets)
                            </span>
                          ) : null}
                        </div> */}
                    </div>
                  </form>
                </div>
                <LotteryHistory accountAddress={accountAddress} />
              </div>
            </div>
          </div>
          <div className="col-lg-4 hide_on_moble right_top_wagger">
            <StickyBox offsetTop={115} offsetBottom={0}>
              <div className="card top_waggers_card">
                <div className="card-header card__header--has-btn ">
                  {isWagerTableVisible ? (
                    <>
                      <img
                        alt=""
                        className="l_img"
                        src={TopWaggersIcon}
                        style={{ position: 'absolute', height: '40px' }}
                      />
                      <h4 style={{ marginLeft: '50px' }}>Top Wagers</h4>
                    </>
                  ) : (
                    <>
                      <img
                        alt=""
                        className="l_img"
                        src={lottery}
                        style={{ position: 'absolute', height: '40px' }}
                      />
                      <h4 style={{ marginLeft: '50px', maxWidth: '108px' }}>
                        tickets sold
                        <br />
                        <>
                          {isUserNotloggedIn ? (
                            <>
                              <Odometer
                                format="d"
                                duration={2000}
                                value={publicData?.ticketsSold}
                              />
                              /60000000
                            </>
                          ) : (
                            <>
                              <Odometer
                                format="d"
                                duration={2000}
                                value={odometerValue}
                              />
                              /60000000
                            </>
                          )}
                        </>
                      </h4>
                    </>
                  )}

                  <a
                    className={`btn btn-outline-primary btn-xs card-header__button ${isWagerTableVisible ? 'isWagerVisible' : null
                      }`}
                    href="#"
                    onClick={setWagerVisible}
                  >
                    {isWagerTableVisible ? (
                      <i className="mdi mdi-close" />
                    ) : (
                      'Top Wagers'
                    )}
                  </a>
                </div>
                <div className="card-body p-0">
                  {isWagerTableVisible ? (
                    <TopWager />
                  ) : (
                    <div className="p-3">
                      <table className="progress-table">
                        <tbody>
                          <tr>
                            <td className="progress-table__progress-bar progress-table__progress-bar--second">
                              <span className="spe_pot">
                                1st Pot Winner - 300,000,000
                              </span>
                              <p>
                                <img className="tron_logo" src={Tron} alt="" />

                                <Odometer
                                  format="d"
                                  duration={2000}
                                  value={boxshareValue.boxshare1}
                                />
                              </p>
                            </td>
                            <td className="progress-table__progress-label">
                              {pots.Pot1}/1
                            </td>
                          </tr>
                          <tr>
                            <td className="progress-table__progress-bar progress-table__progress-bar--second">
                              <span className="spe_pot">
                                2nd Pot Winner - 30,000,000
                              </span>
                              <p>
                                <img className="tron_logo" src={Tron} alt="" />

                                <Odometer
                                  format="d"
                                  duration={2000}
                                  value={boxshareValue.boxshare2}
                                />
                              </p>
                            </td>
                            <td className="progress-table__progress-label">
                              {pots.Pot2}/6
                            </td>
                          </tr>
                          <tr>
                            <td className="progress-table__progress-bar progress-table__progress-bar--second">
                              <span>3rd Pot Winner - 3,000,000</span>
                              <p>
                                <img className="tron_logo" src={Tron} alt="" />

                                <Odometer
                                  format="d"
                                  duration={2000}
                                  value={boxshareValue.boxshare3}
                                />
                              </p>
                            </td>
                            <td className="progress-table__progress-label">
                              {pots.Pot3}/60
                            </td>
                          </tr>
                          <tr>
                            <td className="progress-table__progress-bar progress-table__progress-bar--second">
                              <span>4th Pot Winner - 300,000</span>
                              <p>
                                <img className="tron_logo" src={Tron} alt="" />
                                <Odometer
                                  format="d"
                                  duration={2000}
                                  value={boxshareValue.boxshare4}
                                />
                              </p>
                            </td>
                            <td className="progress-table__progress-label">
                              {pots.Pot4}/600
                            </td>
                          </tr>
                          <tr>
                            <td className="progress-table__progress-bar progress-table__progress-bar--second">
                              <span>5th Pot Winner - 30,000</span>
                              <p>
                                <img className="tron_logo" src={Tron} alt="" />
                                <Odometer
                                  format="d"
                                  duration={2000}
                                  value={boxshareValue.boxshare5}
                                />
                              </p>
                            </td>
                            <td className="progress-table__progress-label">
                              {pots.Pot5}/6000
                            </td>
                          </tr>
                          <tr>
                            <td className="progress-table__progress-bar progress-table__progress-bar--second">
                              <span>6th Pot Winner - 1,500</span>
                              <p>
                                <img className="tron_logo" src={Tron} alt="" />
                                <Odometer
                                  format="d"
                                  duration={2000}
                                  value={boxshareValue.boxshare6}
                                />
                              </p>
                            </td>
                            <td className="progress-table__progress-label">
                              {pots.Pot6}/600000
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
              {/* //Ticket Prize Timer  Starts */}
              <div className="card">
                <div className="card-header card__header--has-btn ">
                  {isWinnerListVisible ? (
                    <>
                      <img
                        alt=""
                        className="l_img"
                        src={WinnerIcon}
                        style={{ position: 'absolute', height: '40px' }}
                      />
                      <h4 style={{ marginLeft: '50px' }}>Winning Numbers</h4>
                    </>
                  ) : (
                    <>
                      {DistributionTime - Date.now() > 0 ? (
                        <h4>
                          <img
                            alt=""
                            className="p_icon"
                            src={priceDistibutionIcon}
                          />{' '}
                          Price Distributes in
                        </h4>
                      ) : (
                        <h4>
                          Prize Distribution
                          {/* {this.state.DistributionTime - Date.now()} */}
                        </h4>
                      )}
                    </>
                  )}

                  <a
                    className={`btn btn-outline-primary btn-xs card-header__button ${isWinnerListVisible ? 'isWagerVisible' : null
                      }`}
                    href="#"
                    onClick={setWinnerListVisible}
                  >
                    {isWinnerListVisible ? (
                      <i className="mdi mdi-close" />
                    ) : (
                      'Winning Number'
                    )}
                  </a>
                </div>
                <div className="card-body p-0">
                  {isWinnerListVisible ? (
                    <div className="winning_number_col p-0">
                      <WinningNumbers />
                    </div>
                  ) : (
                    <div className="col text-center distribution_col">
                      <>
                        {!isPublicDataLoading ? (
                          <>
                            {!moment(
                              new Date(publicData?.lotteryTime * 1000),
                            ).isBefore(moment()) ? (
                              <h4
                                className="widget-game-result__score-result widget-game-result__score-result--winner"
                                style={{
                                  marginBottom: '0px',
                                  fontSize: '3em',
                                }}
                              >
                                <Countdown
                                  date={publicData?.lotteryTime * 1000}
                                  renderer={renderer}
                                />
                              </h4>
                            ) : (
                              <h5>Prize is being Distributed</h5>
                            )}

                            {/* rajesh {DistributionTime - Date.now() > 0 ? (
                                <h4
                                  className="widget-game-result__score-result widget-game-result__score-result--winner"
                                  style={{
                                    marginBottom: '0px',
                                    fontSize: '3em',
                                  }}
                                >
                                  <Countdown
                                    date={DistributionTime}
                                    renderer={this.renderer}
                                  />
                                </h4>
                              ) : (
                                <h4>Prize is being Distributed</h4>
                              )} */}
                          </>
                        ) : (
                          <h4 className="no_top_waggers">
                            <span className="spinner">
                              <div className="bounce1" />
                              <div className="bounce2" />
                              <div className="bounce3" />
                            </span>
                          </h4>
                        )}
                      </>
                    </div>
                  )}
                </div>
              </div>
              {/* //Ticket Prize Timer ends */}
            </StickyBox>
          </div>
        </div>
      </div>
      <ToastsContainer store={ToastsStore} />
      <Modal show={showWaggerModal} onHide={handleCloseWaggerModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <div className="card">
            <div className="card-header top_waggers_card card__header--has-btn ">
              {isWagerTableVisible ? (
                <>
                  <img
                    alt=""
                    className="l_img"
                    src={TopWaggersIcon}
                    style={{ position: 'absolute', height: '40px' }}
                  />
                  <h4 style={{ marginLeft: '50px' }}>Top Wagers</h4>
                </>
              ) : (
                <>
                  <img
                    alt=""
                    className="l_img"
                    src={lottery}
                    style={{ position: 'absolute', height: '40px' }}
                  />
                  <h4 style={{ marginLeft: '50px', maxWidth: '108px' }}>
                    tickets sold{`\n`}
                    <>
                      {isUserNotloggedIn ? (
                        <>
                          <Odometer
                            format="d"
                            duration={2000}
                            value={publicData?.ticketsSold}
                          />
                          /60000000
                        </>
                      ) : (
                        <>
                          <Odometer
                            format="d"
                            duration={2000}
                            value={odometerValue}
                          />
                          /60000000
                        </>
                      )}
                    </>
                  </h4>
                </>
              )}

              <a
                className={`btn btn-outline-primary btn-xs card-header__button ${isWagerTableVisible ? 'isWagerVisible' : null
                  }`}
                href="#"
                onClick={setWagerVisible}
              >
                {isWagerTableVisible ? (
                  <i className="mdi mdi-close" />
                ) : (
                  'Top Wagers'
                )}
              </a>
            </div>
            <div className="card-body">
              {isWagerTableVisible ? (
                <div>
                  <div className="top_heade_waggers">
                    <div onClick={getPastWaggers} aria-hidden>
                      <span>
                        <i className="mdi mdi-arrow-left" />
                      </span>
                    </div>
                    <div>
                      <strong>{topWaggersDate}</strong>
                    </div>
                    <div>
                      {isFuturVisible ? (
                        <div onClick={getFutureWaggers} aria-hidden>
                          <span>
                            <i className="mdi mdi-arrow-right" />
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {topWagerData ? (
                    <div>
                      {isTopwagersLoading ? (
                        <h4 className="no_top_waggers">
                          <span className="spinner">
                            <div className="bounce1" />
                            <div className="bounce2" />
                            <div className="bounce3" />
                          </span>
                        </h4>
                      ) : (
                        <table className="wager_table table">
                          <thead>
                            <tr>
                              <td>Player</td>
                              <td>Total Wagered</td>
                              <td>Tokens</td>
                            </tr>
                          </thead>
                          <tbody>
                            {topWager.length > 0 ? (
                              <>
                                {topWager.map((tpWager) => (
                                  <tr key={tpWager.id}>
                                    <td>
                                      {/* <img
                                                  className="pot_box_icon"
                                                  src={WinnerIcon}
                                                /> */}
                                      <p
                                        style={{
                                          marginBottom: '.5em',
                                          color: '#f5bc00',
                                        }}
                                      >
                                        {tpWager.user_name.toUpperCase()}
                                      </p>
                                      <div className="middle_truncate">
                                        {smartTruncate(
                                          tpWager.public_address,
                                          18,
                                          {
                                            position: 8,
                                          },
                                        )}
                                      </div>
                                    </td>
                                    <td>{tpWager.ticket_count}</td>
                                    <td>{tpWager.token_amount}</td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              'No Top Wagers!'
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ) : (
                    <h4 className="no_top_waggers">
                      <span className="spinner">
                        <div className="bounce1" />
                        <div className="bounce2" />
                        <div className="bounce3" />
                      </span>
                    </h4>
                  )}
                </div>
              ) : (
                <table className="progress-table">
                  <tbody>
                    <tr>
                      <td className="progress-table__progress-bar progress-table__progress-bar--second">
                        <span className="spe_pot">
                          1st Pot Winner - 300,000,000
                        </span>
                        <p>
                          <img className="tron_logo" src={Tron} alt="" />

                          <Odometer
                            format="d"
                            duration={2000}
                            value={boxshareValue.boxshare1}
                          />
                        </p>
                      </td>
                      <td className="progress-table__progress-label">
                        {pots.Pot1}/1
                      </td>
                    </tr>
                    <tr>
                      <td className="progress-table__progress-bar progress-table__progress-bar--second">
                        <span className="spe_pot">
                          2nd Pot Winner - 30,000,000
                        </span>
                        <p>
                          <img className="tron_logo" src={Tron} alt="" />

                          <Odometer
                            format="d"
                            duration={2000}
                            value={boxshareValue.boxshare2}
                          />
                        </p>
                      </td>
                      <td className="progress-table__progress-label">
                        {pots.Pot2}/6
                      </td>
                    </tr>
                    <tr>
                      <td className="progress-table__progress-bar progress-table__progress-bar--second">
                        <span>3rd Pot Winner - 3,000,000</span>
                        <p>
                          <img className="tron_logo" src={Tron} alt="" />

                          <Odometer
                            format="d"
                            duration={2000}
                            value={boxshareValue.boxshare3}
                          />
                        </p>
                      </td>
                      <td className="progress-table__progress-label">
                        {pots.Pot3}/60
                      </td>
                    </tr>
                    <tr>
                      <td className="progress-table__progress-bar progress-table__progress-bar--second">
                        <span>4th Pot Winner - 300,000</span>
                        <p>
                          <img className="tron_logo" src={Tron} alt="" />
                          <Odometer
                            format="d"
                            duration={2000}
                            value={boxshareValue.boxshare4}
                          />
                        </p>
                      </td>
                      <td className="progress-table__progress-label">
                        {pots.Pot4}/600
                      </td>
                    </tr>
                    <tr>
                      <td className="progress-table__progress-bar progress-table__progress-bar--second">
                        <span>5th Pot Winner - 30,000</span>
                        <p>
                          <img className="tron_logo" src={Tron} alt="" />
                          <Odometer
                            format="d"
                            duration={2000}
                            value={boxshareValue.boxshare5}
                          />
                        </p>
                      </td>
                      <td className="progress-table__progress-label">
                        {pots.Pot5}/6000
                      </td>
                    </tr>
                    <tr>
                      <td className="progress-table__progress-bar progress-table__progress-bar--second">
                        <span>6th Pot Winner - 1,500</span>
                        <p>
                          <img className="tron_logo" src={Tron} alt="" />
                          <Odometer
                            format="d"
                            duration={2000}
                            value={boxshareValue.boxshare6}
                          />
                        </p>
                      </td>
                      <td className="progress-table__progress-label">
                        {pots.Pot6}/600000
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="mobile_modal"
        show={showPrizeModal}
        onHide={handleClosePrizeModal}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header card__header--has-btn ">
              {isWinnerListVisible ? (
                <>
                  <img
                    alt=""
                    className="l_img"
                    src={WinnerIcon}
                    style={{ position: 'absolute', height: '40px' }}
                  />
                  <h4 style={{ marginLeft: '50px' }}>Winning Numbers</h4>
                </>
              ) : (
                <>
                  {DistributionTime - Date.now() > 0 ? (
                    <h4>Price Distributes in</h4>
                  ) : (
                    <h4>Prize Distribution</h4>
                  )}
                </>
              )}

              <a
                className={`btn btn-outline-primary btn-xs card-header__button ${isWinnerListVisible ? 'isWagerVisible' : null
                  }`}
                href="#"
                onClick={setWinnerListVisible}
              >
                {isWinnerListVisible ? (
                  <i className="mdi mdi-close" />
                ) : (
                  'Winning Number'
                )}
              </a>
            </div>
            <div className="card-body p-0">
              {isWinnerListVisible ? (
                <div className="winning_number_col p-0">
                  <WinningNumbers />
                </div>
              ) : (
                <div className="col text-center distribution_col">
                  <>
                    {!isPublicDataLoading ? (
                      <>
                        {!moment(
                          new Date(publicData?.lotteryTime * 1000),
                        ).isBefore(moment()) ? (
                          <h4
                            className="widget-game-result__score-result widget-game-result__score-result--winner"
                            style={{
                              marginBottom: '0px',
                              fontSize: '3em',
                            }}
                          >
                            <Countdown
                              date={publicData?.lotteryTime * 1000}
                              renderer={renderer}
                            />
                          </h4>
                        ) : (
                          <h5>Prize is being Distributed</h5>
                        )}

                        {/* rajesh {DistributionTime - Date.now() > 0 ? (
                                <h4
                                  className="widget-game-result__score-result widget-game-result__score-result--winner"
                                  style={{
                                    marginBottom: '0px',
                                    fontSize: '3em',
                                  }}
                                >
                                  <Countdown
                                    date={DistributionTime}
                                    renderer={this.renderer}
                                  />
                                </h4>
                              ) : (
                                <h4>Prize is being Distributed</h4>
                              )} */}
                      </>
                    ) : (
                      <h4 className="no_top_waggers">
                        <span className="spinner">
                          <div className="bounce1" />
                          <div className="bounce2" />
                          <div className="bounce3" />
                        </span>
                      </h4>
                    )}
                  </>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* {isWinningBoxOpen && (
          <WinningBox
            winningData={winningBoxData}
            close={() => onSubmitWinningBoxOpen(false)}
            getWinningData={getWinningData}
          />
        )} */}

      <Modal
        show={showEnergyModal}
        onHide={handleCloseEnergyModal}
        className="energy_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Buy Energy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EnergyBuy
            creatOrderValues={creatOrderValues}
            handleCloseEnergyModal={handleCloseEnergyModal}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Lottery
