import React from 'react'

const DiceCube = ({ isRolling }) => {
  return (
    <>
      <div id="container">
        <div id="cube" className={isRolling ? 'rolling' : 'stop'}>
          <div className="facet top_dice">
            <div className="dot"></div>
          </div>
          <div className="facet front_dice">
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="facet left_dice">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="facet right_dice">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="facet back_dice">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="facet bottom_dice">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DiceCube
