/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import TruncateMiddle from 'react-truncate-string'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import avatar from '../public/images/icons/avatar.svg'
// import config from "../../config.json";

export default class DealerReferralHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: [new Date(), new Date()],
      reflist: [],
      totalref: 0,
      totalearning: 0,
      data: '',
      isExtendedvisible: false,
      referralHistoryData: [],
    }
  }

  componentDidMount() {
    const { accountAddress } = this.props

    axios
      .get(`tron/user/dealerRefList/${accountAddress}`)
      .then((res) => {
        this.setState({
          reflist: res.data.data.refList,
          data: res.data.status,
          totalref: res.data.data.totalReferral,
          totalearning: res.data.data.totalEarning,
        })
      })
      .catch((err) => {
        console.log(err, 'catch')
      })
  }

  getReferralHistory = (data) => {
    const { accountAddress } = this.props
    const refHistoryof = {
      dealerAddress: accountAddress,
      referralAddress: data,
    }
    this.setState({
      refereeAddress: data,
    })
    axios
      .post(`tron/user/dealerRefTxnHistory`, refHistoryof)
      .then((res) => {
        this.setState({
          referralHistoryData: res.data.data,
        })
      })
      .catch((err) => {
        console.log(err, 'catch')
      })
  }

  onDateChange = (date) => this.setState({ date })

  render() {
    const {
      totalref,
      totalearning,
      date,
      isExtendedvisible,
      refereeAddress,
      referralHistoryData,
      data,
      reflist,
    } = this.state
    const { accountAddress } = this.props

    return (
      <>
        <h5 className="title">Dealer Referral</h5>
        <div className="card account_card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 h_b_r">
                <div className="media">
                  <img className="dealeravatar_dp mr-3" src={avatar} alt="" />
                  <div className="media-body">
                    <p>
                      <small>User</small>
                    </p>
                    <h5 className="mt-0 user_address_token">
                      <TruncateMiddle text={accountAddress} />
                      {/* {this.props.accountAddress} */}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 total_bets_col text-center h_b_r">
                <p>
                  <small>Total Referral Person</small>
                </p>
                <h5>
                  <strong>{totalref}</strong>
                </h5>
              </div>
              <div className="col-md-4 daily_task_col text-center">
                <p>
                  <small>Total Referral Earning</small>
                </p>
                <h5>
                  <strong>{totalearning}</strong>
                </h5>
                {/* <img src={daily_task} /> */}
              </div>
            </div>
          </div>
        </div>
        <h5 className="title">Referral History</h5>
        <div className="card mb-3 transaction_card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <DateRangePicker onChange={this.onDateChange} value={date} />
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          {isExtendedvisible ? (
            <>
              <table className="table table-striped table-dark">
                <thead>
                  <tr>
                    <td colSpan="3">{refereeAddress}</td>
                    <td>
                      <a
                        href
                        onClick={() => {
                          this.setState({
                            isExtendedvisible: false,
                          })
                        }}
                        className="btn btn-outline-primary close_extended_btn"
                      >
                        <i className="fas fa-times-circle" />
                      </a>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {referralHistoryData.length > 0 ? (
                    <>
                      {referralHistoryData.map((refdata, index) => (
                        <tr key={refdata.public_address}>
                          <td>{index + 1}</td>
                          <td>
                            {new Date(refdata.created_at).toLocaleString()}
                          </td>
                          <td>{refdata.dealer_share}</td>

                          <td>
                            {refdata.level ? (
                              <div>
                                <span style={{ color: 'green' }}>
                                  Pot{refdata.pot_no}
                                </span>{' '}
                                <span style={{ color: '#ede324' }}>
                                  Level {refdata.level}
                                </span>
                              </div>
                            ) : (
                              refdata.source
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan="4" className="text-center">
                          <strong>No record yet!</strong>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </>
          ) : data ? (
            <table className="table table-striped table-dark referral_table_tb">
              <thead>
                <tr>
                  <th scope="col">S.N</th>
                  <th scope="col">SignUP</th>
                  <th scope="col">Address</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {reflist.map((reflistdata, index) => (
                  <tr
                    key={reflistdata.public_address}
                    onClick={() => {
                      this.setState({
                        isExtendedvisible: true,
                      })
                      this.getReferralHistory(reflistdata.public_address)
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{new Date(reflistdata.created_at).toLocaleString()}</td>
                    <td>{reflistdata.public_address}</td>

                    <td>{reflistdata.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 className="no_h4">you have no any referral</h4>
          )}
        </div>
      </>
    )
  }
}
DealerReferralHistory.propTypes = {
  accountAddress: PropTypes.string.isRequired,
}
