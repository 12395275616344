import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useAccount } from '../context/AccountProvider'

const Referral = ({
  generatedReferalCode,
  changed,
  afterGeneratedRefCode,
  onClick,
}) => {
  const {
    register,
    errors,
    formState: { isValid },
  } = useForm({ mode: 'onChange' })

  const { refferalCode } = useAccount()

  const copyToClipboard = () => {
    const copyText = document.getElementById('myreferal')
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand('copy')
  }

  console.log(generatedReferalCode, 'generatedReferalCode')

  return (
    <>
      <span className="text-primary">
        Enjoy lifetime rewards just by referring your friends! Receive a minimum
        of 10% of all their ticket purchase and up to 2.5% prize share from
        their winning. All rewards are sent out automatically to your game
        wallet through our smart contracts.
      </span>
      <p>Just copy the link and share it to all your friends!!</p>
      <div className="d-flex genrated-col">
        <input
          type="text"
          className="form-control"
          defaultValue={generatedReferalCode}
          name="referal"
          id="myreferal"
          placeholder="Choose your refferal code."
          onKeyUp={changed}
          {...register('referal', {
            required: true,
            pattern: /^[^<>'\"/;`%]*$/i,
          })}
          readOnly={afterGeneratedRefCode}
          onKeyPress={(event) => {
            if (!/[a-zA-Z[0-9]/.test(event.key)) {
              event.preventDefault()
            }
          }}
        />

        {afterGeneratedRefCode === '' ? (
          <button
            type="button"
            className="btn btn-primary"
            disabled={!isValid}
            onClick={onClick}
          >
            Generate Referral code
          </button>
        ) : (
          <button
            className="btn btn-outline-primary"
            type="button"
            id="button-addon2"
            onClick={copyToClipboard}
          >
            Copy link
          </button>
        )}
      </div>
      {errors && <p className="text-danger">{errors}</p>}
    </>
  )
}
export default Referral
Referral.propTypes = {
  generatedReferalCode: PropTypes.string.isRequired,
  changed: PropTypes.func.isRequired,
  afterGeneratedRefCode: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
