import React, { createContext, useContext } from 'react'
import { useState } from 'react'

const LotteryContext = createContext()

export function useLottery() {
  return useContext(LotteryContext)
}

export default function LotteryProvider({ children }) {
  const [lottoTickets, setLottoTickets] = useState(0)
  const [lottoTokens, setLottoTokens] = useState(0)
  const [freezeLottoTokens, setFreezeLottoTokens] = useState(0)
  const [receiveBalance, setReceiveBalance] = useState(0)
  const [totalFrozenBalance, setTotalFrozenBalance] = useState(0)
  const [isRecieveBalanceLoading, setIsRecieveBalanceLoading] = useState(false)

  const onSubmitLottoTickets = async (data) => {
    setLottoTickets(data)
  }
  const onSubmitLottoTokens = async (data) => {
    setLottoTokens(data)
  }
  const onSubmitFreezeLottoTokens = async (data) => {
    setFreezeLottoTokens(data)
  }
  const onSubmitRecieveBalance = (amount) => {
    setReceiveBalance(amount)
  }
  const onSubmitTotalFrozenBalance = (amount) => {
    setTotalFrozenBalance(amount)
  }

  const values = {
    lottoTickets,
    lottoTokens,
    freezeLottoTokens,
    receiveBalance,
    totalFrozenBalance,
    isRecieveBalanceLoading,
    onSubmitLottoTickets,
    onSubmitLottoTokens,
    onSubmitFreezeLottoTokens,
    onSubmitRecieveBalance,
    onSubmitTotalFrozenBalance,
    setIsRecieveBalanceLoading,
  }

  return (
    <LotteryContext.Provider value={values}>{children}</LotteryContext.Provider>
  )
}
