import React, { useState } from 'react'
import { Alert, Button, Col, Nav, Row, Tab } from 'react-bootstrap'
import copy from 'copy-to-clipboard'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import CustomInput from '../components/Input'
import SelectInput from '../components/SelectInput'
import { bscadminAddress } from '../utils/ContractAddress'

const data = [
  {
    value: 'tron',
    text: 'Tron',
    icon: <i className="icon tron_red"></i>,
  },
  {
    value: 'mtw',
    text: 'MTW',
    icon: <i className="icon mtw"></i>,
  },
]
const networkList = [
  {
    value: 'tron',
    text: 'Tron',
    icon: <i className="icon tron_red"></i>,
  },
  {
    value: 'mtw',
    text: 'MTW',
    icon: <i className="icon mtw"></i>,
  },
]

const Wallet = () => {
  const [selectedOption, setSelectedOption] = useState(data[0])
  const [amountDetails, setAmountDetails] = useState({})
  const [accountAddress, setAccountAddress] = useState("TF7w57FkSR4MiUNQdjJ4pecCzrSiowCAxV")
  const [withdrawAmount, setWithdrawAmount] = useState(0)
  const [error, setError] = useState('')

  const handleChange = (e) => {
    const { name, value } = e.target
    setAccountAddress(value)
  }

  const handleSelectChange = (e) => {
    setSelectedOption(e)
  }
  //   const onDepositHandler = async () => {
  //     try {
  //       if (!window.ethereum)
  //         throw new Error('No crypto wallet found. Please install it.')

  //       await window.ethereum.request('eth_requestAccounts')
  //       const provider = new ethers.providers.Web3Provider(window.ethereum)
  //       const signer = provider.getSigner()
  //       console.log(signer, 'signer')
  //       ethers.utils.getAddress(bscadminAddress)
  //       const tx = await signer.sendTransaction({
  //         to: bscadminAddress,
  //         value: ethers.utils.parseEther(amountDetails.depositeAmount),
  //       })

  //       console.log('tx', tx)
  //     } catch (err) {
  //       setError(err.message)
  //     }
  //   }

  const handleCopyAddress = () => {
    copy(accountAddress)
    ToastsStore.success('Address Copied Successfully')
  }

  return (
    <>
      <div className="wallet_body">
        <Tab.Container id="left-tabs-example" defaultActiveKey="deposit">
          <Row>
            <Col sm={3} className="tab_col">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="deposit">Deposit</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="withdraw">Withdraw</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9} className="tab_content_col">
              <Tab.Content>
                <Tab.Pane eventKey="deposit">
                  <h5>Deposit</h5>
                  <Row>
                    <Col md={12}>
                      {/* <div className="mb-4">
                        <SelectInput
                          selectedOption={selectedOption}
                          handleChange={handleSelectChange}
                          data={data}
                        />
                      </div> */}


                      {/* <p>BSC Deposite Address</p>
                      <CustomInput
                        type="text"
                        iconRight={'copy'}
                        placeholder="Enter Address"
                        onChange={handleChange}
                        name="accountAddress"
                        value={accountAddress}
                        onClick={handleCopyAddress}
                      /> */}
                      <p>Tron Deposite Address</p>
                      <CustomInput
                        type="text"
                        iconRight={'copy'}
                        placeholder="Enter Address"
                        onChange={handleChange}
                        name="accountAddress"
                        value={"TF7w57FkSR4MiUNQdjJ4pecCzrSiowCAxV"}
                        onClick={handleCopyAddress}
                      />
                      {error && (
                        <Alert key="danger" variant="danger">
                          {error}
                        </Alert>
                      )}
                      {/* <Button
                        variant="primary"
                        className="primary w-100 mt-4"
                        onClick={onDepositHandler}
                      >
                        Add
                      </Button> */}
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="withdraw">
                  <h5>Withdraw</h5>

                  <Row>
                    <Col md={12}>
                      <div className="mb-4">
                        <SelectInput
                          selectedOption={selectedOption}
                          handleChange={handleSelectChange}
                          data={data}
                        />
                      </div>
                      <CustomInput
                        type="number"
                        placeholder="Enter Amount"
                        name="accountAddress"
                        handleChange={(e)=>setWithdrawAmount(e.target.value)}
                      />

                      <Button
                        variant="primary"
                        className="primary w-100 mt-4"
                        disabled={withdrawAmount >= 500 ? false : true}
                      >
                        Withdraw
                      </Button>
                      {error && (
                        <Alert key="danger" variant="danger">
                          {error}
                        </Alert>
                      )}
                    </Col>
                  </Row>

                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      <ToastsContainer store={ToastsStore} />
    </>
  )
}

export default Wallet
