/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import Sample from '../public/images/sample.png'
import {
  FaDiscord,
  FaTelegram,
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaTwitter,
} from 'react-icons/fa'
import logo from '../../assets/img/tronLogo.png'
import { Col, Nav, Row } from 'react-bootstrap'
// import GeniusGeek from '../public/images/esports/sponsor-geniusgeek.png'
// import GhostGames from '../public/images/esports/sponsor-ghostgames.png'
// import GameChat from '../public/images/esports/sponsor-gamechat.png'
// import EliteSound from '../public/images/esports/sponsor-elitesound.png'
// import DragonFire from '../public/images/esports/sponsor-dragonfire.png'

class Footer extends Component {
  render() {
    return (
      <>
        <footer id="footer" className="footer">
          <Row>
            <Col md={2}>
              <h5>I-Gaming</h5>
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link href="/">All Games</Nav.Link>
                <Nav.Link eventKey="/inhouse/dice">Dice</Nav.Link>
                <Nav.Link eventKey="/inhouse/color-prediction">Color Prediction</Nav.Link>
                <Nav.Link eventKey="/dapp/lotto">Lotto</Nav.Link>
              </Nav>
            </Col>
            <Col md={2}>
              <h5>Features</h5>
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link href="/dapp/stake/lotto">Staking</Nav.Link>
                {/* <Nav.Link eventKey="link-1">Tronsheep Box</Nav.Link> */}
                {/* <Nav.Link eventKey="link-2">Cashback</Nav.Link> */}
                {/* <Nav.Link eventKey="link-2">Daily Tasks</Nav.Link> */}
              </Nav>
            </Col>
            {/* <Col md={2}>
              <h5>Promo</h5>
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link href="/home">Promotions</Nav.Link>
              </Nav>
            </Col> */}
            {/* <Col md={2}>
              <h5>About Us</h5>
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link href="/home">News</Nav.Link> 
                <Nav.Link eventKey="link-1">Tronsheep Box</Nav.Link>
                <Nav.Link eventKey="link-2">About TSP</Nav.Link>
                <Nav.Link eventKey="link-2">Team</Nav.Link>
                <Nav.Link eventKey="link-2">Docs</Nav.Link>
                <Nav.Link eventKey="link-2">Official Mirrors</Nav.Link>
              </Nav>
            </Col> */}
            <Col md={2}>
              <h5>Help</h5>
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link href="/faq">FAQs</Nav.Link>
                {/* <Nav.Link href="link-1">Privacy policy</Nav.Link> */}
                <Nav.Link href="/terms">Terms of Service</Nav.Link>
                {/* <Nav.Link eventKey="link-2">Sportsbetting T&C</Nav.Link>
                <Nav.Link eventKey="link-2">Live Support</Nav.Link>
                <Nav.Link eventKey="link-2">Responsible Gambling</Nav.Link> */}
              </Nav>
            </Col>
            <Col md={2}></Col>
            <Col md={2}>
              <div className="last_col">
                <div className="head">
                  <i className="icon"></i>
                  <img src={logo} alt="logo" className="logo" />
                </div>
                <p>
                  this website offers gaming with risk experience. to be a user
                  of our site you must be over 18 years. we are not responsible
                  for the violation of your local laws related to i-gaming.
                  play responsibly and have fun on Tronsweep.
                </p>
              </div>
            </Col>
          </Row>

          <div className="middle_box"></div>
          <div className="bottom_box">
            <div className="social_box">
              <ul>
                <li>
                  <a href="https://www.facebook.com/tronsweep/">
                    <i className="icon facebook"></i>
                  </a>
                </li>

                <li>
                  <a href="https://www.instagram.com/tronsweep/">
                    <i className="icon instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCw3M4GK5FvhblClO063yxNA">
                    <i className="icon youtube"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/tronsweeplotto">
                    <i className="icon twitter"></i>
                  </a>
                </li>
                {/* <li>
                  <a href="https://t.me/Tronsweep">
                    <i className="icon telegram"></i>
                  </a>
                </li> */}


              </ul>
            </div>
            <div className="about_tsp_box">
              <ul>
                <li>
                  <div>
                    <i className="icon tsp_coin"></i> TSP{' '}
                    <i className="icon arrow"></i>
                    <i className="icon tronlink_icon"></i> Tronlink Pro
                  </div>
                </li>
                {/* <li>
                  <div>
                    <i className="icon btc_coin"></i> 1 BTC = $27,239.8799
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
          <p className="copyright">
            Copyrights c 2019 -2022 Tronsweep. All right reserved.{' '}
          </p>
        </footer>
      </>
    )
  }
}

export default Footer
