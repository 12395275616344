import React, { useEffect, useState } from 'react'
import axios from 'axios'

import TruncateMiddle from 'react-truncate-string'
import noData from '../../public/images/icons/no_data.svg'
import { checkTicketNumber } from '../../utils/functions'
import { useAccount } from '../../context/AccountProvider'
import TopWager from './TopWager'
let allTicketsCount = 20
let myTicketCount = 20
let winnerListCount = 20
let myWinningCount = 20
let potCount = 0

const LotteryHistory = () => {
  const { accountAddress, isUserNotloggedIn } = useAccount()
  const [allTicketStatus, setAllTicketStatus] = useState(false)
  const [myWinningStatus, setMyWinningStatus] = useState(false)
  const [activePot, setActivePot] = useState(-1)
  const [allTicketParched, setAllTicketParched] = useState([])
  const [winnerlist, setWinnerList] = useState([])
  const [myTickets, setMyTickets] = useState([])
  const [myWinning, setMyWinning] = useState([])
  const [isWinnerDataFound, setISWinnerDataFound] = useState(false)
  const [myTicketLoadMoreVisible, setMyTicketLoadMoreVisible] = useState(false)
  const [allTicketLoadMoreVisible, setAllTicketLoadMoreVisible] =
    useState(false)
  const [winnerListLoadMoreVisible, setWinnerListLoadMoreVisible] =
    useState(false)
  const [myWinningLoadMoreVisible, setMyWinningLoadMoreVisible] =
    useState(false)
  const [myTicketsData, setMyTicketsData] = useState(false)
  const [clickpot, setClickpot] = useState(0)

  const getAllTickets = async (loadmore = false) => {
    if (loadmore) {
      allTicketsCount += 20
    }
    axios
      .get(`tron/data/tickets/${allTicketsCount}`)
      .then((res) => {
        setAllTicketParched(res.data.data)
        setAllTicketStatus(res.data.status)
        if (allTicketsCount >= res.data.data.length) {
          setAllTicketLoadMoreVisible(true)
        }
      })
      .catch(() => {})
  }

  const getMyTickets = (loadmore = false) => {
    if (loadmore) {
      myTicketCount += 20
    }

    axios
      .get(`tron/user/purchase/history/${accountAddress}/${myTicketCount}`)
      .then((res) => {
        setMyTickets(res.data.data)
        setMyTicketsData(res.data.data)
        if (res.data.status) {
          if (myTicketCount > res.data.data.length) {
            setMyTicketLoadMoreVisible(false)
          }
        } else {
          setMyTicketsData(true)
        }
      })
      .catch(() => {})
  }

  const getMyWinning = (loadmore = false) => {
    if (loadmore) {
      myWinningCount += 20
    }

    axios
      .get(`tron/user/winnerByAddress/${accountAddress}/${myWinningCount}`)
      .then((res) => {
        setMyWinning(res.data.data)
        setMyWinningStatus(res.data.status)
        if (res.data.status) {
          if (myWinningCount > res.data.data.length) {
            setMyWinningLoadMoreVisible(false)
          }
        } else {
          setMyWinningStatus(true)
        }
      })
  }

  const potWiseWinner = async (potno = 0, loadmore = false) => {
    setISWinnerDataFound(false)

    if (potCount !== potno) {
      potCount = potno
      winnerListCount = 20
    }

    // if (potCount !== potno) {
    //   console.log('newCall')
    //   winnerListCount = 20
    // }

    setClickpot(potno)
    if (loadmore) {
      winnerListCount += 20
    }

    axios
      .get(`tron/data/winnerByPot/${potno}/${winnerListCount}`)
      .then((res) => {
        setISWinnerDataFound(true)
        if (res.data.status) {
          setWinnerList(res.data.data)
        } else {
          setISWinnerDataFound(false)
          setWinnerList([])
        }

        setActivePot(potno)
        if (winnerListCount > res.data.data.length) {
          setWinnerListLoadMoreVisible(false)
        } else {
          setWinnerListLoadMoreVisible(true)
        }
      })
      .catch(() => {})

    return potno
  }

  useEffect(() => {
    getAllTickets()
  }, [])

  const buttons = ['Pot 1', 'Pot 2', 'Pot 3', 'Pot 4', 'Pot 5', 'Pot 6']

  return (
    <>
      <div className="product-tabs card card--lg" style={{ marginTop: 10 }}>
        <ul
          className="nav nav-tabs nav-justified nav-product-tabs"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className="nav-link active"
              href="#tab-info"
              role="tab"
              data-toggle="tab"
              aria-selected="false"
              onClick={() => getAllTickets()}
            >
              All Tickets
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link "
              href="#tab-desciption"
              role="tab"
              data-toggle="tab"
              aria-selected="true"
              onClick={() => {
                if (isUserNotloggedIn) {
                  return
                }
                getMyTickets()
              }}
            >
              My Tickets
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              href="#tab-reviews"
              role="tab"
              data-toggle="tab"
              aria-selected="false"
              onClick={() => potWiseWinner()}
            >
              Winner List
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#tab-mywinning"
              role="tab"
              data-toggle="tab"
              aria-selected="false"
              onClick={() => {
                if (isUserNotloggedIn) {
                  return
                }
                getMyWinning()
              }}
            >
              My Winnings
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#tab-topwaggers"
              role="tab"
              data-toggle="tab"
              aria-selected="false"
              onClick={() => {
                if (isUserNotloggedIn) {
                  return
                }
              }}
            >
              Top Wagers
            </a>
          </li>
        </ul>
        {/* show purched ticket history */}
        <div className="tab-content history_tab_content">
          <div role="tabpanel" className="tab-pane fade " id="tab-desciption">
            <div className="table-responsive">
              {isUserNotloggedIn ? (
                <>
                  <div className="no_data">
                    <img src={noData} alt="no data" className="no_data_img" />
                    <h4 className="no_h4">No record found!</h4>
                  </div>
                </>
              ) : (
                <>
                  {myTicketsData ? (
                    <div>
                      {myTickets ? (
                        <>
                          <table className="table table-striped table-dark">
                            <thead>
                              <tr>
                                {/* <th>Sr. No.</th> */}
                                <th>Ticket No.</th>
                                <th>Quantity</th>
                                <th>Amount Paid</th>
                                <th>Purchase At</th>
                              </tr>
                            </thead>
                            <tbody>
                              {myTickets.map((ticket) => (
                                <tr key={ticket.id}>
                                  <td>
                                    {checkTicketNumber(ticket.ticket_numbers)}
                                  </td>
                                  <td>{ticket.ticket_count}</td>
                                  <td>{ticket.purchase_amount} TRX</td>
                                  <td>
                                    {new Date(
                                      ticket.created_at,
                                    ).toLocaleString()}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {myTicketLoadMoreVisible ? (
                            <div style={{ textAlign: 'center' }}>
                              <button
                                style={{
                                  marginBottom: 10,
                                  marginTop: 10,
                                }}
                                type="button"
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => getMyTickets(true)}
                              >
                                Load more
                              </button>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div className="no_data">
                          <img
                            src={noData}
                            alt="no data"
                            className="no_data_img"
                          />
                          <h4 className="no_h4">No record found!</h4>
                        </div>
                      )}
                    </div>
                  ) : (
                    <h4 className="no_h4">
                      {' '}
                      <span className="spinner">
                        <div className="bounce1" />
                        <div className="bounce2" />
                        <div className="bounce3" />
                      </span>
                    </h4>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            role="tabpanel"
            className="tab-pane fade active show"
            id="tab-info"
          >
            <div className="table-responsive">
              {allTicketStatus ? (
                <>
                  {allTicketParched ? (
                    <>
                      <table className="table table-striped table-dark">
                        <thead>
                          <tr>
                            <th>Address</th>
                            <th>Quantity</th>
                            <th>Ticket No.</th>
                            <th>Date</th>
                            {/* <th>Transaction ID</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {allTicketParched.map((allTicket, i) => (
                            <tr key={i}>
                              <td>
                                <p
                                  style={{
                                    marginBottom: '.5em',
                                    color: '#f5bc00',
                                  }}
                                >
                                  {allTicket.userName.toUpperCase()}
                                </p>
                                <div className="middle_truncate">
                                  <TruncateMiddle
                                    text={allTicket.public_address}
                                  />
                                </div>
                              </td>
                              <td>{allTicket.ticket_count}</td>
                              <td>
                                {checkTicketNumber(allTicket.ticket_numbers)}{' '}
                              </td>
                              <td>
                                {new Date(
                                  allTicket.created_at,
                                ).toLocaleString()}
                              </td>
                              {/* <td>
                              <div className="middle_truncate">
                                <a href={`https://tronscan.org/#/transaction/${allTicket.transaction_id}`} target="_blank">
                                <TruncateMiddle text={allTicket.transaction_id} />
                             </a>
                             </div>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {allTicketLoadMoreVisible ? (
                        <div style={{ textAlign: 'center' }}>
                          <button
                            style={{
                              marginBottom: 10,
                              marginTop: 10,
                            }}
                            type="button"
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => getAllTickets(true)}
                          >
                            Load more
                          </button>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div className="no_data">
                      <img src={noData} alt="no data" className="no_data_img" />
                      <h4 className="no_h4">No record found!</h4>
                    </div>
                  )}{' '}
                </>
              ) : (
                <h4 className="no_h4">
                  <span className="spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                  </span>
                </h4>
              )}
            </div>
          </div>
          <div role="tabpanel" className="tab-pane fade" id="tab-reviews">
            <div
              className="btn-group pot_btn_group"
              role="group"
              aria-label="Basic example"
            >
              {/* Rajesh */}
              {buttons.map((item, index, arr) => (
                <button
                  key={index}
                  type="button"
                  className={`btn btn-outline-primary btn-sm ${
                    activePot === index + 1 ? 'active_btn' : ''
                  }`}
                  onClick={() => potWiseWinner(index + 1)}
                >
                  {item}
                </button>
              ))}
            </div>
            <div className="table-responsive">
              {isWinnerDataFound ? (
                <div>
                  {winnerlist.length === 0 ? (
                    <div className="no_data">
                      <img src={noData} alt="no data" className="no_data_img" />
                      <h4 className="no_h4">No record found!</h4>
                    </div>
                  ) : (
                    <>
                      <table
                        className="table table-striped table-dark"
                        id="scrollableDiv"
                      >
                        <thead>
                          <tr>
                            {/* <th>Sr. No.</th> */}
                            <th>Address</th>
                            <th>Amount</th>
                            <th>Prize</th>
                            <th>ticket no.</th>
                            <th>date</th>
                            <th>Hash</th>
                          </tr>
                        </thead>
                        <tbody>
                          {winnerlist.map((winner) => (
                            <tr key={winner.id}>
                              <td>
                                <p
                                  style={{
                                    marginBottom: '.5em',
                                    color: '#f5bc00',
                                  }}
                                >
                                  {winner.user_name.toUpperCase()}
                                </p>
                                <div className="middle_truncate">
                                  <TruncateMiddle
                                    text={winner.public_address}
                                  />
                                </div>
                              </td>
                              <td>
                                <p>
                                  {parseInt(winner.pot_no) === 6
                                    ? '1500'
                                    : parseInt(winner.pot_no) === 5
                                    ? '30,000'
                                    : parseInt(winner.pot_no) === 4
                                    ? '300,000'
                                    : parseInt(winner.pot_no) === 3
                                    ? '3,000,000'
                                    : parseInt(winner.pot_no) === 2
                                    ? '30,000,000'
                                    : parseInt(winner.pot_no) === 1
                                    ? '300,000,000'
                                    : null}{' '}
                                  TRX
                                </p>
                              </td>
                              <td>{winner.pot_no}</td>
                              <td>{winner.winning_number}</td>
                              <td>
                                {new Date(winner.created_at).toLocaleString()}
                              </td>
                              <td>
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={
                                    'https://tronscan.org/#/transaction' +
                                    `/${winner.txn_hash}`
                                  }
                                >
                                  <div className="middle_truncate">
                                    <TruncateMiddle text={winner.txn_hash} />
                                  </div>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {winnerListLoadMoreVisible ? (
                        <div style={{ textAlign: 'center' }}>
                          <button
                            style={{
                              marginBottom: 10,
                              marginTop: 10,
                            }}
                            type="button"
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => potWiseWinner(clickpot, true)}
                          >
                            Load more
                          </button>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              ) : (
                <h4 className="no_h4">
                  <span className="spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                  </span>
                </h4>
              )}
            </div>
          </div>
          <div role="tabpanel" className="tab-pane fade" id="tab-mywinning">
            <div className="table-responsive">
              {isUserNotloggedIn ? (
                <>
                  <div className="no_data">
                    <img src={noData} alt="no data" className="no_data_img" />
                    <h4 className="no_h4">No record found!</h4>
                  </div>
                </>
              ) : (
                <>
                  {myWinningStatus ? (
                    <div>
                      {myWinning ? (
                        <>
                          <table className="table table-striped table-dark">
                            <thead>
                              <tr>
                                <th>Amount Won</th>
                                <th>Winning Number</th>
                                <th>Pot Number</th>
                                <th>Created</th>
                                <th>Hash</th>
                              </tr>
                            </thead>
                            <tbody>
                              {myWinning.map((mWinning) => (
                                <tr key={mWinning.id}>
                                  <td>{mWinning.amount_won} TRX</td>
                                  <td>{mWinning.winning_number}</td>
                                  <td>{mWinning.pot_no}</td>
                                  <td>
                                    {new Date(
                                      mWinning.created_at,
                                    ).toLocaleString()}
                                  </td>
                                  <td>
                                    <a
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      href={
                                        'https://tronscan.org/#/transaction' +
                                        `/${mWinning.txn_hash}`
                                      }
                                    >
                                      <div className="middle_truncate">
                                        <TruncateMiddle
                                          text={mWinning.txn_hash}
                                        />
                                      </div>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {myWinningLoadMoreVisible ? (
                            <div style={{ textAlign: 'center' }}>
                              <button
                                style={{
                                  marginBottom: '10px',
                                  marginTop: 10,
                                }}
                                type="button"
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => getMyWinning(true)}
                              >
                                Load more
                              </button>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div className="no_data">
                          <img
                            src={noData}
                            alt="no data"
                            className="no_data_img"
                          />
                          <h4 className="no_h4">No record found!</h4>
                        </div>
                      )}
                    </div>
                  ) : (
                    <h4 className="no_h4">
                      {' '}
                      <span className="spinner">
                        <div className="bounce1" />
                        <div className="bounce2" />
                        <div className="bounce3" />
                      </span>{' '}
                    </h4>
                  )}
                </>
              )}
            </div>
          </div>
          <div role="tabpanel" className="tab-pane fade" id="tab-topwaggers">
            <div className="table-responsive">
              <TopWager fromTab />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default LotteryHistory
