/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { InputGroup, Form, Tabs, Tab, Table } from 'react-bootstrap'
import 'react-rangeslider/lib/index.css'
import noData from '../../public/images/icons/no_data.svg'
import CircleImg from '../../../assets/img/circle.svg'
import '../../lottery/components/lottery.css'
import './egaming.css'
import '../../../assets/css/color/blue.css'
import CircleSound from '../../../assets/audio/circle.wav'
import TapSound from '../../../assets/audio/tap.mp3'
import { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
import {
  initiateCircleSocket,
  circlePlaceBet,
  circleBetResponse,
  allCircleBetData,
  circleGameState,
  circleGameResult,
  circleConnectUser,
  circleUserWinning,
} from '../../helper/socket'
import { getCircleMyBets, getAllCircleBets, getGameResults } from './Service'
import isEmpty from 'is-empty'
import moment from 'moment'
import { ToastsStore } from 'react-toasts'
import { useAccount } from '../../context/AccountProvider'
import ClockSound from '../../../assets/audio/clock-sound.mp3'
import DiceLose from '../../../assets/audio/dice-lose.mp3'
import DiceWin from '../../../assets/audio/dice-win.mp3'
import Result from '../../../assets/audio/result.mp3'
import Chat from '../../dynamicLotto/components/chat'
import { getIcon, getColorByName } from '../../utils/functions'

// To include the default styles

const minimumBet = 10

const CircleGame = () => {
  const [socket, setSocket] = useState(null)
  const [betsOn, setBetsOn] = useState(false)
  const [isTimerShow, setTimerShow] = useState(true)
  const [betTimer, setBetTimer] = useState(0)
  const [myAllBets, setMyAllBets] = useState([])
  const [allBets, setAllBets] = useState([])
  const [allBetsHistory, setAllBetsHistory] = useState([])
  const [statusMessage, setStatusMessage] = useState('-')
  const [userResultAvailable, setUserResultAvailable] = useState(false)
  const { totalTRX, tokenInUsd, onSetResultTimer, selectedToken } = useAccount()
  const [isWin, setIsWin] = useState(false)
  const [betAmount, setBetAmount] = useState(20)
  const [resultMessage, setResultMessage] = useState(null)
  const [loader, setLoader] = useState({
    blueLoader: true,
    redLoader: true,
    greenLoader: true,
    yellowLoader: true,
  })
  const [winningData, setWinningData] = useState({})

  const handleMaxBetValue = () => {
    setBetAmount(totalTRX.toFixed(6))
  }

  const getMyBetsHandler = async (loadmore) => {
    if(statusMessage == "Show Result" || statusMessage == "No more bets") return
    const res = await getCircleMyBets(loadmore)
    setMyAllBets(res.bet_data)
  }

  const getAllCircleBetsHandler = async (loadmore) => {
    if(statusMessage == "Show Result" || statusMessage == "No more bets") return
    const res = await getAllCircleBets(loadmore)
    setAllBets(res.User_bets.circle_user_bets)
  }

  const getGameResultsHandler = async () => {
    const res = await getGameResults()
    console.log(res,"getGameResultsHandler")
    // setAllBets(res.User_bets.circle_user_bets)

    setAllBetsHistory(
      res.data.map((item) => {
        return { number_picked: item.color }
      }),
    )

  }

  const handleMinBetValue = () => {
    if (betAmount === minimumBet) return
    setBetAmount(minimumBet)
  }
  const handleHalfBetValue = () => {
    if (betAmount === minimumBet) return
    let updatedAmount = betAmount / 2
    setBetAmount(updatedAmount)
  }
  const handleDoubleBetValue = () => {
    if (betAmount === minimumBet) return
    if (betAmount > totalTRX) {
      setBetAmount(totalTRX)
    }
    let updatedAmount = betAmount * 2
    setBetAmount(updatedAmount)
  }

  const handleBetAmountChange = (e) => {
    const { value } = e.target

    setBetAmount(value)
  }

  const BetTimer = ({ expiryTimestamp }) => {
    const { seconds, minutes, hours, days } = useTimer({
      expiryTimestamp,
      onExpire: () => {},
    })

    // let mili = setInterval(() => ((seconds * 10)), 10)
    return (
      <div className="bet_timer">
        <span>
          {/* <span className="num">{seconds < 10 && 0}</span> */}
          <span>{seconds}</span>
          {/* <span>{mili }</span> */}
        </span>
      </div>
    )
  }

  const updateLoader = (key) => {
    let loaderKey
    if (key === 'BLUE') {
      loaderKey = 'blueLoader'
    }
    if (key === 'GREEN') {
      loaderKey = 'greenLoader'
    }
    if (key === 'RED') {
      loaderKey = 'redLoader'
    }
    if (key === 'YELLOW') {
      loaderKey = 'yellowLoader'
    }

    setLoader((loader) => ({
      ...loader,
      [loaderKey]: true,
    }))
  }

  const betPlaceHanlder = (amount, key) => {
    if (!socket?.connected || betAmount <= 0) return
    updateLoader(key)

    localStorage.setItem("resultTime",8000)
    const data = {
      amount: amount,
      color: key,
      token: selectedToken.value,
    }

    circlePlaceBet(data)
    setTimeout(() => {
      setLoader({
        blueLoader: false,
        redLoader: false,
        greenLoader: false,
        yellowLoader: false,
      })
    }, 1000)
  }

  //
  //

  const spinWheelHandler = (number) => {
    soundEffectPlay()
    const wheel = document.getElementById('wheel')
    let spins = [54, 108, 162, 216]
    var spin = spins[Math.floor(Math.random() * spins.length)]

    const randDeg = 6.66666666667 * (number + spin)
    document.body.classList.add('is-spinning')

    wheel.style.transform = `scale(1.07) rotate(${randDeg}deg)`
    wheel.dataset.currDeg = randDeg
  }

  const soundEffectPlay = () => {
    var audio = document.getElementById('audio')
    audio.pause()
    audio.currentTime = 0
    setTimeout(function () {
      audio.pause()
      audio.currentTime = 0
    }, 6000)
    audio.play()
  }
  const soundEffectPause = () => {
    var audio = document.getElementById('audio')
    audio.pause()
  }
  const soundTapPlay = () => {
    var audio = document.getElementById('audioTap')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const clockSoundPlay = () => {
    var audio = document.getElementById('clockSound')
    audio.play()
    audio.loop = true
    audio.volume = 0.2
  }
  const clockSoundPause = () => {
    var audio = document.getElementById('clockSound')
    audio.pause()
  }

  const diceLose = () => {
    var audio = document.getElementById('diceLose')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const diceWin = () => {
    var audio = document.getElementById('diceWin')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const resultSound = () => {
    var audio = document.getElementById('result')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }

  useEffect(() => {
    getMyBetsHandler()
    getAllCircleBetsHandler()
    getGameResultsHandler()
    const socketTemp = initiateCircleSocket()
    setSocket(socketTemp)
    circleConnectUser({})
    allCircleBetData((err, data) => {
      setAllBets((prev) => [...prev, data])
    })

    document.body.classList.remove('is-spinning')
    soundEffectPause()
    circleGameResult((err, data) => {
      spinWheelHandler(data.number_picked)
      setTimeout(() => {
        setWinningData(data)
        setAllBetsHistory((prev) => [{number_picked:data.color_won}, ...prev])
        setResultMessage(data.color_won)
      }, 6000)
    })

    circleGameState((err, data) => {
      const time = new Date()
      time.setSeconds(
        time.getSeconds() +
          (data.current_counter < 15
            ? 15 - data.current_counter
            : data.current_counter),
      )
      setBetTimer(time)

      if (data.game_number_entity.game_state === 'PREPARING_FOR_BET') {
        setResultMessage(null)
        setUserResultAvailable(false)
        setBetsOn(true)
        setStatusMessage('Preparing next round')
        setTimerShow(false)
        clockSoundPause()
        setLoader({
          blueLoader: true,
          redLoader: true,
          greenLoader: true,
          yellowLoader: true,
        })
      }
      if (data.game_number_entity.game_state === 'BET_STARTED') {
        clockSoundPlay()
        setBetsOn(true)
        setTimerShow(true)
        setStatusMessage('Place Your Bets')
        setLoader({
          blueLoader: false,
          redLoader: false,
          greenLoader: false,
          yellowLoader: false,
        })
      }

      if (data.game_number_entity.game_state === 'BET_STOPPED') {
        setWinningData(null)
        clockSoundPause()
        setBetsOn(false)
        setTimerShow(false)
        setStatusMessage('No more bets')
        setLoader({
          blueLoader: true,
          redLoader: true,
          greenLoader: true,
          yellowLoader: true,
        })
        getAllCircleBetsHandler()
      }

      if (data.game_number_entity.game_state === 'RESULT_CALCULATED') {
        clockSoundPause()
        setBetsOn(false)
        setTimerShow(false)
        setStatusMessage('Show Result')
        setLoader({
          blueLoader: true,
          redLoader: true,
          greenLoader: true,
          yellowLoader: true,
        })
        setTimeout(() => {
        getAllCircleBetsHandler()
        },7000)
      }
    })

    circleBetResponse((err, data) => {
      if (!isEmpty(data.bet_data)) {
        ToastsStore.success(data.message)
        setAllBets((allBets) => [data.bet_data, ...allBets])
      } else ToastsStore.info(data.message)
    })

    circleUserWinning((err, data) => {
      setTimeout(() => {
        if (!isEmpty(data)) {
          setUserResultAvailable(true)
          let totalAmount = 0
          for (let i = 0; i < data.length; i++) {
            totalAmount += data[i].amount_won
          }
          if (totalAmount > 0) {
            setIsWin(true)
            diceWin()
          } else {
            setIsWin(false)
            diceLose()
          }
        } else {
          setUserResultAvailable(false)
          resultSound()
        }
      }, 6000)
    })

    return () => {
      clockSoundPause()
      socketTemp.close()
    }
  }, [])

  const winningColor = () => {
    let color = ''
    if (winningData?.color_won === 'BLUE') {
      color = 'blue'
    } else if (winningData?.color_won === 'GREEN') {
      color = 'green'
    } else if (winningData?.color_won === 'YELLOW') {
      color = 'yellow'
    } else if (winningData?.color_won === 'RED') {
      color = 'red'
    }
    return color
  }

  const getColorByNumber = (num) => {
    if (num == 0) return 'YELLOW'
    if (
      num === 1 ||
      num === 9 ||
      num === 11 ||
      num === 19 ||
      num === 21 ||
      num === 33 ||
      num === 35 ||
      num === 43 ||
      num === 45 ||
      num === 53
    )
      return 'GREEN'
    if (
      num === 2 ||
      num === 4 ||
      num === 6 ||
      num === 8 ||
      num === 10 ||
      num === 12 ||
      num === 14 ||
      num === 16 ||
      num === 18 ||
      num === 20 ||
      num === 22 ||
      num === 24 ||
      num === 26 ||
      num === 28 ||
      num === 30 ||
      num === 32 ||
      num === 34 ||
      num === 36 ||
      num === 38 ||
      num === 40 ||
      num === 42 ||
      num === 44 ||
      num === 46 ||
      num === 48 ||
      num === 50 ||
      num === 52
    )
      return 'BLUE'
    if (
      num === 3 ||
      num === 5 ||
      num === 7 ||
      num === 13 ||
      num === 15 ||
      num === 17 ||
      num === 23 ||
      num === 25 ||
      num === 27 ||
      num === 29 ||
      num === 31 ||
      num === 37 ||
      num === 39 ||
      num === 41 ||
      num === 47 ||
      num === 49 ||
      num === 51
    )
      return 'RED'
  }

  return (
    <>
      <div className="main_container blue-theme">
        <div className="row ">
          <div className="col-lg-8">
            <div className="dice_main circle_game">
              <div className="game">
                <div
                  className={`img_wrap ${
                    isEmpty(winningData?.color_won)
                      ? 'animate-color'
                      : winningColor()
                  }`}
                >
                  {isTimerShow && isEmpty(resultMessage) ? (
                    <BetTimer expiryTimestamp={betTimer} />
                  ) : (
                    <div
                      className="bet_timer spinner-grow text-dark"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}

                  {!isTimerShow && !isEmpty(resultMessage) ? (
                    <div className="bet_timer" style={{ fontSize: '40px' }}>
                      <span className={`${winningColor()}`}>
                        {resultMessage}
                      </span>
                    </div>
                  ) : null}

                  {statusMessage && (
                    <div className="status_message">{statusMessage}</div>
                  )}

                  <img src={CircleImg} id="wheel" />
                  <span
                    className={
                      !isEmpty(winningData?.color_won)
                        ? `arrow ${winningColor()}`
                        : 'arrow'
                    }
                  ></span>
                </div>
                <div className="history_col">
                  <ul>
                    {!isEmpty(allBetsHistory) ? 
                    allBetsHistory.slice(0, 32).map((item, i) => (
                      <li key={i}>
                      <span className={getColorByName(item.number_picked)}></span>
                      </li>
                    )) : null }
                  </ul>
                </div>
              </div>
              <div className="bet_col">
                <div className="box">
                  <div
                    className="btn_cta blue"
                    onClick={
                      loader.blueLoader
                        ? null
                        : () => {
                            soundTapPlay()
                            betPlaceHanlder(betAmount, 'BLUE')
                          }
                    }
                  >
                    {loader.blueLoader ? (
                      <div
                        className="spinner-border btn-loader"
                        role="status"
                      ></div>
                    ) : (
                      <>
                        <span>2X</span>
                      </>
                    )}
                  </div>

                  <div
                    className="btn_cta red"
                    onClick={
                      loader.redLoader
                        ? null
                        : () => {
                            soundTapPlay()
                            betPlaceHanlder(betAmount, 'RED')
                          }
                    }
                  >
                    {loader.redLoader ? (
                      <div
                        className="spinner-border btn-loader"
                        role="status"
                      ></div>
                    ) : (
                      '3X'
                    )}
                  </div>
                </div>
                <div className="box cl_box">
                  <div className="bet_details">
                    <div className="top_box">
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">
                        {selectedToken.icon}
                        </InputGroup.Text>
                        <Form.Control
                          value={betAmount}
                          onChange={handleBetAmountChange}
                          onBlur={(e) => {
                            if (e.target.value < minimumBet) {
                              setBetAmount(minimumBet)
                            }
                          }}
                          readOnly={totalTRX < minimumBet ? true : false}
                        />
                      </InputGroup>
                    </div>
                    <div className="bet_in_amount">
                      {/* <p>${(betAmount.blueBet * tokenInUsd).toFixed(6)}</p> */}
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span
                        onClick={() => {
                          soundTapPlay()
                          handleMinBetValue()
                        }}
                      >
                        Min
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          soundTapPlay()
                          handleHalfBetValue()
                        }}
                      >
                        1/2
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          soundTapPlay()
                          handleDoubleBetValue()
                        }}
                      >
                        x2
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          soundTapPlay()
                          handleMaxBetValue()
                        }}
                      >
                        Max
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="box">
                  <div
                    className="btn_cta green"
                    onClick={
                      loader.greenLoader
                        ? null
                        : () => {
                            soundTapPlay()
                            betPlaceHanlder(betAmount, 'GREEN')
                          }
                    }
                  >
                    {loader.greenLoader ? (
                      <div
                        className="spinner-border btn-loader"
                        role="status"
                      ></div>
                    ) : (
                      '5X'
                    )}
                  </div>

                  <div
                    className="btn_cta yellow"
                    onClick={
                      loader.yellowLoader
                        ? null
                        : () => {
                            soundTapPlay()
                            betPlaceHanlder(betAmount, 'YELLOW')
                          }
                    }
                  >
                    {loader.yellowLoader ? (
                      <div
                        className="spinner-border btn-loader"
                        role="status"
                      ></div>
                    ) : (
                      '50X'
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="tab_box bets_info_box mt-5">
              <Tabs
                defaultActiveKey="allBets"
                id="uncontrolled-tab-example"
                className="mb-3 justify-content-center"
                onSelect={(k) => {
                  if (k === 'allBets') {
                    getAllCircleBetsHandler()
                  } else {
                    getMyBetsHandler()
                  }
                }}
              >
                <Tab eventKey="allBets" title="All Bets">
                  {isEmpty(allBets) ? (
                    <div className="no_data">
                      <img src={noData} alt="no data" className="no_data_img" />
                      <h4 className="no_h4">No record found!</h4>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Table variant="dark">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Time</th>
                            <th>Player</th>
                            <th>Target</th>
                            <th>Bet Amount</th>
                            <th>Multiplier</th>
                            <th>Result</th>
                            <th>Payout</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allBets.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                              {moment(item.createdAt).format(
                                  'MMMM Do YYYY, h:mm:ss a',
                                )}
                              </td>
                              <td>{item.user_id}</td>
                              <td>
                                {item.color_picked === 'RED' && (
                                  <span className="cp_result_box cp_red">
                                    {item.color_picked.charAt(0)}
                                  </span>
                                )}
                                {item.color_picked === 'GREEN' && (
                                  <span className="cp_result_box cp_green">
                                    {item.color_picked.charAt(0)}
                                  </span>
                                )}
                                {item.color_picked === 'BLUE' && (
                                  <span className="cp_result_box cp_blue">
                                    {item.color_picked.charAt(0)}
                                  </span>
                                )}
                                {item.color_picked === 'YELLOW' && (
                                  <span className="cp_result_box cp_yellow">
                                    {item.color_picked.charAt(0)}
                                  </span>
                                )}
                              </td>
                              <td>
                                {' '}
                                <span>
                                <i className={`icon ${getIcon(item.token)}`}></i>{' '}
                                  {item.amount_placed}
                                </span>
                              </td>
                              <td>
                                {item.color_picked === 'YELLOW' && '50X'}
                                {item.color_picked === 'GREEN' && '5X'}
                                {item.color_picked === 'RED' && '3X'}
                                {item.color_picked === 'BLUE' && '2X'}
                              </td>
                              <td>
                                {getColorByNumber(item.result) === 'RED' && (
                                  <span className="cp_result_box cp_red">
                                    {getColorByNumber(item.result).charAt(0)}
                                  </span>
                                )}
                                {getColorByNumber(item.result) === 'GREEN' && (
                                  <span className="cp_result_box cp_green">
                                    {getColorByNumber(item.result).charAt(0)}
                                  </span>
                                )}
                                {getColorByNumber(item.result) === 'BLUE' && (
                                  <span className="cp_result_box cp_blue">
                                    {getColorByNumber(item.result).charAt(0)}
                                  </span>
                                )}
                                {getColorByNumber(item.result) === 'YELLOW' && (
                                  <span className="cp_result_box cp_yellow">
                                    {getColorByNumber(item.result).charAt(0)}
                                  </span>
                                )}
                              </td>
                              <td>
                                {isEmpty(item.amount_won) ? (
                                  <span style={{ justifyContent: 'center' }}>
                                    -
                                  </span>
                                ) : (
                                  <span className="result_green">
                                    <i className={`icon ${getIcon(item.token)}`}></i>{' '}
                                    {parseInt(item.amount_won)}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {
                        allBets.length >= 20 && allBets.length <200 ? <div style={{ textAlign: 'center' }}>
                        <button
                          style={{
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => getAllCircleBetsHandler(true)}
                        >
                          Load more
                        </button>
                      </div> : null
                      }
                    </div>
                  )}
                </Tab>
                <Tab eventKey="myBets" title="My Bets">
                  {isEmpty(myAllBets) ? (
                    <div className="no_data">
                      <img src={noData} alt="no data" className="no_data_img" />
                      <h4 className="no_h4">No record found!</h4>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Table variant="dark">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Time</th>
                            <th>Target</th>
                            <th>Bet Amount</th>
                            <th>Multiplier</th>
                            <th>Result</th>
                            <th>Payout</th>
                          </tr>
                        </thead>
                        <tbody>
                          {myAllBets.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                              {moment(item.createdAt).format(
                                  'MMMM Do YYYY, h:mm:ss a',
                                )}
                              </td>
                              <td>
                                {item.color_picked === 'RED' && (
                                  <span className="cp_result_box cp_red">
                                    {item.color_picked.charAt(0)}
                                  </span>
                                )}
                                {item.color_picked === 'GREEN' && (
                                  <span className="cp_result_box cp_green">
                                    {item.color_picked.charAt(0)}
                                  </span>
                                )}
                                {item.color_picked === 'BLUE' && (
                                  <span className="cp_result_box cp_blue">
                                    {item.color_picked.charAt(0)}
                                  </span>
                                )}
                                {item.color_picked === 'YELLOW' && (
                                  <span className="cp_result_box cp_yellow">
                                    {item.color_picked.charAt(0)}
                                  </span>
                                )}
                              </td>
                              <td>
                                {' '}
                                <span>
                                <i className={`icon ${getIcon(item.token)}`}></i>{' '}
                                  {item.amount_placed}
                                </span>
                              </td>
                              <td>
                                {item.color_picked === 'YELLOW' && '50X'}
                                {item.color_picked === 'GREEN' && '5X'}
                                {item.color_picked === 'RED' && '3X'}
                                {item.color_picked === 'BLUE' && '2X'}
                              </td>
                              <td>
                                {getColorByNumber(item.result) === 'RED' && (
                                  <span className="cp_result_box cp_red">
                                    {getColorByNumber(item.result).charAt(0)}
                                  </span>
                                )}
                                {getColorByNumber(item.result) === 'GREEN' && (
                                  <span className="cp_result_box cp_green">
                                    {getColorByNumber(item.result).charAt(0)}
                                  </span>
                                )}
                                {getColorByNumber(item.result) === 'BLUE' && (
                                  <span className="cp_result_box cp_blue">
                                    {getColorByNumber(item.result).charAt(0)}
                                  </span>
                                )}
                                {getColorByNumber(item.result) === 'YELLOW' && (
                                  <span className="cp_result_box cp_yellow">
                                    {getColorByNumber(item.result).charAt(0)}
                                  </span>
                                )}
                              </td>
                              <td>
                                {isEmpty(item.amount_won) ? (
                                  <span style={{ justifyContent: 'center' }}>
                                    -
                                  </span>
                                ) : (
                                  <span className="result_green">
                                    <i className={`icon ${getIcon(item.token)}`}></i>{' '}
                                    {parseInt(item.amount_won)}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {
                        myAllBets.length >= 20 ? 
                        <div style={{ textAlign: 'center' }}>
                        <button
                          style={{
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => getMyBetsHandler(true)}
                        >
                          Load more
                        </button>
                      </div> : null
                      }
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className="col-sm-4">
            {' '}
            <Chat />
          </div>
        </div>
      </div>
      <audio preload="auto" id="audio">
        <source src={CircleSound}></source>
      </audio>
      <audio preload="auto" id="audioTap">
        <source src={TapSound}></source>
      </audio>
      <audio preload="auto" id="diceLose">
        <source src={DiceLose}></source>
      </audio>
      <audio preload="auto" id="diceWin">
        <source src={DiceWin}></source>
      </audio>
      <audio preload="auto" id="result">
        <source src={Result}></source>
      </audio>
      <audio preload="auto" id="clockSound">
        <source src={ClockSound}></source>
      </audio>
    </>
  )
}

export default CircleGame
