/* eslint-disable no-nested-ternary */
/* eslint-disable react/static-property-placement */
import React, { useContext, useEffect, useState } from 'react'

import { ToastsContainer, ToastsStore } from 'react-toasts'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'

import tron from '../../public/images/icons/tron_icon.png'
import Utils from '../../utils/index'
import { tokencontractAddress } from '../../utils/ContractAddress'

import { AppContext } from '../../context/index'
import { useAccount } from '../../context/AccountProvider'
import { useLottery } from '../../context/LotteryProvider'
// import config from '../../../config.json'

const UnfreezeModal = (props) => {
  const { close, show } = props
  const context = useContext(AppContext)
  const {
    setUnfreeze,
    setUpdateUnfreezeData,
    frezeeamount,
    setUnfreezeDice,
    setUpdatedFrezeeAmount,
  } = context
  const { accountAddress } = useAccount()
  const { freezeLottoTokens, onSubmitFreezeLottoTokens } = useLottery()
  const [requnFreeze, setRequnFreeze] = useState(0)
  const [loading, isLoading] = useState(false)
  const [unfreezed, setUnfreezed] = useState(false)
  const [isBigValue, setIsBigValue] = useState(false)

  const setUnfreezeHandler = async () => {
    setUnfreezed(true)
    setUnfreeze(unfreezed)
  }

  const unfreezeSweepToken = async () => {
    try {
      isLoading(true)
      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      await tokencontract
        .subscribeUnfreezeRequest(requnFreeze * 10 ** 8)
        .send({ callValue: 500000 })

      ToastsStore.success('Unfrozen successfully')

      freezeTokenHandler()
      getUnfreezeReq()
      unfreezeRequest()

      close()

      isLoading(false)
    } catch (error) {
      isLoading(false)
      console.log(error, 'error_c')
      ToastsStore.error('Transcation failed')
    }
  }

  const getUnfreezeReq = () => {
    axios
      .get(`tron/user/getUnfreezeReq/${accountAddress}`)
      .then((res) => {
        setUpdateUnfreezeData(res)
      })
      .catch((error) => {
        console.log(error, 'res')
      })
  }

  const handleAll = () => {
    setRequnFreeze(Math.floor(freezeLottoTokens * 100) / 100)
  }

  const handleunFreezeToken = (event) => {
    setRequnFreeze(+event.target.value)
    if (event.target.value > freezeLottoTokens) {
      setIsBigValue(true)
    } else {
      setIsBigValue(false)
    }
  }

  const unfreezeRequest = async () => {
    try {
      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      let dice = await tokencontract.unfreezeRequest(accountAddress).call()
      dice = +dice
      setUnfreezeDice(dice)
    } catch (error) {
      console.log(error)
    }
  }

  const freezeTokenHandler = async () => {
    try {
      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      const freeze = await tokencontract.frozenBalance(accountAddress).call()
      const freezetoken = +freeze / 10 ** 8

      setUpdatedFrezeeAmount(freezetoken)
      onSubmitFreezeLottoTokens(freezetoken)
      setRequnFreeze(Math.floor(freezetoken * 100) / 100)
    } catch (error) {
      console.log(error, 'unfreezAmount')
    }
  }

  useEffect(() => {
    freezeTokenHandler()
  }, [])

  return (
    <>
      <Modal
        show={show}
        onHide={close}
        size="sm"
        dialogClassName="withdraw_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Unfreeze</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <img className="icon_img_trs" height="30" src={tron} alt="" />
                </span>
              </div>
              <input
                type="number"
                className="form-control"
                value={requnFreeze}
                onChange={handleunFreezeToken}
              />
              <div
                className="input-group-append"
                aria-hidden
                onClick={handleAll}
              >
                <span className="input-group-text">
                  <a href="#!">All</a>
                </span>
              </div>
              {isBigValue ? (
                <small className="text-danger">
                  Input value can not be more than your frozen Lotto amount
                </small>
              ) : null}
            </div>
          </div>
          {requnFreeze > 0 ? (
            !loading ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={unfreezeSweepToken}
              >
                Unfreeze
              </button>
            ) : (
              <button type="button" className="btn btn-primary">
                <i className="fa fa-refresh fa-spin" />
              </button>
            )
          ) : (
            <button type="button" className="btn btn-primary" disabled>
              Unfreeze
            </button>
          )}
        </Modal.Body>
      </Modal>

      <ToastsContainer store={ToastsStore} />
    </>
  )
}
export default UnfreezeModal
