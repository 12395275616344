/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-multi-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useContext, useEffect } from 'react'
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom'
import TronWeb from 'tronweb'
import axios from 'axios'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Reactour from 'reactour'

import Home from './app/components/home'
// import Profile from './app/user/profile'
import Lottery from './app/lottery/components/lottery'
// TRON IMPORT
import Navbar from './app/includes/navbar'
import Footer from './app/includes/footer'

import TokenSale from './app/components/tokensale/TokenSale'

import FAQPage from './app/pages/FAQPage'
import BecomeDealer from './app/lottery/components/pages/BecomeDealer'
import WinningBox from './app/lottery/components/winning_box'
// import League from "./app/components/League";
// import Deposit from './app/user/Deposit';
// eslint-disable-next-line no-unused-vars
import Dice from './app/components/eGaming/Dice'
// import Notifications from './app/components/Notifications'
// import Dividend from './app/user/Dividend'
import MyAccountLayout from './app/user/MyAccountLayout'
import { useLottery } from './app/context/LotteryProvider'
import { AppContext } from './app/context'
import { useAccount } from './app/context/AccountProvider'
import {
  tokencontractAddress,
  tspcontractAddress,
  contractAddress,
} from './app/utils/ContractAddress'

import { useTSPToken } from './app/context/TSPProvider'
import WagersAndPrizePot from './app/user/WagersAndPrizePot'
import { useWinningBox } from './app/context/WinningBoxProvider'
import Tutorial from './app/pages/tutorial'
import { Modal } from 'react-bootstrap'
import { ascii_to_hex } from './app/utils/functions'
import StakeAndEarn from './app/pages/StakeAndEarn'
import Sidebar from './app/includes/Sidebar'
import { useCallback } from 'react'
import ColorPrediction from './app/components/eGaming/ColorPrediction'
import FooterNavbar from './app/includes/FooterNavbar'
import SidebarMobile from './app/includes/SidebarMobile'
import { useSidebarToggle } from './app/context/SidebarToggleProvider'
import CircleGame from './app/components/eGaming/CircleGame'
import DynamicLotto from './app/dynamicLotto/components/lottery'
import UnderConstruction from './app/pages/underConstruction'
import ToMoon from './app/components/eGaming/ToMoon'
import Terms from './app/pages/terms'
import UserActivity from './app/user/userActivity'
import AccountStatement from './app/user/accountStatement'
import ChangePassword from './app/user/changePassword'
import Roulette from './app/components/eGaming/roulette/roulette'

let tempAddress
let contract
export default function App() {
  const context = useContext(AppContext)
  const {
    setDataLoaded,
    setAccountAddress,
    setAccountBalance,
    setWinningBoxData,
    setIsDealer,
    setBuyTicket,
    isDataLoaded,

    handleOpenLottoDividend,
  } = context

  const {
    onSubmitAccountAddress,
    onSubmitAccountBalance,
    accountAddress,
    accountBalance,
    onSubmitNoWalletDetect,
    onSubmitUserLoggedIn,
    onSubmitRefferalCode,
    onSubmitWalletBalance,
  } = useAccount()
  const { onSubmitLottoTokens } = useLottery()
  const { onSubmitTSPToken } = useTSPToken()
  const {
    onSubmitWinningBoxOpen,
    isWinningBoxOpen,
    onSubmitWinningBoxData,
    winningBoxData,
  } = useWinningBox()
  const { setIsOpen, isOpen } = useSidebarToggle()

  const [accountAddressLocal, setAccountAddressLocal] = useState('')

  //const [winningNumbers, setWinningNumbers] = useState(0)
  //const [dividend, setDividend] = useState('')
  //const [fetch, setFetch] = useState(false)
  const [refCode, setRefCode] = useState('')
  const [username, setUserName] = useState('')
  const [isTourOpen, setIsTourOpen] = useState(false)
  //const [unfreezedice, setUnfreezeDice] = useState(0)
  //const [tronWeb, setTronWeb] = useState({})
  const [openNoticeModal, setOpenNoticeModal] = useState(false)

  const handleCloseNoticeModal = () => {
    setOpenNoticeModal(false)
    localStorage.setItem('readNotice', true)
  }
  const handleOpenNoticeModal = () => setOpenNoticeModal(true)

  const getBalance = async () => {
    //if wallet installed and logged , getting TRX token balance
    if (window.tronWeb && window.tronWeb.ready) {
      let walletBalances = await window.tronWeb.trx.getAccount(
        window.tronWeb.defaultAddress.base58,
      )
      return walletBalances
    } else {
      return 0
    }
  }

  const isWalletBlocked = () => {
    let walletList = ["TBCDKhETbBXkN1ozCncF5RGjgY1RKKVCEo","TAW4v89xL5RiuzT4BvfsgYk1dYRa7f26g2"]
    localStorage.setItem('walletBlocked', walletList.includes(window.tronWeb.defaultAddress.base58))
    return walletList.includes(window.tronWeb.defaultAddress.base58)
  }

  const getWalletDetails = async () => {
    if (window.tronWeb && !isWalletBlocked()) {
      //checking if wallet injected
      if (window.tronWeb.ready) {
        let tempBalance = await getBalance()
        //  let tempFrozenBalance = 0

        if (!tempBalance.balance) {
          tempBalance.balance = 0
        }

        //we have wallet and we are logged in
        window.consoleClearHandler('WALLET CONNECTED')
        setAccountAddressLocal(window.tronWeb.defaultAddress.base58)
        const loginType = localStorage.getItem('loginType')
        if (loginType === 'tronlink') {
          onSubmitAccountAddress(window.tronWeb.defaultAddress.base58)
        }
        //setAccountAddress(window.tronWeb.defaultAddress.base58)
        tempAddress = window.tronWeb.defaultAddress.base58

        onSubmitAccountBalance(tempBalance.balance / 1000000)
        setAccountBalance(tempBalance.balance / 1000000)

        setDataLoaded(true)
      } else {
        //we have wallet but not logged in
        // setMyMessage(<h3>WALLET DETECTED PLEASE LOGIN</h3>);

        setAccountAddressLocal('')
        onSubmitAccountAddress('')
        setAccountAddress('')
        onSubmitAccountBalance(0)
        setAccountBalance(0)
        setDataLoaded(true)
      }
    } else {
      //wallet is not detected at all
      onSubmitNoWalletDetect(true)
      setDataLoaded(true)
      window.consoleClearHandler('WALLET NOT DETECTED')
    }
  }
  useEffect(() => {
    window.addEventListener('message', (res) => {
      if (res.data.message && res.data.message.action == 'setAccount') {
        if (window.tronWeb) {
          if (res.data.message.data.address !== tempAddress) {
            localStorage.clear()
            window.location.reload()
          }
        }
      }
    })
  }, [])

  useEffect(() => {
    openTour()

    let interval

    interval = setInterval(async () => {
      getWalletDetails()
      //wallet checking interval 2sec
    }, 2000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (accountAddressLocal) {
      const fetchData = async () => {
        const res = await Signupcall(accountAddressLocal)

        onSubmitRefferalCode(res?.data?.referal_code)
      }
      fetchData()
      signupCheck(accountAddressLocal)
      sweepTokenHandler()
      onSubmitNoWalletDetect(false)
      onSubmitUserLoggedIn(false)
    } else {
      onSubmitNoWalletDetect(true)
      onSubmitUserLoggedIn(true)
    }

    window.addEventListener('message', (res) => {
      if (res.data.message && res.data.message.action === 'setAccount') {
        if (window.tronWeb) {
          if (res.data.message.data.address !== accountAddressLocal) {
            signupCheck(res.data.message.data.address)
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountAddressLocal])

  const sweepTokenHandler = async () => {
    if (accountAddressLocal && tokencontractAddress) {
      try {
        const tokencontract = await window.tronWeb
          .contract()
          .at(tokencontractAddress)
        const sweep = await tokencontract.balanceOf(accountAddressLocal).call()
        const sweeptoken = +sweep / 10 ** 8

        onSubmitLottoTokens(sweeptoken)
      } catch (error) {
        console.log(error, 'errorSweep')
      }
    }
  }

  const tspsweepTokenHandler = async () => {
    if (accountAddress) {
      try {
        let tokencontract = await window.tronWeb
          .contract()
          .at(tspcontractAddress)
        const sweep = await tokencontract.balanceOf(accountAddress).call()
        const sweeptoken = +sweep

        onSubmitTSPToken(sweeptoken)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (accountAddress) {
      tspsweepTokenHandler()
    }
  }, [accountAddress])

  const closeTour = () => {
    localStorage.setItem('end_tour', true)

    setIsTourOpen(false)
  }

  const openTour = () => {
    if (!localStorage.getItem('end_tour')) {
      setIsTourOpen(true)
    }
  }

  const getWinningData = () => {
    axios
      .get(`tron/user/getWinningDataForWinningBox/${accountAddressLocal}`)
      .then((res) => {
        const resData = res.data.winningData
        if (resData.length > 0) {
          setWinningBoxData(true, resData)
          onSubmitWinningBoxOpen(true)
          onSubmitWinningBoxData(resData)
        }
      })
      .catch(() => {
        // console.log(res, 'unfreezeRes')
      })
  }

  const changeGreetStatus = () => {
    const payload = {
      publicAddress: accountAddress,
      winningNumber: winningBoxData.map((item) => {
        return item.winning_number
      }),
    }
    axios
      .post(`tron/user/changeGreetStatus/`, payload)
      .then((res) => {
        console.log(res, 'changeGreetStatus')
        onSubmitWinningBoxOpen(false)
      })
      .catch((res) => {
        console.log(res, 'Error')
      })
  }

  const signupCheck = async (address) => {
    try {
      const ref = window.location.href.split('=')
      const resp = await Signupcall(address, ref[1])
      if (Object.keys(resp.data).includes('message')) {
        ToastsStore.success(resp.data.message)
      }
      if (resp.data.status && ref[1]) {
        axios
          .post(`tron/user/manageReferrals`, {
            public_address: address,
            refCode: ref[1],
          })
          .then(() => {
            ToastsStore.success('Referral code applied successfully')
          })
          .catch((error) => {
            console.log(error)
          })
      }

      getWinningData()
      const winningD = []
      const winningNumbers = []

      if (resp.data.winningData.length > 0) {
        for (let i = 0; i < resp.data.winningData.length; i++) {
          winningD.push(resp.data.winningData[i])
          winningNumbers.push(resp.data.winningData[i].winning_number)

          onSubmitAccountBalance(winningNumbers)
        }
      }
      if (resp.data.referal_code != null) {
        setRefCode(resp.data.referal_code)
      }

      setUserName(resp.data.userName)
      setIsDealer(resp.data.is_dealer)
    } catch (error) {
      console.log(error, 'dallaError')
    }
  }

  // sign up api call
  const Signupcall = async (publicAddress, ref = null) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`tron/user/signup`, {
          publicAddress,
          referalCode: ref,
        })
        .then((res) => {
          resolve(res)

          // this.setState({ buyTicket: res.data.buy_Ticket })
          setBuyTicket(res.data.buy_Ticket)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  }

  useEffect(() => {
    const isRead = localStorage.getItem('readNotice')
    if (!isRead) {
      handleOpenNoticeModal()
    }
  }, [])

  const noticeModalCloseHanlder = () => {
    localStorage.setItem('readNotice', true)
    handleCloseNoticeModal()
  }

  const gameWaltbalance = useCallback(async () => {
    try {
      contract = await window.tronWeb.contract().at(contractAddress)
      const valtBal = await contract.userVaultBalance(accountAddress).call()
      const gameWaltbal = TronWeb.fromSun(valtBal)

      const walletBalanceTemp = Math.floor(gameWaltbal * 100) / 100

      onSubmitWalletBalance(walletBalanceTemp)
    } catch (error) {
      console.log(error, 'error')
    }
  }, [accountAddress])
  // useEffect(() => {
  //   if (accountAddress) {
  //     gameWaltbalance()
  //   }
  // }, [accountAddress])

  return (
    <>
      <Router>
        {/* <MobileMenu
          pageWrapId="page-wrap"
          outerContainerId="App"
          username={username}
          accountAddress={accountAddress}
          accountBalance={accountBalance}
          refCode={refCode}
        /> */}

        {isDataLoaded ? (
          <>
            {isWinningBoxOpen && (
              <WinningBox
                winningData={winningBoxData}
                close={() => changeGreetStatus()}
                getWinningData={getWinningData}
              />
            )}

            <Navbar
              accountAddress={accountAddress}
              accountBalance={accountBalance}
              fetch={fetch}
              refCode={refCode}
              username={username}
              openTour={openTour}
              closeTour={closeTour}
            />
            {/* <MobileHeader/> */}
            <div className="main_wrapper">
              <div
                className="menu_overlay"
                onClick={() => setIsOpen((prev) => !prev)}
              ></div>
              <Sidebar />
              <SidebarMobile />
              <div className="content_wrap">
                <Route exact path="/" component={Home} />
                {/* <Route exact path="/ico" component={Ico} /> */}
                {/* <Route exact path="/my-account" component={Profile} /> */}
                <Route exact path="/dividend/">
                  <MyAccountLayout
                    accountAddress={accountAddress}
                    accountBalance={accountBalance}
                  />
                </Route>
                <Route exact path="/dapp/stake/lotto">
                  <StakeAndEarn />
                </Route>
                <Route exact path="/account-activity">
                  <UserActivity />
                </Route>
                <Route exact path="/account-statement">
                  <AccountStatement />
                </Route>
                <Route exact path="/change-password">
                  <ChangePassword />
                </Route>
                <Route exact path="/account">
                  <MyAccountLayout />
                </Route>
                <Route exact path="/referral/">
                  <MyAccountLayout />
                </Route>
                <Route exact path="/dealer/referral/">
                  <MyAccountLayout />
                </Route>
                <Route exact path="/tronsweep-token/">
                  <MyAccountLayout />
                </Route>
                <Route exact path="/notifications/">
                  <MyAccountLayout />
                </Route>
                <Route exact path="/wagers-and-pot-prize">
                  <MyAccountLayout />
                </Route>

                {/* <Route exact path={"/slider"} component={Slider} /> */}
                <Route exact path="/dapp/lotto">
                  <Lottery accountAddress={accountAddress} />
                </Route>
                <Route exact path="/partner/mtw/lotto/:id">
                  <DynamicLotto accountAddress={accountAddress} />
                </Route>
                <Route exact path="/faq">
                  <FAQPage />
                </Route>

                <Route exact path="/learn">
                  <Tutorial />
                </Route>
                <Route exact path="/terms">
                  <Terms />
                </Route>
                {/* <Route exact path="/becomedealer">
                  <BecomeDealer />
                </Route> */}
                <Route exact path="/inhouse/dice">
                  <Dice />
                </Route>
                <Route exact path="/inhouse/roulette">
                  <Roulette />
                </Route>
                <Route exact path="/inhouse/color-prediction">
                  <ColorPrediction />
                </Route>
                <Route exact path="/inhouse/fortune-wheel">
                  <CircleGame />
                </Route>
                <Route exact path="/inhouse/to-moon">
                  <ToMoon />
                </Route>

                <Route exact path="/tokensale">
                  <TokenSale />
                </Route>
              </div>
            </div>

            <FooterNavbar />

            {/* <Route
      exact
      path={"/league"}
      render={() => {
        return <League />;
      }}
    /> */}
            <Tour
              isOpen={isTourOpen}
              accountAddress={accountAddress}
              closeTour={closeTour}
              handleOpenDividend={handleOpenLottoDividend}
            />

            <ToastsContainer store={ToastsStore} />
            <Footer />
          </>
        ) : (
          <div className="loader_container">
            <div className="item item-1" />
            <div className="item item-2" />
            <div className="item item-3" />
            <div className="item item-4" />
          </div>
        )}
      </Router>
    </>
  )
}

const Tour = withRouter(
  ({
    isOpen,
    closeTour,
    location: { pathname },
    history,
    disableBody,
    enableBody,
    accountAddress,
    handleOpenDividend,
  }) => {
    const steps = [
      accountAddress
        ? {
            selector: '[data-tut="step-1"]',
            content: 'Your Profile.',
            style: {
              color: 'black ',
            },
          }
        : {
            selector: '[data-tut="step-1"]',
            content: () => (
              <div className="tour_desc">
                <h4>Login to your tron wallet here</h4>
                <p>
                  Login to your TRONLink wallet or install using
                  <a href="http://u6.gg/gmc5D"> http://u6.gg/gmc5D</a> and
                  download the chrome extension. (Tronsweep is only availble
                  using Chrome browser for the time being)
                </p>
              </div>
            ),
            style: {
              color: 'black ',
            },
          },
      ...(pathname === '/'
        ? [
            {
              selector: '[data-tut="step-3"]',
              content: 'Buy Token',
              action: () => history.push('/dapp/lotto'),
              style: {
                color: 'black ',
              },
            },
          ]
        : pathname === '/ico'
        ? [
            {
              selector: '.buy_btn',
              content: () => (
                <div className="tour_desc">
                  <h4>Buy Token</h4>
                  <p>
                    Select the number of tokens you want to buy, and accept the
                    confirmation from Tronlink wallet.
                  </p>
                </div>
              ),

              style: {
                color: 'black ',
              },
            },
          ]
        : [
            {
              selector: '[data-tut="step-3"]',
              content: () => (
                <div className="tour_desc">
                  <h4>Buy Tickets</h4>
                  <p>
                    Select the number of tickets you want to buy, and accept the
                    confirmation from Tronlink wallet.
                  </p>
                </div>
              ),
              style: {
                color: 'black ',
              },
            },
            {
              selector: '[data-tut="step-4"]',
              content: () => (
                <div className="tour_desc">
                  <h4>Lotto Dividend</h4>
                  <p>
                    You can check the dividend status, lotto tokens and your
                    share from the dividend pool.
                  </p>
                </div>
              ),
              style: {
                color: 'black ',
              },
              action: () => {
                // const btn = document.getElementById('lottoDividendBtn')
                // btn.click()
                console.log('checkLog')
              },
            },
            {
              selector: '[data-tut="step-5"]',
              content: () => (
                <div className="tour_desc">
                  <h4>Freeze Lotto Token</h4>
                  <p>
                    As you buy the lottery ticket, you are rewarded with lotto
                    token as per minning chart. You can freeze lotto token to
                    get dividend on daily basis.
                  </p>
                </div>
              ),
              style: {
                color: 'black ',
              },
              action: () => {
                // const btn = document.getElementById('lottoDividendBtn')
                // btn.click()
                // handleOpenDividend()
              },
            },
            {
              selector: '[data-tut="step-6"]',
              content: () => (
                <div className="tour_desc">
                  <h4>Game Wallet</h4>
                  <p>
                    Your share of dividend from games, winninig prize and profit
                    earned from becoming a dealer or refferal are sent in game
                    wallet.
                  </p>
                </div>
              ),
              style: {
                color: 'black ',
              },
              // action: () => {
              //   const btn = document.querySelector(
              //     '.dividend_modal button.close',
              //   )
              //   btn.click()
              // },
            },
            {
              selector: '[data-tut="step-7"]',
              content: () => (
                <div className="tour_desc">
                  <h4>Freeze TSP Token</h4>
                  <p>
                    For the frozen TSP Token, assured return of 3x possible for
                    the first round of the Tronweep Mega Lotto. Sweep Token
                    Holders will get dividend from all the games available on
                    the platform.
                  </p>
                </div>
              ),
              style: {
                color: 'black ',
              },
              // action: () => {
              //   const btn = document.getElementById('tspDividendBtn')
              //   btn.click()
              // },
            },
            {
              selector: '[data-tut="step-8"]',
              content: () => (
                <div className="tour_desc">
                  <h4>Referral</h4>
                  <p>
                    Generate refral code and invite your friends to the
                    platform.
                  </p>
                </div>
              ),

              style: {
                color: 'black ',
              },
              // action: () => {
              //   const btn = document.getElementById('closeTspDividend')
              //   if (btn) {
              //     btn.click()
              //   }
              // },
            },
            {
              selector: '[data-tut="step-9"]',
              content: () => (
                <div className="tour_desc">
                  <h4>Token Sale</h4>
                  <p />
                </div>
              ),

              style: {
                color: 'black ',
              },
              action: () => {
                // var btn = document.getElementById("tokenSaleBtn");
                // btn.click();
                closeTour()
              },
            },
          ]),
    ]
    let disableBodyTrour = disableBody
    let enableBodyTour = enableBody
    disableBodyTrour = (target) => disableBodyScroll(target)
    enableBodyTour = (target) => enableBodyScroll(target)

    return (
      <Reactour
        steps={steps}
        isOpen={isOpen}
        accentColor="#f5bc00"
        onRequestClose={closeTour}
        update={pathname}
        onAfterOpen={disableBodyTrour}
        onBeforeClose={enableBodyTour}
      />
    )
  },
)

// export default App
// App.contextType = AppContext
