import React from 'react'
import Select from 'react-select'

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#262628',
    border: 0,
    color: '#fff',
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box

    backgroundColor: '#262628',
    // kill the gap
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? 'red' : '#262628',
      color: '#8B8B8B',
      cursor: isDisabled ? 'not-allowed' : 'default',
      border: 0,
      zIndex: 1,
    }
  },
  singleValue: (provided) => ({
    ...provided,
    color: '#F5BC00',
  }),
}

function SelectInput({ selectedOption, handleChange, data }) {
  return (
    <>
      <Select
        styles={colourStyles}
        placeholder="Select Option"
        value={selectedOption}
        options={data}
        onChange={handleChange}
        getOptionLabel={(e) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {e.icon}
            <span style={{ marginLeft: 5 }}>{e.value}</span>
            <span style={{ marginLeft: 38, color : "White" }}>{e.text}</span>
          </div>
        )}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </>
  )
}

export default SelectInput
