import React, { createContext, useContext } from 'react'
import { useState } from 'react'

const TSPTokenContext = createContext()

export function useTSPToken() {
  return useContext(TSPTokenContext)
}

export default function TSPTokenProvider({ children }) {
  const [tspToken, setTSPToken] = useState(0)
  const [tspUnfreezeToken, setTSPUnfreezeToken] = useState(0)
  const [tspHolderShare, setTspHolderShare] = useState(0)
  const [tspTotalFrozen, setTspTotalFrozen] = useState(0)
  const [tspReceiveAmount, setTspReceiveAmount] = useState(0)
  const [isRecieveBalanceLoading, setIsRecieveBalanceLoading] = useState(false)

  const onSubmitTSPToken = (data) => {
    setTSPToken(data)
  }
  const onSbumitTSPUnfreezeToken = (data) => {
    setTSPUnfreezeToken(data)
  }
  const onSubmitTspHolderShare = (amount) => {
    setTspHolderShare(amount)
  }
  const onSubmitTspFrozen = (amount) => {
    setTspTotalFrozen(amount)
  }
  const onSubmitTspRecieveAmount = (amount) => {
    setTspReceiveAmount(amount)
  }

  const values = {
    tspToken,
    tspUnfreezeToken,
    tspHolderShare,
    tspTotalFrozen,
    tspReceiveAmount,
    isRecieveBalanceLoading,
    onSubmitTSPToken,
    onSbumitTSPUnfreezeToken,
    onSubmitTspHolderShare,
    onSubmitTspFrozen,
    onSubmitTspRecieveAmount,
    setIsRecieveBalanceLoading,
  }

  return (
    <TSPTokenContext.Provider value={values}>
      {children}
    </TSPTokenContext.Provider>
  )
}
