/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect, useCallback } from 'react'
import TronWeb from 'tronweb'
import ReactTooltip from 'react-tooltip'
import Countdown from 'react-countdown-now'
import moment from 'moment'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { AppContext } from '../../context'
import FreezeModal from './freezeModal'
import UnfreezeModal from './unfreezeModal'
import { useAccount } from '../../context/AccountProvider'
import { useLottery } from '../../context/LotteryProvider'
import axios from 'axios'

import {
  contractAddress,
  tokencontractAddress,
} from '../../utils/ContractAddress'
import SkeletonLoader from '../../components/SkeletonLoader'
import { getPublicDividend } from '../Service'
const tomorrow = moment(new Date()).add(1, 'days')
const timerTimestamp = new Date(tomorrow).getTime()

const levelToken = 10000000
const LottoDividend = ({
  stage,
  level,
  totalfrozen,
  totalmineyet,
  loading,
  uloading,
  cancelunfreeze,
  currentBar,
  lotteryData,
  fromStake,
}) => {
  const context = useContext(AppContext)
  const { accountAddress, isUserNotloggedIn, whlBalance, userBalanceData } =
    useAccount()
  const {
    lottoTokens,
    freezeLottoTokens,
    receiveBalance,
    totalFrozenBalance,
    onSubmitRecieveBalance,
    setIsRecieveBalanceLoading,
    isRecieveBalanceLoading,
    onSubmitFreezeLottoTokens,
  } = useLottery()
  const [show, setShow] = useState(false)
  const [showUnfreeze, setShowUnfreeze] = useState(false)
  const [currentdifficulty, setCurrentdifficulty] = useState(0)
  const [dividendtimer, setDividendtimer] = useState(0)
  const [dividend, setDividend] = useState(0)
  const [dataLoading, setDataLoading] = useState(false)
  const [lottoPublicData, setLottoPublicData] = useState({})
  const [isPublicDataLoading, setIsPublicDataLoading] = useState(false)
  const [tokenData, setTokenData] = useState({
    available: {},
    frozen: {},
  })

  const {
    sweepToken,
    frezeeamount,
    unfreezeclick,
    unfreezeTime,
    unfreezeTimmer,
    unfreezedice,
    receive,
  } = context

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const unfreezeHandleClose = () => setShowUnfreeze(false)
  const unfreezeHandleShow = () => setShowUnfreeze(true)

  const freezeToken = async () => {
    setIsRecieveBalanceLoading(true)
    if (accountAddress) {
      try {
        const tokencontract = await window.tronWeb
          .contract()
          .at(tokencontractAddress)
        const freeze = await tokencontract.frozenBalance(accountAddress).call()
        const freezetoken = +freeze / 10 ** 8

        console.log(freezetoken, 'freezetoken')

        onSubmitFreezeLottoTokens(freezetoken)
      } catch (error) {
        console.log(error, 'error')
      }
    }
  }

  const receiveHandler = useCallback(async () => {
    try {
      const contract = await window.tronWeb.contract().at(contractAddress)
      const ticketbal = await contract.dividendPool().call()
      const percentvalue = await contract.divDistributePercentage().call()

      const percent = parseInt(percentvalue._hex, 16)
      const dividendTemp = TronWeb.fromSun(parseInt(ticketbal, 10))

      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      const totalfrozen = await tokencontract.frozenTotal().call()
      const totalFrozenBalanceTemp = +totalfrozen / 10 ** 8

      const receivebal =
        (((+dividendTemp * percent) / 100 / totalFrozenBalanceTemp) *
          freezeLottoTokens *
          10 ** 8) /
        10 ** 8

      setDividend(dividendTemp)
      onSubmitRecieveBalance(receivebal)
      setIsRecieveBalanceLoading(false)
    } catch (error) {
      console.log(error, 'error')
    }
  }, [freezeLottoTokens, totalFrozenBalance])

  const dividendDistributionTime = async () => {
    setDataLoading(true)
    try {
      let tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      // let freezeblockTime = await tokencontract.FREEZE_TOKEN_BLOCK_TIME().call()
      // freezeblockTime = +freezeblockTime
      // let time = await tokencontract.dividendDistributionTime().call()
      // time = +time * 1000
      let difficultylevel = await tokencontract.difficultyLevel().call()
      difficultylevel = +difficultylevel
      setCurrentdifficulty(50 + difficultylevel)

      setDataLoading(false)
    } catch (error) {
      console.log(error)
      setDataLoading(false)
    }
  }
  useEffect(() => {
    freezeToken()
    dividendDistributionTime()
  }, [])

  useEffect(() => {
    receiveHandler()
  }, [receiveHandler])

  const getPublicDividendDataHandler = async () => {
    setIsPublicDataLoading(true)
    //setIsRecieveBalanceLoading(true)
    const res = await getPublicDividend(lotteryData)

    console.log(res, 'fromStake')
    if (fromStake) {
      for (const dData of res.data.dividend_pools) {
        if (dData.token === 'TRX') {
          setLottoPublicData(dData)
          setDividendtimer(dData.distributionTime * 1000)
        }
      }
    }

    setIsPublicDataLoading(false)
    // setIsRecieveBalanceLoading(false)
    // }

    // else {
    //   setIsPublicDataLoading(false)
    //   // setIsRecieveBalanceLoading(false)
    // }
  }

  useEffect(() => {
    getPublicDividendDataHandler()
  }, [])

  const youWillRecieve =
    ((lottoPublicData?.availableDividend * 0.9) /
      lottoPublicData?.totalFrozen) *
    whlBalance

  console.log(userBalanceData, 'userBalanceData')

  useEffect(() => {
    if (fromStake) {
      const availableData = userBalanceData?.token_balance?.find(
        (t) => t.token === 'PLAY',
      )
      const frozenData = userBalanceData?.frozen_balance?.find(
        (t) => t.token === 'PLAY',
      )
      console.log(availableData, 'availableData')
      setTokenData({ available: availableData, frozen: frozenData })
    }
  }, [fromStake])

  return (
    <div>
      <div className="accordion accordion--space-between" id="accordionFaqs">
        <div className="w-100">
          <div
            id="collapse-0"
            className="collapse show"
            aria-labelledby="heading-0"
            data-parent="#accordionFaqs"
          >
            <div className="card__content text-center">
              <p className="mb-0">
                Current stage :{stage}-{level} (Play {currentdifficulty}{' '}
                {lotteryData?.currency} to mine 1 {lotteryData?.dividendToken})
              </p>
              <div className="progressBar">
                <ProgressBar
                  animated
                  now={((currentBar * 1000) / levelToken) * 100}
                />

                <span>{`${levelToken}/${
                  lottoPublicData?.tokenMined
                    ? parseInt(lottoPublicData?.tokenMined)
                    : 0
                }`}</span>
              </div>
              {/* <p className="text-muted">
                Next stage :{stage}-{level + 1} (Play {currentdifficulty + 2}TRX
                to mine 1 {lotteryData?.dividendToken})
              </p> */}
              <div className="lotto_d_wrapper">
                Current Dividend Pool
                <div className="col text-center">
                  <h5 className="widget-game-result__score-result widget-game-result__score-result--winner">
                    {isPublicDataLoading ? (
                      <SkeletonLoader width="100px" />
                    ) : (
                      lottoPublicData?.availableDividend +
                      ` ${lotteryData?.currency}`
                    )}
                  </h5>
                </div>
                {isPublicDataLoading ? (
                  <SkeletonLoader width="100px" />
                ) : (
                  <>
                    {!moment(new Date(dividendtimer)).isBefore(moment()) ? (
                      <div className="col text-center">
                        <h4 className="widget-game-result__score-result widget-game-result__score-result--winner">
                          <Countdown date={dividendtimer} />
                        </h4>
                      </div>
                    ) : (
                      <h4>dividend is being distributed</h4>
                    )}
                  </>
                )}
                {/* <p className="info_text">
                  There is total of{' '}
                  {lottoPublicData?.dividend_pool?.totalFrozen
                    ? lottoPublicData?.dividend_pool?.totalFrozen?.toFixed(2)
                    : 0}{' '}
                  {lotteryData?.dividendToken} Token staked in the network.
                </p> */}
                {isUserNotloggedIn ? (
                  <button className="btn btn-outline-warning" type="button">
                    You will receive{' '}
                    <>
                      {' '}
                      0
                      {/* {!isNaN(youWillRecieve)
                        ? youWillRecieve.toFixed(3)
                        : 0}{' '} */}
                      {lotteryData?.currency}
                    </>
                  </button>
                ) : (
                  <>
                    {isRecieveBalanceLoading ? (
                      <SkeletonLoader width="200px" height="40px" />
                    ) : (
                      <button className="btn btn-outline-warning" type="button">
                        You will receive{' '}
                        <>
                          0
                          {/* {!isNaN(youWillRecieve)
                            ? youWillRecieve.toFixed(3)
                            : 0}{' '} */}
                          {lotteryData?.currency}
                        </>
                      </button>
                    )}
                  </>
                )}
              </div>
              <br />
              <div className="row mt-1">
                <div className="col-sm-6 col-6 text-center">
                  <div className="box card">
                    <div className="card-body">
                      <h5 className="mb-0">
                        {/* {context.updatedSweepToken == 0
                          ? Math.floor(context.sweepToken * 100) / 100
                          : Math.floor(context.updatedSweepToken * 100) / 100 <
                            Math.floor(context.sweepToken * 100) / 100
                          ? Math.floor(context.updatedSweepToken * 100) / 100
                          : Math.floor(context.sweepToken * 100) / 100} */}
                        {tokenData?.available?.balance?.toFixed(2)}
                        <span className="ml-2">
                          {lotteryData?.dividendToken}
                        </span>
                      </h5>
                      <p>Available</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start  justify-content-center">
                    {tokenData?.available?.balance?.toFixed(2) > 1 ? (
                      !loading ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-block mr-1"
                          data-tut="step-5"
                          h="5"
                          // onClick={() => context.freezeSweepTokenData(10)}
                          data-toggle="modal"
                          data-target=".bd-example-modal-sm-freeze"
                          onClick={handleShow}
                        >
                          Stake
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary btn-block mr-1"
                        >
                          <i className="fa fa-refresh fa-spin" />
                        </button>
                      )
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary btn-block mr-1"
                        disabled
                      >
                        {' '}
                        Stake
                      </button>
                    )}
                    <a
                      className="info_a"
                      href="#!"
                      data-class="freez_tooltip"
                      data-html="true"
                      data-tip="
                        <ul>
                        <li>1. Minimum of 1 LOTTO to stake. Paying out LOTTO drops takes roughy 1-2 minutes after the countdown ends. You will not be able to stake during this time.</li>
                       <li>2. LOTTO must be staked to get LOTTO drops. LOTTO that is staked before the countdown ends will receive LOTTO drops. It is recommended to stake your LOTTO at least 10 minutes before the countdown ends.</li>
                       <li>3. Withdrawing and freezing LOTTO will consume a small amount of TRX (roughly 0.5-0.8TRX). It is recommended to operate only when needed.</li>
                       <li>4. Unfreezing LOTTO requires 24 hours to be deposited into your wallet. LOTTO that is being unstaked will not receive LOTTO drops.</li>
                       </ul>
                        "
                    >
                      <i className="fas fa-question-circle" />
                    </a>
                    <ReactTooltip />
                  </div>
                  {/* {disabledfreezebtn ? (
                    <p style={{ fontSize: 13 }}>
                      (Freezing not allowed before 10 min dividend distribution
                      )
                    </p>
                  ) : null} */}
                </div>
                <div className="col-sm-6 col-6 text-center">
                  <div className="card box">
                    <div className="card-body">
                      <h5 className="mb-0">
                        {tokenData?.frozen?.balance?.toFixed(2) || 0}{' '}
                        <span className="ml-1">
                          {lotteryData?.dividendToken}
                        </span>
                      </h5>
                      <p>Staked</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start  justify-content-center">
                    {tokenData?.frozen?.balance > 0 ? (
                      !uloading ? (
                        <button
                          type="button"
                          className="btn btn-info btn-block mr-1"
                          onClick={unfreezeHandleShow}
                        >
                          Unstake
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary btn-block mr-1"
                        >
                          <i className="fa fa-refresh fa-spin" />
                        </button>
                      )
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary btn-block mr-1"
                        disabled
                      >
                        {' '}
                        Unstake
                      </button>
                    )}
                    <a
                      className="info_a"
                      href="#!"
                      data-class="freez_tooltip"
                      data-html="true"
                      data-tip="
                        <ul>
                        <li>Unfreezing LOTTO requires 24 hours to be deposited into your wallet. LOTTO that is being unstaked will not receive LOTTO drops.</li>
                       </ul>
                        "
                    >
                      <i className="fas fa-question-circle" />
                    </a>
                    <ReactTooltip />
                  </div>
                </div>
              </div>
              {unfreezeclick ? (
                <div className="row mt-12" style={{ marginTop: 20 }}>
                  <div className="col-sm-12 text-center">
                    <div className="box card">
                      <div className="card-body unfreeze_body">
                        <div className="row mt-12">
                          <div className="col-sm-4 ">
                            <h5>unstake time:</h5>
                            <h4>{new Date(unfreezeTime).toLocaleString()} </h4>
                          </div>
                          <div className="col-sm-4 ">
                            <h5>Unstake Amount</h5>
                            <h4>
                              {Math.floor((unfreezedice / 10 ** 8) * 100) / 100}{' '}
                              <small className="ml-2">Lotto</small>
                            </h4>
                          </div>
                          <div className="col-sm-4 ">
                            <h5>time till unstake</h5>
                            <h4 className="widget-game-result__score-result widget-game-result__score-result--winner">
                              <Countdown date={unfreezeTimmer} />{' '}
                            </h4>
                            <button
                              type="button"
                              className="cancel_btn mr-1"
                              onClick={cancelunfreeze}
                              data-html="true"
                              data-tip="
                       Cancel
                        "
                            >
                              <i className="mdi mdi-close" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* <div
        class="modal bd-example-modal-sm-freeze"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        id="freezemodal"
      >
        <div class="modal-dialog modal-sm withdraw_modal" role="document">
          <div className="modal-header">
            <h5 class="modal-title">Freeze</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-content">
            <div className="body">
              <FreezeModal
                accountAddress={props.accountAddress}
                lotto={Math.floor(props.sweepToken * 100) / 100}
                sweepTokenFunc={props.sweepTokenFunc}
                test="rajesh"

              />
            </div>
          </div>
        </div>
      </div> */}
      <FreezeModal
        accountAddress={accountAddress}
        lotto={whlBalance}
        show={show}
        close={handleClose}
        whlBalance={whlBalance}
      />
      <UnfreezeModal
        accountAddress={accountAddress}
        unfreezeAmount={Math.floor(freezeLottoTokens * 100) / 100}
        show={showUnfreeze}
        close={unfreezeHandleClose}
      />
      {/* <div
        class="modal bd-example-modal-sm-unfreeze"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        id="unfreezemodal"
      >
        <div class="modal-dialog modal-sm withdraw_modal" role="document">
          <div className="modal-header">
            <h5 class="modal-title">Unfreeze</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-content">
            <div className="body">
             
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Content / End --> */}
      {/* <Footer/> */}
      <ToastsContainer store={ToastsStore} />
    </div>
  )
}

export default LottoDividend
