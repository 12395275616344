import React from 'react'

export default function Terms() {
    return (
        <div style={{color: "#cccccc"}}>
            <h2 className="mt-5" style={{textAlign: 'center'}}>Terms and Conditions</h2>

            <h3>1. Introduction</h3>
            <p>These terms and conditions and the documents referred to below (the "Terms") apply to the use of the current website (the "Website") and its related or connected services (collectively, the "Service"). You should carefully review these Terms as they contain important information concerning your rights and obligations regarding the use of the Website and form a binding legal agreement between you - our customer (the "Customer"), and us (the "Website"). By using this Website and/or accessing the Service, you, whether you are a guest or a registered user with an account (“Account”), agree to be bound by these Terms, together with any amendments, which may be published from time to time. If you do not accept these Terms, you should refrain from accessing the Service and using the Website. </p>
            <h3>2. General Terms</h3>
            <p>We reserve the right to revise and amend the Terms (including any documents referred to and linked to below) at any time. You should visit this page periodically to review the Terms and Conditions. Amendments will be binding and effective immediately upon publication on this Website. If you object to any such changes, you must immediately stop using the Service. Your continued use of the Website following such publication will indicate your agreement to be bound by the Terms as amended. Any bets not settled prior to the changed Terms taking effect will be subject to the pre-existing Terms. If the Terms and Conditions of Tronsweep will be updated, the platform will notify you and ask you to confirm your consent.
            </p>
            <h3>3. Your Obligations</h3>
            <p>You acknowledge that at all times when accessing the Website and using the Service: </p>
            <ol>
                <li>You are over 18, or of the legal age at which gambling or gaming activities are allowed under the law or jurisdiction that applies to you. We reserve the right to request proof of age documents from you at any time. </li>
                <li>You are of legal capacity and can enter into a binding legal agreement with us. You must not access the Website or utilize the Service if you are not of legal capacity. </li>
                <li>You are aware that the right to access and use the Website and any products there offered, may be considered illegal in certain countries. We are not able to verify the legality of service in each and every jurisdiction, consequently, you are responsible in determining whether your accessing and using our website is compliant with the applicable laws in your country and you warrant to us that gambling is not illegal in the territory where you reside.</li>
                <li>When attempting to open an account or using the Website, it is the responsibility of the player to verify whether gambling is legal in that particular jurisdiction. </li>
                <li>You are the authorized user of the payment method you use and all funds, which are on your account and used within the Services are legal. </li>
                <li>You must make all payments to us in good faith and not attempt to reverse a payment made or take any action which will cause such payment to be reversed by a third party.</li>
                <li>When placing bets you may lose some or all of your money deposited to the Service in accordance with these Terms and you will be fully responsible for that loss. </li>
                <li>When placing bets you must not use any information obtained in breach of any legislation in force in the country in which you were when the bet was placed. </li>
                <li>You are not acting on behalf of another party or for any commercial purposes, but solely on your own behalf as a private individual in a personal capacity. </li>
                <li>You must not either attempt to manipulate any market or element within the Service in bad faith nor in a manner that adversely affects the integrity of the Service or us. </li>
                <li>You must generally act in good faith in relation to the use of the Service at all times and for all bets made using the Service. </li>
                <li>You, or, if applicable, your employees, employers, agents, or family members, are not registered as an Affiliate in our Affiliate program.</li>
                <li>While using Tronsweep at any time you consent to provide us only true, real, and objective data on default and when We request it.</li>
                <li>You understand the volatility of the crypto market and you accept the possibility of your crypto assets price changing while you are waiting for your withdrawal or your withdrawal is being processed by our Risk Department.</li>
                <li>You are a mentally competent person and you do not suffer from gambling addiction. In case a person with such a problem enters the Site - the responsibility lies on their side only.</li>
                <li>You agree not to use any third-party software in any of the subdivisions of Tronsweep as well as not to abuse any game/functioning bugs found on the platform.</li>
            </ol>

            <h3>4. Restricted use</h3>
            <ol>
                <li>You must not use the Service:
                    <ol>
                        <li>If you are under the age of 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you) or if you are not legally able to enter into a binding legal agreement with us or you acting as an agent for, or otherwise on behalf, of a person under 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you);</li>
                        <li>If you reside in a country in which access to online gambling to its residents or to any person within such country is prohibited; </li>
                        <li>To collect nicknames, e-mail addresses, and/or other information of other Customers by any means (for example, by sending spam, other types of unsolicited emails, or the unauthorized framing of, or linking to, the Service); </li>
                        <li>To disrupt or unduly affect or influence the activities of other Customers or the operation of the Service generally; </li>
                        <li>To promote unsolicited commercial advertisements, affiliate links, and other forms of solicitation which may be removed from the Service without notice;</li>
                        <li>In any way which, in our reasonable opinion, could be considered as an attempt to: (i) cheat the Service or another Customer using the Service; or (ii) collude with any other Customer using the Service in order to obtain a dishonest advantage; </li>
                        <li>To scrape our odds or violate any of our Intellectual Property Rights;</li>
                        <li>For any unlawful activity whatsoever;</li>
                        <li>Creating multiple accounts for the purpose of collusion, fraud, Service abuse, and/or any kind of Service manipulation is prohibited and may result in account closure. </li>
                    </ol>

                </li>
                <li>You cannot sell or transfer your account to third parties, nor can you acquire a player account from a third party. </li>
                <li>We may immediately terminate your Account upon written notice to you if you use the Service for unauthorized purposes. We may also take legal action against you for doing so in certain circumstances. </li>
                <li>Restrictions to the use of the third-party products (Live, Table Games and Slots, etc.) are set on the side of the product provider and are not under the control of the Tronsweep side.</li>

            </ol>

            <h3>5. Registration</h3>
            <h5>You agree that at all times when using the Service: </h5>
            <ol>
                <li>We reserve the right to refuse to accept a registration application from any applicant at our sole discretion and without any obligation to communicate a specific reason. </li>
                <li>Before using the Service, you must personally complete the registration form and read and accept these Terms. We may require you to become a verified Customer which includes passing certain checks. You may be required to provide valid proof of identification and any other document as it may be deemed necessary. This includes but is not limited to, a picture ID (copy of passport, driver's license, or national ID card) and a recent utility bill listing your name and address as proof of residence. We reserve the right to suspend wagering or restrict Account options on any Account until the required information is received. This procedure is done in accordance with the applicable gaming regulation and the anti-money laundering legal requirements. Additionally, you will need to fund your Service Account using the payment methods set out on the payment section of our Website. </li>
                <li>If You register via email You have to provide accurate contact information, inclusive of a valid email address (“Registered Email Address”), and update such information in the future to keep it accurate. It is your responsibility to keep your contact details up to date on your Account. Failure to do so may result in you failing to receive important Account related notifications and information from us, including changes we make to these Terms. We identify and communicate with our Customers via their Registered Email Address. It is the responsibility of the Customer to maintain an active and unique email account, to provide us with the correct email address, and to advise the Company of any changes in their email address. Each Customer is wholly responsible for maintaining the security of his Registered Email Address to prevent the use of his Registered Email Address by any third party. Company shall not be responsible for any damages or losses deemed or alleged to have resulted from communications between Company and the Customer using the Registered Email Address. We will immediately suspend your Account upon written notice to you to this effect if you intentionally provide false or inaccurate personal information.</li>
                <li>You are only allowed to register one Account with the Service. Accounts are subject to immediate closure if it is found that you have multiple Accounts registered with us. This includes the use of representatives, relatives, associates, affiliates, related parties, connected persons, and/or third parties operating on your behalf. </li>
                <li>In order to ensure your financial worthiness and to confirm your identity, we may ask you to provide us with additional personal information, such as your name and surname, or use any third-party information providers we consider necessary. In case any additional personal information should be obtained via third-party sources, we will inform you about the data obtained. </li>
                <li>You must keep your password for the Service confidential. Provided that the Account information requested has been correctly supplied, we are entitled to assume that bets, deposits, and withdrawals have been made by you. We advise you to change your password on a regular basis and never disclose it to any third party. It is your responsibility to protect your password and any failure to do so shall be at your sole risk, expense and responsibility. You may log out of the Service at the end of each session. If you believe any of your Account Information is being misused by a third party, or your Account has been hacked into, or your password has been discovered by a third party, you must notify us immediately. You must notify us if your Registered Email Address has been hacked into, we may, however, require you to provide additional information/ documentation so that we can verify your identity. We will immediately suspend your Account once we are aware of such an incident. In the meantime, you are responsible for all activity on your Account including third-party access, regardless of whether or not their access was authorized by you. </li>
                <li>You must not at any time transmit any content or other information on the Service to another Customer or any other party by way of screen capture (or another similar method), nor display any such information or content in a frame or in any other manner that is different from how it would appear if such Customer or third party had typed the URL for the Service into the browser line. </li>
                <li>When registering, you will receive the possibility to use all crypto assets available on the website. Those will be the crypto assets of your deposits, withdrawals, and bets placed and matched into the Service as set out in these Terms. Some payment methods do not process in all crypto assets. In such cases, a processing crypto assets will be displayed, along with a conversion calculator available on the page. </li>
                <li>We are under no obligation to open an Account for you and our website sign-up page is merely an invitation to treat. It is entirely within our sole discretion whether or not to proceed with the opening of an Account for you and, should we refuse to open an Account for you, we are under no obligation to provide you with a reason for the refusal. </li>
                <li>Upon receipt of your application, we may be in touch to request further information and/ or documentation from you in order for us to comply with our regulatory and legal obligations. </li>
                <li>Users who register via mail, need to verify their account (via mail). If it will not be done within 30 days, we will delete this account. The company reserves the right to change this period. </li>
                <li>We allow only one account per e-mail address, wallet, etc. If more players wish to use the same account, exceptions can be made after proving the identity of all the connected account users. It is necessary to indicate correct and real names. Players who created multiple accounts with the sole intention of taking advantage of our bonus promotions (if any) will not be eligible to receive any winnings made with our bonuses. Furthermore, their accounts will be blocked upon recognition without prior notice.</li>
                <li>A player can have only one account. All duplicate player accounts will be counted as “duplicates” and will be closed immediately. Any winnings, bonuses, and returns that you have gained from the duplicate account we call into question, and also, we may reclaim it. You will return to us all demanded funds, which have been withdrawn from your duplicate account.</li>
                <h5>Identification</h5>
                <li>On the Website, all transactions are checked to prevent money laundering and other illegal activity. If you agree with the Terms, you authorize us to undertake a personal identification check, due to our requirements or requirements from the third party (including regulatory authority) to confirm your identity and contact details. In certain circumstances, we may have to contact you and ask you to provide the necessary information in order to complete the check. If you do not provide us with the required information, the Company reserves the right to freeze or close your account until you have provided us with the requested information. </li>
                <li>If you do not confirm that you already are 18 years old, the Company has the right to freeze your account until you prove your age.</li>
                <li>If you breach these Terms, or we have a reasonable suspicion that you did so, we may with or without prior notice to you terminate your account. In such case, all your outstanding bets will be canceled and your account will be closed, and we may also confiscate and recover from your account balance the amount of the winnings awarded or paid to you and all bonuses, bonus money, and other incentives, which you shall forfeit in such case. Following this, on your request and subject to our discretion, we will return the remainder of the real money balance of your account (if any) to you, subject to reasonable charges, regulatory obligations, and our ability to do so using the payment method we verified as belonging solely to you.</li>
            </ol>



            <h3>6. Your Account </h3>
            <ol>
                <li>Accounts could use several crypto assets, in this case, all Account balances and transactions appear in the crypto assets used for the transaction. </li>
                <li>We do not give credit for the use of the Service.</li>
                <li>We may close or suspend an Account if you are not or we reasonably believe that you are not complying with these Terms, or to ensure the integrity or fairness of the Service or if we have other reasonable grounds to do so. We may not always be able to give you prior notice. If we close or suspend your Account due to you not complying with these Terms, we may cancel and/or void any of your bets and withhold any money in your account (including the deposit). </li>
                <li>We reserve the right to close or suspend any Account without prior notice and without returning all funds. Contractual obligations already matured will however be honored. </li>
                <li>We reserve the right to refuse, restrict, cancel or limit any wager at any time for whatever reason, including any bet perceived to be placed in a fraudulent manner in order to circumvent our betting limits and/or our system regulations. </li>
                <li>If any amount is mistakenly credited to your Account it remains our property and when we become aware of any such mistake, we shall notify you and the amount will be withdrawn from your Account. </li>
                <li>If for any reason, your Account goes overdrawn, you shall be in debt to us for the amount overdrawn.</li>
                <li>You must inform us as soon as you become aware of any errors with respect to your Account. In the event we deem in our sole discretion that you have been taking unfair advantage of any bonuses or have executed any other act in bad faith in relation to a bonus, promotion offered on the Website, we shall have the right to block or terminate your account and, in such circumstances, we shall be under no obligation to refund you any funds that may be in your Account.</li>
                <li>Please remember that betting is purely for entertainment and pleasure and you should stop as soon as it stops being fun. Absolutely do not bet anything you can’t afford to lose. If you feel that you may have lost control of your gambling, we offer a self-exclusion option. Just send a message to our Customer Support Department using your Registered Email Address that you wish to SELF-EXCLUDE and this request will take effect within 24 hours from the moment of its receipt. In this case, your account will be disabled until further notice, and you won’t be able to login into it. You can also use the time alert function to help you control the time spent in an active gaming session. Time Alert will allow you to set reminders about the amount of time spent in an active gaming session and will alert you when your allotted time for the game is over. Please read our Responsible Gaming and Gambling notice to get more information. </li>
                <li>You cannot transfer, sell, or pledge Your Account to another person. This prohibition includes the transfer of any assets of the value of any kind, including but not limited to ownership of accounts, winnings, deposits, bets, rights, and/or claims in connection with these assets, legal, commercial, or otherwise. The prohibition on said transfers also includes however is not limited to the encumbrance, pledging, assigning, usufruct, trading, brokering, hypothecation, and/or gifting in cooperation with a fiduciary or any other third party, company, natural or legal individual, foundation, and/or association in any way shape or form</li>
                <li>Should you wish to close your account with us, please send an email from your Registered Email Address to our Customer Support Department via the links on the Website. </li>
                <li>Tronsweep keeps the data on the betting history for not longer than 30 days.</li>
                <li>When the User makes a request for the account closure, the User accepts the fact that their account is not going to be ever unlocked. It is pretty advisable to withdraw all of your funds before closing the account.</li>
            </ol>

            <h3>7. Deposit of Funds</h3>
            <ol>
                <li>Platform has a minimum deposit amount for every crypto asset. Sending any amount below the minimum will result in funds loss and is not refundable. The maximum deposit amount is not limited. Tronsweep doesn't have any fee for deposits. Some of the deposits may take some time related to the network hash rate. </li>
                <li>Fees and charges may apply to customer deposits and withdrawals, which can be found on the Website. In most cases, we absorb transaction fees for deposits to your Tronsweep.io Account. You are responsible for your own wallet charges that you may incur due to depositing funds with us.</li>
                <li>You agree to fully pay any and all payments and charges due to us or to payment providers in connection with your use of the Service. You further agree not to make any charge-backs or renounce or cancel or otherwise reverse any of your deposits, and in any such event you will refund and compensate us for such unpaid deposits including any expenses incurred by us in the process of collecting your deposit, and you agree that any winnings from wagers utilizing those charged back funds will be forfeited. </li>
                <li>Funds originating from criminal and/or illegal and/or unauthorized activities must not be deposited to the Site.</li>
                <li>We reserve the right to forfeit any positive balance on your Account in case of reset of the wager or any actions that belong to duplicate accounts, conspirations, frauds, criminal activity, or fakes.</li>
            </ol>

            <h3>8. Withdrawal of Funds</h3>
            <ol>
                <li>Due to the workflow of our anti-fraud system, the waiting time for your withdrawal can be prolonged up to the point when all the needed procedures will be conducted to supply a decent level of financial security for users (up to seven (7) days and more if needed).                 </li>
                <li>Please note that we cannot guarantee the successful processing of withdrawals or refunds in the event you break the rules stated in our Terms and Conditions. </li>
                <li>The responsibility for withdrawing the funds lies with the User only and the Site does not refund the funds that may occur as lost funds in case the receiving wallet/platform doesn’t accept transactions from smart-contract, any definite coins, or in case the user chose the wrong chain of transfer.</li>
                <li>We reserve the right to change the withdrawal fee at any time without prior notice.</li>
                <li>PLAY token withdrawal processing may take up to 24 hours, in some cases may be postponed for a longer period, not limited. Tronsweep reserves the right, at any time, to hold PLAY withdrawals to verify the source of obtaining. Tronsweep reserves the right to restrict the Service, deposit, or withdrawal until the investigation is sufficiently determined, or for any other reason in Tronsweep Risk Department discretion. Tronsweep reserves the right to limit the number of transactions for some time without warning.

                </li>
                <li>In case your winning is 50,000$ or higher, Tronsweep, in exceptional cases, reserves the right to pay the winning in equal parts within 30 days at a maximum.</li>
                <li>Regardless of the amount of your win for any bet or spin, including any bonus round or a free spin, as well as free spins purchased on our platform, the amount of win which we are obliged to pay you may not exceed the equivalent of 300,000 dollars (“ Maximum win limit ”) in any crypto asset. You confirm that you understand that some games have the technical ability to win more than the Maximum Win Limit, but you agree that we have the right to cut the amount above the Maximum Win Limit from Your Account and pay You only the Maximum Win Limit. We have this right in respect of any wins that have exceeded the Maximum Win Limit. The decision to cut the amount above the Maximum Win Limit is made based on your game history, betting level, and account, as well as other information that we analyze.
                    In addition, the total win amount, including the amount that is above the Maximum win limit, may be credited to your account, but the paid amount will not exceed the Maximum payout limit. In any case, we reserve the right to make any decision on the payment of an amount above the Maximum Win Limit.
                    Tronsweep reserves the right to pay the Maximum Win Limit in equal parts within 90 days at a maximum.
                </li>
                <li>You confirm that you understand that if we make a decision to cut the payout to the amount of the Maximum Win Limit, we will send you an appropriate letter. In this case, after receiving the letter, you will not have any claims to the platform, as the acceptance of these Terms has confirmed your awareness of the implementation of the Maximum Win Limit on the platform Tronsweep. You confirm your understanding that implementation of the Maximum Win Limit is the right of Tronsweep, which in no way violates the rights of the platform user. Any claims made by the User after receiving the relevant letter of cutting the amount will be considered as evidence of abuse of rights by the user in order to unreasonably obtain additional funds and may be construed as extortion.</li>
            </ol>

            <h3>9. Payment Transactions and Processors</h3>
            <ol>
                <li>You are fully responsible for paying all monies owed to us. You must make all payments to us in good faith and not attempt to reverse a payment made or take any action which will cause such payment to be reversed by a third party in order to avoid a liability legitimately incurred. You will reimburse us for any charge-backs, denial, or reversal of payment you make and any loss suffered by us as a consequence thereof.</li>
                <li>Before making a deposit the User has to be sure of the correctness of the chain of the token he/she is depositing as well as meeting the minimal amount of deposit to make sure the deposit will be credited and reflected on the balance. In case a mistake in the deposit/withdrawal is done by the user - the responsibility lies on their side only.</li>
                <li>All transactions made on our site might be checked to prevent money laundering or terrorism financing activity. Suspicious transactions will be reported to the relevant authority. </li>
            </ol>

            <h3>10. Errors</h3>
            <ol>
                <li>In the event of an error or malfunction of our system or processes, all bets are rendered void. You are under an obligation to inform us immediately as soon as you become aware of any error with the Service. In the event of communication or system errors or bugs or viruses occurring in connection with the Service and/or payments made to you as a result of a defect or error in the Service, we will not be liable to you or to any third party for any direct or indirect costs, expenses, losses or claims arising or resulting from such errors, and we reserve the right to void all games/bets in question and take any other action to correct such errors. </li>
                <li>We make every effort to ensure that we do not make errors in posting bookmaker lines. However, if as a result of human error or system problems a bet is accepted at an odd that is: materially different from those available in the general market at the time the bet was made; or clearly incorrect given the chance of the event occurring at the time the bet was made then we reserve the right to cancel or void that wager or to cancel or void a wager made after an event has started. </li>
                <li>We have the right to recover from you any amount overpaid and to adjust your Account to rectify any mistake. An example of such a mistake might be where a price is incorrect or where we enter a result of an event incorrectly. If there are insufficient funds in your Account, we may demand that you pay us the relevant outstanding amount relating to any erroneous bets or wagers. Accordingly, we reserve the right to cancel, reduce or delete any pending plays, whether placed with funds resulting from the error or not.</li>
            </ol>
{/* 
            <h3>11. Rules, refunds, and cancellations in Sports betting</h3>
            <ol>
                <li>The winner of an event will be determined on the date of the event's settlement, and we will not recognize protested or overturned decisions for wagering purposes.</li>
                <li>In the situations where the bet or odds were calculated incorrectly (due to human error, system error, or mistakes made by the referring results source), we will reset/correct the results.</li>
                <li>If a match result is overturned for any reason by the governing body of the match within the payout period then all money will be refunded.</li>
                <li>If a draw occurs in a game where a draw option is offered, all stakes on a team win or lose will be lost. If a draw option is not offered then everyone receives a refund in the outcome of a draw in the match. And if a draw option has not been made available, then extra time will count, if played.</li>
                <li>Minimum and maximum wager amounts on all events will be determined by us and are subject to change without prior written notice. We also reserve the right to adjust limits on individual Accounts as well.</li>
                <li>Customers are solely responsible for their own Account transactions. Once a transaction is complete, it cannot be changed. We do not take responsibility for missing or duplicate wagers made by the Customer and will not entertain discrepancy requests because a play is missing or duplicated. Customers may review their transactions in the My Bets section of the site after each session to ensure all requested wagers were accepted.</li>
                <li>A matchup will have action as long as the two teams are correct, and regardless of the League header in which it is placed on our Website. </li>
                <li>12.8. The start dates and times displayed on the Website for eSport matches are an indication only and are not guaranteed to be correct. If a match is suspended or postponed, and not resumed within 72 hours from the actual scheduled start time, the match will have no action and wagers will be refunded. The exception being any wager on whether a team/player advances in a tournament or wins the tournament, will have action regardless of a suspended or postponed match. </li>
                <li>12.9. The statistics of the official scoring provider or the official website of the respective competition or match are used to calculate bets. If statistics from the official scoring provider or the official website are not available, or if there are clear indications that statistics from the official scoring provider or official website are incorrect, we will wait for the official result to calculate the markets. Tronsweep reserves the right to recalculate bets, the result of which has been changed after the end of the event or incorrectly calculated before. </li>
                <li>12.10. If an event is posted by us with an incorrect date, all wagers have action based on the date announced by the governing body. </li>
                <li>12.11. If a team is using stand-ins, the result is still valid as it was the team's choice to use the stand-ins. </li>
                <li>12.12. The Company reserves the right to remove events, markets, and any other products from the Website. </li>
            </ol> */}

            <h3>11. Responsibility</h3>
            <p>We cannot be held liable for any failure or delay in providing the Service due to an event of Force Majeure which could reasonably be considered to be outside our control despite our execution of reasonable preventative measures such as: an act of God; trade or labor dispute; power cut; act, failure or omission of any government or authority; obstruction or failure of telecommunication services; or any other delay or failure caused by a third party, and we will not be liable for any resulting loss or damage that you may suffer. In such an event, we reserve the right to cancel or suspend the Service without incurring any liability. </p>
            
            <h3>12. Liability</h3>
            <ol>
                <li>To the extent permitted by applicable law, we will not compensate you for any reasonably foreseeable loss or damage (either direct or indirect) you may suffer if we fail to carry out our obligations under these terms unless we breach any duties imposed on us by law (including if we cause death or personal injury by our negligence) in which case we shall not be liable to you if that failure is attributed to: (I) Your own fault; (II) A third party unconnected with our performance of these terms (for instance problems due to communications network performance, congestion, and connectivity or the performance of your computer equipment); or (III) Any other events which neither we nor our suppliers could have foreseen or forestalled even if we or they had taken reasonable care. As this service is for consumer use only we will not be liable for any business losses of any kind. </li>
                <li>We strongly recommend that You: (I) Take care to verify the suitability and compatibility of the service with your own computer equipment prior to use; and (II) Take reasonable precautions to protect yourself against harmful programs or devices including through installation of antivirus software. </li>
            </ol>

            <h3>13. Under Age Gambling</h3>
            <ol>
                <li>If we suspect that you are currently under 18 years or were under 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you) when you placed any bets through the Service your Account will be suspended (locked) to prevent you placing any further bets or making any withdrawals from your Account. We will then investigate the matter, including whether you have been betting as an agent for, or otherwise on behalf of, of a person under 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you). If have found that you: (a) are currently; (b) were under 18 years or below the majority age which applies to you at the relevant time; or (c) have been betting as an agent for or at the behest of a person under 18 years or below the majority age which applies: all winnings currently or due to be credited to your Account will be retained; all winnings gained from betting through the Service whilst under age must be paid to us on demand (if you fail to comply with this provision we will seek to recover all costs associated with recovery of such sums); and/or any monies deposited in your Account which are not winnings will be returned to you OR retained until you turn 18 years old at our sole discretion. We reserve the right to deduct payment transaction fees from the amount to return, including transaction fees for deposits to your Tronsweep.io account which we covered.</li>
                <li>This condition also applies to you if you are over the age of 18 years but you are placing your bets within a jurisdiction that specifies a higher age than 18 years for legal betting and you are below that legal minimum age in that jurisdiction.</li>
                <li>In the event we suspect you are in breach of the provisions of this Clause or are attempting to rely on them for a fraudulent purpose, we reserve the right to take any action necessary in order to investigate the matter, including informing the relevant law enforcement agencies.</li>
            </ol>

            <h3>14. External Links</h3>
            <p>The Service may contain links to third-party websites that are not maintained by, or related to, us, and over which we have no control. Links to such websites are provided solely as a convenience to Customers and are in no way investigated, monitored, or checked for accuracy or completeness by us. Links to such websites do not imply any endorsement by us of, and/or any affiliation with, the linked websites or their content or their owner(s). We have no control over or responsibility for the availability nor their accuracy, completeness, accessibility, and usefulness. Accordingly, when accessing such websites we recommend that you take the usual precautions when visiting a new website including reviewing their privacy policy and terms of use. </p>

            <h3>15. Breach of These Terms</h3>
            <ol>
                <li>Without limiting our other remedies, we may suspend or terminate your Account and refuse to continue to provide you with the Service, in either case without giving you prior notice, if, in our reasonable opinion, you breach any material term of these Terms. Notice of any such action taken will, however, be promptly provided to you.</li>
                <li>You agree to fully indemnify, defend and hold harmless Tronsweep and its shareholders, directors, agents, and employees from and against all claims, demands, liabilities, damages, losses, costs, and expenses, including legal fees and any other charges whatsoever, howsoever caused, that may arise as a result of: (i) your breach of this Agreement, in whole or in part; (ii) violation by you of any law or any third party rights; and (iii) use by you of the Service.</li>
            </ol>
        </div>
    )
}
