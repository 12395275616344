/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { InputGroup, Form, Tabs, Tab, Table, Row, Col } from 'react-bootstrap'
import 'react-rangeslider/lib/index.css'
import noData from '../../public/images/icons/no_data.svg'
import '../../lottery/components/lottery.css'
import './egaming.css'
import '../../../assets/css/color/blue.css'
import { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
import {
  colorBetData,
  colorBetPlace,
  colorBetResult,
  colorConnectUser,
  colorGameState,
  initiateSocketForColor,
  colorPredictionUserWinning,
  colorPredictionGameResult,
} from '../../helper/socket'
import { useAccount } from '../../context/AccountProvider'
import { getAllColorBets, getMyColorBets } from './Service'
import isEmpty from 'is-empty'
import moment from 'moment'
import TapSound from '../../../assets/audio/tap.mp3'
import { ToastsStore } from 'react-toasts'
import DiceLose from '../../../assets/audio/dice-lose.mp3'
import DiceWin from '../../../assets/audio/dice-win.mp3'
import Result from '../../../assets/audio/result.mp3'
import ClockSound from '../../../assets/audio/clock-sound.mp3'
import Modal from 'react-bootstrap/Modal'
import Chat from '../../dynamicLotto/components/chat'
import { getIcon } from '../../utils/functions'



// To include the default styles

const minimumBet = 10

const ColorPrediction = () => {
  const { totalTRX, onSetResultTimer, selectedToken } = useAccount()
  const [target, setTarget] = useState('GREEN')
  const [myAllBets, setMyAllBets] = useState([])
  const [allBets, setAllBets] = useState([])
  let amount = 0
  const [betAmount, setBetAmount] = useState(amount.toFixed(6))
  const [betTimer, setBetTimer] = useState(100)
  const [socket, setSocket] = useState(null)
  const [loader, setLoader] = useState(true)
  const [multiplier, setMultiplier] = useState(1)
  const [isWin, setIsWin] = useState(false)
  const [isResultState, setIsResultState] = useState(false)
  const [result, setResult] = useState([])
  const [amountWon, setAmountWon] = useState(0)
  const [userResultAvailable, setUserResultAvailable] = useState(false)
  const [lastRolls, setLastRolls] = useState([])
  const [showRuleModal, setShowRuleModal] = useState(false)

  const handleCloseRuleModal = () => setShowRuleModal(false)


  useEffect(() => {
    let amount = totalTRX > 20 ? 20 : totalTRX
    setBetAmount(amount?.toFixed(6))
  }, [totalTRX])

  const betPlaceHanlder = () => {
    if (!socket?.connected || betAmount <= 0) return
    localStorage.setItem("resultTime",1000)
    setLoader(true)
    const data = {
      amount: parseFloat(betAmount),
      target: target,
      token: selectedToken.value,
    }
    colorBetPlace(data)
  }

  const hanldeMinBetValue = () => {
    setBetAmount(minimumBet)
  }

  const handleHalfBetValue = () => {
    if (betAmount === minimumBet) return
    setBetAmount(betAmount / 2)
  }
  const handleDoubleBetValue = () => {
    setBetAmount(betAmount * 2)
  }

  const handleBetAmountChange = (e) => {
    const { value } = e.target
    setBetAmount(value)
  }
  const handleMaxBetValue = () => {
    setBetAmount(totalTRX.toFixed(6))
  }

  const BetTimer = ({ expiryTimestamp }) => {
    const { seconds, minutes, hours, days } = useTimer({
      expiryTimestamp,
      onExpire: () => { },
    })
    return (
      <div className="bet_timer" style={{ fontSize: '20px' }}>
        <span>
          <span className="num">{minutes}</span>
        </span>
        <span className="num">:</span>
        <span>
          {/* <span className="num">{seconds < 10 && 0}</span> */}
          <span className="num">{seconds}</span>
        </span>
      </div>
    )
  }

  useEffect(() => {
    Promise.all([getAllColorBetsHanlder(), getMyColorBetsHanlder()])
    const socketTemp = initiateSocketForColor()
    setSocket(socketTemp)
    colorConnectUser({})
    colorBetResult((err, data) => {
      if (!isEmpty(data)) {
        ToastsStore.info(data.message)
        setLoader(false)
      } else ToastsStore.info(data.message)
    })

    colorGameState((err, data) => {

      if (data.game_number_entity.game_state === 'BET_STARTED') {
        getAllColorBetsHanlder()
        setLoader(false)
        const time = new Date()
        setBetTimer(time)
        time.setSeconds(time.getSeconds() + (data.bet_stop_at))
        setTimeout(() => {
          setIsResultState(false)
          clockSoundPlay()
        }, 2000)
      }
      if (data.game_number_entity.game_state === 'BET_STOPPED') {
        getAllColorBetsHanlder()
        setLoader(true)
        clockSoundPause()
      }
    })

    colorBetData((err, data) => {
      setLoader(false)
      // setAllBets((prev) => [data, ...prev])
    })
    colorPredictionUserWinning((err, data) => {
      setIsResultState(true)
      if (!isEmpty(data)) {
        setUserResultAvailable(true)
        let totalAmount = 0
        for (let i = 0; i < data.length; i++) {
          totalAmount += data[i].amount_won
        }
        setAmountWon(totalAmount)
        if (totalAmount > 0) {
          setIsWin(true)
          diceWin()
        } else {
          setIsWin(false)
          diceLose()
        }
      } else {
        setUserResultAvailable(false)
        resultSound()
      }
    })

    colorPredictionGameResult((err, data) => {
      setResult(data)
      setLastRolls((prev) => [...prev, data])
    })

    return () => {
      clockSoundPause()
      socketTemp.close()
    }
  }, [])


  useEffect(() => {
    if (lastRolls.length > 10) {
      let a = lastRolls.shift()
    }
  }, [lastRolls]);

  const getAllColorBetsHanlder = async (loadmore) => {
    const res = await getAllColorBets(loadmore)
    if (res?.User_bets) {
      setAllBets(res.User_bets.color_user_bets)
    }
  }
  const getMyColorBetsHanlder = async (loadmore) => {
    const res = await getMyColorBets(loadmore)
    if (res?.User_bets) {
      setMyAllBets(res.User_bets.color_user_bets)
    }
  }

  const soundTapPlay = () => {
    var audio = document.getElementById('audioTap')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const diceLose = () => {
    var audio = document.getElementById('diceLose')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const diceWin = () => {
    var audio = document.getElementById('diceWin')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const resultSound = () => {
    var audio = document.getElementById('result')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const clockSoundPlay = () => {
    var audio = document.getElementById('clockSound')
    audio.play()
    audio.loop = true;
    audio.volume = 0.2
  }
  const clockSoundPause = () => {
    var audio = document.getElementById('clockSound')
    audio.pause()
  }

  const checkClass = (key) => {
    if (key === "VIOLET") return 'cp_violet';
    if (key === "VIOLET GREEN" || key == 5) return 'cp_green_violet'
    if (key === "VIOLET RED" || key == 0) return 'cp_red_voilet'
    if (key === "GREEN" || key == 1 || key == 3 || key == 7 || key == 9) return 'cp_green'
    if (key === "RED" || key == 2 || key == 4 || key == 6 || key == 8) return 'cp_red'
  }

  return (
    <>
      <div className="main_container blue-theme">
        <div className="row ">
          <div className="col-lg-8">
            <div className="dice_main color_prediction">
              <div className="dice_head">
                <BetTimer expiryTimestamp={betTimer} />
                <ul>
                  {lastRolls.map((item, i) => (
                    <li key={i}>
                      <span className={checkClass(item.color_won)}>{!isEmpty(item.number_picked) ? item.number_picked : item.color_won.charAt(0)}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="color_game">
                <div className="box_wrap">
                  {isResultState ? (
                    <div className="color-box-status">
                      <div className="color-box-title">
                        {userResultAvailable ? (
                          <>
                            <h1>You {isWin ? 'Win' : 'Loose'}</h1>
                            {isWin ? <h3>{amountWon} TRX</h3> : null}
                            {!isWin ? (
                              <>
                                <h3>Game Result</h3>
                                <h4>{result?.color_won}, {result?.number_picked}</h4>
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <h1>Game Result</h1>
                            <h4>{result?.color_won}, {result?.number_picked}</h4>
                          </>
                        )}
                      </div>
                    </div>
                  ) : null}

                  <Modal show={showRuleModal} onHide={handleCloseRuleModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Color Prediction Rules</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="table-responsive">
                        <table className="table table-dark">
                          <thead>
                            <tr>
                              <th scope="col">Select</th>
                              <th scope="col">Numbers</th>
                              <th scope="col">Multiplier</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">RED</th>
                              <td>
                                2,4,6,8
                              </td>
                              <td>
                                x2
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"></th>
                              <td>
                                0
                              </td>
                              <td>
                                x1.5
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">GREEN</th>
                              <td>1,3,7,9</td>
                              <td>x2</td>
                            </tr>
                            <tr>
                              <th scope="row"></th>
                              <td>5</td>
                              <td>x1.5</td>
                            </tr>
                            <tr>
                              <th scope="row">VIOLET</th>
                              <td>0,5</td>
                              <td>x4.5</td>
                            </tr>
                            <tr>
                              <th scope="row">NUMBER</th>
                              <td>0-9</td>
                              <td>x9</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <a href="#!" className='cp_rule' onClick={() => setShowRuleModal(true)}>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                  </a>
                  <div
                    className={`green box ${target === 'GREEN' ? 'selected' : ''
                      }`}
                    onClick={() => {
                      setTarget('GREEN')
                      soundTapPlay()
                      setMultiplier(2)
                    }}
                  >
                    <div className="content">
                      <h5>Bet Green</h5>
                      <p>1:2</p>
                    </div>
                  </div>
                  <div
                    className={`voilet box ${target === 'VIOLET' ? 'selected' : ''
                      }`}
                    onClick={() => {
                      setTarget('VIOLET')
                      soundTapPlay()
                      setMultiplier(4.5)
                    }}
                  >
                    <div className="content">
                      <h5>Bet Violet</h5>
                      <p>1:4.5</p>
                    </div>
                  </div>
                  <div
                    className={`red box ${target === 'RED' ? 'selected' : ''}`}
                    onClick={() => {
                      setTarget('RED')
                      soundTapPlay()
                      setMultiplier(2)
                    }}
                  >
                    <div className="content">
                      <h5>Bet Red</h5>
                      <p>1:2</p>
                    </div>
                  </div>
                </div>
                <div className="number_box">
                  <div className="number_row">
                    <div
                      className={`number ${target == 0 ? 'selected' : null}`}
                      onClick={() => {
                        setTarget(0)
                        soundTapPlay()
                        setMultiplier(9)
                      }}
                    >
                      <p>0</p>
                      <span className="red_violet"></span>
                    </div>
                    <div
                      className={`number ${target == 1 ? 'selected' : null}`}
                      onClick={() => {
                        setTarget(1)
                        soundTapPlay()
                        setMultiplier(9)
                      }}
                    >
                      <p>1</p>
                      <span className="green"></span>
                    </div>
                    <div
                      className={`number ${target == 2 ? 'selected' : null}`}
                      onClick={() => {
                        setTarget(2)
                        soundTapPlay()
                        setMultiplier(9)
                      }}
                    >
                      <p>2</p>
                      <span className="red"></span>
                    </div>
                    <div
                      className={`number ${target == 3 ? 'selected' : null}`}
                      onClick={() => {
                        setTarget(3)
                        soundTapPlay()
                        setMultiplier(9)
                      }}
                    >
                      <p>3</p>
                      <span className="green"></span>
                    </div>
                    <div
                      className={`number ${target == 4 ? 'selected' : null}`}
                      onClick={() => {
                        setTarget(4)
                        soundTapPlay()
                        setMultiplier(9)
                      }}
                    >
                      <p>4</p>
                      <span className="red"></span>
                    </div>
                  </div>
                  <div className="number_row">
                    <div
                      className={`number ${target == 5 ? 'selected' : null}`}
                      onClick={() => {
                        setTarget(5)
                        soundTapPlay()
                        setMultiplier(9)
                      }}
                    >
                      <p>5</p>
                      <span className="green_violet"></span>
                    </div>
                    <div
                      className={`number ${target == 6 ? 'selected' : null}`}
                      onClick={() => {
                        setTarget(6)
                        soundTapPlay()
                        setMultiplier(9)
                      }}
                    >
                      <p>6</p>
                      <span className="red"></span>
                    </div>
                    <div
                      className={`number ${target == 7 ? 'selected' : null}`}
                      onClick={() => {
                        setTarget(7)
                        soundTapPlay()
                        setMultiplier(9)
                      }}
                    >
                      <p>7</p>
                      <span className="green"></span>
                    </div>
                    <div
                      className={`number ${target == 8 ? 'selected' : null}`}
                      onClick={() => {
                        setTarget(8)
                        soundTapPlay()
                        setMultiplier(9)
                      }}
                    >
                      <p>8</p>
                      <span className="red"></span>
                    </div>
                    <div
                      className={`number ${target == 9 ? 'selected' : null}`}
                      onClick={() => {
                        setTarget(9)
                        soundTapPlay()
                        setMultiplier(9)
                      }}
                    >
                      <p>9</p>
                      <span className="green"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bet_col">
                <div className="bet_details">
                  <div className="top_box">
                    <div
                      className="btn_box_bet"
                      onClick={() => {
                        hanldeMinBetValue()
                        soundTapPlay()
                      }}
                    >
                      <span>Min</span>
                    </div>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        {selectedToken.icon}
                      </InputGroup.Text>
                      <Form.Control
                        value={betAmount}
                        onChange={handleBetAmountChange}
                        onBlur={(e) => {
                          if (e.target.value < minimumBet) {
                            setBetAmount(minimumBet)
                          }
                        }}
                        readOnly={totalTRX < minimumBet ? true : false}
                      />
                    </InputGroup>
                    <div
                      className="btn_box_bet"
                      onClick={() => {
                        handleHalfBetValue()
                        soundTapPlay()
                      }}
                    >
                      <span>1/2</span>
                    </div>
                  </div>
                  <div className="top_box">
                    <div
                      className="btn_box_bet"
                      onClick={() => {
                        handleMaxBetValue()
                        soundTapPlay()
                      }}
                    >
                      <span>Max</span>
                    </div>
                    {/* <h5>$0.0000000</h5> */}
                    <div
                      className="btn_box_bet"
                      onClick={() => {
                        handleDoubleBetValue()
                        soundTapPlay()
                      }}
                    >
                      <span>2x</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`bet_btn ${betAmount == 0 ? 'disabled' : ''}`}
                  onClick={!loader ? (() => { betPlaceHanlder(); soundTapPlay() }) : null}
                >
                  <div className="btn_text_box">
                    {loader ? (
                      <div
                        className="spinner-border btn-loader"
                        role="status"
                      ></div>
                    ) : (
                      <>
                        <h5>Bet</h5>
                        <p>Profit on win: {betAmount * multiplier} {selectedToken.value}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="tab_box bets_info_box mt-5">
              <Tabs
                defaultActiveKey="allBets"
                id="uncontrolled-tab-example"
                className="mb-3 justify-content-center"
                onSelect={(k) => {
                  if (k === 'allBets') {
                    getAllColorBetsHanlder()
                  } else {
                    getMyColorBetsHanlder()
                  }
                }}
              >
                <Tab eventKey="allBets" title="All Bets">
                  {isEmpty(allBets) ? (
                    <div className="no_data">
                      <img src={noData} alt="no data" className="no_data_img" />
                      <h4 className="no_h4">No record found!</h4>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Table variant="dark">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Time</th>
                            <th>Player</th>
                            <th>Bet Amount</th>
                            <th>Target</th>
                            <th>Multiplier</th>
                            <th>Result</th>
                            <th>Payout</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allBets.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                {moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a',)}
                              </td>
                              <td>{item.user_id}</td>
                              <td>
                                {' '}
                                <span>
                                <i className={`icon ${getIcon(item.token)}`}></i>
                                  {' '}
                                  {item.amount_placed}
                                </span>
                              </td>
                              <td><span className={`cp_result_box ${checkClass(item?.color_picked ?? item?.number_picked)}`}>{item?.number_picked ?? item?.color_picked.charAt(0)}</span></td>
                              <td>{item?.multiplier}x</td>
                              <td><span className={`cp_result_box ${checkClass(item?.result)}`}>{item?.result}</span></td>
                              <td>
                                {item.amount_won == 0 ? (
                                  <span style={{ justifyContent: 'center' }}>
                                    -
                                  </span>
                                ) : (
                                  <span className="result_green">
                                    <i className={`icon ${getIcon(item.token)}`}></i>
                                    {' '}
                                    {parseInt(item.amount_won)}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {
                        allBets.length >= 20 && allBets.length <200 ? <div style={{ textAlign: 'center' }}>
                        <button
                          style={{
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => getAllColorBetsHanlder(true)}
                        >
                          Load more
                        </button>
                      </div> : null
                      }
                    </div>
                  )}
                </Tab>
                <Tab eventKey="myBets" title="My Bets">
                  {isEmpty(myAllBets) ? (
                    <div className="no_data">
                      <img src={noData} alt="no data" className="no_data_img" />
                      <h4 className="no_h4">No record found!</h4>
                    </div>
                  ) : (
                    <div className="table-responsive">

                      <Table variant="dark">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Time</th>
                            <th>Bet Amount</th>
                            <th>Target</th>
                            <th>Multiplier</th>
                            <th>Result</th>
                            <th>Payout</th>
                          </tr>
                        </thead>
                        <tbody>
                          {myAllBets.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                {moment(item.createdAt).format(
                                  'MMMM Do YYYY, h:mm:ss a',
                                )}
                              </td>
                              <td>
                                {' '}
                                <span>
                                <i className={`icon ${getIcon(item.token)}`}></i>
                                  {' '}
                                  {item.amount_placed}
                                </span>
                              </td>
                              <td>
                                <span className={`cp_result_box ${checkClass(item?.color_picked ?? item?.number_picked)}`}>{item?.number_picked ?? item?.color_picked.charAt(0)}</span>
                              </td>

                              <td>x{item.multiplier.toFixed(2)}</td>
                              <td><span className={`cp_result_box ${checkClass(item?.result)}`}>{item?.result}</span></td>
                              <td>
                                {item.amount_won == 0 ? (
                                  <span style={{ justifyContent: 'center' }}>
                                    -
                                  </span>
                                ) : (
                                  <span className="result_green">
                                     <i className={`icon ${getIcon(item.token)}`}></i>                                    
                                    {' '}
                                    {parseInt(item.amount_won)}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {
                        myAllBets.length >= 20 ? 
                        <div style={{ textAlign: 'center' }}>
                        <button
                          style={{
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => getMyColorBetsHanlder(true)}
                        >
                          Load more
                        </button>
                      </div> : null
                      }
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className='col-sm-4'> <Chat /></div>
        </div>
      </div>
      <audio preload="auto" id="audioTap">
        <source src={TapSound}></source>
      </audio>
      <audio preload="auto" id="diceLose">
        <source src={DiceLose}></source>
      </audio>
      <audio preload="auto" id="diceWin">
        <source src={DiceWin}></source>
      </audio>
      <audio preload="auto" id="result">
        <source src={Result}></source>
      </audio>
      <audio preload="auto" id="clockSound">
        <source src={ClockSound}></source>
      </audio>
    </>
  )
}

export default ColorPrediction
