import axios from 'axios'
let count = 20

export const getSeed = async () => {
  try {
    const { data } = await axios.get(`dice/bet/random-seed`)
    return data
  } catch (err) {
    return err
  }
}
export const getDiceMyBets = async (loadmore) => {
  if (loadmore) count += 20  
  try {
    const { data } = await axios.get(`dice/bet/dice-bet-history?page=1&limit=${count}`)
    return data
  } catch (err) {
    return err
  }
}

export const getAllDiceBets = async (loadmore) => {
  if (loadmore) count += 20  
  try {
    const { data } = await axios.get(`dice/bet/dice-bet-history/all?page=1&limit=${count}`)
    return data
  } catch (err) {
    return err
  }
}

export const getCircleMyBets = async (loadmore) => {
  if (loadmore) count += 20  
  try {
    const { data } = await axios.get(`circle/bet/user/bet-data?page=1&limit=${count}`)
    return data
  } catch (err) {
    return err
  }
}

export const getAllCircleBets = async (loadmore) => {
  if (loadmore) count += 20  
  try {
    const { data } = await axios.get(`circle/bet/all-bets?page=1&limit=${count}`)
    return data
  } catch (err) {
    return err
  }
}

export const getGameResults = async (loadmore) => {
  try {
    const { data } = await axios.get(`circle/bet/color-won/logs`)
    return data
  } catch (err) {
    return err
  }
}

export const getAllColorBets = async (loadmore) => {
  if (loadmore) count += 20  

  try {
    const { data } = await axios.get(`color-prediction/bet/all-bets?page=1&limit=${count}`)
    return data
  } catch (err) {
    return err
  }
}
export const getMyColorBets = async (loadmore) => {
  if (loadmore) count += 20  
  try {
    const { data } = await axios.post(`color-prediction/bet/user/bet-data?page=1&limit=${count}`)
    return data
  } catch (err) {
    return err
  }
}


export const getRouletteMyBets = async (loadmore) => {
  if (loadmore) count += 20  
  try {
    const { data } = await axios.get(`roulette/bet/roulette-bet-history?page=1&limit=${count}`)
    return data
  } catch (err) {
    return err
  }
}

export const getAllRouletteBets = async (loadmore) => {
  if (loadmore) count += 20  
  try {
    const { data } = await axios.get(`roulette/bet/roulette-bet-history/all?page=1&limit=${count}`)
    return data
  } catch (err) {
    return err
  }
}
