/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react'

import TronWeb from 'tronweb'
import ReactTooltip from 'react-tooltip'
import axios from 'axios'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import TruncateMiddle from 'react-truncate-string'
import Modal from 'react-bootstrap/Modal'
import { AppContext } from '../context/index'

import { tspcontractAddress, contractAddress } from '../utils/ContractAddress'

import TSP from '../public/images/icons/tsp.png'
import tron from '../public/images/icons/tron_icon.png'
import avatar from '../public/images/icons/avatar.svg'
import TransactionHistory from './transaction_history'
import { useAccount } from '../context/AccountProvider'

const Account = () => {
  const context = useContext(AppContext)
  const { tspWalt, gameWalt, setUpdateGameWallet, setTSPbalance } = context

  const { accountAddress, accountBalance } = useAccount()
  const [eligible, setEligible] = useState('')
  const [amount, setAmount] = useState(0)
  const [tspamount, setTspamount] = useState(0)
  const [userName, setUserName] = useState('')
  const [refBy, setRefBy] = useState('')
  const [refereeAddress, setRefereeAddress] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingtsp, setLoadingTSP] = useState(false)
  const [lottoamount, setLottoamount] = useState(0)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [showTspwithdrawModal, setShowTspwithdrawModal] = useState(false)
  const [allowedWallets] = [
    "TMYPnqrWvbWoD2yA22uPo6RsPAwB8P1BTQ",
    "TPUq5X1PJWNuLU3NBbKiKRp7b9bG9Xcyga",
    "TUBffi2ihfz4s8bMdjYvjB6hzcwMgDnHaJ",
    "THJXuuY2LM6zMb8TZxKZUQxSus7jUph48r",
    "TRXjobuBASpVUxBYpNRtQTCDqkSQyvPgxJ",
    "THbwbkKGhUJU8sbt62WDDJjgebxXkdkArE",
    "TUyiU1ZXBv7CftF7LpLzT4kmJRHjPkC7SW",
    "TFTvsksmjXduJpmJavAz6A6AvHmJuYdauT",
    "TBzDKW5KEGayYFPAQmXEzKqoSYN6fv5sWH",
    "TB4addPLhBFnNDwUHyZLqxfguABH8S68Eq"
  ]

  const handleCloseWithdrawModal = () => {
    setShowWithdrawModal(false)
  }
  const handleOpenWithdrawModal = () => {
    setShowWithdrawModal(true)
  }
  const handleClose = () => setShowTspwithdrawModal(false)

  const handleShow = () => setShowTspwithdrawModal(true)

  const checkDealer = async () => {
    await axios
      .post(`tron/user/checkDealer`, {
        address: accountAddress,
      })
      .then((res) => {
        setEligible(res.data.status)
      })
      .catch((res) => {
        console.log(res, 'catch')
      })
  }

  const WithdrawTSPAmount = async () => {
    try {
      setLoadingTSP(true)
      if (tspWalt >= tspamount) {
        const contract = await window.tronWeb.contract().at(tspcontractAddress)
        const Withdrawamount = await contract
          .withdraw(tspamount * 1000000)
          .send()
        if (Withdrawamount) {
          ToastsStore.success('Transaction Successful')

          handleClose()
          setLoadingTSP(false)

          tspWaltbalance()
        }
      } else {
        ToastsStore.error('Please enter eligible withdraw amount')
        setLoadingTSP(false)
      }
    } catch (error) {
      ToastsStore.error('Transaction Failed')
      setLoadingTSP(false)
    }
  }

  const makeDealer = async () => {
    axios
      .get(`tron/user/madeDealer/${accountAddress}`)
      .then((res) => {
        console.log(res)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleAmount = (event) => {
    setAmount(+event.target.value)
  }

  const handleAll = () => {
    setAmount(gameWalt)
  }

  const handleTSPAmount = (event) => {
    setTspamount(+event.target.value)
  }

  const handleTSPAll = () => {
    setTspamount(tspWalt)
  }

  const becomeDealer = async () => {
    try {
      setLoading(true)
      const contract = await window.tronWeb.contract().at(contractAddress)
      await contract.makeDealer(accountAddress).send({ callValue: 5000000000 })
      ToastsStore.success('Transaction Seccessful')
      setLoading(false)

      await makeDealer()
      await checkDealer()
    } catch (error) {
      ToastsStore.error('Transaction Failed')
      setLoading(false)
    }
  }

  const fetchUserName = async () => {
    axios
      .get(`tron/user/player/${accountAddress}`, {})
      .then((res) => {
        setUserName(res.data.data.user_name)
        setRefBy(res.data.data.referred_by)
        setRefereeAddress(res.data.data.public_address)
      })
      .catch((res) => {
        console.log(res, 'catch')
      })
  }

  const WithdrawAmount = async () => {
    try {
      setLoading(true)
      if (gameWalt >= amount) {
        const contract = await window.tronWeb.contract().at(contractAddress)
        const Withdrawamount = await contract.withdraw(amount * 1000000).send()
        if (Withdrawamount) {
          ToastsStore.success('Transaction Successful')

          setLoading(false)
          setTimeout(() => {
            gameWaltbalance()
          }, 1000)
        }
      } else {
        ToastsStore.error('Please enter eligible withdraw amount')
        setLoading(false)
      }
    } catch (error) {
      ToastsStore.error('Transaction Failed')
      setLoading(false)
    }
  }

  const gameWaltbalance = async () => {
    try {
      const contract = await window.tronWeb.contract().at(contractAddress)
      const valtBal = await contract.userVaultBalance(accountAddress).call()
      const gameWaltbal = TronWeb.fromSun(valtBal)
      setUpdateGameWallet(gameWaltbal)
    } catch (error) {
      console.log(error, 'checkError')
    }
  }

  const tspWaltbalance = async () => {
    try {
      const contract = await window.tronWeb.contract().at(tspcontractAddress)
      const valtBal = await contract.userWallet(accountAddress).call()
      const tspWaltbal = TronWeb.fromSun(valtBal)

      setTSPbalance(tspWaltbal)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    tspWaltbalance()
    checkDealer()
    gameWaltbalance()
    fetchUserName()

    setAmount(gameWalt)
    setTspamount(tspWalt)
  }, [])
  return (
    <>
      <>
        <h5 className="title">Account</h5>
        <div className="card account_card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 col-8 h_b_r">
                <div className="media">
                  <img className="avatar_dp mr-3" src={avatar} alt="" />
                  <div className="media-body">
                    <p className="d-flex">
                      <small>
                        Welcome{' '}
                        <span>
                          <b>{userName}</b>
                        </span>
                      </small>
                    </p>
                    <h5 className="mt-0 user_address_token mb-0">
                      <TruncateMiddle text={accountAddress} />
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-md-4 daily_task_col text-center">
                <p>
                  <small>Referred by</small>
                </p>

                {refBy != null ? (
                  <div>
                    <p>{refBy}</p>
                    <TruncateMiddle text={refereeAddress} />
                  </div>
                ) : (
                  <p>No Referred by</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card account_card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 h_b_r hhhh">
                <div className="media">
                  <img className="avatar_dp mr-3" src={tron} alt="" />
                  <div className="media-body">
                    <div className="d-flex justify-content-center">
                      <small>TronLink Balance </small>
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary dropdown-toggle ml-3 dd_pp_btn"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {' '}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a className="dropdown-item" href>
                            LOTTO EARNING : {lottoamount} TRX
                          </a>
                          <a className="dropdown-item" href>
                            TSP EARNING : {tspamount} TRX
                          </a>
                          <a className="dropdown-item" href>
                            TOTAL EARNING : {lottoamount + tspamount} TRX
                          </a>
                        </div>
                      </div>
                    </div>
                    <h5 className="mt-0">
                      {Math.floor(accountBalance * 100) / 100} TRX
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6 tron_sweep_col text-center h_b_r">
                <div className="media">
                  <img className="avatar_dp mr-3" src={TSP} alt="" />
                  <div className="media-body">
                    <p>
                      <small>TSP Dividend</small>
                    </p>
                    <h5 className="mt-0">
                      {Math.floor(tspWalt * 100) / 100}
                      TRX
                    </h5>

                    {tspWalt > 0 ? (
                      <button
                        onClick={handleShow}
                        className="btn btn-outline-primary btn-block withdraw_btn"
                        type="button"
                      >
                        Withdraw
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6 tron_sweep_col text-center h_b_r">
                <div className="media">
                  <img className="avatar_dp mr-3" src={tron} alt="" />
                  <div className="media-body">
                    <p>
                      <small>Game Wallet Balance</small>
                    </p>
                    <h5 className="mt-0 d-flex justify-content-between">
                      {Math.floor(gameWalt * 100) / 100} TRX
                    </h5>
                    {gameWalt > 0 || allowedWallets.includes(accountAddress) ? (
                      <button
                        onClick={() => handleOpenWithdrawModal()}
                        className="btn btn-outline-primary btn-block withdraw_btn"
                        type="button"
                      >
                        Withdraw
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          dialogClassName="withdraw_modal"
          show={showWithdrawModal}
          size="sm"
          onHide={handleCloseWithdrawModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {' '}
              Withdraw <small>({Math.floor(amount * 100) / 100})</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="body">
              <div className="form-grou mb-0">
                <div className="input-group mb-0">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <img
                        className="icon_img_trs"
                        src={tron}
                        height="30"
                        alt=""
                      />
                    </span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    value={Math.floor(amount * 100) / 100}
                    onChange={handleAmount}
                  />
                  <div
                    className="input-group-append"
                    onClick={handleAll}
                    aria-hidden
                  >
                    <span className="input-group-text">
                      <a href>All</a>
                    </span>
                  </div>
                </div>
              </div>
              <span className='mb-1' style={{fontSize: "10px", color: "gray"}}>Min 500 TRX or greater can be withdrawn</span><br />

              {amount >= 500 ? (
                !loading ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={WithdrawAmount}
                  >
                    Submit
                  </button>
                ) : (
                  <button className="btn btn-primary" type="button">
                    <i className="fa fa-refresh fa-spin" />
                  </button>
                )
              ) : (
                <button className="btn btn-primary" disabled type="button">
                  Submit
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="sm"
          show={showTspwithdrawModal}
          onHide={handleClose}
          dialogClassName="withdraw_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Withdraw <small>({Math.floor(tspamount * 100) / 100})</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="body">
              <div className="form-group">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <img
                        className="icon_img_trs"
                        src={tron}
                        height="30"
                        alt=""
                      />
                    </span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    value={Math.floor(tspamount * 100) / 100}
                    onChange={handleTSPAmount}
                  />
                  <div
                    className="input-group-append"
                    onClick={handleTSPAll}
                    aria-hidden
                  >
                    <span className="input-group-text">
                      <a href>All</a>
                    </span>
                  </div>
                </div>
              </div>
              <span className='mb-1' style={{fontSize: "10px", color: "gray"}}>Min 500 TRX or greater can be withdrawn</span><br />

              {tspamount >= 500 ? (
                !loadingtsp ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={WithdrawTSPAmount}
                  >
                    Submit
                  </button>
                ) : (
                  <button className="btn btn-primary" type="button">
                    <i className="fa fa-refresh fa-spin" />
                  </button>
                )
              ) : (
                <button className="btn btn-primary" disabled type="button">
                  Submit
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <ToastsContainer store={ToastsStore} />
        <TransactionHistory accountAddress={accountAddress} />
      </>
    </>
  )
}

export default Account
