import React from 'react'
import './becomedealer.css'
import Sample from '../../../public/images/sample.png'
import SampleIcon from '../../../public/images/icons/sample.png'

const BecomeDealer = () => {
  return (
    <>
      <div className="main_container  become_dealer_wrapper">
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-md-8 banner_left">
                <h1>Benefits of become a dealer!</h1>
                <ul>
                  <li>
                    <strong>
                      <i className="mdi mdi-check" /> Lorem ipsum dolor sit amet
                    </strong>{' '}
                    consectetur adipiscing elit. Curabitur
                  </li>
                  <li>
                    <strong>
                      <i className="mdi mdi-check" /> Lorem ipsum dolor sit amet
                    </strong>{' '}
                    consectetur adipiscing elit. Curabitur
                  </li>
                  <li>
                    <strong>
                      <i className="mdi mdi-check" /> Lorem ipsum dolor sit amet
                    </strong>{' '}
                    consectetur adipiscing elit. Curabitur
                  </li>
                  <li>
                    <strong>
                      <i className="mdi mdi-check" /> Lorem ipsum dolor sit amet
                    </strong>{' '}
                    consectetur adipiscing elit. Curabitur
                  </li>
                  <li>
                    <strong>
                      <i className="mdi mdi-check" /> Lorem ipsum dolor sit amet
                    </strong>{' '}
                    consectetur adipiscing elit. Curabitur
                  </li>
                  <li>
                    <strong>
                      <i className="mdi mdi-check" /> Lorem ipsum dolor sit amet
                    </strong>{' '}
                    consectetur adipiscing elit. Curabitur
                  </li>
                  <li>
                    <strong>
                      <i className="mdi mdi-check" /> Lorem ipsum dolor sit amet
                    </strong>{' '}
                    consectetur adipiscing elit. Curabitur
                  </li>
                </ul>
                <button type="button" className="btn btn-primary">
                  Become a Dealer
                </button>
              </div>
              <div className="col-md-4 banner_right">
                <img alt="" src={Sample} />
              </div>
            </div>
          </div>
        </div>

        <div className="benefits p-tb white-sec">
          <div className="container">
            <div className="sec-title text-center">
              <h3>Benefits of Becoming Dealer</h3>
            </div>
            <div className="sub-txt text-center">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                cursus tincidunt ultrices. Ut quis blandit dolor. Ut laoreet
                sagittis arcu eu tristique.{' '}
              </p>
            </div>
          </div>
          <div className="container">
            <div className="benefits-boxes row">
              <div className="col-md-6">
                <div className="item wow fadeInUp">
                  <div className="bf-image">
                    <img src={SampleIcon} alt="Read Time Update" />
                  </div>
                  <div className="bf-details">
                    <h3> Lorem ipsum dolor </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean cursus tincidunt ultrices. Ut quis blandit dolor.
                      Ut laoreet sagittis arcu eu tristique.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="item wow fadeInUp">
                  <div className="bf-image">
                    <img src={SampleIcon} alt="Cloud Based" />
                  </div>
                  <div className="bf-details">
                    <h3> Lorem ipsum dolor sit</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean cursus tincidunt ultrices. Ut quis blandit dolor.
                      Ut laoreet sagittis arcu eu tristique.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="item wow fadeInUp" data-wow-delay="0.1s">
                  <div className="bf-image">
                    <img src={SampleIcon} alt="No transaction fees" />
                  </div>
                  <div className="bf-details">
                    <h3> Lorem ipsum dolor sit amet</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean cursus tincidunt ultrices. Ut quis blandit dolor.
                      Ut laoreet sagittis arcu eu tristique.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="item wow fadeInUp" data-wow-delay="0.1s">
                  <div className="bf-image">
                    <img src={SampleIcon} alt="Instant operations" />
                  </div>
                  <div className="bf-details">
                    <h3> Lorem ipsum dolor sit amet</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean cursus tincidunt ultrices. Ut quis blandit dolor.
                      Ut laoreet sagittis arcu eu tristique.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="item wow fadeInUp" data-wow-delay="0.2s">
                  <div className="bf-image">
                    <img src={SampleIcon} alt="Strong teams &amp; Advisors" />
                  </div>
                  <div className="bf-details">
                    <h3> Lorem ipsum dolor sit amet</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean cursus tincidunt ultrices. Ut quis blandit dolor.
                      Ut laoreet sagittis arcu eu tristique.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="item wow fadeInUp" data-wow-delay="0.2s">
                  <div className="bf-image">
                    <img src={SampleIcon} alt="Protects the identity" />
                  </div>
                  <div className="bf-details">
                    <h3> Lorem ipsum dolor sit amet</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean cursus tincidunt ultrices. Ut quis blandit dolor.
                      Ut laoreet sagittis arcu eu tristique.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="earn_win p-tb bg">
          <div className="container">
            <div className="sec-title text-center">
              <h3>How to earn?</h3>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="col-md-6 banner_right">
                <img src={Sample} alt="" />
              </div>
              <div className="col-md-6 sub-txt">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur tristique orci vitae urna fermentum, nec euismod
                  orci tristique. Mauris viverra lectus sit amet bibendum
                  sollicitudin. Donec imperdiet magna at sagittis egestas.
                  Curabitur vel ipsum metus. In urna purus, ullamcorper cursus
                  enim non, pretium molestie tortor. Morbi egestas in nisi nec
                  hendrerit. Curabitur pretium at velit eu ultrices. Maecenas id
                  aliquet lacus. Donec nec dolor sem. Quisque suscipit magna in
                  suscipit commodo. Curabitur vel ipsum metus. In urna purus,
                  ullamcorper cursus enim non, pretium molestie tortor. Morbi
                  egestas in nisi nec hendrerit. Curabitur pretium at velit eu
                  ultrices. Maecenas id aliquet lacus. Donec nec dolor sem.
                  Quisque suscipit magna in suscipit commodo.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="earn_win p-tb">
          <div className="container">
            <div className="sec-title text-center">
              <h3>How much can you earn?</h3>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="col-md-6 sub-txt">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur tristique orci vitae urna fermentum, nec euismod
                  orci tristique. Mauris viverra lectus sit amet bibendum
                  sollicitudin. Donec imperdiet magna at sagittis egestas.
                  Curabitur vel ipsum metus. In urna purus, ullamcorper cursus
                  enim non, pretium molestie tortor. Morbi egestas in nisi nec
                  hendrerit. Curabitur pretium at velit eu ultrices. Maecenas id
                  aliquet lacus. Donec nec dolor sem. Quisque suscipit magna in
                  suscipit commodo. Curabitur vel ipsum metus. In urna purus,
                  ullamcorper cursus enim non, pretium molestie tortor. Morbi
                  egestas in nisi nec hendrerit. Curabitur pretium at velit eu
                  ultrices. Maecenas id aliquet lacus. Donec nec dolor sem.
                  Quisque suscipit magna in suscipit commodo.
                </p>
              </div>
              <div className="col-md-6 banner_right">
                <img src={Sample} alt="" />
              </div>
            </div>
            <div className="become_dealer_btn">
              <button type="button" className="btn btn-outline-primary">
                Become a Dealer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BecomeDealer
