import React from 'react'
import Slider from 'react-slick'

const GallerySlider = ({ settings, children }) => {
  return (
    <>
      <Slider {...settings}>{children}</Slider>
    </>
  )
}

export default GallerySlider
