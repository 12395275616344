import React, { useEffect, useRef, useState } from 'react'
import { InputGroup, Form, Tabs, Tab, Table } from 'react-bootstrap'
import './styles.scss'
import './flipper.scss'
import anime from 'animejs'
import moment from 'moment'
import isEmpty from 'is-empty'
import noData from '../../../public/images/icons/no_data.svg'
import { getIcon } from '../../../utils/functions'
import Chat from '../../../lottery/components/chat'
import ClockSound from '../../../../assets/audio/clock-sound.mp3'
import DiceLose from '../../../../assets/audio/dice-lose.mp3'
import DiceWin from '../../../../assets/audio/dice-win.mp3'
import Result from '../../../../assets/audio/result.mp3'
import CircleSound from '../../../../assets/audio/circle.wav'
import TapSound from '../../../../assets/audio/tap.mp3'
import { useAccount } from '../../../context/AccountProvider'
import {
  initiateRouletteSocket,
  rouletteBetHistory,
  rouletteBetResponse,
  roulettePlaceBet,
} from '../../../helper/socket'
import { getAllRouletteBets, getRouletteMyBets } from '../Service'
import ChipBlue from './img/chip_blue.png'
import Chip20 from '../../../../assets/img/roulette/chip-20.png'
import Chip50 from '../../../../assets/img/roulette/chip-50.png'
import Chip100 from '../../../../assets/img/roulette/chip-100.png'
import Chip500 from '../../../../assets/img/roulette/chip-100.png'
import loopAudio from '../../../../assets/audio/loop.mp3'
import spinSound from '../../../../assets/audio/spin.wav'
import tabChip from '../../../../assets/audio/chips.mp3'
import { ToastsStore } from 'react-toasts'
import { useTimer } from 'react-timer-hook'

window.anime = anime

var currentBallRotation = 0
var currentWheelRotation = 0
var currentWheelIndex = 0
const rouletteWheelNumbers = [
  0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24,
  16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26,
]

const rouletteWheelNumbersPositions = {
  0: 360,
  26: 370,
  3: 380,
  35: 390,
  12: 399,
  28: 49,
  7: 58,
  29: 68,
  18: 78,
  22: 88,
  9: 97,
  31: 107,
  14: 117,
  20: 126,
  1: 136,
  33: 146,
  16: 154,
  24: 165,
  5: 175,
  10: 185,
  23: 195,
  8: 205,
  30: 214,
  11: 224,
  36: 234,
  13: 243,
  27: 253,
  6: 263,
  34: 273,
  17: 282,
  25: 292,
  2: 302,
  21: 311,
  4: 321,
  19: 331,
  15: 340,
  32: 350,
}

const minimumBet = 10
let betDetailsTemp = []

const slotListTemp = [
  {
    row: [
      {
        slotColor: 'slot-red',
        value: 3,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'odd',
        color: 'red',
        row: 'row1',
        groupTwoV1: '30',
        groupTwoV2: '36',
        groupTwoH: '23',
        groupThree: '320',
        groupFour1: '3625',
      },
      {
        slotColor: 'slot-black',
        value: 6,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'even',
        color: 'black',
        row: 'row1',
        groupTwoV1: '36',
        groupTwoV2: '69',
        groupTwoH: '56',
        groupThree: '',
        groupFour1: '3625',
        groupFour2: '6958',
      },
      {
        slotColor: 'slot-red',
        value: 9,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'odd',
        color: 'red',
        row: 'row1',
        groupTwoV1: '69',
        groupTwoV2: '912',
        groupTwoH: '89',
        groupThree: '',
        groupFour1: '6958',
        groupFour2: '912811',
      },
      {
        slotColor: 'slot-red',
        value: 12,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'even',
        color: 'red',
        row: 'row1',
        groupTwoV1: '912',
        groupTwoV2: '1215',
        groupTwoH: '1112',
        groupThree: '',
        groupFour1: '912811',
        groupFour2: '12111514',
      },
      {
        slotColor: 'slot-black',
        value: 15,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'second12',
        evenOdd: 'odd',
        color: 'black',
        row: 'row1',
        groupTwoV1: '1215',
        groupTwoV2: '1518',
        groupTwoH: '1415',
        groupThree: '',
        groupFour1: '12111514',
        groupFour2: '15141817',
      },
      {
        slotColor: 'slot-red',
        value: 18,
        bet: 0,
        isChipAdded: false,
        group12: 'second12',
        group18: 'firstTo18',
        evenOdd: 'even',
        color: 'red',
        row: 'row1',
        groupTwoV1: '1518',
        groupTwoV2: '1821',
        groupTwoH: '1718',
        groupThree: '',
        groupFour1: '15141817',
        groupFour2: '18172120',
      },
      {
        slotColor: 'slot-red',
        value: 21,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'second12',
        evenOdd: 'odd',
        color: 'red',
        row: 'row1',
        groupTwoV1: '1821',
        groupTwoV2: '2124',
        groupTwoH: '2021',
        groupThree: '',
        groupFour1: '18172120',
        groupFour2: '21202423',
      },
      {
        slotColor: 'slot-black',
        value: 24,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'second12',
        evenOdd: 'even',
        color: 'black',
        row: 'row1',
        groupTwoV1: '2124',
        groupTwoV2: '2427',
        groupTwoH: '2324',
        groupThree: '',
        groupFour1: '21202423',
        groupFour2: '24232726',
      },
      {
        slotColor: 'slot-red',
        value: 27,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'odd',
        color: 'red',
        row: 'row1',
        groupTwoV1: '2427',
        groupTwoV2: '2730',
        groupTwoH: '2627',
        groupThree: '',
        groupFour1: '24232726',
        groupFour2: '27263029',
      },
      {
        slotColor: 'slot-red',
        value: 30,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'even',
        color: 'red',
        row: 'row1',
        groupTwoV1: '2730',
        groupTwoV2: '3033',
        groupTwoH: '2930',
        groupThree: '',
        groupFour1: '27263029',
        groupFour2: '30293332',
      },
      {
        slotColor: 'slot-black',
        value: 33,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'odd',
        color: 'black',
        row: 'row1',
        groupTwoV1: '3033',
        groupTwoV2: '3336',
        groupTwoH: '3233',
        groupThree: '',
        groupFour1: '30293332',
        groupFour2: '33323635',
      },
      {
        slotColor: 'slot-red',
        value: 36,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'even',
        color: 'red',
        row: 'row1',
        groupTwoV1: '3336',
        groupTwoV2: '',
        groupTwoH: '3536',
        groupThree: '',
        groupFour1: '33323635',
        groupFour2: '',
      },
    ],
  },
  {
    row: [
      {
        slotColor: 'slot-black',
        value: 2,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'even',
        color: 'black',
        row: 'row2',
        groupTwoV1: '25',
        groupTwoV2: '20',
        groupTwoH: '12',
        groupTwoH2: '23',
        groupThree: '320',
        groupThree2: '210',
        groupFour1: '3625',
        groupFour2: '2514',
      },
      {
        slotColor: 'slot-red',
        value: 5,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'odd',
        color: 'red',
        row: 'row2',
        groupTwoV1: '25',
        groupTwoV2: '58',
        groupTwoH: '45',
        groupTwoH2: '56',
        groupThree: '',
        groupFour1: '3625',
        groupFour2: '2514',
        groupFour3: '6958',
        groupFour4: '5487',
      },
      {
        slotColor: 'slot-black',
        value: 8,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'even',
        color: 'black',
        row: 'row2',
        groupTwoV1: '58',
        groupTwoV2: '811',
        groupTwoH: '78',
        groupTwoH2: '89',
        groupThree: '',
        groupFour1: '6958',
        groupFour2: '912811',
        groupFour3: '5487',
        groupFour4: '871110',
      },
      {
        slotColor: 'slot-black',
        value: 11,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'odd',
        color: 'black',
        row: 'row2',
        groupTwoV1: '811',
        groupTwoV2: '1114',
        groupTwoH: '1011',
        groupTwoH2: '1112',
        groupThree: '',
        groupFour1: '912811',
        groupFour2: '12111514',
        groupFour3: '871110',
        groupFour4: '11101413',
      },
      {
        slotColor: 'slot-red',
        value: 14,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'second12',
        evenOdd: 'even',
        color: 'red',
        row: 'row2',
        groupTwoV1: '1114',
        groupTwoV2: '1417',
        groupTwoH: '1314',
        groupTwoH2: '1415',
        groupThree: '',
        groupFour1: '12111514',
        groupFour2: '15141817',
        groupFour3: '11101413',
        groupFour4: '14131716',
      },
      {
        slotColor: 'slot-black',
        value: 17,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'second12',
        evenOdd: 'odd',
        color: 'black',
        row: 'row2',
        groupTwoV1: '1417',
        groupTwoV2: '1720',
        groupTwoH: '1617',
        groupTwoH2: '1718',
        groupThree: '',
        groupFour1: '15141817',
        groupFour2: '18172120',
        groupFour3: '14131716',
        groupFour4: '17162019',
      },
      {
        slotColor: 'slot-black',
        value: 20,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'second12',
        evenOdd: 'even',
        color: 'black',
        row: 'row2',
        groupTwoV1: '1720',
        groupTwoV2: '2023',
        groupTwoH: '1920',
        groupTwoH2: '2021',
        groupThree: '',
        groupFour1: '18172120',
        groupFour2: '21202423',
        groupFour3: '17162019',
        groupFour4: '20192322',
      },
      {
        slotColor: 'slot-red',
        value: 23,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'second12',
        evenOdd: 'odd',
        color: 'red',
        row: 'row2',
        groupTwoV1: '2023',
        groupTwoV2: '2326',
        groupTwoH: '2223',
        groupTwoH2: '2324',
        groupThree: '',
        groupFour1: '21202423',
        groupFour2: '24232726',
        groupFour3: '20192322',
        groupFour4: '23222625',
      },
      {
        slotColor: 'slot-black',
        value: 26,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'even',
        color: 'black',
        row: 'row2',
        groupTwoV1: '2326',
        groupTwoV2: '2629',
        groupTwoH: '2526',
        groupTwoH2: '2627',
        groupThree: '',
        groupFour1: '24232726',
        groupFour2: '27263029',
        groupFour3: '23222625',
        groupFour4: '26252928',
      },
      {
        slotColor: 'slot-black',
        value: 29,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'odd',
        color: 'black',
        row: 'row2',
        groupTwoV1: '2629',
        groupTwoV2: '2932',
        groupTwoH: '2829',
        groupTwoH2: '2930',
        groupThree: '',
        groupFour1: '27263029',
        groupFour2: '30293332',
        groupFour3: '26252928',
        groupFour4: '29283231',
      },
      {
        slotColor: 'slot-red',
        value: 32,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'even',
        color: 'red',
        row: 'row2',
        groupTwoV1: '2932',
        groupTwoV2: '3235',
        groupTwoH: '3132',
        groupTwoH2: '3233',
        groupThree: '',
        groupFour1: '30293332',
        groupFour2: '33323635',
        groupFour3: '29283231',
        groupFour4: '32313534',
      },
      {
        slotColor: 'slot-black',
        value: 35,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'odd',
        color: 'black',
        row: 'row2',
        groupTwoV1: '3235',
        groupTwoV2: '',
        groupTwoH: '3435',
        groupTwoH2: '3536',
        groupThree: '',
        groupFour1: '33323635',
        groupFour2: '32313534',
        groupFour3: '',
        groupFour4: '',
      },
    ],
  },
  {
    row: [
      {
        slotColor: 'slot-red',
        value: 1,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'odd',
        color: 'red',
        row: 'row3',
        groupTwoV1: '10',
        groupTwoV2: '14',
        groupTwoH: '12',
        groupTwoH2: '',
        groupThree: '210',
        groupFour1: '2514',
        groupFour2: '',
        groupFour3: '',
        groupFour4: '',
      },
      {
        slotColor: 'slot-black',
        value: 4,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'even',
        color: 'black',
        row: 'row3',
        groupTwoV1: '14',
        groupTwoV2: '47',
        groupTwoH: '45',
        groupTwoH2: '',
        groupThree: '',
        groupFour1: '2514',
        groupFour2: '5487',
        groupFour3: '',
        groupFour4: '',
      },
      {
        slotColor: 'slot-red',
        value: 7,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'odd',
        color: 'red',
        row: 'row3',
        groupTwoV1: '47',
        groupTwoV2: '710',
        groupTwoH: '78',
        groupTwoH2: '',
        groupThree: '',
        groupFour1: '5487',
        groupFour2: '871110',
        groupFour3: '',
        groupFour4: '',
      },
      {
        slotColor: 'slot-black',
        value: 10,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'first12',
        evenOdd: 'even',
        color: 'black',
        row: 'row3',
        groupTwoV1: '710',
        groupTwoV2: '1013',
        groupTwoH: '1011',
        groupTwoH2: '',
        groupThree: '',
        groupFour1: '871110',
        groupFour2: '11101413',
        groupFour3: '',
        groupFour4: '',
      },
      {
        slotColor: 'slot-black',
        value: 13,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'second12',
        evenOdd: 'odd',
        color: 'black',
        row: 'row3',
        groupTwoV1: '1013',
        groupTwoV2: '1316',
        groupTwoH: '1314',
        groupTwoH2: '',
        groupThree: '',
        groupFour1: '11101413',
        groupFour2: '14131716',
        groupFour3: '',
        groupFour4: '',
      },
      {
        slotColor: 'slot-red',
        value: 16,
        bet: 0,
        isChipAdded: false,
        group18: 'firstTo18',
        group12: 'second12',
        evenOdd: 'even',
        color: 'red',
        row: 'row3',
        groupTwoV1: '1316',
        groupTwoV2: '1619',
        groupTwoH: '1617',
        groupTwoH2: '',
        groupThree: '',
        groupFour1: '14131716',
        groupFour2: '17162019',
        groupFour3: '',
        groupFour4: '',
      },
      {
        slotColor: 'slot-red',
        value: 19,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'second12',
        evenOdd: 'odd',
        color: 'red',
        row: 'row3',
        groupTwoV1: '1619',
        groupTwoV2: '1922',
        groupTwoH: '1920',
        groupTwoH2: '',
        groupThree: '',
        groupFour1: '17162019',
        groupFour2: '20192322',
        groupFour3: '',
        groupFour4: '',
      },
      {
        slotColor: 'slot-black',
        value: 22,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'second12',
        evenOdd: 'even',
        color: 'black',
        row: 'row3',
        groupTwoV1: '1922',
        groupTwoV2: '2225',
        groupTwoH: '2223',
        groupTwoH2: '',
        groupThree: '',
        groupFour1: '20192322',
        groupFour2: '23222625',
        groupFour3: '',
        groupFour4: '',
      },
      {
        slotColor: 'slot-red',
        value: 25,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'odd',
        color: 'red',
        row: 'row3',
        groupTwoV1: '2225',
        groupTwoV2: '2528',
        groupTwoH: '2526',
        groupTwoH2: '',
        groupThree: '',
        groupFour1: '23222625',
        groupFour2: '26252928',
        groupFour3: '',
        groupFour4: '',
      },
      {
        slotColor: 'slot-black',
        value: 28,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'even',
        color: 'black',
        row: 'row3',
        groupTwoV1: '2528',
        groupTwoV2: '2831',
        groupTwoH: '2829',
        groupTwoH2: '',
        groupThree: '',
        groupFour1: '26252928',
        groupFour2: '29283231',
        groupFour3: '',
      },
      {
        slotColor: 'slot-black',
        value: 31,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'odd',
        color: 'black',
        row: 'row3',
        groupTwoV1: '2831',
        groupTwoV2: '3134',
        groupTwoH: '3132',
        groupTwoH2: '',
        groupThree: '',
        groupFour1: '29283231',
        groupFour2: '32313534',
        groupFour3: '',
      },
      {
        slotColor: 'slot-red',
        value: 34,
        bet: 0,
        isChipAdded: false,
        group18: 'nintyTo36',
        group12: 'third12',
        evenOdd: 'even',
        color: 'red',
        row: 'row3',
        groupTwoV1: '3134',
        groupTwoV2: '',
        groupTwoH: '3435',
        groupTwoH2: '',
        groupThree: '',
        groupFour1: '32313534',
        groupFour2: '',
        groupFour3: '',
      },
    ],
  },
]
const slotVSpacerListTemp = [
  {
    row: [
      {
        value: '30',
        betValues: '3-0',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '36',
        betValues: '3-6',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '69',
        betValues: '6-9',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '912',
        betValues: '9-12',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1215',
        betValues: '12-15',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1518',
        betValues: '15-18',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1821',
        betValues: '18-21',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2124',
        betValues: '21-24',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2427',
        betValues: '24-27',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2730',
        betValues: '27-30',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '3033',
        betValues: '30-33',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '3336',
        betValues: '33-36',
        bet: 0,
        isChipAdded: false,
      },
    ],
  },
  {
    row: [
      {
        value: '20',
        betValues: '2-0',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '25',
        betValues: '2-5',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '58',
        betValues: '5-8',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '811',
        betValues: '8-11',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1114',
        betValues: '11-14',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1417',
        betValues: '14-17',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1720',
        betValues: '17-20',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2023',
        betValues: '20-23',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2326',
        betValues: '23-26',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2629',
        betValues: '26-29',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2932',
        betValues: '29-32',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '3235',
        betValues: '32-35',
        bet: 0,
        isChipAdded: false,
      },
    ],
  },
  {
    row: [
      {
        value: '10',
        betValues: '1-0',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '14',
        betValues: '1-4',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '47',
        betValues: '4-7',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '710',
        betValues: '7-10',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1013',
        betValues: '10-13',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1316',
        betValues: '13-16',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1619',
        betValues: '16-19',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1922',
        betValues: '19-22',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2225',
        betValues: '22-25',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2528',
        betValues: '25-28',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2831',
        betValues: '28-31',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '3134',
        betValues: '31-34',
        bet: 0,
        isChipAdded: false,
      },
    ],
  },
]
const slotHSpacerListTemp = [
  {
    row: [
      {
        value: '23',
        betValues: '2-3',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '56',
        betValues: '5-6',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '89',
        betValues: '8-9',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1112',
        betValues: '11-12',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1415',
        betValues: '14-15',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1718',
        betValues: '17-18',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2021',
        betValues: '20-21',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2324',
        betValues: '23-24',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2627',
        betValues: '26-27',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2930',
        betValues: '29-30',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '3233',
        betValues: '32-33',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '3536',
        betValues: '35-36',
        bet: 0,
        isChipAdded: false,
      },
    ],
  },
  {
    row: [
      {
        value: '12',
        betValues: '1-2',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '45',
        betValues: '4-5',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '78',
        betValues: '7-8',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1011',
        betValues: '10-11',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1314',
        betValues: '13-14',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1617',
        betValues: '16-17',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1920',
        betValues: '19-20',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2223',
        betValues: '22-23',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2526',
        betValues: '25-26',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2829',
        betValues: '28-29',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '3132',
        betValues: '31-32',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '3435',
        betValues: '34-35',
        bet: 0,
        isChipAdded: false,
      },
    ],
  },
]
const slotVHSpacerListTemp = [
  {
    row: [
      {
        value: '320',
        betValues: '3-2-0',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '3625',
        betValues: '3-6-2-5',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '6958',
        betValues: '6-9-5-8',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '912811',
        betValues: '9-12-8-11',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '12111514',
        betValues: '12-11-15-14',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '15141817',
        betValues: '15-14-18-17',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '18172120',
        betValues: '18-17-21-20',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '21202423',
        betValues: '21-20-24-23',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '24232726',
        betValues: '24-23-27-26',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '27263029',
        betValues: '27-26-30-29',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '30293332',
        betValues: '30-29-33-32',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '33323635',
        betValues: '33-32-36-35',
        bet: 0,
        isChipAdded: false,
      },
    ],
  },
  {
    row: [
      {
        value: '210',
        betValues: '2-1-0',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2514',
        betValues: '2-5-1-4',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '5487',
        betValues: '5-4-8-7',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '871110',
        betValues: '8-7-11-10',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '11101413',
        betValues: '11-10-14-13',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '14131716',
        betValues: '14-13-17-16',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '17162019',
        betValues: '17-16-20-19',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '20192322',
        betValues: '20-19-23-22',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '23222625',
        betValues: '23-22-26-25',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '26252928',
        betValues: '26-25-29-28',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '29283231',
        betValues: '29-28-32-31',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '32313534',
        betValues: '32-31-35-34',
        bet: 0,
        isChipAdded: false,
      },
    ],
  },
]
const slotTSpacerListTemp = [
  {
    row: [
      {
        value: '3',
        betValues: '3',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '6',
        betValues: '6',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '9',
        betValues: '9',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '12',
        betValues: '12',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '15',
        betValues: '15',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '18',
        betValues: '18',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '21',
        betValues: '21',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '24',
        betValues: '24',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '27',
        betValues: '27',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '30',
        betValues: '30',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '33',
        betValues: '33',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '36',
        betValues: '36',
        bet: 0,
        isChipAdded: false,
      },
    ],
  },
]
const slotTDSpacerListTemp = [
  {
    row: [
      {
        value: '36',
        betValues: '3-6',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '69',
        betValues: '6-9',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '912',
        betValues: '9-12',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1215',
        betValues: '12-15',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1518',
        betValues: '15-18',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '1821',
        betValues: '18-21',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2124',
        betValues: '21-24',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2427',
        betValues: '24-27',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '2730',
        betValues: '27-30',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '3033',
        betValues: '30-33',
        bet: 0,
        isChipAdded: false,
      },
      {
        value: '3336',
        betValues: '33-36',
        bet: 0,
        isChipAdded: false,
      },
    ],
  },
]
const group12ListTemp = [
  {
    value: '1 ST 12',
    bet: 0,
    isChipAdded: false,
    group12: 'first12',
    betValues: '1-2-3-4-5-6-7-8-9-10-11-12',
    className: '1st12',
  },
  {
    value: '2 ND 12',
    bet: 0,
    isChipAdded: false,
    group12: 'second12',
    betValues: '13-14-15-16-17-18-19-20-21-22-23-24',
    className: '2nd12',
  },
  {
    value: '3 RD 12',
    bet: 0,
    isChipAdded: false,
    group12: 'third12',
    betValues: '25-26-27-28-29-30-31-32-33-34-35-36',
    className: '3rd12',
  },
]
const bottomRandomListTemp = [
  {
    value: '1 TO 18',
    bet: 0,
    isChipAdded: false,
    group18: 'firstTo18',
    betValues: '1-2-3-4-5-6-7-8-9-10-11-12-13-14-15-16-17-18',
    className: 'slot-1-18',
  },
  {
    value: 'EVEN',
    bet: 0,
    isChipAdded: false,
    group18: 'even',
    betValues: '2-4-6-8-10-12-14-16-18-20-22-24-26-28-30-32-34-36',
    className: 'slot-even',
  },
  {
    value: 'RED',
    bet: 0,
    isChipAdded: false,
    group18: 'red',
    betValues: '1-3-5-7-9-12-14-16-18-19-21-23-25-27-30-32-34-36',
    className: 'slot-all-red slot-red',
  },
  {
    value: 'BLACK',
    bet: 0,
    isChipAdded: false,
    group18: 'black',
    betValues: '2-4-6-8-10-11-13-15-17-20-22-24-26-28-29-31-33-35',
    className: 'slot-all-black slot-black',
  },
  {
    value: 'ODD',
    bet: 0,
    isChipAdded: false,
    group18: 'odd',
    betValues: '1-3-5-7-9-11-13-15-17-19-21-23-25-27-29-31-33-35',
    className: 'slot-odd',
  },
  {
    value: '19 TO 36',
    bet: 0,
    isChipAdded: false,
    group18: 'nintyTo36',
    betValues: '19-20-21-22-23-24-25-26-27-28-29-30-31-32-33-34-35-36',
    className: 'slot-19-36',
  },
]
const rowListTemp = [
  {
    value: '2:1',
    bet: 0,
    isChipAdded: false,
    groupRow: 'row1',
    betValues: '3-6-9-12-15-18-21-24-27-30-33-36',
    className: 'slot-2to1-1',
  },

  {
    value: '2:1',
    bet: 0,
    isChipAdded: false,
    groupRow: 'row2',
    betValues: '2-5-8-11-14-17-20-23-26-29-32-35',
    className: 'slot-2to1-2',
  },
  {
    value: '2:1',
    bet: 0,
    isChipAdded: false,
    groupRow: 'row3',
    betValues: '1-4-7-10-13-16-19-22-25-28-31-34',
    className: 'slot-2to1-3',
  },
]
const zeroTemp = [
  {
    value: '0',
    bet: 0,
    isChipAdded: false,
    groupTwoH: '10',
    groupTwoH2: '20',
    groupTwoH3: '30',
    groupThree: '320',
    groupThree2: '210',
    betValues: '0',
  },
]

let rotate = 0
let lastBetsRecord = {}
export default function Roulette() {
  const { totalTRX, tokenInUsd, onSetResultTimer, selectedToken } = useAccount()
  const [socket, setSocket] = useState(null)
  const [betTimer, setBetTimer] = useState(0)
  
  const [boardRotating, setBoardRotating] = useState(false)
  const [isRotating, setIsRotating] = useState(false)
  const [resultAvailable, setResultAvailable] = useState(false)
  const [result, setResult] = useState([])
  const [betActive, setBetActive] = useState(false)
  const [myAllBets, setMyAllBets] = useState([])
  const [allBets, setAllBets] = useState([])
  const [betAmount, setBetAmount] = useState(100)
  const [seletedChip, setSelected] = useState('10')
  const chipsList = ['10', '20', '50', '100', '500']
  const [totalAmount, setTotalAmount] = useState(0)
  const [betDetails, setBetDetails] = useState([])
  const audioRef = useRef()
  const [numberDetails, setNumberDetails] = useState({
    first12: false,
    second12: false,
    third12: false,
    firstTo18: false,
    even: false,
    odd: false,
    nintyTo36: false,
    red: false,
    black: false,
    row1: false,
    row2: false,
    row3: false,
    12: false,
    23: false,
    45: false,
    56: false,
    78: false,
    89: false,
    1011: false,
    1112: false,
    1314: false,
    1415: false,
    1617: false,
    1718: false,
    1920: false,
    2021: false,
    2223: false,
    2324: false,
    2526: false,
    2627: false,
    2829: false,
    2930: false,
    3132: false,
    3233: false,
    3435: false,
    3536: false,
    10: false,
    20: false,
    30: false,
    320: false,
    210: false,
    36: false,
    69: false,
    912: false,
    1518: false,
    1821: false,
    2124: false,
    2427: false,
    2730: false,
    3033: false,
    3336: false,
    3625: false,
    6958: false,
    25: false,
    58: false,
    811: false,
    1114: false,
    1417: false,
    1720: false,
    2023: false,
    2326: false,
    2629: false,
    2932: false,
    3235: false,
    912811: false,
    12151114: false,
    15181417: false,
    18211720: false,
    12111514: false,
    15141817: false,
    18172120: false,
    21202423: false,
    24232726: false,
    27263029: false,
    30293332: false,
    2514: false,
    5487: false,
    871110: false,
    11101413: false,
    14131716: false,
    17162019: false,
    20192322: false,
    23222625: false,
    26252928: false,
    29283231: false,
    32313534: false,
    14: false,
    47: false,
    710: false,
    1013: false,
    1619: false,
    1922: false,
    2225: false,
    2528: false,
    3134: false,
  })
  const [chipValues, setChipValues] = useState({
    chip10: 10,
    chip20: 20,
    chip50: 50,
    chip100: 100,
    chip500: 500,
  })
  const [isFliped, setIsFliped] = useState(false)
  const [slotList, setSlotList] = useState([...slotListTemp])
  const [slotVSpacerList, setSlotVSpacerList] = useState([
    ...slotVSpacerListTemp,
  ])
  const [slotHSpacerList, setSlotHSpacerList] = useState([
    ...slotHSpacerListTemp,
  ])
  const [slotVHSpacerList, setSlotVHSpacerList] = useState([
    ...slotVHSpacerListTemp,
  ])
  const [slotTSpacerList, setSlotTSpacerList] = useState([
    ...slotTSpacerListTemp,
  ])
  const [slotTDSpacerList, setSlotTDSpacerList] = useState([
    ...slotTDSpacerListTemp,
  ])
  const [group12List, setGroup12List] = useState([...group12ListTemp])
  const [bottomRandomList, setBottomRandomList] = useState([
    ...bottomRandomListTemp,
  ])
  const [rowList, setRowList] = useState([...rowListTemp])
  const [zeroList, setZeroList] = useState([...zeroTemp])
  const [repeatLastBetData, setRepeatLastBetData] = useState({
    slotList: [],
    slotVSpacerList: [],
    slotHSpacerList: [],
    slotVHSpacerList: [],
    slotTSpacerList: [],
    slotTDSpacerList: [],
  })
  const [isBetPlaced, setIsBetPlaced] = useState(false)

  const [timer, setTimer] = useState(30);
  const [statusMessage, setStatusMessage] = useState('-')
  const id = useRef(null);
  const clear = () => {
    window.clearInterval(id.current)
  }

  const startTimer = () => {
    clear();
    // if (timer < 0) return
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1)
    }, 1000)
    return () => clear();
  }

  useEffect(() => {
    startTimer()
  }, [])

  useEffect(() => {
    if (timer === 0) {
      betPlaceHanlder()
      clear()
    }
    if (timer > 5) {
      setStatusMessage("PLACE YOUR BETS")
    }

    if (timer <= 5) {
      setStatusMessage("Stop Placing Bets")
    }
  }, [timer])


  useEffect(() => {
    if (isBetPlaced) {
      slotHSpacerList.forEach((el) => {
        if (
          el.row.some((col) => {
            return col.isChipAdded
          })
        ) {
          alert('chipAdded')
        }
      })
    }
  }, [isBetPlaced])

  const handleMouseEnter = (key) => {
    setNumberDetails((prev) => ({ ...prev, [key]: true }))
  }
  const handleMouseLeave = (key) => {
    setNumberDetails((prev) => ({ ...prev, [key]: false }))
  }

  const soundTapPlay = () => {
    var audio = document.getElementById('audioTap')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }

  const updateChip = (i) => {
    let newchip = 'chip_'

    if ((i === 1 && seletedChip == 500) || (i === -1 && seletedChip == 10)) {
      return
    }

    if (chipsList.indexOf(seletedChip) >= 0) {
      const amount = chipsList[chipsList.indexOf(seletedChip) + i]
      setSelected(amount)
    } else {
      setSelected('10')
    }

    // setIsFliped(true)
    const coin = document.getElementById('coinBet')
    rotate = rotate + 180
    coin.style.transform = `rotateY(${rotate}deg)`
    //  let newChip = seletedChip + chipsList[]
  }

  const getRouletteWheelNumber = (index) =>
    rouletteWheelNumbers[index >= 0 ? index % 37 : 37 - Math.abs(index % 37)]

  const getRouletteWheelColor = (i) => {
    const red = [
      1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
    ]
    const black = [
      2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
    ]

    return red.includes(i) ? 'red' : black.includes(i) ? 'black' : 'green'
  }

  window.rouletteWheelNumbers = rouletteWheelNumbers

  const startRotation = (speed) => {
    spinSoundPlay()
    if (isRotating) return

    setIsRotating(true)
    setBoardRotating(true)

    const bezier = [0.165, 0.84, 0.44, 1.005]

    const newWheelIndex = currentWheelIndex 
    const resultColor = getRouletteWheelColor(speed)
      ; (() => {
        const newRotaion = 720 + rouletteWheelNumbersPositions[speed]
        var myAnimation = anime({
          targets: ['.layer-2', '.layer-4'],
          rotate: function () {
            return newRotaion
          },
          duration: function () {
            return 5000
          },
          loop: 1,
          // easing: "cubicBezier(0.010, 0.990, 0.855, 1.010)",
          easing: `cubicBezier(${bezier.join(',')})`,
          // easing: "cubicBezier(0.000, 1.175, 0.980, 0.990)",
          complete: (...args) => {
            currentWheelRotation = newRotaion
            currentWheelIndex = newWheelIndex
          },
        })
      })()
      ; (() => {
        const newRotaion = -4 * 720 + currentBallRotation
        var myAnimation1 = anime({
          targets: '.ball-container',
          translateY: [
            { value: 0, duration: 2000 },
            { value: 20, duration: 1000 },
            { value: 25, duration: 900 },
            { value: 50, duration: 1000 },
          ],
          rotate: [{ value: newRotaion, duration: 4000 }],
          duration: function () {
            return 4000 // anime.random(800, 1400);
          },
          loop: 1,
          easing: `cubicBezier(${bezier.join(',')})`,
          complete: () => {
            currentBallRotation = newRotaion
            setIsRotating(false)
            setTimeout(() => {
              setBoardRotating(false)
            }, 5000)
          
            spinSoundPause()
          },
        })
      })()

    setTimeout(() => {
      setBetActive(false)
    }, 5000)
  }

  const clockSoundPlay = () => {
    var audio = document.getElementById('clockSound')
    audio.play()
    audio.loop = true
    audio.volume = 0.2
  }
  const clockSoundPause = () => {
    var audio = document.getElementById('clockSound')
    audio.pause()
  }

  const resultSound = () => {
    var audio = document.getElementById('result')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const diceWin = () => {
    var audio = document.getElementById('diceWin')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const diceLose = () => {
    var audio = document.getElementById('diceLose')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }

  const getMyBetsHandler = async (loadmore) => {
    const res = await getRouletteMyBets(loadmore)
    if (res.history.length > 0) {
      setMyAllBets(res.history)
    }
  }

  const getAllRouletteBetsHandler = async (loadmore) => {
    const res = await getAllRouletteBets(loadmore)
    if (res.history.dice_game_history.length > 0) {
      setAllBets(res.history.dice_game_history)
    }
  }

  const betPlaceHanlder = () => {
    if (!socket?.connected || betAmount <= 0) return
    if (betDetails.length === 0) {
      ToastsStore.info('Select Chips')
      setBetActive(false)
      setTimer(30)
      startTimer()
      return
    }
    localStorage.setItem('resultTime', 5000)
    localStorage.setItem('lastBet', JSON.stringify(betDetails))

    setBetActive(true)
    const data = {
      bets: betDetails,
      token: 'CRD',
      bet_type: 'OUT',
    }
    roulettePlaceBet(data)


    lastBetsRecord = {
      slotList,
      slotVSpacerList,
      slotVHSpacerList,
      slotHSpacerList,
      slotTSpacerList,
      slotTDSpacerList,
      group12List,
      bottomRandomList,
      rowList,
      zeroList,
    }

    localStorage.setItem('lastBetsRecord', JSON.stringify(lastBetsRecord))
    setTimeout(() => {
      const slotListTP = [...slotListTemp]
      const slotVSpacerListTP = [...slotVSpacerListTemp]
      const slotVHSpacerListTP = [...slotVHSpacerListTemp]
      const slotHSpacerListTP = [...slotHSpacerListTemp]
      const slotTSpacerListTP = [...slotTSpacerListTemp]
      const slotTDSpacerListTP = [...slotTDSpacerListTemp]
      slotListTP.forEach((el) => {
        el.row.forEach((col) => {
          col.isChipAdded = false
          col.bet = 0
        })
      })

      slotVSpacerListTP.forEach((el) => {
        el.row.forEach((col) => {
          col.isChipAdded = false
          col.bet = 0
        })
      })
      slotVHSpacerListTP.forEach((el) => {
        el.row.forEach((col) => {
          col.isChipAdded = false
          col.bet = 0
        })
      })
      slotHSpacerListTP.forEach((el) => {
        el.row.forEach((col) => {
          col.isChipAdded = false
          col.bet = 0
        })
      })
      slotTSpacerListTP.forEach((el) => {
        el.row.forEach((col) => {
          col.isChipAdded = false
          col.bet = 0
        })
      })
      slotTDSpacerListTP.forEach((el) => {
        el.row.forEach((col) => {
          col.isChipAdded = false
          col.bet = 0
        })
      })
      group12List.forEach((col) => {
        col.isChipAdded = false
        col.bet = 0
      })
      bottomRandomList.forEach((col) => {
        col.isChipAdded = false
        col.bet = 0
      })
      rowList.forEach((col) => {
        col.isChipAdded = false
        col.bet = 0
      })
      zeroList.forEach((col) => {
        col.isChipAdded = false
        col.bet = 0
      })

      setSlotList(slotListTP)
      setSlotVSpacerList(slotVSpacerListTP)
      setSlotVHSpacerList(slotVHSpacerListTP)
      setSlotHSpacerList(slotHSpacerListTP)
      setSlotTSpacerList(slotTSpacerListTP)
      setSlotTDSpacerList(slotTDSpacerListTP)
      setGroup12List(group12List)
      setBottomRandomList(bottomRandomList)
      setRowList(rowList)
      setZeroList(zeroList)
      setIsBetPlaced(true)
    }, 1000)
  }

  useEffect(() => {
    getMyBetsHandler()
    getAllRouletteBetsHandler()
    const socketTemp = initiateRouletteSocket()
    setSocket(socketTemp)

    rouletteBetHistory((err, data) => {
      setTimeout(() => {
        setAllBets((prev) => [data, ...prev])
      }, 8000)
      
    })

    rouletteBetResponse((err, data) => {
      ToastsStore.info(data.message)
      if (isEmpty(data.bet_data)) {
        setIsRotating(false)
        setBetActive(false)
        startTimer()
      }

      if (data.bet_data !== null ) {
        startRotation(data.bet_data.result)
      }

      setTimeout(() => {
        getMyBetsHandler()
        setResult((result) => [...result, data.bet_data])
        setResultAvailable(true)
        data.bet_data.isWin ? diceWin() : diceLose()
        clearChipHandler('betPlace')
      }, localStorage.getItem('resultTime'))

      setTimeout(() => {
        setTimer(30)
        startTimer()
        setBetActive(false)
      }, 5000)

      setTimeout(() => {
        // some other login to display
        setResultAvailable(false)
      }, 8000)
    })
  }, [])

  const handleChipAddHandler = (e, bets, arrayType, rowIndex, colIndex) => {
    audioRef.current.play()
    tapChipSoundPlay()
    setTotalAmount((prev) => parseInt(prev) + parseInt(seletedChip))
    const betsNumber = bets.length > 2 ? bets.split('-') : [bets]
    const betFields = {
      amount: parseInt(seletedChip),
      fields: betsNumber,
    }
    if (betDetailsTemp.length === 0) {
      betDetailsTemp.push(betFields)
    } else {
      if (
        (typeof bets === 'number' &&
          !betDetailsTemp.some((p) => p.fields.join('') == bets)) ||
        (typeof bets === 'string' &&
          !betDetailsTemp.some(
            (p) => p.fields.join('') == bets.split('-').join(''),
          ))
      ) {
        betDetailsTemp.push(betFields)
      } else {
        betDetailsTemp.forEach((el) => {
          if (
            (typeof bets === 'number' && el.fields.join('') == bets) ||
            (typeof bets === 'string' &&
              el.fields.join('') == bets.split('-').join(''))
          ) {
            el.amount = el.amount + parseInt(seletedChip)
          }
        })
      }
    }
    setBetDetails(betDetailsTemp)

    if (arrayType === 'slotList') {
      const tempSlotList = [...slotList]
      tempSlotList.forEach((el, index) => {
        if (index === rowIndex) {
          el.row.forEach((col, i) => {
            if (i === colIndex) {
              col.isChipAdded = true
              col.bet = parseInt(col.bet) + parseInt(seletedChip)
              col.chipType =
                seletedChip === '10'
                  ? ChipBlue
                  : seletedChip === '20'
                    ? Chip20
                    : seletedChip === '50'
                      ? Chip50
                      : seletedChip === '100'
                        ? Chip100
                        : Chip500
            }
          })
        }
      })
      setSlotList(tempSlotList)
      return
    }
    if (arrayType === 'slotVSpacerList') {
      const slotVSpacerListTemp = [...slotVSpacerList]
      slotVSpacerListTemp.forEach((el, index) => {
        if (index === rowIndex) {
          el.row.forEach((col, i) => {
            if (i === colIndex) {
              col.isChipAdded = true
              col.bet = parseInt(col.bet) + parseInt(seletedChip)
              col.chipType =
                seletedChip === '10'
                  ? ChipBlue
                  : seletedChip === '20'
                    ? Chip20
                    : seletedChip === '50'
                      ? Chip50
                      : seletedChip === '100'
                        ? Chip100
                        : Chip500
            }
          })
        }
      })
      setSlotVSpacerList(slotVSpacerListTemp)
      return
    }
    if (arrayType === 'slotHSpacerList') {
      const slotHSpacerListTemp = [...slotHSpacerList]
      slotHSpacerListTemp.forEach((el, index) => {
        if (index === rowIndex) {
          el.row.forEach((col, i) => {
            if (i === colIndex) {
              col.isChipAdded = true
              col.bet = parseInt(col.bet) + parseInt(seletedChip)
              col.chipType =
                seletedChip === '10'
                  ? ChipBlue
                  : seletedChip === '20'
                    ? Chip20
                    : seletedChip === '50'
                      ? Chip50
                      : seletedChip === '100'
                        ? Chip100
                        : Chip500
            }
          })
        }
      })
      setSlotHSpacerList(slotHSpacerListTemp)
      return
    }
    if (arrayType === 'slotVHSpacerList') {
      const slotVHSpacerListTemp = [...slotVHSpacerList]
      slotVHSpacerListTemp.forEach((el, index) => {
        if (index === rowIndex) {
          el.row.forEach((col, i) => {
            if (i === colIndex) {
              col.isChipAdded = true
              col.bet = parseInt(col.bet) + parseInt(seletedChip)
              col.chipType =
                seletedChip === '10'
                  ? ChipBlue
                  : seletedChip === '20'
                    ? Chip20
                    : seletedChip === '50'
                      ? Chip50
                      : seletedChip === '100'
                        ? Chip100
                        : Chip500
            }
          })
        }
      })
      setSlotVHSpacerList(slotVHSpacerListTemp)
      return
    }
    if (arrayType === 'slotTSpacerList') {
      const slotTSpacerListTemp = [...slotTSpacerList]
      slotTSpacerListTemp.forEach((el, index) => {
        if (index === rowIndex) {
          el.row.forEach((col, i) => {
            if (i === colIndex) {
              col.isChipAdded = true
              col.bet = parseInt(col.bet) + parseInt(seletedChip)
              col.chipType =
                seletedChip === '10'
                  ? ChipBlue
                  : seletedChip === '20'
                    ? Chip20
                    : seletedChip === '50'
                      ? Chip50
                      : seletedChip === '100'
                        ? Chip100
                        : Chip500
            }
          })
        }
      })
      setSlotTSpacerList(slotTSpacerListTemp)
      return
    }
    if (arrayType === 'slotTDSpacerList') {
      const slotTDSpacerListTemp = [...slotTDSpacerList]
      slotTDSpacerListTemp.forEach((el, index) => {
        if (index === rowIndex) {
          el.row.forEach((col, i) => {
            if (i === colIndex) {
              col.isChipAdded = true
              col.bet = parseInt(col.bet) + parseInt(seletedChip)
              col.chipType =
                seletedChip === '10'
                  ? ChipBlue
                  : seletedChip === '20'
                    ? Chip20
                    : seletedChip === '50'
                      ? Chip50
                      : seletedChip === '100'
                        ? Chip100
                        : Chip500
            }
          })
        }
      })
      setSlotTDSpacerList(slotTDSpacerListTemp)
      return
    }
    if (arrayType === 'group12List') {
      const group12ListTemp = [...group12List]
      group12ListTemp.forEach((el, index) => {
        if (index === colIndex) {
          el.isChipAdded = true
          el.bet = parseInt(el.bet) + parseInt(seletedChip)
          el.chipType =
            seletedChip === '10'
              ? ChipBlue
              : seletedChip === '20'
                ? Chip20
                : seletedChip === '50'
                  ? Chip50
                  : seletedChip === '100'
                    ? Chip100
                    : Chip500
        }
      })
      setGroup12List(group12ListTemp)
      return
    }
    if (arrayType === 'bottomRandomList') {
      const bottomRandomListTemp = [...bottomRandomList]
      bottomRandomListTemp.forEach((el, index) => {
        if (index === colIndex) {
          el.isChipAdded = true
          el.bet = parseInt(el.bet) + parseInt(seletedChip)
          el.chipType =
            seletedChip === '10'
              ? ChipBlue
              : seletedChip === '20'
                ? Chip20
                : seletedChip === '50'
                  ? Chip50
                  : seletedChip === '100'
                    ? Chip100
                    : Chip500
        }
      })
      setBottomRandomList(bottomRandomListTemp)
      return
    }
    if (arrayType === 'rowList') {
      const rowListTemp = [...rowList]
      rowListTemp.forEach((el, index) => {
        if (index === colIndex) {
          el.isChipAdded = true
          el.bet = parseInt(el.bet) + parseInt(seletedChip)
          el.chipType =
            seletedChip === '10'
              ? ChipBlue
              : seletedChip === '20'
                ? Chip20
                : seletedChip === '50'
                  ? Chip50
                  : seletedChip === '100'
                    ? Chip100
                    : Chip500
        }
      })
      setRowList(rowListTemp)
      return
    }
    if (arrayType === 'zeroList') {
      const zeroListTemp = [...zeroList]
      zeroListTemp.forEach((el, index) => {
        if (index === colIndex) {
          el.isChipAdded = true
          el.bet = parseInt(el.bet) + parseInt(seletedChip)
          el.chipType =
            seletedChip === '10'
              ? ChipBlue
              : seletedChip === '20'
                ? Chip20
                : seletedChip === '50'
                  ? Chip50
                  : seletedChip === '100'
                    ? Chip100
                    : Chip500
        }
      })
      setZeroList(zeroListTemp)
      return
    }

    const node = document.createElement('div')

    const textnode = document.createTextNode(
      seletedChip === '10'
        ? chipValues.chip10
        : seletedChip === '20'
          ? chipValues.chip20
          : seletedChip === '50'
            ? chipValues.chip50
            : seletedChip === '100'
              ? chipValues.chip100
              : chipValues.chip500,
    )
    node.className = `slot-bet ${seletedChip}`
    node.style.backgroundImage = `url(${seletedChip === '10'
      ? ChipBlue
      : seletedChip === '20'
        ? Chip20
        : seletedChip === '50'
          ? Chip50
          : seletedChip === '100'
            ? Chip100
            : Chip500
      })`
    node.appendChild(textnode)
    if (e.target.parentNode.classList.contains('slot')) {
      if (!e.target.classList.contains('slot-name')) {
        e.target.style.backgroundImage = `url(${seletedChip === '10'
          ? ChipBlue
          : seletedChip === '20'
            ? Chip20
            : seletedChip === '50'
              ? Chip50
              : seletedChip === '100'
                ? Chip100
                : Chip500
          })`
        e.target.innerText =
          parseInt(e.target.innerText) + parseInt(seletedChip)
      }
      if (!e.target.classList.contains('slot-bet')) {
        e.target.parentNode.appendChild(node)
      }
    } else if (e.target.classList.contains('slot')) {
      e.target.appendChild(node)
    }
  }
  const handleChipRemoveHandler = (e) => {
    setTotalAmount((prev) => parseInt(prev) - parseInt(e?.target?.innerText))
    e.preventDefault()
    e.target.parentElement
      .querySelectorAll('.slot-bet')
      .forEach((e) => e.remove())
  }
  const clearChipHandler = (e) => {
    setTotalAmount(0)
    setBetDetails([])
    setSlotList(slotListTemp)
    setSlotVSpacerList(slotVSpacerListTemp)
    setSlotHSpacerList(slotHSpacerListTemp)
    setSlotVHSpacerList(slotVHSpacerListTemp)
    setSlotTSpacerList(slotTSpacerListTemp)
    setSlotTDSpacerList(slotTDSpacerListTemp)
    setGroup12List(group12ListTemp)
    setRowList(rowListTemp)
    setRowList(zeroTemp)
    
    betDetailsTemp = []
    // setSlotList(slotListTemp)
    document.querySelectorAll('.slot-bet').forEach((e) => e.remove())
    if (e === 'betPlace') return
    localStorage.removeItem('lastBetsRecord')
    localStorage.removeItem('lastBet')
  }

  const repeatLastBet = () => {
    const lastBet = JSON.parse(localStorage.getItem('lastBet'))
    const lastBetsRecordTM = JSON.parse(localStorage.getItem('lastBetsRecord'))

    if (lastBet) {
      setBetDetails(lastBet)
      const totalCount = lastBet.reduce(
        (partialSum, a) => partialSum + a.amount,
        0,
      )

      setTotalAmount(totalCount)
    }

    if (lastBetsRecordTM) {
      setSlotList(lastBetsRecordTM.slotList)
      setSlotVSpacerList(lastBetsRecordTM.slotVSpacerList)
      setSlotVHSpacerList(lastBetsRecordTM.slotVHSpacerList)
      setSlotHSpacerList(lastBetsRecordTM.slotHSpacerList)
      setSlotTSpacerList(lastBetsRecordTM.slotTSpacerList)
      setSlotTDSpacerList(lastBetsRecordTM.slotTDSpacerList)
      setGroup12List(lastBetsRecordTM.group12List)
      setBottomRandomList(lastBetsRecordTM.bottomRandomList)
      setRowList(lastBetsRecordTM.rowList)
      setZeroList(lastBetsRecordTM.zeroList)
      setIsBetPlaced(false)
    }
  }

  useEffect(() => {
    document
      .getElementById('backgroundMusic')
      .play()
      .catch((error) => {
        document.addEventListener(
          'click',
          () => {
            document.getElementById('backgroundMusic').play()
          },
          { once: true },
        )
      })
    var vid = document.getElementById('backgroundMusic')
    vid.volume = 0.2
  }, [])

  const spinSoundPlay = () => {
    var audio = document.getElementById('spinSound')
    if (audio) {
      audio.pause()
      audio.currentTime = 0
      audio.play()
    }
  }
  const tapChipSoundPlay = () => {
    var audio = document.getElementById('tabChip')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const spinSoundPause = () => {
    var audio = document.getElementById('spinSound')
    audio.pause()
  }

  useEffect(() => {
    document.body.classList.add('roulette')
    return () => {
      betDetailsTemp = []
      document.body.classList.remove('roulette')
    }
  }, [])

  useEffect(() => {
    if (result.length > 8) {
      let a = result.shift()
    }
  }, [result])


  return (
    <div className="main_container blue-theme">
      {/* <button onClick={() => audioRef.current.play()}>Play</button> */}
      <audio
        id="backgroundMusic"
        controls="controls"
        preload="auto"
        autobuffer="true"
        style={{ display: 'none' }}
        ref={audioRef}
        loop={true}
        autoplay={true}
      >
        <source src={loopAudio} />
      </audio>
      <div className="row ">
        <div className="col-lg-8">
          <div className="dice_main">
            <div className="dice_head hide_on_web">
              {!isEmpty(result)
                ? result?.map((item) => (
                  <div
                    className={
                      `flipper flip ` + getRouletteWheelColor(item?.result)
                    }
                  >
                    <div className="front-face">{item?.result}</div>
                    <div className="back-face">{item?.result}</div>
                  </div>
                ))
                : null}
            </div>
            <div className="roulette-game">
              <div className="left_box"></div>
              <div id="app">
                <div>
                  <div className="roulette-wheel">
                    {timer > 0 && !betActive ?
                      <div className='roulette-game-info'>
                        <div className="bet_timer" style={{ fontSize: '40px', height: 'inherit', width: 'inherit', justifyContent: 'center' }}>
                          <span>{timer}</span>
                        </div>
                        <span className={`status_message`}>
                          {statusMessage}
                        </span>
                      </div> : null
                    }
                    <div
                      className={boardRotating ? `layer-2 wheel` : `layer-2 wheel rotating`}
                      style={{ transform: 'rotate(0deg)' }}
                    ></div>
                    <div className="layer-3"></div>
                    <div
                      className="layer-4 wheel"
                      style={{ transform: 'rotate(0deg)' }}
                    ></div>
                    <div className="layer-5"></div>
                    <div
                      className={"ball-container"}
                      style={{ transform: 'rotate(0deg)' }}
                    >
                      {
                          boardRotating ?
                        <div
                        className="ball"
                        style={{ transform: 'translate(0, -163.221px)'}}
                      ></div>
                      : null
                      }
                    </div>
                    <svg width="380" height="380">
                      <circle
                        cx="190"
                        cy="190"
                        r="190"
                        style={{ touchAction: 'none' }}
                      ></circle>
                    </svg>
                  </div>
                </div>
              </div>

              {!isEmpty(result) && resultAvailable ? (
                <div className="roulette_win text-center">
                  <div
                    className={getRouletteWheelColor(
                      result.slice(-1)[0]?.result,
                    )}
                    style={{ padding: '15px' }}
                  >
                    <div className="row">
                      <div className="col">
                        <span>
                          {result.slice(-1)[0]?.result <= 18 ? 'LOW' : 'HIGH'}
                        </span>
                      </div>
                      <div className="col">
                        <span>
                          {result.slice(-1)[0]?.result == 0
                            ? null
                            : result.slice(-1)[0]?.result % 2 == 0
                              ? 'EVEN'
                              : 'ODD'}
                        </span>
                      </div>
                    </div>
                    <h1 style={{ fontSize: '60px', margin: '30px 30px' }}>
                      {result.slice(-1)[0]?.result}
                    </h1>
                    <div>
                      <h3 className="mb-0">
                        {result.slice(-1)[0]?.isWin ? 'You WON' : 'You Loose'}
                      </h3>
                      <p className="mb-0">
                        {!isEmpty(result.slice(-1)[0]?.winnings)
                          ? result.slice(-1)[0]?.winnings
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="last_bets_box hide_on_mobile">
                {!isEmpty(result)
                  ? result.map((item) => (
                    <div className={`flipper flip ` + getRouletteWheelColor(item?.result)}>
                      <div className="front-face">{item?.result}</div>
                      <div className="back-face">{item?.result}</div>
                    </div>
                  ))
                  : null}
              </div>
            </div>
            <div className="info_box_r">
              <h5>Max Payout 100,000.</h5>
              <h5 className="ml-2">
                Out Bet Limit : 100-10,000, Number Bet Limit : 10-1000.
              </h5>
            </div>

            <div className="roulette_box">
              <div className="table">
                {
                  timer <= 5 || isRotating ? <div className='selection-layer' style={{ width: '100%', background: '#0000008c', position: 'absolute', height: '100%', zIndex: '9' }}>
                  </div> : null
                }
                <div className="slots">
                  {zeroList.map((item, index) => (
                    <button
                      className={`slot slot-green slot-0 ${numberDetails[item.groupTwoH] ||
                        numberDetails[item.groupTwoH2] ||
                        numberDetails[item.groupTwoH3] ||
                        numberDetails[item.groupThree] ||
                        numberDetails[item.groupThree]
                        ? 'selected'
                        : ''
                        }`}
                      onClick={(e) =>
                        handleChipAddHandler(
                          e,
                          item.betValues,
                          'zeroList',
                          null,
                          index,
                        )
                      }
                      onContextMenu={handleChipRemoveHandler}
                    >
                      <div className="slot-name">{item.value}</div>
                      {item.isChipAdded ? (
                        <div
                          className={`slot-bet ${seletedChip}`}
                          style={{
                            backgroundImage: `url(${item.chipType})`,
                          }}
                        >
                          {item.bet}
                        </div>
                      ) : null}
                    </button>
                  ))}

                  {slotList.map((item, index1) => {
                    let i = 1
                    return item.row.map((col, index2) => {
                      i++
                      return (
                        <button
                          className={`slot slot-${index1 + 1}-${i} ${col.slotColor
                            } ${numberDetails[col.group18] ||
                              numberDetails[col.group12] ||
                              numberDetails[col.evenOdd] ||
                              numberDetails[col.color] ||
                              numberDetails[col.row] ||
                              numberDetails[col?.groupTwoV1] ||
                              numberDetails[col?.groupTwoH] ||
                              numberDetails[col?.groupTwoH2] ||
                              numberDetails[col?.groupThree] ||
                              numberDetails[col?.groupThree2] ||
                              numberDetails[col?.groupFour1] ||
                              numberDetails[col?.groupFour2] ||
                              numberDetails[col?.groupFour3] ||
                              numberDetails[col?.groupFour4] ||
                              numberDetails[col?.groupTwoV2]
                              ? 'selected'
                              : ''
                            }`}
                          onClick={(e) =>
                            handleChipAddHandler(
                              e,
                              col.value,
                              'slotList',
                              index1,
                              index2,
                            )
                          }
                          onContextMenu={handleChipRemoveHandler}
                        >
                          <div className="slot-name">
                            {col.value}
                            {col.isChipAdded ? (
                              <div
                                className={`slot-bet ${seletedChip}`}
                                style={{
                                  backgroundImage: `url(${col.chipType})`,
                                }}
                              >
                                {col.bet}
                              </div>
                            ) : null}
                          </div>
                        </button>
                      )
                    })
                  })}
                  {rowList.map((item, index) => (
                    <button
                      className={`slot ${item.className}`}
                      onMouseEnter={() => handleMouseEnter(item.groupRow)}
                      onMouseLeave={() => handleMouseLeave(item.groupRow)}
                      onClick={(e) =>
                        handleChipAddHandler(
                          e,
                          item.betValues,
                          'rowList',
                          null,
                          index,
                        )
                      }
                      onContextMenu={handleChipRemoveHandler}
                    >
                      <div className="slot-name">{item.value}</div>
                      {item.isChipAdded ? (
                        <div
                          className={`slot-bet ${seletedChip}`}
                          style={{
                            backgroundImage: `url(${item.chipType})`,
                          }}
                        >
                          {item.bet}
                        </div>
                      ) : null}
                    </button>
                  ))}
                  {group12List.map((item, index) => (
                    <button
                      className={`slot slot-${item.className}`}
                      onMouseEnter={() => handleMouseEnter(item.group12)}
                      onMouseLeave={() => handleMouseLeave(item.group12)}
                      onClick={(e) =>
                        handleChipAddHandler(
                          e,
                          item.betValues,
                          'group12List',
                          null,
                          index,
                        )
                      }
                      onContextMenu={handleChipRemoveHandler}
                    >
                      <div className="slot-name">{item.value}</div>
                      {item.isChipAdded ? (
                        <div
                          className={`slot-bet ${seletedChip}`}
                          style={{
                            backgroundImage: `url(${item.chipType})`,
                          }}
                        >
                          {item.bet}
                        </div>
                      ) : null}
                    </button>
                  ))}
                  {bottomRandomList.map((item, index) => (
                    <button
                      className={`slot ${item.className}`}
                      onMouseEnter={() => handleMouseEnter(item.group18)}
                      onMouseLeave={() => handleMouseLeave(item.group18)}
                      onClick={(e) =>
                        handleChipAddHandler(
                          e,
                          item.betValues,
                          'bottomRandomList',
                          null,
                          index,
                        )
                      }
                      onContextMenu={handleChipRemoveHandler}
                    >
                      <div className="slot-name">{item.value}</div>
                      {item.isChipAdded ? (
                        <div
                          className={`slot-bet ${seletedChip}`}
                          style={{
                            backgroundImage: `url(${item.chipType})`,
                          }}
                        >
                          {item.bet}
                        </div>
                      ) : null}
                    </button>
                  ))}

                  {slotVSpacerList.map((item, index1) => {
                    return item.row.map((col, index2) => {
                      return (
                        <button
                          onClick={(e) =>
                            handleChipAddHandler(
                              e,
                              col.betValues,
                              'slotVSpacerList',
                              index1,
                              index2,
                            )
                          }
                          onContextMenu={handleChipRemoveHandler}
                          onMouseEnter={() => handleMouseEnter(col.value)}
                          onMouseLeave={() => handleMouseLeave(col.value)}
                          className={`slot slot-v-spacer-${index1 + 1}-${index2 + 1
                            }`}
                        >
                          {' '}
                          {col.isChipAdded ? (
                            <div
                              className={`slot-bet ${seletedChip}`}
                              style={{
                                backgroundImage: `url(${col.chipType})`,
                              }}
                            >
                              {col.bet}
                            </div>
                          ) : null}
                        </button>
                      )
                    })
                  })}
                  {slotHSpacerList.map((item, index1) => {
                    let i = 1
                    return item.row.map((col, index2) => {
                      i++
                      return (
                        <button
                          onClick={(e) =>
                            handleChipAddHandler(
                              e,
                              col.betValues,
                              'slotHSpacerList',
                              index1,
                              index2,
                            )
                          }
                          onContextMenu={handleChipRemoveHandler}
                          onMouseEnter={() => handleMouseEnter(col.value)}
                          onMouseLeave={() => handleMouseLeave(col.value)}
                          className={`slot slot-h-spacer-${index1 + 1}-${i}`}
                        >
                          {' '}
                          {col.isChipAdded ? (
                            <div
                              className={`slot-bet ${seletedChip}`}
                              style={{
                                backgroundImage: `url(${col.chipType})`,
                              }}
                            >
                              {col.bet}
                            </div>
                          ) : null}
                        </button>
                      )
                    })
                  })}
                  {slotVHSpacerList.map((item, index1) => {
                    return item.row.map((col, index2) => {
                      return (
                        <button
                          onClick={(e) =>
                            handleChipAddHandler(
                              e,
                              col.betValues,
                              'slotVHSpacerList',
                              index1,
                              index2,
                            )
                          }
                          onContextMenu={handleChipRemoveHandler}
                          onMouseEnter={() => handleMouseEnter(col.value)}
                          onMouseLeave={() => handleMouseLeave(col.value)}
                          className={`slot slot-v-h-spacer-${index1 + 1}-${index2 + 1
                            }`}
                        >
                          {' '}
                          {col.isChipAdded ? (
                            <div
                              className={`slot-bet ${seletedChip}`}
                              style={{
                                backgroundImage: `url(${col.chipType})`,
                              }}
                            >
                              {col.bet}
                            </div>
                          ) : null}
                        </button>
                      )
                    })
                  })}
                  {slotTSpacerList.map((item, index1) => {
                    let i = 1
                    return item.row.map((col, index2) => {
                      i++
                      return (
                        <button
                          onClick={(e) =>
                            handleChipAddHandler(
                              e,
                              col.betValues,
                              'slotTSpacerList',
                              index1,
                              index2,
                            )
                          }
                          onContextMenu={handleChipRemoveHandler}
                          onMouseEnter={() => handleMouseEnter(col.value)}
                          onMouseLeave={() => handleMouseLeave(col.value)}
                          className={`slot slot-t-spacer-${i}`}
                        >
                          {' '}
                          {col.isChipAdded ? (
                            <div
                              className={`slot-bet ${seletedChip}`}
                              style={{
                                backgroundImage: `url(${col.chipType})`,
                              }}
                            >
                              {col.bet}
                            </div>
                          ) : null}
                        </button>
                      )
                    })
                  })}
                  {slotTDSpacerList.map((item, index1) => {
                    let i = 1
                    return item.row.map((col, index2) => {
                      i++
                      return (
                        <button
                          onClick={(e) =>
                            handleChipAddHandler(
                              e,
                              col.betValues,
                              'slotTDSpacerList',
                              index1,
                              index2,
                            )
                          }
                          onContextMenu={handleChipRemoveHandler}
                          onMouseEnter={() => handleMouseEnter(col.value)}
                          onMouseLeave={() => handleMouseLeave(col.value)}
                          className={`slot slot-td-spacer-${i}`}
                        >
                          {' '}
                          {col.isChipAdded ? (
                            <div
                              className={`slot-bet ${seletedChip}`}
                              style={{
                                backgroundImage: `url(${col.chipType})`,
                              }}
                            >
                              {col.bet}
                            </div>
                          ) : null}
                        </button>
                      )
                    })
                  })}
                </div>
              </div>
            </div>

            <div className="bet_col">
              <div className="bet_details" style={{ marginTop: '5px' }}>
                <div className="top_box">
                  <div
                    className={`btn_box_bet ${seletedChip == 10 ? 'disabled' : ''
                      }`}
                    onClick={() => {
                      updateChip(-1)
                      soundTapPlay()
                    }}
                  >
                    <span>-</span>
                  </div>
                  <InputGroup>
                    <div className="chip_main_box">
                      <i
                        id="coinBet"
                        className={`icon chip_${seletedChip} `}
                        style={{
                          margin: 'auto',
                          width: '50px',
                          height: '50px',
                        }}
                      ></i>
                      <span className="chip_number">
                        {seletedChip === '10'
                          ? 10
                          : seletedChip === '20'
                            ? 20
                            : seletedChip === '50'
                              ? 50
                              : seletedChip === '100'
                                ? 100
                                : 500}
                      </span>
                    </div>
                  </InputGroup>

                  <div
                    className={`btn_box_bet ${seletedChip == 500 ? 'disabled' : ''
                      }`}
                    onClick={() => {
                      updateChip(+1)
                      soundTapPlay()
                    }}
                  >
                    <span>+</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4 col-4 text-left">
                    <span>Min 10</span>{' '}
                  </div>
                  <div className="col-sm-4 col-4 text-center">
                    <span>
                      {totalAmount > 0 && (
                        <>
                          BET: <strong>{totalAmount}</strong>
                        </>
                      )}
                    </span>
                  </div>
                  <div className="col-sm-4 col-4 text-right">
                    <span>Max 500</span>{' '}
                  </div>
                </div>
                <div className="top_box">

                </div>
              </div>
              <div className="bet_btn_col">
                <div
                  className={`bet_btn ${betAmount == 0 || timer <= 7 ? 'disabled' : ''}`}
                  onClick={
                    betActive || timer >= 7
                      ? () => {
                        betPlaceHanlder()
                      }
                      : null
                  }
                  style={{ height: '80px' }}
                >
                  <div className="btn_text_box">
                    {betActive || timer <= 7 ? (
                      <div
                        className="spinner-border btn-loader"
                        role="status"
                      ></div>
                    ) : (
                      <>
                        <h5>SPIN</h5>
                      </>
                    )}
                  </div>
                </div>
                <div className="bottom_links">
                  {/* <span
                    onClick={!isRotating || timer <= 5 ? repeatLastBet : null}
                    className={
                      isRotating || timer <= 5
                        ? 'clear_btn roulette_btn_disabled'
                        : 'clear_btn'
                    }
                  >
                    Repeat Last Bet
                  </span> */}

                  <span
                    onClick={(e) => (!isRotating || timer <= 5 ? clearChipHandler(e) : null)}
                    className={
                      isRotating || timer <= 5
                        ? 'clear_btn roulette_btn_disabled'
                        : 'clear_btn'
                    }
                    style={{height:'35px'}}
                  >
                    Clear
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="tab_box bets_info_box">
            <Tabs
              defaultActiveKey="allBets"
              id="uncontrolled-tab-example"
              className="mb-3 justify-content-center"
            >
              <Tab
                eventKey="allBets"
                title="All Bets"
                onClick={() => getAllRouletteBetsHandler()}
              >
                {isEmpty(allBets) ? (
                  <div className="no_data">
                    <img src={noData} alt="no data" className="no_data_img" />
                    <h4 className="no_h4">No record found!</h4>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <Table variant="dark">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Time</th>
                          <th>Player</th>
                          {/* <th>Target</th> */}
                          <th>Bet Amount</th>
                          <th>Result</th>
                          <th>Payout</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allBets.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {moment(item.bet_time).format(
                                'MMMM Do YYYY, h:mm:ss a',
                              )}
                            </td>

                            <td>{item.user_id}</td>
                            {/* <td>
                              {
                                item.target.map((field, i) => (
                                  <>{
                                    field.fields.map((num, i) => (<>{num},</>)
                                    )}
                                  </>))
                              }
                            </td> */}
                            <td>
                              {' '}
                              <span>
                                <i
                                  className={`icon ${getIcon(item.token)}`}
                                ></i>{' '}
                                {item.bet}
                              </span>
                            </td>
                            <td>
                              {
                                <span
                                  className={
                                    item.winnings == 0
                                      ? 'result_red'
                                      : 'result_green'
                                  }
                                >
                                  {item?.result}
                                </span>
                              }
                            </td>
                            <td>
                              {item.winnings == 0 ? (
                                <span style={{ justifyContent: 'center' }}>
                                  -
                                </span>
                              ) : (
                                <span className="result_green">
                                  <i
                                    className={`icon ${getIcon(item.token)}`}
                                  ></i>{' '}
                                  {parseInt(item.winnings)}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {allBets.length >= 20 && allBets.length < 200 ? (
                      <div style={{ textAlign: 'center' }}>
                        <button
                          style={{
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => getAllRouletteBetsHandler(true)}
                        >
                          Load more
                        </button>
                      </div>
                    ) : null}
                  </div>
                )}
              </Tab>
              <Tab
                eventKey="myBets"
                title="My Bets"
                onClick={() => getMyBetsHandler}
              >
                {isEmpty(myAllBets) ? (
                  <div className="no_data">
                    <img src={noData} alt="no data" className="no_data_img" />
                    <h4 className="no_h4">No record found!</h4>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <Table variant="dark">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Time</th>
                          {/* <th>Target</th> */}
                          <th>Bet Amount</th>
                          <th>Result</th>
                          <th>Payout</th>
                        </tr>
                      </thead>
                      <tbody>
                        {myAllBets.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {moment(item.bet_time).format(
                                'MMMM Do YYYY, h:mm:ss a',
                              )}
                            </td>
                            {/* <td>
                              {item.roll == 'under' &&
                                'Roll Under ' + item.target}
                              {item.roll == 'over' &&
                                'Roll Over ' + item.target}
                            </td> */}
                            <td>
                              {' '}
                              <span>
                                <i
                                  className={`icon ${getIcon(item.token)}`}
                                ></i>{' '}
                                {item.bet}
                              </span>
                            </td>
                            <td>
                              {
                                <span
                                  className={
                                    item.winnings == 0
                                      ? 'result_red'
                                      : 'result_green'
                                  }
                                >
                                  {item?.result}
                                </span>
                              }
                            </td>
                            <td>
                              {item.winnings == 0 ? (
                                <span style={{ justifyContent: 'center' }}>
                                  -
                                </span>
                              ) : (
                                <span className="result_green">
                                  <i
                                    className={`icon ${getIcon(item.token)}`}
                                  ></i>{' '}
                                  {parseInt(item.winnings)}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {myAllBets.length >= 20 ? (
                      <div style={{ textAlign: 'center' }}>
                        <button
                          style={{
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => getMyBetsHandler(true)}
                        >
                          Load more
                        </button>
                      </div>
                    ) : null}
                  </div>
                )}
              </Tab>
              {/* <Tab eventKey="highRollers" title="High Rollers">
                  <div className="no_data">
                    <img src={noData} alt="no data" className="no_data_img" />
                    <h4 className="no_h4">No record found!</h4>
                  </div>
                </Tab>
                <Tab eventKey="rareWins" title="Rare Wins">
                  <div className="no_data">
                    <img src={noData} alt="no data" className="no_data_img" />
                    <h4 className="no_h4">No record found!</h4>
                  </div>
                </Tab> */}
            </Tabs>
          </div>
        </div>
        <div className="col-sm-4">
          {' '}
          <Chat />
        </div>
      </div>
      <audio preload="auto" id="audio">
        <source src={CircleSound}></source>
      </audio>
      <audio preload="auto" id="audioTap">
        <source src={TapSound}></source>
      </audio>
      <audio preload="auto" id="diceLose">
        <source src={DiceLose}></source>
      </audio>
      <audio preload="auto" id="diceWin">
        <source src={DiceWin}></source>
      </audio>
      <audio preload="auto" id="result">
        <source src={Result}></source>
      </audio>
      <audio preload="auto" id="clockSound">
        <source src={ClockSound}></source>
      </audio>

      <audio preload="auto" id="spinSound">
        <source src={spinSound}></source>
      </audio>
      <audio preload="auto" id="tabChip">
        <source src={tabChip}></source>
      </audio>
    </div>
  )
}
