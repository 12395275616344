import React from 'react'
import { NavLink } from 'react-router-dom'
import img from '../../../assets/img/casino.png'
import lottoImg from '../../../assets/img/lotto.png'
import egamingImg from '../../../assets/img/egaming.png'
import liveGaming from '../../../assets/img/livegaming.png'
import cardGame from '../../../assets/img/cardgame.png'
import { settings2 } from '../../utils/SliderSettings'
import GallerySlider from '../GallerySlider'

const Games = ({ title, icon, games }) => {
  return (
    <div className="games_section">
      <h5>
        <i className={`icon-md ${icon}`}></i> {title}
      </h5>

      <GallerySlider settings={settings2}>
        {games.map((game) => (
          <div className="slide_card">
            {game.id ? (
              <a href={`${game.path}/${game.id}`}>
                <div className={`game_card ${game.name}`}></div>
              </a>
            ) : (
              <a href={`${game.path}`}>
                <div className={`game_card ${game.name}`}></div>
              </a>
            )}
          </div>
        ))}
      </GallerySlider>
    </div>
  )
}

export default Games
