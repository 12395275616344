import React, { useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'
import config from '../../config.json'

const SocketContext = React.createContext()

export function useSocket() {
  return useContext(SocketContext)
}

export function SocketProvider({ id, children }) {
  const [socket, setSocket] = useState()

  useEffect(() => {
    const newSocket = io(config.production.chatDomain, {
      path: config.production.chatpath,
    })
    setSocket(newSocket)

    return () => newSocket.close()
  }, [id])

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}
