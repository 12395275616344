import {
  tokencontractAddress,
  contractAddress,
  tspcontractAddress,
} from './ContractAddress'

const utils = {
  tronWeb: false,
  contract: false,
  tokencontract: false,
  tspcontract: false,
  async setTronWeb(tronWeb) {
    try {
      this.tronWeb = tronWeb
      this.contract = await tronWeb.contract().at(contractAddress)
      this.tokencontract = await tronWeb.contract().at(tokencontractAddress)
      this.tspcontract = await tronWeb.contract().at(tspcontractAddress)
    } catch (error) {
      console.log(error)
    }
  },
}

export default utils
