/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

export default function LoginPopup(props) {
  const { onClose } = props
  return (
    <>
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={onClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <span>
        Please login to your TRONLink wallet. If you do not have TRONLink wallet
        installed please visit{' '}
        <a target="_blank" href="http://u6.gg/gmc5D">
          http://u6.gg/gmc5D
        </a>{' '}
        and download the chrome extension. (TRONSWEEP is only availble using
        Chrome browser for the time being)
      </span>
    </>
  )
}
