import React, { useEffect, useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { BsFillDice6Fill, BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { useSidebarToggle } from '../context/SidebarToggleProvider'
const menuItemList = [
  {
    name: 'Games',
    path: '/#',
    icon: 'lotto',
    iconClosed: <BsChevronDown />,
    iconOpen: <BsChevronUp />,
    subMenu: [
      {
        name: 'Lotto',
        path: '/dapp/lotto',
        icon: <i className={`icon lotto`}></i>,
      },
      {
        name: 'Dice',
        path: '/inhouse/dice',
        icon: <BsFillDice6Fill />,
      },
      {
        name: 'Color Prediction',
        path: '/inhouse/color-prediction',
        icon: <i className={`icon color`}></i>,
      },
      {
        name: 'Wheel Of Fortune',
        path: '/inhouse/fortune-wheel',
        icon: <i className={`icon wheel`}></i>,
      },
    ],
  },
  {
    name: 'Dividend',
    path: '/dividend',
    icon: 'tsp',
    subMenu: [],
  },
  {
    name: 'Referral',
    path: '/referral',
    icon: 'referral',
    subMenu: [],
  },
  {
    name: 'Buy TSP',
    path: '/tronsweep-token',
    icon: 'buy_tsp',
    subMenu: [],
  },
  {
    name: 'Stake & Earn',
    path: '/dapp/stake/lotto',
    icon: 'stake',
    subMenu: [],
  },
  {
    name: 'FAQ',
    path: '/faq',
    icon: 'faq',
    subMenu: [],
  },
  {
    name: 'Tutorials',
    path: '/learn',
    icon: 'tutorials',
    subMenu: [],
  },
]
const SidebarMobile = () => {
  const { setIsOpen, isOpen } = useSidebarToggle()
  const [subnav, setSubnav] = useState(false)

  const showSubnav = () => setSubnav(!subnav)

  return (
    <>
      <div className="sidebar  mobile_only rajesh">
        <ul>
          {menuItemList.map((menu, i) => {
            return (
              <li
                onClick={() => {
                  menu.subMenu.length > 0 && showSubnav()
                  setIsOpen((prev) => !prev)
                }}
                key={i}
              >
                <NavLink
                  className={menu.subMenu.length > 0 ? 'top_link' : ''}
                  activeClassName="active"
                  to={menu.path}
                >
                  <div>
                    {menu.name === 'Games' ? (
                      <BsFillDice6Fill />
                    ) : (
                      <i className={`icon ${menu.icon}`}></i>
                    )}
                    <span>{menu.name}</span>
                  </div>
                  {/* {menu.subMenu.length > 0 && (
                    <div className="arrow_box">
                      {menu.subMenu.length > 0 && subnav
                        ? menu.iconOpen
                        : menu.subMenu.length > 0
                        ? menu.iconClosed
                        : null}
                    </div>
                  )} */}
                </NavLink>

                {subnav && (
                  <ul>
                    {menu.subMenu.map((submenu, i) => (
                      <li key={i}>
                        <NavLink activeClassName="active" to={submenu.path}>
                          {submenu.icon}
                          <span>{submenu.name}</span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default SidebarMobile
