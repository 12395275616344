/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react'

import { ToastsContainer, ToastsStore } from 'react-toasts'
import Modal from 'react-bootstrap/Modal'
import tron from '../public/images/icons/tron_icon.png'

// import tokencontractAddress from "../../tokencontractAddress";
import {
  tokencontractAddress,
  tspcontractAddress,
} from '../utils/ContractAddress'

import { AppContext } from '../context/index'
import { useTSPToken } from '../context/TSPProvider'
import { useAccount } from '../context/AccountProvider'

const TspFreezeModal = (props) => {
  const { close, tsp, show } = props
  const { accountAddress } = useAccount()
  const {
    onSubmitTSPToken,
    tspToken,
    onSbumitTSPUnfreezeToken,
    tspUnfreezeToken,
    tspTotalFrozen,
    onSubmitTspRecieveAmount,
    onSubmitTspHolderShare,
    tspHolderShare,
  } = useTSPToken()
  const context = useContext(AppContext)
  const {
    setUpdateTspFrezeeamount,
    tspDividend,

    tspfrezeeamount,
    setTspRecieve,
    setUpdateTspSweepToken,
  } = context
  const [reqFreezeToken, setReqFreezeToken] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isBigValue, setIsBigValue] = useState(false)

  const handleFreezeToken = (event) => {
    setReqFreezeToken(+event.target.value)
    if (event.target.value > tspToken / 10 ** 8) {
      setIsBigValue(true)
    } else {
      setIsBigValue(false)
    }
  }

  const handleFreeze = async () => {
    if (tspToken > reqFreezeToken) {
      try {
        setLoading(true)
        const tspcontract = await window.tronWeb
          .contract()
          .at(tspcontractAddress)
        const tokens = +reqFreezeToken
        await tspcontract.freezeToken(tokens * 10 ** 8).send()
        ToastsStore.success('Frozen successfully')
        // this.state.reqFreezeToken = 0;
        setTimeout(() => {
          tspfreezeToken()
          tspReceive()
          tspsweepTokenHandler()
          close()

          setLoading(false)
        }, 1000)
      } catch (error) {
        setLoading(true)
        ToastsStore.error('Transcation failed')
      }
    } else {
      ToastsStore.error('Enter valid token to freeze')
    }
  }

  const handleAll = () => {
    setReqFreezeToken(tsp)
  }

  const tspfreezeToken = async () => {
    try {
      const tspcontract = await window.tronWeb.contract().at(tspcontractAddress)
      const freeze = await tspcontract.frozenBalance(accountAddress).call()
      const freezetoken = +freeze / 10 ** 8
      setUpdateTspFrezeeamount(freezetoken)
      onSbumitTSPUnfreezeToken(freezetoken)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const tspReceive = async () => {
    try {
      const tspcontract = await window.tronWeb.contract().at(tspcontractAddress)
      const percentvalue = await tspcontract.divDistributePercentage().call()
      const percent = +percentvalue
      const receivebal =
        (((+tspDividend + tspHolderShare) * percent) / 100 / tspTotalFrozen) *
        tspUnfreezeToken

      setTspRecieve(receivebal)
      onSubmitTspRecieveAmount(receivebal)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const tspsweepTokenHandler = async () => {
    try {
      let tokencontract = await window.tronWeb.contract().at(tspcontractAddress)
      const sweep = await tokencontract.balanceOf(accountAddress).call()
      const sweeptoken = +sweep
      setReqFreezeToken(sweeptoken / 10 ** 8)
      setUpdateTspSweepToken(sweeptoken)
      onSubmitTSPToken(sweeptoken)
    } catch (error) {
      console.log(error, 'Error')
    }
  }

  useEffect(() => {
    tspsweepTokenHandler()
    tspfreezeToken()
    setTimeout(() => {
      tspReceive()
    }, 500)
  }, [])

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={close}
          size="sm"
          dialogClassName="withdraw_modal"
          autoFocus={false}
          enforceFocus={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>TSP Freeze</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img
                      className="icon_img_trs"
                      alt=""
                      height="30"
                      src={tron}
                    />
                  </span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={reqFreezeToken}
                  onChange={handleFreezeToken}
                  defaultValue={tspToken}
                />

                <div
                  aria-hidden
                  className="input-group-append"
                  onClick={handleAll}
                >
                  <span className="input-group-text">
                    <a href="#!">All</a>
                  </span>
                </div>
                {isBigValue ? (
                  <small className="text-danger">
                    Input value can not be more than your TSP balance
                  </small>
                ) : null}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary 01"
              onClick={handleFreeze}
              disabled={isBigValue}
            >
              {!loading ? 'Freeze' : <i className="fa fa-refresh fa-spin" />}
            </button>
          </Modal.Body>
        </Modal>

        <ToastsContainer store={ToastsStore} />
      </div>
    </>
  )
}

export default TspFreezeModal
