import React, { Component } from 'react'
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom'
import StickyBox from 'react-sticky-box'
import Notifications from '../components/Notifications'
import { AppContext } from '../context'
import Dividend from './Dividend'
import Sidenav from './sidenav'
import Profile from './profile'
import ReferralHistory from './referral_history'
import TronsweepToken from './Tronseep_Token'
import DealerReferralHistory from './dealerreferral_history'
import WagersAndPrizePot from './WagersAndPrizePot'
import UserActivity from './userActivity'

class MyAccountLayout extends Component {
  // eslint-disable-next-line react/state-in-constructor

  constructor(props) {
    super(props)
    this.state = {
      // userinfo: [],
      isChatOpen: false,
    }
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    // const { accountAddress } = this.context
    // axios
    //   .get(`tron/user/player/${accountAddress}`, {})
    //   .then(() => {
    //     // this.setState({ userinfo: res.data.data });
    //   })
    //   .catch((res) => {
    //     console.log(res)
    //   })
    // eslint-disable-next-line func-names
    // document.getElementById('account_nav').addEventListener('click', (e) => {
    //   if (e.target && e.target.nodeName === 'A') {
    //     this.closeNav()
    //   }
    // })
  }

  chatBoxHandler = () => {
    const { isChatOpen } = this.state
    this.setState({
      isChatOpen: !isChatOpen,
    })
  }

  closeNav = () => {
    this.setState({ isChatOpen: false })
  }

  render() {
    const { isChatOpen } = this.state
    const { accountAddress, accountBalance, isDealer } = this.context
    return (
      <>
        <div className="main_container">
          <div className="container">
            {/* <div className="top_nav_lottery">
              <ul>
                <li>
                  <span
                    aria-hidden
                    className="chat_icon_toggle"
                    onClick={this.chatBoxHandler}
                  >
                    <i className="mdi mdi-menu" />
                  </span>
                </li>
              </ul>
            </div> */}
            <div className="row">
              {/* <div
                className={`col-md-4 mobile_left_menu ${
                  isChatOpen ? 'chap_open' : ''
                }`}
              >
                <StickyBox offsetTop={120} offsetBottom={20}>
                  <Sidenav
                    isDealer={isDealer}
                    accountAddress={accountAddress}
                  />
                </StickyBox>
              </div> */}
              <div className="col-md-8">
                <div className="profile_inner">
                  <Router>
                    <Route exact path="/account">
                      <Profile accountAddress={accountAddress} />
                    </Route>
                    <Route exact path="/dividend">
                      <Dividend
                        accountAddress={accountAddress}
                        accountBalance={accountBalance}
                      />
                    </Route>
                    <Route exact path="/referral">
                      <ReferralHistory accountAddress={accountAddress} />
                    </Route>
                    <Route exact path="/account-activity">
                      <UserActivity accountAddress={accountAddress} />
                    </Route>
                    <Route exact path="/dealer/referral">
                      <DealerReferralHistory accountAddress={accountAddress} />
                    </Route>
                    <Route exact path="/tronsweep-token">
                      {' '}
                      <TronsweepToken accountAddress={accountAddress} />
                    </Route>
                    <Route exact path="/notifications">
                      <Notifications />
                    </Route>
                    <Route exact path="/wagers-and-pot-prize">
                      <WagersAndPrizePot />
                    </Route>
                  </Router>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

MyAccountLayout.propTypes = {}
MyAccountLayout.contextType = AppContext
export default withRouter(MyAccountLayout)
