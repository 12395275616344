import io from 'socket.io-client'

let socket, socketUser, socketCircle, socketColor, socketRoulette
const SOCKET_URL_DICE = process.env.REACT_APP_DICE
const SOCKET_URL_CIRCLE = process.env.REACT_APP_CIRCLE
const SOCKET_URL_USER = process.env.REACT_APP_AUTH
const SOCKET_URL_COLOR = process.env.REACT_APP_COLOR
const SOCKET_URL_ROULETTE = process.env.REACT_APP_ROULETTE
let token = localStorage.getItem('token')

//dice
export const initiateDiceSocket = () => {
  socket = io(SOCKET_URL_DICE, {
    forceNew: true,
    query: `token=${token}`,
    path: "/api/v1/dice/socket.io"
  })
  return socket
}

// circle
export const initiateCircleSocket = () => {
  socketCircle = io(SOCKET_URL_CIRCLE, {
    forceNew: true,
    query: `token=${token}`,
    path: "/api/v1/circle/socket.io"
  })
  return socketCircle
}

// user
export const initiateSocketForUser = () => {
  socketUser = io(SOCKET_URL_USER, {
    forceNew: true,
    query: `token=${token}`,
    path: "/api/v1/auth/socket.io"
  })
  return socketUser
}

// color
export const initiateSocketForColor = () => {
  socketColor = io(SOCKET_URL_COLOR, {
    forceNew: true,
    query: `token=${token}`,
    path: "/api/v1/color-prediction/socket.io"
  })
  return socketColor
}

// roulette
export const initiateRouletteSocket = () => {
  socketRoulette = io(SOCKET_URL_ROULETTE, {
    forceNew: true,
    query: `token=${token}`,
    path: "/api/v1/roulette/socket.io"
  })
  return socketRoulette
}

//dice
export const subscribeToBetPlace = (callback) => {
  if (!socket) return
  socket.on('dice-bet-response', (data) => {
    callback(null, data)
  })
}

export const subscribeToBetHistory = (callback) => {
  if (!socket) return
  socket.on('dice-bet-history', (data) => {
    callback(null, data)
  })
}

export const diceBetPlace = (data) => {
  if (!socket) return
  socket.emit('dice-place-bet', data)
}

//user
export const subscribeToUserBalance = (callback) => {
  if (!socketUser) return
  socketUser.on('user-balance', (data) => {
    callback(null, data)
  })
}

//circle
export const circlePlaceBet = (data) => {
  if (!socketCircle) return
  socketCircle.emit('circle-place-bet', data)
}

export const circleBetResponse = (callback) => {
  if (!socketCircle) return
  socketCircle.on('circle-bet-response', (data) => {
    callback(null, data)
  })
}

export const allCircleBetData = (callback) => {
  if (!socketCircle) return
  socketCircle.on('circle-bet-data', (data) => {
    callback(null, data)
  })
}
export const circleGameState = (callback) => {
  if (!socketCircle) return
  socketCircle.on('circle-game-state', (data) => {
    callback(null, data)
  })
}

export const circleGameResult = (callback) => {
  if (!socketCircle) return
  socketCircle.on('circle-game-result', (data) => {
    callback(null, data)
  })
}

export const circleUserWinning = (callback) => {
  if (!socketCircle) return
  socketCircle.on('circle-user-winning', (data) => {
    callback(null, data)
  })
}

export const circleConnectUser = (data) => {
  if (!socketCircle) return
  socketCircle.emit('circle-connect-user', data)
}

// color
export const colorBetPlace = (data) => {
  if (!socketColor) return
  socketColor.emit('color-prediction-place-bet', data)
}

export const colorBetResult = (callback) => {
  if (!socketColor) return
  socketColor.on('color-prediction-bet-response', (data) => {
    callback(null, data)
  })
}
export const colorGameState = (callback) => {
  if (!socketColor) return
  socketColor.on('color-prediction-game-state', (data) => {
    callback(null, data)
  })
}
export const colorBetData = (callback) => {
  if (!socketColor) return
  socketColor.on('color-prediction-bet-data', (data) => {
    callback(null, data)
  })
}
export const colorConnectUser = (data) => {
  if (!socketColor) return
  socketColor.emit('color-prediction-connect-user', data)
}
export const colorPredictionUserWinning = (callback) => {
  if (!socketColor) return
  socketColor.on('color-prediction-user-winning', (data) => {
    callback(null, data)
  })
}
export const colorPredictionGameResult = (callback) => {
  if (!socketColor) return
  socketColor.on('color-prediction-game-result', (data) => {
    callback(null, data)
  })
}

//Roulette
export const roulettePlaceBet = (data) => {
  if (!socketRoulette) return
  socketRoulette.emit('roulette-place-bet', data)
}

export const rouletteBetResponse = (callback) => {
  if (!socketRoulette) return
  socketRoulette.on('roulette-bet-response', (data) => {
    callback(null, data)
  })
}

export const rouletteBetHistory = (callback) => {
  if (!socketRoulette) return
  socketRoulette.on('roulette-bet-history', (data) => {
    callback(null, data)
  })
}






