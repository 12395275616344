import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import axios from 'axios'
import TruncateMiddle from 'react-truncate-string'

import { ToastsContainer, ToastsStore } from 'react-toasts'
import QR from '../../assets/img/qrcode.jpg'
//import QR from '../../../assets/img/qrcode.jpg'
// import config from '../../config.json'
import Utils from '../utils/index'
import { AppContext } from '../context/index'
import {
  tokencontractAddress,
  tspcontractAddress,
} from '../utils/ContractAddress'
import { useAccount } from '../context/AccountProvider'
import { useTSPToken } from '../context/TSPProvider'

let datalength = 20

const TronsweepToken = () => {
  const context = useContext(AppContext)
  const { accountAddress, accountBalance } = useAccount()
  const { onSubmitTSPToken, tspToken } = useTSPToken()
  const { setUpdateTspSweepToken, tspModalOpenHandler } = context
  const [date, setDate] = useState([null, new Date()])
  const [buyToken, setBuyToken] = useState([])
  const [totalearning, setTotalEarning] = useState(0)
  const [data, setData] = useState('')
  const [sweepToken, setSweepToken] = useState(0)
  const [show, setShow] = useState(true)
  const [value, setValue] = useState(8)
  const [isAddressQrVisible, setIsAddressQrVisible] = useState(false)

  const handelChange = (event) => {
    setValue(event.target.value)
  }

  const onDateChange = (date) => {
    setDate(date)
    onselectapiCall(false, date)
  }

  const onselectapiCall = (loadmore = false, datetepm) => {
    if (loadmore) {
      datalength += 20
    }

    axios
      .post(`tron/user/buyTokenTxn`, {
        buyer_address: accountAddress,
        datetepm,
        dataLength: datalength,
      })
      .then((res) => {
        setBuyToken(res.data.data)
        setData(res.data.status)
        if (datalength > res.data.data.length) {
          setShow(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const tspsweepTokenHandler = async () => {
    if (accountAddress) {
      try {
        let tokencontract = await window.tronWeb
          .contract()
          .at(tspcontractAddress)
        const sweep = await tokencontract.balanceOf(accountAddress).call()
        const sweeptoken = +sweep
        setUpdateTspSweepToken(sweeptoken)

        setSweepToken(sweeptoken)
        onSubmitTSPToken(sweeptoken)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    tspsweepTokenHandler()
  }, [])

  const buyTspToken = async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      let tspcontract = await window.tronWeb.contract().at(tspcontractAddress)
      await tspcontract.buyTokens().send({ callValue: value * 10 ** 6 })
      ToastsStore.success(`${value / 8} TSP Successfully Bought`)
      setTimeout(() => {
        ToastsStore.success('20 % TSP benefits added Successfully')
      }, 1000)

      tspsweepTokenHandler()
      setValue(8)
    } catch (error) {
      ToastsStore.error('Transaction Failed')
    }
  }

  useEffect(() => {
    onselectapiCall()
  }, [])

  return (
    <>
      <h5 className="title">Buy TSP</h5>
      <div className="card account_card mb-3">
        <div className="card-body">
          <div className="calculator_col p-0">
            <form className="d-flex align-items-center justify-content-center token_sale_form">
              <div className="form-group">
                <label htmlFor="value">
                  TRX You Pay
                  <input
                    id="value"
                    type="number"
                    value={value}
                    onChange={handelChange}
                    className="form-control"
                  />
                </label>
                {accountBalance && (
                  <small>
                    Available:{' '}
                    <strong>
                      {Math.floor(accountBalance * 100) / 100} TRX
                    </strong>
                  </small>
                )}
              </div>
              <div className="form-group tsp_h5"></div>
              <div className="form-group">
                <label htmlFor="getValue">
                  TSP You Get
                  <input
                    type="text"
                    id="getValue"
                    value={value / 8}
                    className="form-control get_tsp"
                    readOnly
                  />
                </label>
                {sweepToken && (
                  <small
                    style={{
                      cursor: 'pointer',
                      color: '#f5bc00',
                    }}
                    onClick={tspModalOpenHandler}
                  >
                    {(sweepToken / 10 ** 8).toFixed(2)} TSP
                  </small>
                )}
              </div>
            </form>
            <button
              type="button"
              className="btn btn-primary buy_btn"
              onClick={buyTspToken}
            >
              Buy TSP
            </button>
          </div>

          {isAddressQrVisible ? (
            <div className="token_address_qr">
              <div className="wr_address">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Recipient's username"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    value="ajkshdjkfhakjsdhfjhasjkhsjhshfksfsk"
                    readOnly
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="button-addon2"
                    >
                      Copy
                    </button>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Show QR Code
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* <h5 className="title">Referral</h5>
  <div className="card account_card mb-3">
    <div className="card-body">
      <div className="row">
        <div className="col-md-4 h_b_r">
          <div className="media">
            <img className="avatar_dp mr-3" src={avatar} alt="" />
            <div className="media-body">
              <p>
                <small>User</small>
              </p>
              <h5 className="mt-0 user_address_token">
                <TruncateMiddle text={accountAddress} />
               
              </h5>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-6 total_bets_col text-center h_b_r">
          <p>
            <small>Total TSP</small>
          </p>
          <h5>
            <strong>{(sweepToken / 10 ** 8).toFixed(2)} TSP</strong>
          </h5>
        </div>
        <div className="col-md-4 col-6 daily_task_col text-center">
          <p>
            <small>Total Referral Earning</small>
          </p>
          <h5>
            <strong>{totalearning}</strong>
          </h5>
         
        </div>
      </div>
    </div>
  </div> */}
      <h5 className="title">TSP Transactions</h5>

      <div className="card mb-3 transaction_card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <DateRangePicker onChange={onDateChange} value={date} />
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        {data ? (
          <div>
            <table className="table table-striped table-dark referral_table_tb">
              <thead>
                <tr>
                  <th scope="col">S.N</th>
                  <th scope="col">SignUP</th>
                  <th scope="col">Hash</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {buyToken.map((tspdata, index) => (
                  <tr key={tspdata.txn_hash}>
                    <td>{index + 1}</td>
                    <td>{new Date(tspdata.created_at).toLocaleString()}</td>
                    <td>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          'https://tronscan.org/#/transaction' +
                          `/${tspdata.txn_hash}`
                        }
                      >
                        <div className="middle_truncate">
                          <TruncateMiddle text={tspdata.txn_hash} />
                        </div>
                      </a>
                    </td>
                    <td>{tspdata.amount / 10 ** 8}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {show ? (
              <div style={{ textAlign: 'center' }}>
                <button
                  style={{
                    marginBottom: '10px',
                    marginTop: 10,
                  }}
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => onselectapiCall(true)}
                >
                  Load more
                </button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="card mb-3 transaction_card">
            <h4 className="no_h4">No data available</h4>
          </div>
        )}
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <img src={QR} alt="" />
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore} />
      </div>
    </>
  )
}

export default TronsweepToken
