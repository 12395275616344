import axios from 'axios'
let count = 20

export const userLoginWallet = async (publicAddress, signature) => {
  try {
    const { data } = await axios.post(process.env.REACT_APP_AUTH + `/user/login-metamask`, {
      public_address: publicAddress,
      signature,
    })
    return data
  } catch (err) {
    return err
  }
}
export const userSignupWalletMetaMask = async (payload) => {
  try {
    const { data } = await axios.post(process.env.REACT_APP_AUTH + `/user/address`, payload)
    return data
  } catch (err) {
    return err
  }
}

export const userEmailLogin = async (payload) => {
  try {
    const { data } = await axios.post(process.env.REACT_APP_AUTH + `/user/login`, payload)
    return data
  } catch (err) {
    return err
  }
}
export const userEmailSignup = async (payload) => {
  try {
    const { data } = await axios.post(process.env.REACT_APP_AUTH + `/user/sign-up`, payload)
    return data
  } catch (err) {
    return err
  }
}
export const getNonce = async (payload) => {
  try {
    const { data } = await axios.post(process.env.REACT_APP_AUTH + `/user/get-nonce`, payload)
    return data
  } catch (err) {
    return err
  }
}
export const changePassword = async (payload) => {
  try {
    const { data } = await axios.put(process.env.REACT_APP_AUTH + `/user/password`, payload)
    return data
  } catch (err) {
    return err
  }
}
export const getAllTransaction = async (loadmore) => {
  if (loadmore) count += 20
  try {
    const { data } = await axios.get(process.env.REACT_APP_AUTH + `/user/transactions?page=1&limit=${count}`)
    return data
  } catch (err) {
    return err
  }
}


