/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Chat from './chat'
import { SocketProvider } from '../../utils/socketProvider'

class SidebarLottery extends Component {
  render() {
    const { address, onClose } = this.props

    return (
      <>
        {/* <!-- Sidebar --> */}

        <div id="sidebar" className="sidebar">
          <SocketProvider>
            <Chat publicAddress={address} chatClose={onClose} />
          </SocketProvider>
        </div>
        {/* <!-- Sidebar / End --> */}
      </>
    )
  }
}

export default SidebarLottery
SidebarLottery.propTypes = {
  address: PropTypes.string.isRequired,
}
