import React from 'react'
import { Modal } from 'react-bootstrap'
import { BsXLg } from 'react-icons/bs'

const ModalComponent = ({ show, handleClose, className, children }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} className={className}>
        <span className="close_modal" onClick={handleClose}>
          <BsXLg />
        </span>

        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  )
}

export default ModalComponent
