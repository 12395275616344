/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import Slider from 'react-rangeslider'
import { InputGroup, Form, Tabs, Tab, Table } from 'react-bootstrap'
import 'react-rangeslider/lib/index.css'
import noData from '../../public/images/icons/no_data.svg'
import { useSpring, animated, easings } from '@react-spring/web'
import DiceSound from '../../../assets/audio/dice-sound.mp3'
import TapSound from '../../../assets/audio/tap.mp3'
import SliderSound from '../../../assets/audio/slider.mp3'
import DiceWin from '../../../assets/audio/dice-win.mp3'
import DiceLose from '../../../assets/audio/dice-lose.mp3'
import '../../lottery/components/lottery.css'
import './egaming.css'
import '../../../assets/css/color/blue.css'
import DiceCube from './DiceCube'
import { useEffect } from 'react'
import {
  diceBetPlace,
  initiateDiceSocket,
  subscribeToBetPlace,
  subscribeToBetHistory,
} from '../../helper/socket'
import { getDiceMyBets, getAllDiceBets } from './Service'
import moment from 'moment'
import isEmpty from 'is-empty'
import { ToastsStore } from 'react-toasts'
import { useAccount } from '../../context/AccountProvider'
import Chat from '../../dynamicLotto/components/chat'
import { getIcon } from '../../utils/functions'

// To include the default styles

const minimumBet = 10

const Dice = () => {
  const { totalTRX, tokenInUsd, onSetResultTimer, selectedToken } = useAccount()
  const [predictionValue, setPredictionValue] = useState(50)
  const [isRollUnder, setIsRollUnder] = useState(true)
  const [isRollOver, setIsRollOver] = useState(false)
  const [isRolling, setIsRolling] = useState(false)
  const [luckyNumber, setLuckyNumber] = useState()
  const [minValue, setMinValue] = useState(1)
  const [maxValue, setMaxValue] = useState(100)
  let amount = 0
  const [betAmount, setBetAmount] = useState(amount.toFixed(6))
  const [lastRolls, setLastRolls] = useState([])
  const [socket, setSocket] = useState(null)
  const [myAllBets, setMyAllBets] = useState([])
  const [allBets, setAllBets] = useState([])
  const [multiplier, setMultiplier] = useState(2)
  const [winningChance, setWinningChance] = useState(50)
  const [resultTitle, setResultTitle] = useState()
  const [showResultTitle, setShowResultTitle] = useState(false)
  const [isWin, setIsWin] = useState(false)
  const [isAutoBetsStarted, setIsAutoBetsStarted] = useState(true)

  useEffect(() => {
    let amount = totalTRX > 20 ? 20 : totalTRX
    setBetAmount(amount?.toFixed(6))
  }, [totalTRX])

 
  // const startAuto = () => {
  //   let i = 0;

  //   while (i < 10) {
  //     setTimeout(() => {
  //       diceBetPlaceHanlder()
  //       console.log(i, "i")

  //     }, 5000)
  //     i++;
  //   }
  // }

  const props = useSpring({
    val: luckyNumber,
    from: { val: 0 },
    config: {
      duration: 2000,
      easing: easings.easeInBounce,
    },
  })

  const randomNum = Math.floor(Math.random() * 100)

  const onRollUnderHandler = () => {
    setIsRollUnder(true)
    setIsRollOver(false)
  }
  const onRollOverHandler = () => {
    setIsRollOver(true)
    setIsRollUnder(false)
  }

  const handleChangeStart = () => {
    soundSliderPlay()
  }

  const handleChange = (value) => {
    setPredictionValue(value)
    updateMultiplier(value)
    isRollUnder ? setWinningChance(value) : setWinningChance(100 - value)
  }

  const handleChangeComplete = () => {
    soundSliderPause()
  }

  const horizontalLabels = {
    0: '1',
    100: '100',
  }

  function soundEffect() {
    var audio = document.getElementById('audio')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }

  const diceWin = () => {
    var audio = document.getElementById('diceWin')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const diceLose = () => {
    var audio = document.getElementById('diceLose')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const soundTapPlay = () => {
    var audio = document.getElementById('audioTap')
    audio.pause()
    audio.currentTime = 0
    audio.play()
  }
  const soundSliderPlay = () => {
    var audio = document.getElementById('audioSlider')
    audio.currentTime = 0
    audio.play()
  }
  const soundSliderPause = () => {
    var audio = document.getElementById('audioSlider')
    audio.pause()
    audio.currentTime = 0
  }

  useEffect(() => {
    if (isRollUnder && !isRollOver) {
      setMaxValue(95)
      setMinValue(4)
      if (predictionValue > 95) {
        setPredictionValue(95)
        setWinningChance(95)
        updateMultiplier(95)
      }
      setWinningChance(predictionValue)
      updateMultiplier(predictionValue)
    } else {
      if (predictionValue < 98 && predictionValue > 95) {
        setPredictionValue(98)
        setWinningChance(100 - 98)
        updateMultiplier(98)
      }
      setMaxValue(96)
      setMinValue(5)
      setWinningChance(100 - predictionValue)
      updateMultiplier(predictionValue)
    }
  }, [isRollUnder, isRollOver])

  const hanldeMinBetValue = () => {
    setBetAmount(minimumBet)
  }

  const handleHalfBetValue = () => {
    if (betAmount === minimumBet) return
    setBetAmount(betAmount / 2)
  }
  const handleDoubleBetValue = () => {
    setBetAmount(betAmount * 2)
  }
  const handleMaxBetValue = () => {
    setBetAmount(totalTRX.toFixed(6))
  }

  const handleBetAmountChange = (e) => {
    const { value } = e.target
    setBetAmount(value)
  }

  const getMyBetsHandler = async (loadmore) => {
    const res = await getDiceMyBets(loadmore)
    if (res.history.length > 0) {
      setMyAllBets(res.history)
    }
  }

  const getAllDiceBetsHandler = async (loadmore) => {
    const res = await getAllDiceBets(loadmore)
    if (res.history.dice_game_history.length > 0) {
      setAllBets(res.history.dice_game_history)
    }
  }

  useEffect(() => {
    getMyBetsHandler()
    getAllDiceBetsHandler()
    const socketTemp = initiateDiceSocket()
    setSocket(socketTemp)

    subscribeToBetPlace((err, data) => {
      if (!isEmpty(data.bet_data)) {
        const first2Str = String(data.bet_data.result).slice(0, 2)
        const first2Num = Number(first2Str)
        setLuckyNumber(first2Num)
        setShowResultTitle(true)
        data.bet_data.isWin
          ? setResultTitle('You Won')
          : setResultTitle('You Lose')
        data.bet_data.isWin ? diceWin() : diceLose()
        data.bet_data.isWin ? setIsWin(true) : setIsWin(false)
        setMyAllBets((prev) => [data.bet_data, ...prev])
        setTimeout(() => {
          setLastRolls((prev) => [
            ...prev,
            { target: first2Num, isWin: data.bet_data.isWin },
          ])
          setResultTitle('')
          setShowResultTitle(false)
          setIsRolling(false)
        }, 2000)
      } else {
        setTimeout(() => {
          setIsRolling(false)
        }, 2000)
        ToastsStore.info(data.message)
      }
    })

    subscribeToBetHistory((err, data) => {
      setAllBets((prev) => [data, ...prev])
    })

    return () => {
      socketTemp.close()
    }
  }, [])

  useEffect(() => {
    if (lastRolls.length > 10) {
      let a = lastRolls.shift()
    }
  }, [lastRolls])

  const diceBetPlaceHanlder = () => {
    localStorage.setItem("resultTime", 500)
    if (!socket?.connected || betAmount <= 0) return

    setIsRolling(true)
    //setLuckyNumber(randomNum)
    soundEffect()
    const data = {
      bet: betAmount,
      target: predictionValue,
      seed: 'abc',
      multiplier: multiplier,
      token: selectedToken.value,
      roll: isRollUnder ? 'under' : 'over',
    }
    console.log(data,"Run")

    diceBetPlace(data)
  }

  const updateMultiplier = (value) => {
    var pct = isRollUnder ? 100 / value : 100 / (100 - value)
    var odds = pct - pct * 0.01
    setMultiplier(odds)
    // setPredictionValue(value)
  }

  return (
    <>
      <div className="main_container blue-theme">
        <div className="row ">
          <div className="col-lg-8">
            <div className="dice_main">
              <div className="dice_head">
                <ul>
                  {lastRolls.map((roll, i) => (
                    <li key={i}>
                      <span
                        style={
                          roll.isWin
                            ? { background: '#FFC300', color: 'black' }
                            : null
                        }
                      >
                        {roll.target}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="dice_game">
                <div className="number_col prediction">
                  <h5>{predictionValue}</h5>
                  <p>Prediction</p>
                </div>
                <div className="dice_col">
                  {!showResultTitle ? (
                    <DiceCube isRolling={isRolling} />
                  ) : (
                    <h2>{resultTitle}</h2>
                  )}
                </div>
                <div className="number_col lucky">
                  {isEmpty(luckyNumber) ? (
                    <h5>0</h5>
                  ) : (
                    <h5 className={isWin ? 'result_green' : 'result_red'}>
                      <animated.div className="number">
                        {props.val.interpolate((val) => Math.floor(val))}
                      </animated.div>
                    </h5>
                  )}
                  <p>Lucky Number</p>
                </div>
              </div>
              <div className="rolls_col">
                <div className="roll_under">
                  <div
                    className={`roll_text ${isRollUnder ? 'active' : ''}`}
                    onClick={onRollUnderHandler}
                  >
                    <h5>Roll Under</h5>
                  </div>
                  <div className="roll_detail">
                    <p>Multipleir</p>
                    <h4>x{multiplier.toFixed(2)}</h4>
                  </div>
                </div>
                <div className="roll_over">
                  <div className="roll_detail">
                    <p>Chance to win</p>
                    <h4>{winningChance}%</h4>
                  </div>
                  <div
                    className={`roll_text ${isRollOver ? 'active' : ''}`}
                    onClick={onRollOverHandler}
                  >
                    <h5>Roll Over</h5>
                  </div>
                </div>
              </div>
              <div
                className={`dice_range ${isRollOver ? 'roll_over' : isRollUnder ? 'roll_under' : ''
                  }`}
              >
                <Slider
                  min={minValue}
                  max={maxValue}
                  value={predictionValue}
                  labels={horizontalLabels}
                  onChangeStart={handleChangeStart}
                  onChange={handleChange}
                  onChangeComplete={handleChangeComplete}
                />
                {/* <div className="value">{value}</div> */}
                <div className="line"></div>
              </div>
              <div className="bet_col">
                <div className="bet_details">
                  <div className="top_box">
                    <div
                      className="btn_box_bet"
                      onClick={() => {
                        soundTapPlay()
                        hanldeMinBetValue()
                      }}
                    >
                      <span>Min</span>
                    </div>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        {selectedToken.icon}
                      </InputGroup.Text>
                      <Form.Control
                        value={betAmount}
                        onChange={handleBetAmountChange}
                        onBlur={(e) => {
                          if (e.target.value < minimumBet) {
                            setBetAmount(minimumBet)
                          }
                        }}
                        readOnly={totalTRX < minimumBet ? true : false}
                      />
                    </InputGroup>
                    <div
                      className="btn_box_bet"
                      onClick={() => {
                        soundTapPlay()
                        handleHalfBetValue()
                      }}
                    >
                      <span>1/2</span>
                    </div>
                  </div>
                  <div className="top_box">
                    <div className="btn_box_bet" onClick={handleMaxBetValue}>
                      <span>Max</span>
                    </div>
                    {/* <h5>${(betAmount * tokenInUsd).toFixed(6)}</h5> */}
                    <div
                      className="btn_box_bet"
                      onClick={() => {
                        soundTapPlay()
                        handleDoubleBetValue()
                      }}
                    >
                      <span>2x</span>
                    </div>
                  </div>
                </div>
                <div className={`bet_btn ${betAmount == 0 ? 'disabled' : ''}`}
                  onClick={(betAmount == 0 || !isRolling) ? diceBetPlaceHanlder : null}
                >
                  <div className="btn_text_box">
                    <h5>Bet</h5>
                    <p>
                      Profit on win: {(betAmount * multiplier).toFixed(8)} {selectedToken.value}
                    </p>
                  </div>
                </div>

                {/* <div className={`bet_btn ${betAmount == 0 ? 'disabled' : ''}`}
                  onClick={()=>startAuto()}
                >
                  <div className="btn_text_box">
                    <h5>Bet</h5>
                    <p>
                      Check: {(betAmount * multiplier).toFixed(8)} {selectedToken.value}
                    </p>
                  </div>
                </div> */}


                
              </div>
            </div>
            {/* <div className="tab_box manual_auto">
              <Tabs
                defaultActiveKey="manual"
                id="uncontrolled-tab-example"
                className="mb-3 justify-content-center"
              >
                <Tab eventKey="manual" title="Manual"></Tab>
                <Tab eventKey="auto" title="Auto"></Tab>
              </Tabs>
            </div> */}
            <div className="tab_box bets_info_box">
              <Tabs
                defaultActiveKey="allBets"
                id="uncontrolled-tab-example"
                className="mb-3 justify-content-center"
              >
                <Tab
                  eventKey="allBets"
                  title="All Bets"
                  onClick={() => getAllDiceBetsHandler()}
                >
                  {isEmpty(allBets) ? (
                    <div className="no_data">
                      <img src={noData} alt="no data" className="no_data_img" />
                      <h4 className="no_h4">No record found!</h4>
                    </div>
                  ) : (
                    <div className="table-responsive">

                      <Table variant="dark">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Time</th>
                            <th>Player</th>
                            <th>Target</th>
                            <th>Bet Amount</th>
                            <th>Multiplier</th>
                            <th>Lucky Number</th>
                            <th>Payout</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allBets.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                {moment(item.bet_time).format(
                                  'MMMM Do YYYY, h:mm:ss a',
                                )}
                              </td>

                              <td>{item.user_id}</td>
                              <td>
                                {item.roll == 'under' &&
                                  'Roll Under ' + item.target}
                                {item.roll == 'over' &&
                                  'Roll Over ' + item.target}
                              </td>
                              <td>
                                {' '}
                                <span>
                                  <i className={`icon ${getIcon(item.token)}`}></i>
                                  {' '}
                                  {item.bet}
                                </span>
                              </td>
                              <td>x{item.multiplier.toFixed(2)}</td>
                              <td>
                                {
                                  <span
                                    className={
                                      item.winnings == 0
                                        ? 'result_red'
                                        : 'result_green'
                                    }
                                  >
                                    {item.result}
                                  </span>
                                }
                              </td>
                              <td>
                                {item.winnings == 0 ? (
                                  <span style={{ justifyContent: 'center' }}>
                                    -
                                  </span>
                                ) : (
                                  <span className="result_green">
                                    <i className={`icon ${getIcon(item.token)}`}></i>
                                    {' '}
                                    {parseInt(item.winnings)}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {
                        allBets.length >= 20 && allBets.length < 200 ? <div style={{ textAlign: 'center' }}>
                          <button
                            style={{
                              marginBottom: 10,
                              marginTop: 10,
                            }}
                            type="button"
                            className="btn btn-outline-primary btn-sm"
                            onClick={() => getAllDiceBetsHandler(true)}
                          >
                            Load more
                          </button>
                        </div> : null
                      }
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey="myBets"
                  title="My Bets"
                  onClick={getMyBetsHandler}
                >
                  {isEmpty(myAllBets) ? (
                    <div className="no_data">
                      <img src={noData} alt="no data" className="no_data_img" />
                      <h4 className="no_h4">No record found!</h4>
                    </div>
                  ) : (
                    <div className="table-responsive">

                      <Table variant="dark">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Time</th>
                            <th>Target</th>
                            <th>Bet Amount</th>
                            <th>Multiplier</th>
                            <th>Lucky Number</th>
                            <th>Payout</th>
                          </tr>
                        </thead>
                        <tbody>
                          {myAllBets.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                {moment(item.bet_time).format(
                                  'MMMM Do YYYY, h:mm:ss a',
                                )}
                              </td>
                              <td>
                                {item.roll == 'under' &&
                                  'Roll Under ' + item.target}
                                {item.roll == 'over' &&
                                  'Roll Over ' + item.target}
                              </td>
                              <td>
                                {' '}
                                <span>
                                  <i className={`icon ${getIcon(item.token)}`}></i>
                                  {" "}
                                  {item.bet}
                                </span>
                              </td>
                              <td>x{item.multiplier.toFixed(2)}</td>
                              <td>
                                {
                                  <span
                                    className={
                                      item.winnings == 0
                                        ? 'result_red'
                                        : 'result_green'
                                    }
                                  >
                                    {item.result}
                                  </span>
                                }
                              </td>
                              <td>
                                {item.winnings == 0 ? (
                                  <span style={{ justifyContent: 'center' }}>
                                    -
                                  </span>
                                ) : (
                                  <span className="result_green">
                                    <i className={`icon ${getIcon(item.token)}`}></i>
                                    {' '}
                                    {parseInt(item.winnings)}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {
                        myAllBets.length >= 20 ?
                          <div style={{ textAlign: 'center' }}>
                            <button
                              style={{
                                marginBottom: 10,
                                marginTop: 10,
                              }}
                              type="button"
                              className="btn btn-outline-primary btn-sm"
                              onClick={() => getMyBetsHandler(true)}
                            >
                              Load more
                            </button>
                          </div> : null
                      }
                    </div>
                  )}
                </Tab>
                {/* <Tab eventKey="highRollers" title="High Rollers">
                  <div className="no_data">
                    <img src={noData} alt="no data" className="no_data_img" />
                    <h4 className="no_h4">No record found!</h4>
                  </div>
                </Tab>
                <Tab eventKey="rareWins" title="Rare Wins">
                  <div className="no_data">
                    <img src={noData} alt="no data" className="no_data_img" />
                    <h4 className="no_h4">No record found!</h4>
                  </div>
                </Tab> */}
              </Tabs>
            </div>
          </div>
          {/* <div className="col-lg-4 right_sidebar_dice">
            <StickyBox offsetTop={120} offsetBottom={0}>
              <div className="card">
                <div className="card-header card__header--has-btn ">
                  <h4>DICE GLOBAL RANK</h4>
                  <select className="custom-select">
                    <option>TRX</option>
                    <option>TRON</option>
                  </select>
                </div>
                <div className="card-body p-0">
                  <table className="table table-striped table-dark mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Rank</th>
                        <th scope="col">Player</th>
                        <th scope="col">Total Wagered</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>TFCbd...m3ttR</td>
                        <td>45,946,855</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>TFCbd...m3ttR</td>
                        <td>45,946,855</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>TFCbd...m3ttR</td>
                        <td>45,946,855</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </StickyBox>
          </div> */}
          <div className='col-sm-4'> <Chat /></div>
        </div>

      </div>
      <audio preload="auto" id="audio">
        <source src={DiceSound}></source>
      </audio>
      <audio preload="auto" id="audioTap">
        <source src={TapSound}></source>
      </audio>
      <audio preload="auto" id="audioSlider">
        <source src={SliderSound}></source>
      </audio>
      <audio preload="auto" id="diceWin">
        <source src={DiceWin}></source>
      </audio>
      <audio preload="auto" id="diceLose">
        <source src={DiceLose}></source>
      </audio>
    </>
  )
}

export default Dice
