import React from 'react'

export default function FAQPage() {
  return (
    <div className="main_container container faq_container">
      <h1 className="mt-5" style={{ textAlign: 'center' }}>
        FAQ<span style={{textTransform:"lowercase"}}>s</span>
      </h1>

      <h3 className="mt-5">General</h3>
      <div id="accordion">
        <div className="card">
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#one"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                How and where to buy Tron?
              </button>
            </h5>
          </div>

          <div
            id="one"
            className="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div className="card-body">
              <ol>
                <li>
                  You can buy Tron from popular cryptocurrency exchanges line
                  Binance, Coinbase, OKEX etc.
                </li>
                <li>
                  If you already hold cryptocurrencies, you can swap your
                  existing cryptocurrency to Tron.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#two"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                What are advantages of Tronsweep Lotto over Traditional Lotto?
              </button>
            </h5>
          </div>
          <div
            id="two"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              <table class="table ">
                <thead>
                  <tr>
                    <th scope="col">Traditional Lotto</th>
                    <th scope="col">Tronsweep Lotto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      In traditional lottery, only winners will get benefits
                    </td>
                    <td>
                      All the players get benefits through LOTTO tokens and
                      become stake holder for lifetime.
                    </td>
                  </tr>
                  <tr>
                    <td>No benefits for referrer.</td>
                    <td>
                      Players will get referral benefits everytime their
                      referrals buy tickets and wins.
                    </td>
                  </tr>
                  <tr>
                    <td>No prize sharing is available.</td>
                    <td>Prize sharing is available upto 10 levels.</td>
                  </tr>
                  <tr>
                    <td>Has boundaries and available only in certain areas.</td>
                    <td>
                      Available all over the world and players can refer any
                      person around the world regardless of national boundaries.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Prize distribution happens only when the round is
                      completed.
                    </td>
                    <td>Available prizes will be distributed weekly.</td>
                  </tr>
                  <tr>
                    <td>Player has to claim the prize.</td>
                    <td>
                      Prize will be credited to player’s account automatically.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Prize draw is not 100% trustful, as many lottery draw gets
                      tempered.
                    </td>
                    <td>
                      Prize draw is 100% full proof as the Random Number
                      Generation(RNG) take place by smart contract on public
                      ledger on the blockchain.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <h3 className="mt-5">Tronsweep</h3>
      <div id="accordion">
        <div className="card">
          <div className="card-header" id="xyz">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#three"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                How to create an account?
              </button>
            </h5>
          </div>

          <div
            id="three"
            className="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div className="card-body">
              You need to have Tronlink wallet installed in order to
              authenticate/autherize your self. Tronlink is a browser/mobile
              wallet for Tron. Your wallet address will be your account
              identifier, while logging into the Tronlink wallet you will be
              automatically logged into Tronsweep platform. The entire process
              does not require the user to create an account and provide any
              private keys or passwords.
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#four"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Do Tronsweep have referral program?
              </button>
            </h5>
          </div>
          <div
            id="four"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              Yes, We have and you’ll be rewarded with a referral bonus from
              your referral gaming activity for life. Just place your referral
              link on your website, app, or social profile and receive 10%
              instant commission of ticket purchase by user invited and prize
              share of winning amount upto 10 levels whenever your team member
              win the prize. You can find your referral link on your account,
              under the tab Referrals. The reward will be credited to your
              balance of available tokens automatically with each bet made by
              your referral. There are no limits to how many times you can share
              your link and receive a reward.
            </div>
          </div>
        </div>

        {/* <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#five"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                Why should I freeze my token?
              </button>
            </h5>
          </div>
          <div
            id="five"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              Anim pariatur cliche reprehenderit, enim eiusmod high life
            </div>
          </div>
        </div> */}
      </div>

      <h3 className="mt-5">Dividends</h3>
      <div id="accordion">
        <div className="card">
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#six"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                What is Lotto Dividend?
              </button>
            </h5>
          </div>

          <div
            id="six"
            className="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div className="card-body">
              LOTTO dividend is the sum of revenue collected from LOTTO games
              which is ready to be distributed among all LOTTO token holders.
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#seven"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                What is TSP Dividend?
              </button>
            </h5>
          </div>

          <div
            id="seven"
            className="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div className="card-body">
              TSP dividend is the sum of revenue collected from all games on the
              platform which is ready to be distributed among all TSP token
              holders.
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#eight"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                How is dividend distributed?
              </button>
            </h5>
          </div>
          <div
            id="eight"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              Dividend is distributed on per freezed token basis. You need to
              freeze your tokens to get dividend.
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#nine"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                When is Dividend Distributed?
              </button>
            </h5>
          </div>
          <div
            id="nine"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">Dividend is distributed every week.</div>
          </div>
        </div>

        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#ten"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                Why should I freeze my token?
              </button>
            </h5>
          </div>
          <div
            id="ten"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              The act of freezing your LOTTO tokens will lock them in smart
              contract, that will provide you with a share of dividends until
              they are unfrozen. The process of unfreezing requires 24 hours.
              Tokens that were unfrozen (or in the process of unfreezing) do not
              receive any dividends.
            </div>
          </div>
        </div>
      </div>

      <h3 className="mt-5">LOTTO</h3>
      <div id="accordion">
        <div className="card">
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#eleven"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                What is Mega Lotto?
              </button>
            </h5>
          </div>

          <div
            id="eleven"
            className="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div className="card-body">
              Mega LOTTO is one of the first lotto game that runs on Tronsweep
              platform with total ticket numbers of 60M.
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#twelve"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                What is LOTTO Token?
              </button>
            </h5>
          </div>
          <div
            id="twelve"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              LOTTO is a TRC20 token in TRON blockchain which is minted as per
              the LOTTO mining calculation every time someone buys tickets on
              the platform. The total number of LOTTO token is 1 Billion.
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#thirteen"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                How can I buy LOTTO Token?
              </button>
            </h5>
          </div>
          <div
            id="thirteen"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              LOTTO is issued as a token reward whenever player buys tickets
              with TRX or receive as a reward by participating bounty
              campaign.
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#fourteen"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Where can I use LOTTO Token?
              </button>
            </h5>
          </div>
          <div
            id="fourteen"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              The underlying idea of LOTTO token is receiving dividends from
              LOTTO games. Every player who buy tickets in Tronsweep LOTTO game,
              will receive LOTTO tokens that after freezing will pay dividends
              to their holder.
            </div>
          </div>
        </div>

        {/* <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                type="button"
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#fifteen"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                What is Dealership program in Lotto Mega?
              </button>
            </h5>
          </div>
          <div
            id="fifteen"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              Players having good community are eligible to become a dealer,
              they need to pay 5000TRX to become a dealer. Dealers are
              responsible to guide their team members.
              <ul>
                <li>
                  Dealer gets instant 5% share from tickets sold in their team.
                  Commission will be credited instantly.
                </li>
                <li>
                  Dealer gets 2% of prize share whenever a player from his team
                  wins prize.
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
