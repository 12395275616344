export function checkTicketNumber(num) {
  var numbers = num.split('-')
  if (numbers[1] - numbers[0] > 0) {
    return num
  } else {
    return num.split('-')[1]
  }
}

export const consoleClearHandler = (...args) => {
  console.log(...args)
}

export const ascii_to_hex = (str) => {
  var arr1 = [];
  for (var n = 0, l = str.length; n < l; n++) {
    var hex = Number(str.charCodeAt(n)).toString(16);
    arr1.push(hex);
  }
  return arr1.join('');
}

export const getIcon = (key) => {
  if (key == "TRX") return "tron_red"  
  if (key == "CRD") return "cred_icon"
  if (key == "PLAY") return "play"
  return  null ;
}

export const getColorByName = (key) => {
  if (key === 'BLUE') return 'blue'
  if (key === 'GREEN') return 'green'
  if (key === 'YELLOW') return 'yellow'
  if (key === 'RED') return 'red'
}


