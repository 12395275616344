/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react'

import { ToastsContainer, ToastsStore } from 'react-toasts'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'

import tron from '../public/images/icons/tron_icon.png'
// import tokencontractAddress from "../../tokencontractAddress";
// import config from '../../config.json'
import { tspcontractAddress } from '../utils/ContractAddress'
import { AppContext } from '../context/index'
import { useAccount } from '../context/AccountProvider'
import { useTSPToken } from '../context/TSPProvider'

const TspUnfreezeModal = (props) => {
  const { close, tspUnfreeze, show } = props
  const { accountAddress } = useAccount()
  const { onSbumitTSPUnfreezeToken, tspUnfreezeToken } = useTSPToken()
  const context = useContext(AppContext)
  const {  setTspUnfreezeReqData, setUsertspFreezeToken } =
    context

  const [requnFreeze, setRequnFreeze] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isBigValue, setIsBigValue] = useState(false)

  const unfreezeSweepToken = async () => {
    try {
      setLoading(true)
      let tokencontract = await window.tronWeb.contract().at(tspcontractAddress)
      await tokencontract
        .subscribeUnfreezeRequest(requnFreeze * 10 ** 8)
        .send({ callValue: 500000 })
      ToastsStore.success('Unfrozen successfully')

      tspGetUnfreezeReq()
      tspfreezeToken()
      close()
      setLoading(false)
      // this.context.setRequestUnfreeze(this.state.reqforunfreeze)
    } catch (error) {
      setLoading(false)
      ToastsStore.error('Transcation failed')
    }
  }

  const handleAll = () => {
    setRequnFreeze(tspUnfreeze)
  }

  const handleunFreezeToken = (event) => {
    setRequnFreeze(+event.target.value)
    if (event.target.value > tspUnfreezeToken) {
      setIsBigValue(true)
    } else {
      setIsBigValue(false)
    }
  }

  const tspGetUnfreezeReq = () => {
    axios
      .get(`tron/user/getTspUnfreezeReq/` + `${accountAddress}`)
      .then((res) => {
        setTspUnfreezeReqData(res)
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  const tspfreezeToken = async () => {
    try {
      let tokencontract = await window.tronWeb.contract().at(tspcontractAddress)
      const freeze = await tokencontract.frozenBalance(accountAddress).call()
      const freezetoken = +freeze / 10 ** 8

      setRequnFreeze(freezetoken)
      setUsertspFreezeToken(freezetoken)
      onSbumitTSPUnfreezeToken(freezetoken)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  useEffect(() => {
    tspfreezeToken()
  }, [])

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={close}
          size="sm"
          dialogClassName="withdraw_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>TSP Unfreeze</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img
                      className="icon_img_trs"
                      height="30"
                      src={tron}
                      alt=""
                    />
                  </span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={requnFreeze}
                  defaultValue={tspUnfreezeToken}
                  onChange={handleunFreezeToken}
                />
                <div
                  aria-hidden
                  className="input-group-append"
                  onClick={handleAll}
                >
                  <span className="input-group-text">
                    <a href>All</a>
                  </span>
                </div>
                {isBigValue ? (
                  <small className="text-danger">
                    Input value can not be more than your Unfreeze amount
                  </small>
                ) : null}
              </div>
            </div>
            {requnFreeze > 0 ? (
              !loading ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={unfreezeSweepToken}
                  disabled={isBigValue}
                >
                  Unfreeze
                </button>
              ) : (
                <button type="button" className="btn btn-primary">
                  <i className="fa fa-refresh fa-spin" />
                </button>
              )
            ) : (
              <button type="button" className="btn btn-primary" disabled>
                Unfreeze
              </button>
            )}
          </Modal.Body>
        </Modal>

        <ToastsContainer store={ToastsStore} />
      </div>
    </>
  )
}

export default TspUnfreezeModal
