import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const SkeletonLoader = ({ width, height }) => {
  return (
    <>
      <SkeletonTheme
        width={width}
        height={height}
        baseColor="#202020"
        highlightColor="#444"
      >
        <Skeleton />
      </SkeletonTheme>
    </>
  )
}

export default SkeletonLoader
