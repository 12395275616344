/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import axios from 'axios'
import TruncateMiddle from 'react-truncate-string'
import avatar from '../public/images/icons/avatar.svg'
// import config from '../../config.json'
import { AppContext } from '../context/index'

let datalength = 20
export default class ReferralHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: [null, new Date()],
      reflist: [],
      totalref: 0,
      totalearning: 0,
      data: '',
      isExtendedvisible: false,
      referralHistoryData: [],
      show: true,
    }
  }

  getReferralHistory = (data) => {
    const { accountAddress } = this.context
    const refHistoryof = {
      refereeAddress: accountAddress,
      referralAddress: data,
    }
    this.setState({
      refereeAddress: data,
    })
    axios
      .post(`tron/user/referralTxnHistory`, refHistoryof)
      .then((res) => {
        this.setState({
          referralHistoryData: res.data.data,
        })
      })
      .catch((err) => {
        console.log(err, 'catch')
      })
  }

  onDateChange = (date) => this.setState({ date }, () => this.onselectapiCall())

  onselectapiCall = (loadmore = false) => {
    const { accountAddress } = this.context
    const { date } = this.state
    if (loadmore) {
      datalength += 20
    }

    axios
      .post(`tron/user/all/referral/list`, {
        publicAddress: accountAddress,
        date,
        dataLength: datalength,
      })
      .then((res) => {
        this.setState({
          reflist: res.data.data.refList,
          data: res.data.status,
          totalref: res.data.data.totalReferral,
          totalearning: res.data.data.totalEarning,
        })
        if (datalength > res.data.data.length) {
          this.setState({
            show: false,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  componentDidMount() {
    this.onselectapiCall()
  }

  render() {
    const { accountAddress } = this.context
    const {
      totalref,
      totalearning,
      date,
      isExtendedvisible,
      refereeAddress,
      referralHistoryData,
      data,
      reflist,
      show,
    } = this.state

    return (
      <>
        <h5 className="title">Referral</h5>
        <div className="card account_card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4  h_b_r">
                <div className="media">
                  <img className="avatar_dp mr-3" src={avatar} alt="" />
                  <div className="media-body">
                    <p>
                      <small>User</small>
                    </p>
                    <h5 className="mt-0 user_address_token">
                      <TruncateMiddle text={accountAddress} />
                      {/* {this.props.accountAddress} */}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6 total_bets_col text-center h_b_r">
                <p>
                  <small>Total Referral Person</small>
                </p>
                <h5>
                  <strong>{totalref}</strong>
                </h5>
              </div>
              <div className="col-md-4 col-6 daily_task_col text-center">
                <p>
                  <small>Total Referral Earning</small>
                </p>
                <h5>
                  <strong>{totalearning}</strong>
                </h5>
                {/* <img src={daily_task} /> */}
              </div>
            </div>
          </div>
        </div>
        <h5 className="title">Referral History</h5>

        <div className="card mb-3 transaction_card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <DateRangePicker onChange={this.onDateChange} value={date} />
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          {isExtendedvisible ? (
            <>
              <table className="table table-striped table-dark">
                <thead>
                  <tr>
                    <td colSpan="3">{refereeAddress}</td>
                    <td>
                      <a
                        href
                        onClick={() => {
                          this.setState({
                            isExtendedvisible: false,
                          })
                        }}
                        className="btn btn-outline-primary close_extended_btn"
                      >
                        <i className="fas fa-times-circle" />
                      </a>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {referralHistoryData.length > 0 ? (
                    <>
                      {referralHistoryData.map((refdata, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {new Date(refdata.created_at).toLocaleString()}
                          </td>
                          <td>{refdata.referee_amount}</td>

                          <td>
                            {refdata.level ? (
                              <div>
                                <span style={{ color: 'green' }}>
                                  Pot{refdata.pot_no}
                                </span>{' '}
                                <span style={{ color: '#ede324' }}>
                                  Level {refdata.level}
                                </span>
                              </div>
                            ) : (
                              'Bought Tickets'
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan="4" className="text-center">
                          <strong>No record yet!</strong>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </>
          ) : data ? (
            <div>
              <table className="table table-striped table-dark referral_table_tb">
                <thead>
                  <tr>
                    <th scope="col">S.N</th>
                    <th scope="col">SignUP</th>
                    <th scope="col">Address</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {reflist.map((reflistdata, index) => (
                    <tr
                      key={data.public_address}
                      onClick={() => {
                        this.setState({
                          isExtendedvisible: true,
                        })
                        this.getReferralHistory(reflistdata.public_address)
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>
                        {new Date(reflistdata.created_at).toLocaleString()}
                      </td>
                      <td>
                        <p style={{ marginBottom: '.5em', color: '#f5bc00' }}>
                          {reflistdata.user_name}
                        </p>
                        <div className="middle_truncate">
                          <TruncateMiddle text={reflistdata.public_address} />
                        </div>
                      </td>

                      <td>{reflistdata.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {show ? (
                <div style={{ textAlign: 'center' }}>
                  <button
                    style={{
                      marginBottom: '10px',
                      marginTop: 10,
                    }}
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => this.onselectapiCall(true)}
                  >
                    Load more
                  </button>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="card mb-3 transaction_card">
              <h4 className="no_h4">you have no referral</h4>
            </div>
          )}
        </div>
      </>
    )
  }
}
ReferralHistory.contextType = AppContext
ReferralHistory.propTypes = {
  accountAddress: PropTypes.string.isRequired,
}
