
import React from "react";

export default class RoadMap extends React.PureComponent {

  render() {

    // const params = {
    //   speed: 1600,
    //   pagination: {
    //     el: ".swiper-pagination",
    //     type: "bullets",
    //     clickable: true
    //   },
    //   navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev"
    //   },
    //   direction: "vertical",
    //   spaceBetween: 30,
    //   paginationClickable: true,
    //   nextButton: ".swiper-button-next",
    //   prevButton: ".swiper-button-prev",
    //   breakpoints: {
    //     768: {
    //       direction: "horizontal"
    //     }
    //   }
    // };

    return (
      <>
        <h2
          className="section_header section_header_line"
          style={{ color: "#000", marginBottom: "0" }}
        >
          Roadmap
        </h2>
        <div className="timeline-container content" id="Roadmap">
          <div className="timeline">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div
                  className="swiper-slide"
                  style={{ backgroundImage: "url('landing/img/bg/sky.jpg')" }}
                  data-year="Q1 2020"
                >
                  <div className="swiper-slide-content">
                    <span className="timeline-year">Q1 2020</span>
                    <div className="items">
                      <h4 className="timeline-title">Platfrom Launch</h4>
                      <ul>
                        <li>TSP Token Launch</li>
                        <li>Private Sale</li>
                        <li>Mega Lottery Game Launch</li>
                      </ul>
                    </div>
                    <div className="items">
                      <h4 className="timeline-title">Empower Community</h4>
                      <ul>
                        <li>Onboard Dealers</li>
                        <li>Tronsweep Community Build up</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  className="swiper-slide"
                  style={{ backgroundImage: "url('landing/img/bg/sky.jpg')" }}
                  data-year="Q2 2020"
                >
                  <div className="swiper-slide-content single-content">
                    <span className="timeline-year">Q2 2020</span>
                    <div className="items">
                      <h4 className="timeline-title">Adding dApps on Platform</h4>
                      <ul>
                        <li>Dealer and Users Learning Portal</li>
                        <li>E Gaming Launch(Dice, Crash, Wheel of Fortune)</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  className="swiper-slide"
                  style={{ backgroundImage: "url('landing/img/bg/sky.jpg')" }}
                  data-year="Q3 2020"
                >
                  <div className="swiper-slide-content single-content">
                    <span className="timeline-year">Q3 2020</span>
                    <div className="items">
                      <h4 className="timeline-title">Live Games</h4>
                      <ul>
                        <li>Tronsweep Sports Batting Launch</li>
                        <li>Live Casino</li>
                        <li>Onboard Lienced local games</li>
                      </ul>
                    </div>
                    </div>
                  
                </div>

                <div
                  className="swiper-slide"
                  style={{ backgroundImage: "url('landing/img/bg/sky.jpg')" }}
                  data-year="Q4 2020"
                >
                  <div className="swiper-slide-content single-content">
                    <span className="timeline-year">Q4 2020</span>
                    <div className="items">
                      <h4 className="timeline-title">Becoming Stable</h4>
                      <ul>
                        <li>Stable Coin Integration</li>
                        <li>Partner more dApps</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  className="swiper-slide"
                  style={{ backgroundImage: "url('landing/img/bg/sky.jpg')" }}
                  data-year="Q1 2021"
                >
                  <div className="swiper-slide-content">
                    <span className="timeline-year">Q1 2021</span>
                    <div className="items">
                      <h4 className="timeline-title">
                        LAUNCH CITY CHEF AND CITY HOST
                      </h4>
                      <p className="timeline-text">
                        CITY CHEF / CITY HOST/ VIRTUAL TRAVEL GENIE- TANYA
                      </p>
                    </div>
                    <div className="items">
                      <h4 className="timeline-title">CITY CONCIERGE APP</h4>
                      <p className="timeline-text">
                        Both for Travelers & Homeowners
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="swiper-slide"
                  style={{ backgroundImage: "url('landing/img/bg/sky.jpg')" }}
                  data-year="Q2 2021"
                >
                  <div className="swiper-slide-content">
                    <span className="timeline-year">Q2 2021</span>
                    <div className="items">
                      <h4 className="timeline-title">ENROLLMENT GOAL</h4>
                      <p className="timeline-text">1M - TRAVOLIER LOYAL MEMBERS</p>
                    </div>
                    <div className="items">
                      <h4 className="timeline-title">HOTELIER - STABLE COIN</h4>
                      <p className="timeline-text">
                        Integrated with Fintech Wallet
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="swiper-slide"
                  style={{ backgroundImage: "url('landing/img/bg/sky.jpg')" }}
                  data-year="Q3 2021"
                >
                  <div className="swiper-slide-content single-content">
                    <span className="timeline-year">Q3 2021</span>
                    <div className="items">
                      <h4 className="timeline-title">
                        HOTELIER EXCHANGE (CENTRALIZED)
                      </h4>
                      <p className="timeline-text">
                        Secondary Hotel Room Market Place
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="swiper-slide"
                  style={{ backgroundImage: "url('landing/img/bg/sky.jpg')" }}
                  data-year="Q4 2021"
                >
                  <div className="swiper-slide-content three-content">
                    <span className="timeline-year">Q4 2021</span>
                    <div className="items">
                      <h4 className="timeline-title">HOTELIER EXCHANGE (DEX)</h4>
                      <p className="timeline-text">
                        Secondary Hotel Room Market Place
                      </p>
                    </div>
                    <div className="items">
                      {" "}
                      <h4 className="timeline-title">ENROLLMENT GOAL</h4>
                      <p className="timeline-text">2M - TRAVOLIER LOYAL MEMBERS</p>
                    </div>
                    <div className="items">
                      {" "}
                      <h4 className="timeline-title">Offer</h4>
                      <p className="timeline-text">
                        {" "}
                        SECURITY TOKEN OFFERINGS/IPO Holding Company
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-button-prev" />
              <div className="swiper-button-next" />
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </>
    );
  }
}
