import axios from 'axios'

export const getUserBalance = async () => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_AUTH + `/user/balance`,
    )
    return data
  } catch (err) {
    return err
  }
}
export const getTokenUsd = async () => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_AUTH + `/util/token-usd`,
    )
    return data
  } catch (err) {
    return err
  }
}
