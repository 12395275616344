import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Nav, Tab, Table } from 'react-bootstrap'
import { useAccount } from '../../context/AccountProvider'
import { TOKENGOODIES_API_KEY } from '../../utils/keys'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import isEmpty from 'is-empty'
import axios from 'axios'
import moment from 'moment'

const freezedays = 3 // The number of days you need the energy/bandwidth for. Always pass 3
let priceinsun = 30 // This value should be >= min_energy_price_in_sun variable above for energy orders and >= min_bandwidth_price_in_sun variable above for bandwidth orders
const EnergyBuy = ({ creatOrderValues, handleCloseEnergyModal }) => {
  const { accountAddress } = useAccount()
  const [energyValue, setEnergyValue] = useState(200000)
  const [bandwidthValue, setBandwidthValue] = useState(5000)
  const [isBandWidth, setIsBandWidth] = useState(false)
  const [oderAmount, setOderAmount] = useState(0)
  const [isAgreeEnergyTerms, setIsAgreeEnergyTerms] = useState(false)

  const [errorEnergy, setErrorEnergy] = useState('')
  const [energyAccountAddress, setEnergyAccountAddress] =
    useState(accountAddress)
  const [myAllTransactions, setMyAllTransactions] = useState([])
  const [isMyTrancation, setIsMyTrancation] = useState(false)

  const placeOrderHandler = async () => {
    var url_to_post =
      'https://www.tokengoodies.com/tronresourceexchange/exchange'
    var order_fees_address = creatOrderValues.order_fees_address
    var min_order_amount_in_sun = creatOrderValues.min_order_amount_in_sun // This is the minimum order amount. Your order amount must be >= this amount
    var min_energy_price_in_sun = creatOrderValues.min_energy_price_in_sun
    var min_bandwidth_price_in_sun = creatOrderValues.min_bandwidth_price_in_sun

    // ==========================================================================================
    // Create a send TRX transaction object, sign and submit

    var freezedays = 3 // The number of days you need the energy/bandwidth for. Always pass 3
    var priceinsun = 30 // This value should be >= min_energy_price_in_sun variable above for energy orders and >= min_bandwidth_price_in_sun variable above for bandwidth orders
    var amt = !isBandWidth ? parseInt(energyValue) : parseInt(bandwidthValue) // The amount of energy/bandwidth. E.g 1000000
    if (isBandWidth) {
      priceinsun = 600
    } else {
      priceinsun = 30
    }
    var orderfeesamountinsun = amt * priceinsun * freezedays // IMPORTANT: Make sure this value is >= min_order_amount_in_sun variable above
    var txnbuilderobj = await window.tronWeb.transactionBuilder.sendTrx(
      order_fees_address,
      orderfeesamountinsun,
    )

    if (txnbuilderobj != null) {
      if (txnbuilderobj.raw_data_hex !== undefined) {
        // Sign the transaction
        var signedtxn = await window.tronWeb.trx.sign(txnbuilderobj)
        if (signedtxn == null) return

        // Submit the signed transaction to the API
        var requestbody = new Object()
        requestbody.action = 'create'
        requestbody.type = 'order'
        requestbody.resourceid = 1 // Pass 0 for bandwidth, 1 for energy

        requestbody.order = new Object()
        requestbody.order.freezeto = energyAccountAddress // The address where energy/bandwidth is sent. E.g. TAJAfqk82Kov5WAR8iwPHmaAytCoppU31K
        requestbody.order.amount = amt
        requestbody.order.freezeperiod = freezedays
        requestbody.order.priceinsun = priceinsun * freezedays

        requestbody.signedtxn = JSON.stringify(signedtxn)
        requestbody.api_key = TOKENGOODIES_API_KEY

        var result = await FetchAPIData(url_to_post, requestbody, 60000)
        console.log(result, 'resResult')
        if (result.success) {
          ToastsStore.success('Energy Bought Successfully')
          handleCloseEnergyModal()
          const lastTransactions = JSON.parse(
            localStorage.getItem('energyTransactions'),
          )
          if (lastTransactions && lastTransactions.length > 0) {
            result.date = new Date()
            result.address = accountAddress
            lastTransactions.push(result)
            localStorage.setItem(
              'energyTransactions',
              JSON.stringify(lastTransactions),
            )
            setIsMyTrancation(true)
          } else {
            result.date = new Date()
            result.address = accountAddress
            localStorage.setItem('energyTransactions', JSON.stringify([result]))
            setIsMyTrancation(true)
          }
        } else {
          ToastsStore.error('Something went wrong')
        }

        // result is a json object with the following fields. Check if the API call is successful by checking the "success" key. If the call is successful, the "createtxnhash" contains the hash of your transaction. Ignore the other fields in the response.
        //  {
        //      "success": true,
        //      "action": "create",
        //      "type": "order",
        //      "requestid": "6f4934be-4bac-0170-e03c-dacaba4a",
        //      "contact": false,
        //      "message": "SUCCESS",
        //      "createtxnhash": "0fb53a0496bcd1e8ef6a6be57f4eba76bb9b11d362f58f13712810263561",
        //      "logtype": "RESPONSE",
        //      "url": "",
        //      "ip": ""
        //  }
      }
    }
  }

  useEffect(() => {
    if (isBandWidth) {
      priceinsun = 600
    } else {
      priceinsun = 30
    }
    var amt = !isBandWidth ? parseInt(energyValue) : parseInt(bandwidthValue) // The amount of energy/bandwidth. E.g 1000000
    var orderfeesamountinsun = (amt * priceinsun * freezedays) / 1000000 // IMPORTANT: Make sure this value is >= min_order_amount_in_sun variable above
    setOderAmount(orderfeesamountinsun)
    console.log(amt, energyValue, bandwidthValue, 'orderfeesamountinsun')
  }, [isBandWidth, energyValue, bandwidthValue])

  // The FetchAPIData function below requires jQuery library

  async function FetchAPIData(url_to_post, requestbody, timeout_in_ms) {
    try {
      var result = await axios.post(url_to_post, requestbody)
      return result.data
    } catch (e) {
      console.log(e)
    }
    return null
  }

  useEffect(() => {
    const lastTransactions = JSON.parse(
      localStorage.getItem('energyTransactions'),
    )
    if (lastTransactions && lastTransactions.length > 0) {
      setMyAllTransactions(
        lastTransactions.filter((item) => item.address === accountAddress),
      )
    }
  }, [isMyTrancation])
  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="energy">
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link
              eventKey="energy"
              onClick={() => {
                setIsBandWidth(false)
                setErrorEnergy('')
                setIsAgreeEnergyTerms(false)
              }}
            >
              Energy
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="bandwidth"
              onClick={() => {
                setIsBandWidth(true)
                setErrorEnergy('')
                setIsAgreeEnergyTerms(false)
              }}
            >
              Bandwidth
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="energy">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Energy address</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={energyAccountAddress}
                onChange={(e) => setEnergyAccountAddress(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Energy amount</Form.Label>
              <Form.Control
                type="text"
                placeholder="Password"
                value={energyValue}
                onChange={(e) => {
                  const { value } = e.target
                  if (parseInt(value) < 100000) {
                    setErrorEnergy(
                      'The lowest energy amount at the price you chose is 100000 energy',
                    )
                  } else {
                    setErrorEnergy('')
                  }
                  setEnergyValue(value)
                }}
              />
            </Form.Group>
            {errorEnergy && <Alert variant="danger">{errorEnergy}</Alert>}

            <div className="info_box_energy">
              <h5>Order amount : {oderAmount} TRX</h5>
              {/* <Table>
                      <tbody>
                        <tr>
                          <td>Order amount :</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Savings :</td>
                          <td>750 TRX</td>
                        </tr>
                        <tr>
                          <td>Discount :</td>
                          <td>90%</td>
                        </tr>
                        <tr>
                          <td>TXN Fee :</td>
                          <td> 0 TRX</td>
                        </tr>
                      </tbody>
                    </Table> */}
            </div>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" id={`check-api-checkbox`}>
                <Form.Check.Input
                  type="checkbox"
                  onChange={(e) => setIsAgreeEnergyTerms(e.target.checked)}
                />
                <Form.Check.Label>
                  this service is provide by tokengoodies, I agree tokengoodies{' '}
                  <a
                    target="_blank"
                    href="https://www.tokengoodies.com/resources/disclaimers/terms.htm"
                  >
                    terms and conditions
                  </a>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              onClick={placeOrderHandler}
              disabled={
                !isAgreeEnergyTerms ||
                isEmpty(energyValue) ||
                !isEmpty(errorEnergy)
              }
            >
              Place Order
            </Button>
            <Button
              variant="secondary"
              type="submit"
              className="ml-3"
              onClick={handleCloseEnergyModal}
            >
              Cancel
            </Button>
          </Tab.Pane>
          <Tab.Pane eventKey="bandwidth">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Bandwidth address</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={energyAccountAddress}
                onChange={(e) => setEnergyAccountAddress(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Bandwidth amount</Form.Label>
              <Form.Control
                type="text"
                placeholder="Password"
                value={bandwidthValue}
                onChange={(e) => {
                  const { value } = e.target
                  if (parseInt(value) < 5000) {
                    setErrorEnergy(
                      'The lowest bandwidth amount at the price you chose is 5000 bandwidth',
                    )
                  } else {
                    setErrorEnergy('')
                  }
                  setBandwidthValue(value)
                }}
              />
            </Form.Group>
            {errorEnergy && <Alert variant="danger">{errorEnergy}</Alert>}
            <div className="info_box_energy">
              <h5>Order amount : {oderAmount} TRX</h5>
              {/* <Table>
                      <tbody>
                        <tr>
                          <td>Order amount :</td>
                          <td>{oderAmount} TRX</td>
                        </tr>
                        <tr>
                          <td>Savings :</td>
                          <td>750 TRX</td>
                        </tr>
                        <tr>
                          <td>Discount :</td>
                          <td>90%</td>
                        </tr>
                        <tr>
                          <td>TXN Fee :</td>
                          <td> 0 TRX</td>
                        </tr>
                      </tbody>
                    </Table> */}
            </div>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" id={`check-api-checkbox`}>
                <Form.Check.Input
                  type="checkbox"
                  onChange={(e) => setIsAgreeEnergyTerms(e.target.checked)}
                />
                <Form.Check.Label>
                  this service is provide by tokengoodies, I agree tokengoodies{' '}
                  <a
                    target="_blank"
                    href="https://www.tokengoodies.com/resources/disclaimers/terms.htm"
                  >
                    terms and conditions
                  </a>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              onClick={placeOrderHandler}
              disabled={
                !isAgreeEnergyTerms ||
                isEmpty(bandwidthValue) ||
                !isEmpty(errorEnergy)
              }
            >
              Place Order
            </Button>
            <Button
              variant="secondary"
              type="submit"
              className="ml-3"
              onClick={handleCloseEnergyModal}
            >
              Cancel
            </Button>
          </Tab.Pane>
          {myAllTransactions.length > 0 && (
            <Table striped variant="dark" className="mt-2">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>HASH</th>
                </tr>
              </thead>
              <tbody>
                {myAllTransactions?.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      {moment(item.date).format('MMMM Do YYYY, h:mm:ss a')}
                    </td>
                    <td>
                      <a
                        target="_blank"
                        href={`https://tronscan.org/#/transaction/${item.createtxnhash}`}
                      >
                        {item.createtxnhash}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Tab.Content>
      </Tab.Container>
      <ToastsContainer store={ToastsStore} />
    </>
  )
}

export default EnergyBuy
