/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { InputGroup, Form, Tabs, Tab, Table, Row, Col } from 'react-bootstrap'
import 'react-rangeslider/lib/index.css'
import noData from '../../public/images/icons/no_data.svg'
import CircleImg from '../../../assets/img/circle.svg'
import '../../lottery/components/lottery.css'
import './egaming.css'
import '../../../assets/css/color/blue.css'
import CircleSound from '../../../assets/audio/circle.wav'
import TapSound from '../../../assets/audio/tap.mp3'
import { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
import {
  initiateCircleSocket,
  circlePlaceBet,
  circleBetResponse,
  allCircleBetData,
  circleGameState,
  circleGameResult,
} from '../../helper/socket'
import { getCircleMyBets, getAllCircleBets } from './Service'
import isEmpty from 'is-empty'
import moment from 'moment'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import Rocket from './Rocket'

// To include the default styles

const minimumBet = 0.000001

const ToMoon = () => {
  const [allBets, setAllBets] = useState([])

  return (
    <>
      <div className="main_container blue-theme">
        <div className="row ">
          <div className="col-lg-8">
            <div className="dice_main circle_game too_moon">
              <div className="game">
                <div className="rocket_box">
                  <Rocket />
                </div>
              </div>
            </div>

            <div className="tab_box bets_info_box mt-5">
              <Tabs
                defaultActiveKey="allBets"
                id="uncontrolled-tab-example"
                className="mb-3 justify-content-center"
              >
                <Tab eventKey="allBets" title="All Bets">
                  {isEmpty(allBets) ? (
                    <div className="no_data">
                      <img src={noData} alt="no data" className="no_data_img" />
                      <h4 className="no_h4">No record found!</h4>
                    </div>
                  ) : (
                    <Table variant="dark">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Time</th>
                          <th>Player</th>
                          <th>Bet Amount</th>
                          <th>Multiplier</th>
                          <th>Payout</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {allBets.map((item, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              {moment(item.bet_time).format(
                                'MMMM Do YYYY, h:mm:ss a',
                              )}
                            </td>
                            <td>{item.user_id}</td>
                            <td>
                              {' '}
                              <span>
                                <i className="icon tron_red"></i>{' '}
                                {item.amount_placed}
                              </span>
                            </td>
                            <td>
                              {item.color_picked === 'YELLOW' && '50X'}
                              {item.color_picked === 'GREEN' && '5X'}
                              {item.color_picked === 'RED' && '3X'}
                              {item.color_picked === 'GREY' && '2X'}
                            </td>
                            <td>
                              <span>
                                <i className="icon tron_red"></i>{' '}
                                {item.amount_won}
                              </span>
                            </td>
                          </tr>
                        ))} */}
                      </tbody>
                    </Table>
                  )}
                </Tab>
                <Tab eventKey="myBets" title="My Bets"></Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ToMoon
