import React from 'react'

export default function Tutorial() {
  return (
    <div>
      <div className="main_container container faq_container">
        <h1 className="mt-5" style={{ textAlign: 'center' }}>Learn</h1>

        <div className="row justify-content-center">
          <div className="col-md-12">
            <h3 className="mt-3">Videos</h3>

            <div className="row">
              <div className="col-sm-6">
                <div
                  className="accordion accordion--space-between"
                  id="accordionFaqs"
                >
                  <div className="card">
                    <div
                      id="collapse-0"
                      className="collapse show"
                      aria-labelledby="heading-0"
                      data-parent="#accordionFaqs"
                    >
                      <div className="card__content">
                        <a href="https://www.youtube.com/watch?v=c4MXCF33p2E" target="_blank" rel="noopener noreferrer">
                          <img src={'https://s3.ap-south-1.amazonaws.com/www.tronsweep.com/learn/video/how+to+create+acc.png'} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div
                  className="accordion accordion--space-between"
                  id="accordionFaqs"
                >
                  <div className="card">
                    <div
                      id="collapse-0"
                      className="collapse show"
                      aria-labelledby="heading-0"
                      data-parent="#accordionFaqs"
                    >
                      <div className="card__content">
                        <a href="https://www.youtube.com/watch?v=p_QYKoBqtBc" target="_blank" rel="noopener noreferrer">
                          <img src={'https://s3.ap-south-1.amazonaws.com/www.tronsweep.com/learn/video/how+to+buy+ticket.png'} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <h3 className="mt-5">Articles</h3>
              <div className="row">
                <div className="col-sm-4">
                  <div
                    className="accordion accordion--space-between"
                    id="accordionFaqs"
                  >
                    <div className="card">
                      <div
                        id="collapse-0"
                        className="collapse show"
                        aria-labelledby="heading-0"
                        data-parent="#accordionFaqs"
                      >
                        <div className="card__content">
                          <a href="https://medium.com/@tronsweep.io/how-to-buy-tron-energy-4fd7cd5ad435" target="_blank" rel="noopener noreferrer">
                            <img src={'https://s3.ap-south-1.amazonaws.com/www.tronsweep.com/learn/Create+Account.gif'} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div
                    className="accordion accordion--space-between"
                    id="accordionFaqs"
                  >
                    <div className="card">
                      <div
                        id="collapse-0"
                        className="collapse show"
                        aria-labelledby="heading-0"
                        data-parent="#accordionFaqs"
                      >
                        <div className="card__content">
                          <a href="https://medium.com/@tronsweep.io/how-to-buy-tron-energy-4fd7cd5ad435" target="_blank" rel="noopener noreferrer">
                            <img src={'https://s3.ap-south-1.amazonaws.com/www.tronsweep.com/learn/How+To+Buy+Tron+Energy.gif'} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div
                    className="accordion accordion--space-between"
                    id="accordionFaqs"
                  >
                    <div className="card">
                      <div
                        id="collapse-0"
                        className="collapse show"
                        aria-labelledby="heading-0"
                        data-parent="#accordionFaqs"
                      >
                        <div className="card__content">
                          <a href="https://medium.com/@tronsweep.io/how-to-buy-lotto-tickets-and-freeze-lotto-token-in-tronsweep-42fee0928b38" target="_blank" rel="noopener noreferrer">
                            <img src={'https://s3.ap-south-1.amazonaws.com/www.tronsweep.com/learn/How+To+Buy+LOTTO+Tickets.gif'} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-4 mt-3">
                  <div
                    className="accordion accordion--space-between"
                    id="accordionFaqs"
                  >
                    <div className="card">
                      <div
                        id="collapse-0"
                        className="collapse show"
                        aria-labelledby="heading-0"
                        data-parent="#accordionFaqs"
                      >
                        <div className="card__content">
                          <a href="https://medium.com/@tronsweep.io/how-to-become-a-lotto-dealer-in-tronsweep-62973a1543fb" target="_blank" rel="noopener noreferrer">
                            <img src={'https://s3.ap-south-1.amazonaws.com/www.tronsweep.com/learn/become+dealer.gif'} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
