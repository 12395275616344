
import firebase from 'firebase';
import "firebase/firestore"
const firebaseConfig = {
  apiKey: "AIzaSyCsc-xiciDg_KduiotWrTbgtMpyo-Hte9E",
  authDomain: "tronsweep.firebaseapp.com",
  databaseURL: "https://tronsweep-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tronsweep",
  storageBucket: "tronsweep.appspot.com",
  messagingSenderId: "506643253166",
  appId: "1:506643253166:web:d4da726ba23387388a2a3f",
  measurementId: "G-3Q1LXJZEKQ"
};

firebase.initializeApp(firebaseConfig);


export default firebase