import React from 'react'

const Rocket = () => {
  return (
    <>
      <div class="myRocket">
        <div class="rocket">
          <svg width="63px" height="63px" viewBox="0 0 63 63" version="1.1">
            <g
              id="07---Migration"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Artboard"
                transform="translate(-333.000000, -59.000000)"
                fill-rule="nonzero"
              >
                <g
                  id="Group-3"
                  transform="translate(370.500000, 84.000000) rotate(45.000000) translate(-370.500000, -84.000000) translate(350.000000, 49.000000)"
                >
                  <path
                    d="M20.2611213,56.0890909 C28.6271974,56.1343694 36.1052102,61.3169003 39.0847576,69.1345455 C42.4407841,60.7158107 39.7979614,51.0923198 32.6129695,45.5683811 C25.4279776,40.0444424 15.4485201,39.9637187 8.17510817,45.3707038 C0.901696259,50.7776888 -1.89645886,60.3571677 1.32293946,68.8290909 C4.41291985,61.0902174 11.9283451,56.0344777 20.2611213,56.0890909 L20.2611213,56.0890909 Z"
                    id="Path"
                    fill="#657EFF"
                  ></path>
                  <path
                    d="M25.5938485,42.0636364 L25.4793031,56.8145455 C31.7130116,58.5286368 36.7624212,63.100974 39.0847576,69.1345455 C41.2204407,63.7700463 40.9690926,57.7496734 38.3936896,52.5818602 C35.8182867,47.414047 31.1628598,43.5884665 25.5938485,42.0636364 L25.5938485,42.0636364 Z"
                    id="Path"
                    fill="#314DFF"
                  ></path>
                  <path
                    d="M20.2611213,58.7236364 C22.6864496,58.7410546 25.0946463,59.131689 27.4011213,59.8818182 C31.1258204,52.0689936 33.0358631,43.5151348 32.988394,34.86 C33.1156667,19.6763636 28.1265758,6.50363636 20.7447576,9.59232693e-14 C13.2738485,6.36363636 8.01748491,19.4727273 7.94112127,34.6563636 C7.74703936,43.30931 9.51357931,51.893998 13.108394,59.7672727 C15.4242539,59.0513022 17.8372349,58.6992302 20.2611213,58.7236364 Z"
                    id="Path"
                    fill="#5670F6"
                  ></path>
                  <path
                    d="M20.7574849,9.9475983e-14 C20.7574849,9.9475983e-14 20.7574849,9.9475983e-14 20.7574849,9.9475983e-14 L20.2611213,58.7236364 L20.2611213,58.7236364 C22.6864496,58.7410546 25.0946463,59.131689 27.4011213,59.8818182 C31.1258204,52.0689936 33.0358631,43.5151348 32.988394,34.86 C33.128394,19.6763636 28.1393031,6.50363636 20.7574849,9.9475983e-14 Z"
                    id="Path"
                    fill="#415DF1"
                  ></path>
                  <path
                    d="M20.6429395,14.3436364 C23.7993031,14.3436364 26.8793031,14.5090909 29.8829395,14.7381818 C28.1402402,9.13105528 24.9999744,4.05932897 20.7574849,-3.64153152e-14 C16.4333667,3.9882607 13.196447,9.01227556 11.3520304,14.5981818 C14.4065758,14.42 17.4993031,14.3436364 20.6429395,14.3436364 Z"
                    id="Path"
                    fill="#4462FF"
                  ></path>
                  <path
                    d="M20.6938485,0.0636363636 L20.5793031,14.3436364 L20.5793031,14.3436364 C23.7356667,14.3436364 26.8156667,14.5090909 29.8193031,14.7381818 C28.0947898,9.13766584 24.9768116,4.066572 20.7574849,-3.64153152e-14 C20.7303627,0.01433912 20.7081877,0.0365141744 20.6938485,0.0636363636 Z"
                    id="Path"
                    fill="#304BF5"
                  ></path>
                  <path
                    d="M20.4393031,40.1163636 C18.8229395,42.7890909 17.6774849,48.2490909 17.6265758,54.5490909 C17.5756667,60.8490909 18.6320304,66.3345455 20.1720304,69.0327273 C21.788394,66.36 22.9338485,60.9127273 22.9847576,54.6 C23.0356667,48.2872727 22.0047576,42.8145455 20.4393031,40.1163636 Z"
                    id="Path"
                    fill="#657EFF"
                  ></path>
                  <path
                    d="M20.3629395,40.2436364 L20.1338485,68.9054545 C20.1338485,68.9054545 20.1338485,68.9945455 20.1974849,69.0327273 C21.8138485,66.36 22.9593031,60.9127273 23.0102122,54.6 C23.0611213,48.2872727 22.0047576,42.8145455 20.4647576,40.1163636 L20.3629395,40.2436364 Z"
                    id="Path"
                    fill="#304BF5"
                  ></path>
                  <path
                    d="M20.4774849,34.7073799 C16.5905548,34.6790558 13.4624304,31.5052378 13.4905795,27.6183072 C13.5187286,23.7313766 16.6924921,20.6031969 20.5794232,20.6312782 C24.4663543,20.6593595 27.5945894,23.8330684 27.5665758,27.72 C27.5315765,31.6041674 24.3617521,34.7284647 20.4774849,34.7073799 Z"
                    id="Path"
                    fill="#304BF5"
                  ></path>
                  <path
                    d="M20.5793031,22.8327273 C17.8802575,22.8117234 15.6752064,24.9826759 15.6541476,27.6817211 C15.6330888,30.3807663 17.8039964,32.5858615 20.5030412,32.6069752 C23.202086,32.628089 25.4072253,30.4572262 25.4284629,27.7581818 C25.4425464,25.06183 23.2755469,22.8607046 20.5793031,22.8327273 L20.5793031,22.8327273 Z"
                    id="Path"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    d="M20.5920304,20.7072727 L20.5156667,20.7072727 L20.5156667,22.8581818 L20.5920304,22.8581818 C23.2911966,22.8792691 25.4622085,25.0844702 25.4411213,27.7836364 C25.420034,30.4828026 23.2148329,32.6538145 20.5156667,32.6327273 L20.4393031,32.6327273 L20.4393031,34.7709091 L20.5156667,34.7709091 C24.2847662,34.6323328 27.2746715,31.5472858 27.2951507,27.7756953 C27.31563,24.0041047 24.3594028,20.8867711 20.5920304,20.7072727 L20.5920304,20.7072727 Z"
                    id="Path"
                    fill="#304BF5"
                  ></path>
                  <path
                    d="M25.428394,27.7581818 C25.4419599,26.4609745 24.9378815,25.2118688 24.0277615,24.2874162 C23.1176415,23.3629636 21.8765641,22.8394306 20.5793031,22.8327273 L20.5029395,22.8327273 L20.4265758,32.6073417 L20.5029395,32.6073417 C23.1992912,32.6214251 25.4004167,30.4544256 25.428394,27.7581818 L25.428394,27.7581818 Z"
                    id="Path"
                    fill="#EBEBFF"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="plamya1">
          <svg
            width="18px"
            height="17px"
            viewBox="0 0 18 17"
            version="1.1"
          ></svg>

          <g
            id="07---Migration"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            opacity="0.5"
          >
            <g
              id="Artboard"
              transform="translate(-325.000000, -308.000000)"
              fill="#E14B51"
            >
              <g
                id="Group-2"
                transform="translate(331.085786, 318.707107) rotate(45.000000) translate(-331.085786, -318.707107) translate(320.085786, 310.207107)"
              >
                <path
                  d="M12.0977182,15.4937892 L19.7290587,3.86507984 C20.3350879,2.94160681 20.0777488,1.7017003 19.1542758,1.09567113 C18.8282885,0.881742006 18.4468774,0.767766953 18.0569629,0.767766953 L4.4663777,0.767766953 C3.3618082,0.767766953 2.4663777,1.66319745 2.4663777,2.76776695 C2.4663777,3.1576815 2.58035275,3.5390926 2.79428188,3.86507984 L10.4256224,15.4937892 C10.728637,15.9555257 11.3485902,16.0841952 11.8103267,15.7811807 C11.9247867,15.7060663 12.0226039,15.6082492 12.0977182,15.4937892 Z"
                  id="Triangle"
                ></path>
              </g>
            </g>
          </g>
        </div>
        <div class="plamya2">
          <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1">
            <g
              id="07---Migration"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              opacity="0.7"
            >
              <g
                id="Artboard"
                transform="translate(-330.000000, -311.000000)"
                fill="#E14F4B"
              >
                <g
                  id="Group-2"
                  transform="translate(331.085786, 318.707107) rotate(45.000000) translate(-331.085786, -318.707107) translate(320.085786, 310.207107)"
                >
                  <path
                    d="M12.1225968,9.46466052 L15.4786266,3.78522558 C16.0405534,2.83427239 15.7251853,1.60784078 14.7742321,1.0459139 C14.4660789,0.863823372 14.1147056,0.767766953 13.7567735,0.767766953 L8.76656709,0.767766953 C7.66199759,0.767766953 6.76656709,1.66319745 6.76656709,2.76776695 C6.76656709,3.125699 6.86262351,3.47707238 7.04471403,3.78522558 L10.4007438,9.46466052 C10.6817072,9.94013711 11.294923,10.0978212 11.7703996,9.81685774 C11.9156248,9.73104286 12.036782,9.60988569 12.1225968,9.46466052 Z"
                    id="Triangle"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div class="star star1"></div>
        <div class="star star2"></div>
        <div class="star star3"></div>
        <div class="star star4"></div>
        <div class="star star5"></div>
        <div class="star star6"></div>
        <div class="star star7"></div>
        <div class="star star8"></div>
        <div class="star star9"></div>
        <div class="star star10"></div>
      </div>
    </>
  )
}

export default Rocket
