import axios from 'axios'
export const freezeWHL = async (payload) => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_AUTH + `/user/freeze`,
      payload,
    )
    return data
  } catch (err) {
    return err
  }
}
export const unfreezeWHL = async (payload) => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_AUTH + `/user/unfreeze`,
      payload,
    )
    return data
  } catch (err) {
    return err
  }
}
export const getPublicDividend = async (lotteryData) => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_AUTH +`/user/public-dividend/${lotteryData?.dividendToken}`,
    )
    return data
  } catch (err) {
    return err
  }
}
