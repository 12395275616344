/* eslint-disable react/prefer-stateless-function */
import React, { Component, useState } from 'react'
import HomeSlider from './partials/home_slider'
import Games from './partials/games'
import { Container, Form, InputGroup, Nav, Tab } from 'react-bootstrap'
import SelectInput from './SelectInput'
import { settings2 } from '../utils/SliderSettings'
import GallerySlider from './GallerySlider'

// import CurrentPot from '../lottery/components/currentPot';
// import LastLottoWinners from '../lottery/components/lastLottoWinners';
// import Logo from "../public/images/logo.png";
// import Navbar from '../includes/navbar';
// import Rodgers from '../public/images/samples/avatar-12-xs.jpg';
// import Heroes from '../public/images/esports/samples/post-img2.jpg';
// import LagerthaDax from '../public/images/samples/avatar-6-xs.jpg';

const gameList = [
  {
    title: 'In-House Game',
    icon: 'home_red',
    innerGames: [
      {
        name: 'lotto',
        path: '/dapp/lotto',
      },
      {
        name: 'dice',
        path: '/inhouse/dice',
      },
      {
        name: 'color-prediction',
        path: '/inhouse/color-prediction',
      },
      {
        name: 'fortune-wheel',
        path: '/inhouse/fortune-wheel',
      },
    ],
  },
  {
    title: 'Slot & Roulette',
    icon: 'slots_yellow',
    innerGames: [
      {
        name: 'roulette',
        path: '/inhouse/roulette',
      },
      {
        name: 'slot',
        path: '/#',
      },
      {
        name: '',
        path: '/#',
      },
      {
        name: '',
        path: '/#',
      },
    ],
  },

  {
    title: 'Card Game',
    icon: 'card_game_blue',
    innerGames: [
      {
        name: 'poker',
        path: '/#',
      },
      {
        name: 'rummy',
        path: '/#',
      },
      {
        name: 'hi-low',
        path: '/#',
      },
      // {
      //   name: 'dragon-tiger',
      //   path: '/#',
      // },
      {
        name: 'andar-bahar',
        path: '/#',
      },
    ],
  },
  {
    title: 'Dapp',
    icon: 'dapp',
    innerGames: [
      {
        name: 'mega-lotto',
        path: '/dapp/dice',
      },
      {
        name: '',
        path: '/#',
      },
      {
        name: '',
        path: '#',
      },
      {
        name: '',
        path: '/#',
      },
    ],
  },
  {
    title: 'MTW Token Games',
    icon: 'dapp',
    innerGames: [
      {
        name: 'metawhale-world-lotto',
        path: '/partner/mtw/lotto',
        id: 2,
      },
      {
        name: '',
        path: '/#',
      },
      {
        name: '',
        path: '/#',
      },
      {
        name: '',
        path: '/#',
      },
    ],
  },
  {
    title: 'Recommended',
    icon: 'recomended',
    innerGames: [
      {
        name: 'lotto',
        path: '/dapp/lotto',
      },
      {
        name: 'fortune-wheel',
        path: '/inhouse/fortune-wheel',
      },
      {
        name: 'dice',
        path: '/inhouse/dice',
      },
      {
        name: 'color-prediction',
        path: '/inhouse/color-prediction',
      },
    ],
  },
]

const data = [
  {
    value: 'lobby',
    text: 'Lobby',
    icon: <i className="icon lobby_gray"></i>,
  },
  {
    value: 'slots',
    text: 'Slots',
    icon: <i className="icon slots"></i>,
  },
  {
    value: 'live',
    text: 'Live',
    icon: <i className="icon live"></i>,
  },
  {
    value: 'cardgame',
    text: 'Card Game',
    icon: <i className="icon card_game"></i>,
  },
]
const Home = () => {
  const [selectedOption, setSelectedOption] = useState(data[0])
  const [key, setKey] = useState('lobby')

  const handleSelectChange = (e) => {
    setSelectedOption(e)
    setKey(e.value)
  }
  return (
    <>
      <HomeSlider />

      <div className="home_tabs">
        <div className="mobile_only select_games">
          <SelectInput
            selectedOption={selectedOption}
            handleChange={handleSelectChange}
            data={data}
          />
        </div>

        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="lobby"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="lobby">
                <i className="icon lobby_gray"></i>
                Lobby
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="inhouse">
                <i className="icon home"></i>
                In-House
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="slots">
                <i className="icon slots"></i> Slots
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="live">
                <i className="icon live"></i> Live
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="cardgame">
                <i className="icon card_game"></i> Card Game
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="metaWorldLotto">
                <i className="icon card_game"></i> MTW World
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <i className="icon search"></i>
                </InputGroup.Text>
                <Form.Control placeholder="Search your game" />
              </InputGroup>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="lobby">
              {gameList.map((game) => (
                <Games
                  title={game.title}
                  icon={game.icon}
                  key={game.name}
                  games={game.innerGames}
                />
              ))}
            </Tab.Pane>
            <Tab.Pane eventKey="inhouse">
              {gameList.slice(0, 1).map((game) => (
                <Games
                  title={game.title}
                  icon={game.icon}
                  key={game.name}
                  games={game.innerGames}
                />
              ))}
            </Tab.Pane>
            <Tab.Pane eventKey="slots">
              {gameList.slice(1, 2).map((game) => (
                <Games
                  title={game.title}
                  icon={game.icon}
                  key={game.name}
                  games={game.innerGames}
                />
              ))}
            </Tab.Pane>
            <Tab.Pane eventKey="live"></Tab.Pane>
            <Tab.Pane eventKey="metaWorldLotto">
              {gameList.slice(4, 5).map((game) => (
                <Games
                  title={game.title}
                  icon={game.icon}
                  key={game.name}
                  games={game.innerGames}
                />
              ))}
            </Tab.Pane>
            <Tab.Pane eventKey="cardgame">
              {gameList.slice(2, 3).map((game) => (
                <Games
                  title={game.title}
                  icon={game.icon}
                  key={game.name}
                  games={game.innerGames}
                />
              ))}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  )
}

export default Home
