import React, { useContext, useEffect } from 'react'
import Trophy from '../../public/images/icons/trophy.svg'
import { AppContext } from '../../context'
import Coins from '../../public/images/icons/tsp.png'
import axios from 'axios'
import isEmpty from 'is-empty'
import moment from 'moment'
import Tron from '../../public/images/icons/tron_icon.png'

const Notifications = () => {
  const context = useContext(AppContext)
  const { allNotifications, accountAddress, setIsNotificationAvailable } =
    context

  const readNotificationsFirebase = () => {
    axios
      .post(`/tron/user/notification/update/${accountAddress}`)
      .then((res) => {
        setIsNotificationAvailable(false)
      })
      .catch(() => {})
  }

  const readNotifications = () => {
    axios
      .post(`/tron/user/notification/update`, { publicAddress: accountAddress })
      .then((res) => {
        setIsNotificationAvailable(false)
      })
      .catch(() => { })
  }

  useEffect(() => {
    readNotifications()
    readNotificationsFirebase()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <h5 className="title">Notifications</h5>
      <div className="notification_wrapper">
        <div className="container">
          <ul className="list-unstyled">
            {!isEmpty(allNotifications) ? (
              allNotifications.map((notification) => (
                // eslint-disable-next-line no-underscore-dangle
                <li
                  className={`media ${notification.read ? 'message_read' : ''}`}
                  key={notification._id}
                >
                  {notification.type === 'PW' && (
                    <img src={Trophy} className="mr-3" alt="..." />
                  )}
                  {notification.type === 'DIVIDENT_TSP' && (
                    <img src={Coins} className="mr-3" alt="..." />
                  )}
                  {notification.type === 'DIVIDENT_LOTTO' && (
                    <img src={Tron} className="mr-3" alt="..." />
                  )}
                  <div className="media-body">
                    {notification.type === 'PW' && (
                      <>
                        <h5 className="mt-0 mb-1">Prize Won</h5>
                        <p>
                          Congratulation, you have won the pot{' '}
                          {notification.potNumber} prize, and the amount{' '}
                          {notification.data.amount} TRX is added to your
                          wallet.
                        </p>
                        <span style={{ fontSize: '10px', color: '#989898' }}>
                          {moment(notification.data.created_at).format(
                            'MMMM Do YYYY, h:mm:ss a',
                          )}
                        </span>
                      </>
                    )}
                    {notification.type === 'DIVIDENT_TSP' ? (
                      <>
                        <h5 className="mt-0 mb-1">TSP Dividend Distributed</h5>
                        <p>
                          Congratulation, you are rewarded with a TSP dividend
                          of {notification.data.amount} TRX.
                        </p>
                        <span style={{ fontSize: '10px', color: '#989898' }}>
                          {moment(notification.data.created_at).format(
                            'MMMM Do YYYY, h:mm:ss a',
                          )}
                        </span>
                      </>
                    ) : (
                      notification.type === 'DIVIDENT_LOTTO' && (
                        <>
                          <h5 className="mt-0 mb-1">
                            LOTTO Dividend Distributed
                          </h5>
                          <p>
                            Congratulation, you are rewarded with a LOTTO
                            dividend of {notification.data.amount} TRX.
                          </p>
                          <span style={{ fontSize: '10px', color: '#989898' }}>
                            {moment(notification.data.created_at).format(
                              'MMMM Do YYYY, h:mm:ss a',
                            )}
                          </span>
                        </>
                      )
                    )}
                    {notification.type === 'RF' && (
                      <h5 className="mt-0 mb-1">Referral</h5>
                    )}
                  </div>
                </li>
              ))
            ) : (
              <h4 className="no_h4">No data available</h4>
            )}
          </ul>
        </div>
      </div>
    </>
  )
}

export default Notifications
