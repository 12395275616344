/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'

class LottoRules extends Component {
  render() {
    return (
      <div>
        <div className="content_bottom_body">
          <p> 1. Ticket Cost 100 MTW Token.</p>
          <p>
            {' '}
            2. Users get the WHL token in their wallet.
          </p>
          <p>
            {' '}
            3. Users will get dividend only when their WHL tokens are frozen.
          </p>
          <p>4. Available prizes will be drawn on a daily basis.</p>
        </div>
      </div>
    )
  }
}

export default LottoRules
