import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
// import config from '../../config.json'

let datalength = 20
export default class TransactionHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: [null, new Date()],
      transactionType: 'all',
      transHistory: [],
      data: '',
      show: true,
    }
  }

  componentDidMount() {
    this.history()
  }

  history = (loadmore = false) => {
    const { accountAddress } = this.props
    const { date, transactionType } = this.state
    if (loadmore) {
      datalength += 20
    }

    axios
      .post(`tron/user/transactions`, {
        public_address: accountAddress,
        date,
        action: transactionType,
        dataLength: datalength,
      })
      .then((res) => {
        this.setState({
          transHistory: res.data.data,
          data: res.data.status,
        })
        if (datalength > res.data.data.length) {
          this.setState({
            show: false,
          })
        }
      })
      .catch((res) => {
        console.log(res, 'catch')
      })
  }

  onDateChange = (date) => {
    this.setState({ date }, () => this.history())
  }

  handleChange = (value) => {
    this.setState(
      {
        transactionType: value.target.value,
      },
      () => this.TransactionHistory(),
    )
  }

  TransactionHistory() {
    const { transactionType } = this.state
    if (transactionType === 'Deposit') {
      this.history()
    }
    if (transactionType === 'Withdraw') {
      this.history()
    }
    if (transactionType === 'All') {
      this.history()
    }
  }

  render() {
    const { date, transactionType, data, transHistory, show } = this.state

    return (
      <>
        <h5 className="title">Transaction History</h5>

        <div className="card mb-3 transaction_card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <DateRangePicker onChange={this.onDateChange} value={date} />
              </div>
              <div className="col-md-6">
                <select
                  className="custom-select"
                  value={transactionType}
                  onChange={this.handleChange}
                >
                  <option value="All">All</option>
                  <option value="Deposit">Deposits</option>
                  <option value="Withdraw">Withdraw</option>

                  {/* <option value="Daily Tasks">Daily Tasks</option> */}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-3 transaction_history_card">
          <div className="card-body p-0">
            {data ? (
              <div>
                <table className="table table-striped table-dark mb-0">
                  <thead>
                    <tr>
                      <th scope="col">S.N</th>
                      <th scope="col">Time</th>

                      <th scope="col">Game</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transHistory.map((tdata, index) => (
                      <tr key={tdata.id}>
                        <td>{index + 1}</td>
                        <td>{new Date(tdata.created_at).toLocaleString()}</td>
                        <td>{tdata.game}</td>
                        <td>{tdata.amount}</td>
                        <td>{tdata.action}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {show ? (
                  <div style={{ textAlign: 'center' }}>
                    <button
                      style={{
                        marginBottom: '10px',
                        marginTop: 10,
                      }}
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => this.history(true)}
                    >
                      Load more
                    </button>
                  </div>
                ) : null}
              </div>
            ) : (
              <h4 className="no_h4">No data available</h4>
            )}
          </div>
        </div>
      </>
    )
  }
}
TransactionHistory.propTypes = {
  accountAddress: PropTypes.string.isRequired,
}
