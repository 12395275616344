/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'

class LottoRules extends Component {
  render() {
    return (
      <div>
        <div className="content_bottom_body">
          <p> 1. Ticket Cost 100 TRX.</p>
          <p>
            {' '}
            2. Users get the LOTTO token according to the mining chart.
          </p>
          <p>
            {' '}
            3. Users will get dividend only when their LOTTO tokens are frozen
            in the network.
          </p>
          <p>4. Available prizes will be drawn on a daily basis. </p>
        </div>
      </div>
    )
  }
}

export default LottoRules
