import React, { Component } from 'react'

import './sidenav.css'
import { NavLink } from 'react-router-dom'
import user from '../public/images/icons/user_dark.svg'
import coins from '../public/images/icons/tsp.png'
import Bell from '../public/images/icons/bell.svg'
import referral from '../public/images/icons/referral_dark.svg'
import { AppContext } from '../context'

export default class Sidenav extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { accountAddress } = this.props

    const { isDealer } = this.context

    return (
      <>
        <div className="card">
          <div className="card-body">
            <ul className="nav flex-column account_nav" id="account_nav">
              <li className="nav-item">
                <NavLink
                  to="/account"
                  className={`nav-link `}
                  activeClassName="active"
                >
                  <img className="icon_img" src={user} alt="" /> Account
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className={`nav-link `}
                  to="/dividend"
                  activeClassName="active"
                >
                  {/* <img className="icon_img" src={dividendIcon} />{" "} */}
                  <span className="dividend_icon">
                    <i className="fas fa-coins" />
                  </span>
                  Dividend
                </NavLink>
              </li>
              {isDealer ? (
                <div>
                  <li className="nav-item">
                    <NavLink
                      className={`nav-link `}
                      to="/dealer/referral"
                      activeClassName="active"
                    >
                      <img className="icon_img" src={referral} alt="" />
                      Dealer Referral
                    </NavLink>
                  </li>
                </div>
              ) : null}

              <li className="nav-item">
                <NavLink
                  className={`nav-link `}
                  to="/referral"
                  activeClassName="active"
                >
                  <img className="icon_img" src={referral} alt="" />
                  Referral
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className={`nav-link `}
                  to="/tronsweep-token"
                  activeClassName="active"
                >
                  <img className="icon_img" src={coins} alt="" />
                  TSP Token
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  className={`nav-link `}
                  to="/wagers-and-pot-prize"
                  activeClassName="active"
                >
                  <img className="icon_img" src={coins} alt="" />
                  Wagers and Prize Pots
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </>
    )
  }
}
Sidenav.contextType = AppContext
