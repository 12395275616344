import React from 'react'
import axios from 'axios'
import TruncateMiddle from 'react-truncate-string'
import smartTruncate from 'smart-truncate'
import Countdown, { zeroPad } from 'react-countdown-now'
import moment from 'moment'
const tomorrow = moment(new Date())
  .add(1, 'days')
  .startOf('day')
  .set({ h: '02',m:'30' })

const timerTimestamp = new Date(tomorrow).getTime()
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return true
  }

  return (
    <div className="top_wagger_timer">
      {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
    </div>
  )
}
class TopWager extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      topWagerData: '',
      topWager: [],
      topWaggersDate: '',
      pastDate: new Date(),
      isFuturVisible: false,
    }
  }

  componentDidMount() {
    const theDate = new Date()
    const todayFormat = theDate.toDateString()
    this.setState({
      topWaggersDate: todayFormat,
    })
    axios
      .get(`tron/data/topwagers/${Date.now()}`)
      .then((res) => {
        this.setState({
          topWager: res.data.data,
          topWagerData: true,
        })
      })
      .catch(() => {})
  }

  getPastWaggers = () => {
    const { pastDate } = this.state
    const prevDate = pastDate.setDate(pastDate.getDate() - 1)
    const theDate = new Date(prevDate)
    const dateString = theDate.toDateString()

    this.setState({
      topWaggersDate: dateString,
      topWager: [],
      topWagerData: false,
    })

    axios
      .get(`tron/data/topwagers/${prevDate}`)
      .then((res) => {
        this.setState({
          topWager: res.data.data,
          topWagerData: true,
          isFuturVisible: true,
        })

        if (!res.data.status) {
          this.setState({
            topWagerData: true,
          })
        }
      })
      .catch(() => {})
  }

  getFutureWaggers = () => {
    const { pastDate } = this.state
    const prevDate = pastDate.setDate(pastDate.getDate() + 1)
    const theDate = new Date(prevDate)
    const dateString = theDate.toDateString()

    this.setState({
      topWaggersDate: dateString,
      topWager: [],
      topWagerData: false,
    })

    const currentDate = Date.now()
    const currtheDate = new Date(currentDate)
    const currentDateString = currtheDate.toDateString()

    const prevDateDate = new Date(prevDate)
    const prevDateString = prevDateDate.toDateString()

    if (currentDateString === prevDateString) {
      this.setState({
        isFuturVisible: false,
      })
    }
    axios
      .get(`tron/data/topwagers/${prevDate}`)
      .then((res) => {
        this.setState({
          topWager: res.data.data,
          topWagerData: true,
        })

        // console.log(currentDate)
      })
      .catch(() => {})
  }

  render() {
    const { topWagerData, topWager, topWaggersDate, isFuturVisible } =
      this.state

    const { fromTab } = this.props

    return (
      <>
        <div className="top_heade_waggers">
          <div onClick={this.getPastWaggers} aria-hidden>
            <span>
              <i className="mdi mdi-arrow-left" />
            </span>
          </div>
          <div>
            <strong>{topWaggersDate}</strong>
            {!isFuturVisible && (
              <Countdown date={timerTimestamp} renderer={renderer} />
            )}
          </div>
          <div>
            {isFuturVisible ? (
              <div onClick={this.getFutureWaggers} aria-hidden>
                <span>
                  <i className="mdi mdi-arrow-right" />
                </span>
              </div>
            ) : null}
          </div>
        </div>
        {topWagerData ? (
          <div>
            {topWager ? (
              <table className="wager_table table">
                <thead>
                  <tr>
                    <td>Player</td>
                    <td>Total Wagered</td>
                    <td>Tokens</td>
                  </tr>
                </thead>
                <tbody>
                  {topWager.map((topWagerD) => (
                    <tr key={topWagerData.id}>
                      <td>
                        <p
                          style={{
                            marginBottom: '.5em',
                            color: '#f5bc00',
                          }}
                        >
                          {topWagerD.user_name.toUpperCase()}
                        </p>
                        <div className="middle_truncate">
                          {smartTruncate(topWagerD.public_address, 18, {
                            position: 8,
                          })}
                        </div>
                      </td>
                      <td>
                        {topWagerD.ticket_count} {fromTab && 'Tickets'}
                      </td>
                      <td>
                        {topWagerD.token_amount} {fromTab && 'Lotto'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h4 className="no_top_waggers">No record found!</h4>
            )}
          </div>
        ) : (
          <h4 className="no_top_waggers">
            <span className="spinner">
              <div className="bounce1" />
              <div className="bounce2" />
              <div className="bounce3" />
            </span>
          </h4>
        )}
      </>
    )
  }
}

export default TopWager
