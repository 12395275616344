import React, { useState, useEffect, useCallback } from 'react'
// import PropTypes from 'prop-types'
import axios from 'axios'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import Picker from 'emoji-picker-react'
import ScrollToBottom from 'react-scroll-to-bottom'
import { useSocket } from '../../utils/socketProvider'

import './chat.css'
import isEmpty from 'is-empty'
import { useAccount } from '../../context/AccountProvider'

const Chat = (props) => {
  const { chatClose } = props
  const { accountAddress, noWalletDetect } = useAccount()
  const [chatOpen] = useState(true)
  const [message, setMessage] = useState('')
  const [messageData, setMessagedata] = useState([])
  const socket = useSocket()
  const [username, setUsername] = useState([])

  const [isEmojiOpen, setIsEmojiOpen] = useState(false)
  // const [chosenEmoji, setChosenEmoji] = useState(null)

  const handleShowEmojiOpen = () => setIsEmojiOpen(true)
  const handleCloseEmojiOpen = () => setIsEmojiOpen(false)

  const fetchUserName = useCallback(() => {
    axios
      .get(`tron/user/player/${accountAddress}`, {})
      .then((res) => {
        setUsername(res?.data?.data?.user_name)
      })
      .catch((res) => {
        console.log(res, 'catch')
      })
  }, [accountAddress])

  useEffect(() => {
    fetchUserName()
  }, [fetchUserName])

  const sendmessage = () => {
    if (!isEmpty(message.trim())) {
      let msg = {
        user_name: username,
        userAddress: accountAddress,
        message: message.trim(),
      }

      if (accountAddress) {
        // Send to server
        setMessagedata((prevState) => [...prevState, msg])
        socket.emit('message', msg)
        setMessage('')
      } else {
        ToastsStore.error('Kindly login with wallet')
      }
    }
  }

  // const showWinners = () => {
  //   const { chatOpen } = this.state
  //   this.setState({ chatOpen: !chatOpen })
  // }

  const getAllChats = () => {
    axios
      .get(`/tron/chat/all/${20}`, {})
      .then((res) => {
        if (res.data.status) {
          setMessagedata(res.data.data)
        } else {
          setMessagedata([])
        }
      })
      .catch((res) => {})
  }

  useEffect(() => {
    getAllChats()
    if (socket == null) return
    // Recieve messages
    socket.on('message', (msg) => {
      setMessagedata((prevState) => [...prevState, msg])
      // scrollToBottom()
    })
    return () => socket.off('message')
  }, [socket])

  const onEmojiClick = (event, emojiObject) => {
    // setChosenEmoji(emojiObject);
    // setFeedData((prevState) => ({
    //   ...prevState,
    //   content: prevState.content + emojiObject.emoji,
    // }));
    setMessage((prevState) => prevState + emojiObject.emoji)
    handleCloseEmojiOpen()
  }
  return (
    <div className="chat">
      <aside className="widget card widget--sidebar widget-standings chat_card sticky-top">
        <div className="widget__title card-header card__header--has-btn">
          <h4 className="chat_h4">
            <i className="mdi mdi-forum chat_icon" /> Chat
          </h4>

          <span
            className="close_chat_icon"
            style={{ fontSize: 30 }}
            onClick={chatClose}
          >
            <i className="mdi mdi-close-circle"></i>
          </span>
          {/* <Link
          to="#"
          className="btn btn-outline-primary btn-xs card-header__button chat_winning_numbers"
         
        >
          {this.state.chat ? "Winning Numbers" : "Chat"}
        </Link> */}
        </div>
        {chatOpen ? (
          <div className="widget__content card__content">
            {/* <!-- Game Score --> */}
            <ScrollToBottom className="chat_body">
              <div className="widget-game-result__section ">
                <br />
                {!isEmpty(messageData) ? (
                  <ul className="list-unstyled chat_list">
                    {messageData.map((item) => (
                      <li
                        className="media"
                        style={{ position: 'relative' }}
                        key={item.userAddress}
                      >
                        {item.user_name !== username ? (
                          <span className="user_icon mt-2">
                            <i className="fas fa-user" />
                          </span>
                        ) : null}

                        <div
                          className={
                            item.user_name === username
                              ? 'media-body chat-me mt-2'
                              : 'media-body chat-others mt-2'
                          }
                        >
                          {item.user_name !== username ? (
                            <h5 className="mt-0">{item.user_name}</h5>
                          ) : null}
                          {item.message}
                        </div>
                        {item.user_name === username ? (
                          <span className="send-label">You</span>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </ScrollToBottom>
            {!noWalletDetect && (
              <div className="header-search-form w-100">
                <form id="mobile-search-form" className="search-form">
                  {isEmojiOpen && <Picker onEmojiClick={onEmojiClick} />}
                  <span className="emoji_picker" onClick={handleShowEmojiOpen}>
                    <i className="fa fa-smile-o" aria-hidden="true"></i>
                  </span>

                  <textarea
                    style={{ resize: 'none' }}
                    className="form-control header-mobile__search-control resize-none"
                    placeholder="Type your message here"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyUp={(e) => {
                      if (!isEmpty(e.target.value.trim())) {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                          sendmessage()
                        }
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="header-mobile__search-submit"
                    disabled={isEmpty(message.trim())}
                    onClick={sendmessage}
                  >
                    <i className="fa fa-send" />
                  </button>
                </form>
              </div>
            )}
          </div>
        ) : (
          <div className="widget__content card__content">
            {/* <WinningSlider /> */}
          </div>
        )}
      </aside>

      <ToastsContainer store={ToastsStore} />
    </div>
  )
}

export default Chat
