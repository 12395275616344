/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import './winning_box.css'

const WinningBox = ({ close, winningData, getWinningData }) => {
  // const [winningOpen, setWinningOpen] = useState(false);

  // const handleWinningBoxShow = () => {
  //   setTimeout(() => {
  //     setWinningOpen(true);
  //   }, 3000);
  // };
  // const handleWinningboxHide = () => {
  //   setTimeout(() => {
  //     setWinningOpen(false);
  //   }, 6000);
  // };

  useEffect(() => {
    const present = document.querySelector('.present')
    const image = document.querySelector('.image')
    const container = document.querySelector('.box_container')

    // Setup canvas, drawing functions

    const colors = ['#D8589F', '#EE4523', '#FBE75D', '#4FC5DF']
    const bubbles = 80

    const explode = (x, y) => {
      const particles = []
      const ratio = window.devicePixelRatio
      const c = document.createElement('canvas')
      const ctx = c.getContext('2d')

      c.style.position = 'absolute'
      c.style.left = `${50}%`
      c.style.top = `${50}%`
      c.style.transform = `translate(-50%, -50%)`
      c.style.pointerEvents = 'none'
      c.style.width = `${500}px`
      c.style.height = `${500}px`
      c.style.zIndex = 9999
      c.width = 500 * ratio
      c.height = 500 * ratio
      document.body.appendChild(c)

      for (let i = 0; i < bubbles; i += 1) {
        particles.push({
          x: c.width / 2,
          y: c.height / r(2, 4),
          radius: r(3, 8),
          color: colors[Math.floor(Math.random() * colors.length)],
          rotation: r(230, 310, true),
          speed: r(3, 7),
          friction: 0.99,
          fade: 0.03,
          opacity: r(150, 150, true),
          yVel: 0,
          gravity: 0.06,
        })
      }

      render(particles, ctx, c.width, c.height)
      setTimeout(() => document.body.removeChild(c), 5000)
    }

    const render = (particles, ctx, width, height) => {
      requestAnimationFrame(() => render(particles, ctx, width, height))
      ctx.clearRect(0, 0, width, height)

      particles.forEach((p) => {
        p.x += p.speed * Math.cos((p.rotation * Math.PI) / 180)
        p.y += p.speed * Math.sin((p.rotation * Math.PI) / 180)

        p.opacity -= 0.005
        p.speed *= p.friction
        p.radius -= p.fade
        p.yVel += p.gravity
        p.y += p.yVel

        if (p.opacity < 0 || p.radius < 0) return

        ctx.beginPath()
        ctx.globalAlpha = p.opacity
        ctx.fillStyle = p.color
        ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, false)
        ctx.fill()
      })

      return ctx
    }

    const r = (a, b, c) =>
      parseFloat(
        (Math.random() * ((a || 1) - (b || 0)) + (b || 0)).toFixed(c || 0),
      )

    image.addEventListener('mouseover', () => {
      const x = image.offsetLeft + 600
      const y = image.offsetTop - 120
      explode(x, y)
    })

    const onClickExplode = () => {
      const x = image.offsetLeft + 600
      const y = image.offsetTop - 120
      explode(x, y)
    }

    // container.onClick = () => {
    //   container.classList.toggle("image-zoomed");
    // };

    present.onclick = () => {
      present.classList.toggle('open')
      container.classList.toggle('container-open')
      onClickExplode()
    }

    setTimeout(() => {
      present.onclick()
    }, 2000)
    document.body.classList.add('winning_box_open')
    // setInterval(() => {
    //   onClickExplode();
    // }, 6000);
    // setTimeout(() => {
    //   var box = document.getElementsByClassName("box_wrapper")[0];
    //   console.log(box);
    //   box.style.display = "none";
    //   document.body.classList.remove("winning_box_open");
    // }, 8000);
  }, [])

  return (
    <>
      <div className="box_wrapper ">
        <div className="box_container">
          <div className="image">
            <span className="closeBox" aria-hidden onClick={close}>
              <i className="mdi mdi-close" />
            </span>
            <div className="box_head">
              <h1>
                <i className="mdi mdi-trophy" /> Congratulation!
              </h1>
            </div>
            <div className="box_body cus_scrollbar">
              <table className="table table-striped table-dark">
                <thead>
                  <tr>
                    <th scope="col">Winning Number</th>
                    <th scope="col">Pot</th>
                    <th scope="col">Winning Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {winningData.map((item) => (
                    <tr key={item.id}>
                      <th scope="row">{item.winning_number}</th>
                      <td> {item.pot_no}</td>
                      <td>{item.amount_won} TRX</td>
                    </tr>
                  ))}
                  {winningData.length > 10 && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={getWinningData()}
                    >
                      Load more
                    </button>
                  )}
                </tbody>
              </table>
            </div>

            <>
              {/* <h3>You have won {item.pot_no}</h3>
                <h5>
                  Winning Amount : <strong>{item.amount_won}</strong>
                </h5>
                <p>
                  Winning Number : <strong>{item.winning_number}</strong>
                </p> */}
            </>
          </div>
        </div>
        <div className="present">
          <div className="rotate-container">
            <div className="bottom" />
            <div className="front" />
            <div className="left" />
            <div className="back" />
            <div className="right" />

            <div className="lid">
              <div className="lid-top" />
              <div className="lid-front" />
              <div className="lid-left" />
              <div className="lid-back" />
              <div className="lid-right" />
            </div>
          </div>

          <div className="instruction">Click to open!</div>
        </div>
      </div>
    </>
  )
}

export default WinningBox
WinningBox.propTypes = {
  close: PropTypes.func.isRequired,
  winningData: PropTypes.shape([]).isRequired,
  getWinningData: PropTypes.func.isRequired,
}
