import React, { createContext, useContext, useEffect } from 'react'
import { useState } from 'react'

const SidebarToggleContext = createContext()

export function useSidebarToggle() {
  return useContext(SidebarToggleContext)
}

export default function SidebarToggleProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('sidebar-open')
    } else {
      document.body.classList.remove('sidebar-open')
    }
  }, [isOpen])

  return (
    <SidebarToggleContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </SidebarToggleContext.Provider>
  )
}
