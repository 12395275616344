/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Countdown from 'react-countdown-now'
import TronWeb from 'tronweb'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import moment from 'moment'
import axios from 'axios'

import {
  tspcontractAddress,
  contractAddress,
  tokencontractAddress,
} from '../utils/ContractAddress'

import { AppContext } from '../context'
import TspFreezeModal from './tspFreezeModal'
import TspUnfreezeModal from './tspUnfreezeModal'
import { useAccount } from '../context/AccountProvider'
import { useTSPToken } from '../context/TSPProvider'
import SkeletonLoader from '../components/SkeletonLoader'
const tomorrow = moment(new Date()).add(1, 'days')
const timerTimestamp = new Date(tomorrow).getTime()

const TSPdividend = () => {
  const context = useContext(AppContext)
  const { accountAddress, accountBalance, isUserNotloggedIn } = useAccount()
  const {
    onSubmitTSPToken,
    tspToken,
    onSbumitTSPUnfreezeToken,
    tspUnfreezeToken,
    onSubmitTspHolderShare,
    tspHolderShare,
    onSubmitTspFrozen,
    tspTotalFrozen,
    onSubmitTspRecieveAmount,
    tspReceiveAmount,
    isRecieveBalanceLoading,
    setIsRecieveBalanceLoading,
  } = useTSPToken()
  const {
    setTspTotalFrozen,
    setTsptspDividend,
    setTspTotalmineyet,
    setTspHolderShare,
    settspsweepToken,
    setUsertspFreezeToken,
    tspDividend,
    tspholdershare,
    tspfrezeeamount,
    setTspRecieve,
    setUpdateTSPUnfreezeFlag,

    tspReceive,

    tspUnfreezeclick,
    tspUnfreezeTime,
    tspUnfreezeTimmer,
    unfreezedice,
  } = context
  const [dividendtimer, setDividendtimer] = useState(0)
  const [disabledfreezebtn, setDisabledfreezebtn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [uloading, setUloading] = useState(false)
  const [freezeModalOpen, setFreezeModalOpen] = useState(false)
  const [unfreezeModalOpen, setUnfreezeModalOpen] = useState(false)
  const [poolDataLoading, setPoolDataLoading] = useState(false)
  const [timerDataLoading, setTimerDataLoading] = useState(false)
  const [tspPublicData, setTspPublicData] = useState({})

  const freezeModalOpenHandler = () => setFreezeModalOpen(true)
  const freezeModalCloseHandler = () => setFreezeModalOpen(false)
  const unfreezeModalOpenHandler = () => setUnfreezeModalOpen(true)
  const unfreezeModalCloseHandler = () => setUnfreezeModalOpen(false)

  const tsptotalFrozen = async () => {
    try {
      let tokencontract = await window.tronWeb.contract().at(tspcontractAddress)
      const totalfrozen = await tokencontract.frozenTotal().call()
      const total = +(totalfrozen / 10 ** 8)

      setTspTotalFrozen(total)
      onSubmitTspFrozen(total)
    } catch (error) {
      console.log(error)
    }
  }
  const tspFetchDividentPoll = async () => {
    // setPoolDataLoading(true)
    try {
      let contract = await window.tronWeb.contract().at(tspcontractAddress)
      const ticketbal = await contract.dividendPool().call()

      setTsptspDividend(TronWeb.fromSun(parseInt(ticketbal, 10)))
      // setPoolDataLoading(false)
    } catch (error) {
      // console.log(error);
      // setPoolDataLoading(false)
    }
  }

  const tsptotalMinedYet = async () => {
    try {
      let tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      const total = await tokencontract.totalTokenMinedYet().call()
      const totalmined = +total / 10 ** 8

      setTspTotalmineyet(totalmined)
    } catch (error) {
      console.log(error)
    }
  }

  const tspHolderShareHandler = async () => {
    try {
      const contract = await window.tronWeb.contract().at(contractAddress)
      const share = await contract.tspHolderShare().call()
      const shareTemp = +(share / 10 ** 6)

      setTspHolderShare(shareTemp)
      onSubmitTspHolderShare(shareTemp)
    } catch (error) {
      console.log(error)
    }
  }

  const tspsweepTokenHandler = async () => {
    try {
      let tokencontract = await window.tronWeb.contract().at(tspcontractAddress)
      const sweep = await tokencontract.balanceOf(accountAddress).call()
      const sweeptoken = +sweep

      window.consoleClearHandler(sweeptoken, 'sweeptoken')
      onSubmitTSPToken(sweeptoken)

      settspsweepToken(sweeptoken)
    } catch (error) {
      console.log(error)
    }
  }

  const tspfreezeToken = async () => {
    try {
      let tokencontract = await window.tronWeb.contract().at(tspcontractAddress)
      const freeze = await tokencontract.frozenBalance(accountAddress).call()
      const freezetoken = +freeze / 10 ** 8

      setUsertspFreezeToken(freezetoken)
      onSbumitTSPUnfreezeToken(freezetoken)
    } catch (error) {
      console.log(error, 'errorerrorerror')
    }
  }

  const tspReceiveHandler = useCallback(async () => {
    setIsRecieveBalanceLoading(true)
    try {
      const tspcontract = await window.tronWeb.contract().at(tspcontractAddress)

      const percentvalue = await tspcontract.divDistributePercentage().call()

      const percent = parseInt(percentvalue._hex, 16)
      let receivebal = 0
      receivebal =
        (((+tspDividend + tspHolderShare) * percent) / 100 / tspTotalFrozen) *
        tspUnfreezeToken

      setTspRecieve(receivebal)
      onSubmitTspRecieveAmount(receivebal)
      setIsRecieveBalanceLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [tspDividend, tspHolderShare, tspTotalFrozen, tspUnfreezeToken])

  const cancelunfreeze = async () => {
    try {
      const tspcontract = await window.tronWeb.contract().at(tspcontractAddress)
      await tspcontract.cancelUnfreeze().send()

      ToastsStore.success('Cancel Unstake Successfully')

      setUpdateTSPUnfreezeFlag(false)
      tspfreezeToken()
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const dividendDistributionTime = async () => {
    setTimerDataLoading(true)
    try {
      const tspcontract = await window.tronWeb.contract().at(tspcontractAddress)
      let freezeblockTime = await tspcontract.freezeTokenBlockTime().call()
      freezeblockTime = +freezeblockTime
      let time = await tspcontract.dividendDistributionTime().call()
      time = +time * 1000
      const buttonfreezetime = time - freezeblockTime * 1000 - Date.now()
      if (buttonfreezetime < 0) {
        setDisabledfreezebtn(true)
      } else {
        setDisabledfreezebtn(false)
      }
      setDividendtimer(time)
      setTimerDataLoading(false)
    } catch (error) {
      console.log(error)
      setTimerDataLoading(false)
    }
  }

  useEffect(() => {
    tspReceiveHandler()
  }, [tspReceiveHandler])

  useEffect(() => {
    tspFetchDividentPoll()
    tsptotalFrozen()
    tsptotalMinedYet()
    tspHolderShareHandler()
    tspsweepTokenHandler()
    tspfreezeToken()
    //dividendDistributionTime()
  }, [])

  const getPublicDividendDataHandler = async () => {
    setTimerDataLoading(true)
    setPoolDataLoading(true)
    const res = await axios.get(`tron/data/getPublicDividendData`)
    const { data } = res
    if (data.status) {
      const tspData = data.data.find((p) => p.token === 'TSP')
      setTspPublicData(tspData)
      setDividendtimer(tspData.distributionTime * 1000)
      setTimerDataLoading(false)
      setPoolDataLoading(false)
    } else {
      setTimerDataLoading(false)
      setPoolDataLoading(false)
    }

    console.log(data, 'resData')
  }

  useEffect(() => {
    getPublicDividendDataHandler()
  }, [])

  console.log(
    new Date(dividendtimer),
    moment(new Date(dividendtimer)).isBefore(moment()),
    'dividendtimer',
  )

  return (
    <>
      <div>
        <div className="accordion accordion--space-between" id="accordionFaqs">
          <div className="w-100">
            <div
              id="collapse-0"
              className="collapse show"
              aria-labelledby="heading-0"
              data-parent="#accordionFaqs"
            >
              <div className="card__content text-center">
                <div
                  style={{
                    background: '#000',
                    padding: '40px 10px',
                    borderRadius: '5px',
                  }}
                >
                  Current Dividend Pool
                  <div className="col text-center">
                    <h5 className="widget-game-result__score-result widget-game-result__score-result--winner">
                      {poolDataLoading ? (
                        <SkeletonLoader width="100px" />
                      ) : (
                        tspPublicData?.availableDividend?.toFixed(2) + 'Trx'
                      )}
                    </h5>
                  </div>
                  <>
                    {timerDataLoading ? (
                      <SkeletonLoader width="100px" />
                    ) : (
                      <>
                        {!moment(new Date(dividendtimer)).isBefore(moment()) ? (
                          <div className="col text-center">
                            <h4 className="widget-game-result__score-result widget-game-result__score-result--winner">
                              <Countdown date={dividendtimer} />
                            </h4>
                          </div>
                        ) : (
                          <h4>dividend is being distributed </h4>
                        )}
                      </>
                    )}
                  </>
                  {/* <div className="col text-center">
                    <h5 className="widget-game-result__score-result widget-game-result__score-result--winner">
                      {tspDividend}Trx
                    </h5>
                  </div>
                  {dividendtimer - Date.now() >= 0 ? (
                    <div className="col text-center">
                      <h4 className="widget-game-result__score-result widget-game-result__score-result--winner">
                        <Countdown date={dividendtimer} />{' '}
                      </h4>
                    </div>
                  ) : (
                    <h4>dividend is being distributed</h4>
                  )} */}
                  <p>
                    There is total of {tspPublicData?.totalFrozen?.toFixed(2)}{' '}
                    TSP Token staked in the network.
                  </p>
                  {isUserNotloggedIn ? (
                    <button className="btn btn-outline-warning" type="button">
                      You will receive <>0 TRX</>
                    </button>
                  ) : (
                    <>
                      {isRecieveBalanceLoading ? (
                        <SkeletonLoader width="200px" height="40px" />
                      ) : (
                        <button
                          className="btn btn-outline-warning"
                          type="button"
                        >
                          You will receive{' '}
                          <>
                            {!isNaN(tspReceiveAmount)
                              ? tspReceiveAmount.toFixed(3)
                              : 0}{' '}
                            TRX
                          </>
                        </button>
                      )}
                    </>
                  )}
                  <div className="row mt-4">
                    <div className="col-sm-2" />
                    <div className="col-sm-8 text-center">
                      <div className="box card">
                        <div className="card-body">
                          <a
                            className="info_a info_icon"
                            href="#!"
                            data-class="freez_tooltip"
                            data-html="true"
                            data-tip="Tron number indicates the dividend that will be added to the TSP contract for TSP token holders."
                          >
                            <i class="fas fa-info-circle"></i>
                          </a>

                          <h5 className="mb-0">{tspholdershare} TRX </h5>
                          <p> Lottery</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row mt-1">
                  <div className="col-sm-6 col-6 text-center">
                    <div className="box card">
                      <div className="card-body">
                        <h5 className="mb-0">
                          {(tspToken / 10 ** 8).toFixed(2)} TSP
                        </h5>
                        <p>Available</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start  justify-content-center">
                      {(tspToken / 10 ** 8).toFixed(2) > 0 ? (
                        !loading ? (
                          <button
                            type="button"
                            className="btn btn-primary btn-block mr-1"
                            onClick={freezeModalOpenHandler}
                            disabled={disabledfreezebtn}
                          >
                            Stake
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary btn-block mr-1"
                          >
                            <i className="fa fa-refresh fa-spin" />
                          </button>
                        )
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary btn-block mr-1"
                          disabled
                        >
                          {' '}
                          Stake
                        </button>
                      )}
                      <a
                        className="info_a"
                        href="#!"
                        data-class="freez_tooltip"
                        data-html="true"
                        data-tip="
                        <ul>
                        <li>1. Minimum of 1 TSP to stake. Paying out TSP drops takes roughy 1-2 minutes after the countdown ends. You will not be able to stake during this time.</li>
                       <li>2. TSP must be staked to get TSP drops. TSP that is staked before the countdown ends will receive TSP drops. It is recommended to stake your TSP at least 10 minutes before the countdown ends.</li>
                       <li>3. Withdrawing and freezing TSP will consume a small amount of TRX (roughly 0.5-0.8TRX). It is recommended to only operate when needed.</li>
                       <li>4. Unfreezing TSP requires 24 hours to be deposited into your wallet. TSP that is being unstaked will not receive TSP drops.</li>
                       </ul>
                        "
                      >
                        <i className="fas fa-question-circle" />
                      </a>
                      <ReactTooltip />
                    </div>
                    {disabledfreezebtn ? (
                      <p style={{ fontSize: 13 }}>
                        (Freezing not allowed before 10 min dividend
                        distribution )
                      </p>
                    ) : null}
                  </div>
                  <div className="col-sm-6 col-6 text-center">
                    <div className="card box">
                      <div className="card-body">
                        <h5 className="mb-0">
                          {tspUnfreezeToken.toFixed(2)} TSP
                        </h5>
                        <p>Staked</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start  justify-content-center">
                      {tspUnfreezeToken.toFixed(2) > 0 ? (
                        !uloading ? (
                          <button
                            type="button"
                            className="btn btn-info btn-block mr-1"
                            onClick={unfreezeModalOpenHandler}
                          >
                            Unstake
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary btn-block mr-1"
                          >
                            <i className="fa fa-refresh fa-spin" />
                          </button>
                        )
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary btn-block mr-1"
                          disabled
                        >
                          {' '}
                          Unstake
                        </button>
                      )}
                      <a
                        className="info_a"
                        href="#!"
                        data-class="freez_tooltip"
                        data-html="true"
                        data-tip="
                        <ul>
                          <li>Unfreezing TSP token requires 24 hours to be deposited into your wallet. TSP that is being unstaked will not receive TSP drops.</li>
                       </ul>
                        "
                      >
                        <i className="fas fa-question-circle" />
                      </a>
                      <ReactTooltip />
                    </div>
                  </div>
                </div>

                {tspUnfreezeclick ? (
                  <div id="bottom_timer_col">
                    <div className="row mt-12 " style={{ marginTop: 20 }}>
                      <div className="col-sm-12 text-center">
                        <div className="box card">
                          <div className="card-body unfreeze_body">
                            <div className="row mt-12">
                              <div className="col-md-4">
                                <h5>unstake time:</h5>
                                <h4>
                                  {new Date(tspUnfreezeTime).toLocaleString()}
                                </h4>
                              </div>
                              <div className="col-md-4">
                                <h5>Stake Amount</h5>
                                <h4>
                                  {Math.floor((unfreezedice / 10 ** 8) * 100) /
                                    100}{' '}
                                  <small className="ml-1">TSP</small>
                                </h4>
                              </div>
                              <div className="col-md-4">
                                <h5>time till unstake</h5>
                                <h4 className="widget-game-result__score-result widget-game-result__score-result--winner">
                                  <Countdown date={tspUnfreezeTimmer} />{' '}
                                </h4>
                                <button
                                  type="button"
                                  className="cancel_btn mr-1"
                                  onClick={cancelunfreeze}
                                >
                                  <i className="mdi mdi-close" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <TspFreezeModal
          accountAddress={accountAddress}
          tsp={parseInt((tspToken / 10 ** 8).toFixed(2))}
          show={freezeModalOpen}
          close={freezeModalCloseHandler}
        />
        <TspUnfreezeModal
          accountAddress={accountAddress}
          tspUnfreeze={parseInt(tspUnfreezeToken.toFixed(2))}
          show={unfreezeModalOpen}
          close={unfreezeModalCloseHandler}
        />
        {/* <!-- Content / End --> */}
        {/* <Footer/> */}
        <ToastsContainer store={ToastsStore} />
      </div>
    </>
  )
}

export default TSPdividend
