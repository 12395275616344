import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'

const CustomInput = ({
  iconLeft,
  iconRight,
  value,
  name,
  onChange,
  placeholder,
  type,
  onClick,
}) => {
  return (
    <>
      <div className="input_box">
        <InputGroup className="custom_input">
          {iconLeft && (
            <InputGroup.Text>
              <i className={`icon ${iconLeft}`} />
            </InputGroup.Text>
          )}

          <Form.Control
            type={type}
            value={value}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
          />
          {iconRight && (
            <InputGroup.Text onClick={onClick && onClick}>
              <i className={`icon ${iconRight}`} />
            </InputGroup.Text>
          )}
        </InputGroup>
      </div>
    </>
  )
}

export default CustomInput
