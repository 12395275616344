import React, { createContext, useContext, useEffect } from 'react'
import { useState } from 'react'
import { getUserBalance } from '../includes/Service'

const AccountContext = createContext()

export function useAccount() {
  return useContext(AccountContext)
}

export default function AccountProvider({ children }) {
  const [accountAddress, setAccountAddress] = useState('')
  const [walletAddress, setWalletAddress] = useState('')
  const [accountBalance, setAccountBalance] = useState('')
  const [noWalletDetect, setNoWalletDetect] = useState(false)
  const [isUserNotloggedIn, setIsUserNotLoggedIn] = useState(false)
  const [refferalCode, setRefferalCode] = useState('')
  const [walletBalance, setWalletBalance] = useState(0)
  const [totalTRX, setTotalTRX] = useState(0)
  const [whlBalance, setWhlBalance] = useState(0)
  const [userBalanceData, setUserBalanceData] = useState({})
  const [tokenInUsd, setTokenInUsd] = useState(0)
  const [selectedToken, setSelectedToken] = useState('TRX')
  const [resultTimer, setResultTimer] = useState(0)

  const onSubmitAccountAddress = (data) => {
    setAccountAddress(data)
  }
  const onSubmitWalletAddress = (data) => {
    setWalletAddress(data)
  }
  const onSubmitAccountBalance = (data) => {
    setAccountBalance(data)
  }
  const onSubmitNoWalletDetect = (flag) => {
    setNoWalletDetect(flag)
  }

  const onSubmitUserLoggedIn = (flag) => {
    setIsUserNotLoggedIn(flag)
  }
  const onSubmitRefferalCode = (refCode) => {
    setRefferalCode(refCode)
  }
  const onSubmitWalletBalance = (amount) => {
    setWalletBalance(amount)
  }

  const onSubmitWhlBalance = (amount) => {
    setWhlBalance(amount)
  }

  const onSubmitUserBalanceData = (data) => {
    setUserBalanceData(data)
  }
  const onSetSelectedToken =(data)=>{
    setSelectedToken(data)
  }
  const onSetResultTimer =(data)=>{
    console.log(data,"data", resultTimer,"resultimer")
    setResultTimer(data)
  }

  const getUserBalance = async () => {
    const res = await getUserBalance()
    setUserBalanceData(res?.User_info || {})
    const userBalanceList = [
      ...res?.User_info?.user_balance,
      ...res?.User_info?.token_balance,
    ]

    for (const innerData of userBalanceList) {
      if (innerData.token === 'WHL') {
        onSubmitWhlBalance(innerData.balance)
      }
    }
  }

  const getBalance = async () => {
    //if wallet installed and logged , getting TRX token balance
    if (window.tronWeb && window.tronWeb.ready) {
      let walletBalances = await window.tronWeb.trx.getAccount(
        window.tronWeb.defaultAddress.base58,
      )
      return walletBalances
    } else {
      return 0
    }
  }

  const getWalletDetails = async () => {
    if (window.tronWeb) {
      //checking if wallet injected
      if (window.tronWeb.ready) {
        let tempBalance = await getBalance()
        //  let tempFrozenBalance = 0

        if (!tempBalance.balance) {
          tempBalance.balance = 0
        }

        //we have wallet and we are logged in
        window.consoleClearHandler('WALLET CONNECTED')

        setAccountAddress(window.tronWeb.defaultAddress.base58)
      } else {
      }
    } else {
      window.consoleClearHandler('WALLET NOT DETECTED')
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    const loginType = localStorage.getItem('loginType')
    if (loginType === 'tronlink') {
      const interval = setInterval(async () => {
        getWalletDetails()
        //wallet checking interval 2sec
      }, 2000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [])

  return (
    <AccountContext.Provider
      value={{
        onSubmitAccountAddress,
        onSubmitAccountBalance,
        accountAddress,
        accountBalance,
        noWalletDetect,
        isUserNotloggedIn,
        refferalCode,
        onSubmitNoWalletDetect,
        onSubmitUserLoggedIn,
        onSubmitRefferalCode,
        onSubmitWalletBalance,
        walletBalance,
        totalTRX,
        setTotalTRX,
        onSubmitWalletAddress,
        walletAddress,
        onSubmitWhlBalance,
        whlBalance,
        onSubmitUserBalanceData,
        userBalanceData,
        getUserBalance,
        setTokenInUsd,
        tokenInUsd,
        selectedToken,
        onSetSelectedToken,
        resultTimer,
        onSetResultTimer
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}
