/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import './home_slider.css'

class HomeSlider extends Component {
  componentDidMount() {}

  render() {
    const settings = {
      className: 'center',
      centerMode: false,
      infinite: true,
      autoplay: true,
      dots: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            dots: false,
          },
        },
      ],
    }

    return (
      <div className="home_slider">
        {/* <div className="bg_overlay" />
        <iframe
          src="https://player.vimeo.com/video/379973802?background=1&autoplay=1&loop=1&byline=0&title=0"
          frameBorder="0"
          allowFullScreen
          title=""
        /> */}
        <div className="slider_container">
          <Slider {...settings}>
          <a href="/dapp/lotto">
              <div className="slide_item slide2" />
            </a>
            <a href="/dapp/lotto">
              <div className="slide_item slide1" />
            </a>
            
            <a href="/partner/mtw/lotto/2">
              <div className="slide_item slide3" />
            </a>
            
          </Slider>
        </div>
      </div>
    )
  }
}

export default HomeSlider
