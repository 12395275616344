import React, { Component } from 'react'
// import axios from 'axios'
import TronWeb from 'tronweb'

import { contractAddress } from '../utils/ContractAddress'
// import StickyBox from 'react-sticky-box'
// import config from '../../config.json'
import { AppContext } from '../context'
import './profile.css'
// import Sidenav from './sidenav'
import Account from './account'
// import DailyTask from './DailyTask'
// import ReferralHistory from './referral_history'
// import DealerReferralHistory from './dealerreferral_history'
// import Dividend from './Dividend'
// import TronsweepToken from './Tronseep_Token'
let contract
class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // userinfo: [],
      isChatOpen: false,
      gameWalletAmount: 0,
    }
  }

  gameWaltbalance = async () => {
    const { setUpdateGameWallet } = this.context
    const { accountAddress } = this.context

    try {
      contract = await window.tronWeb.contract().at(contractAddress)
      const valtBal = await contract.userVaultBalance(accountAddress).call()
      const gameWaltbal = TronWeb.fromSun(valtBal)
      setUpdateGameWallet(gameWaltbal)
      this.setState({
        gameWalletAmount: gameWaltbal,
      })
    } catch (error) {
      console.log(error, 'error')
    }
  }

  componentDidMount() {
    // const { accountAddress } = this.context
    setTimeout(() => {
      this.gameWaltbalance()
    }, 3000)
    // axios
    //   .get(`tron/user/player/${accountAddress}`, {})
    //   .then(() => {
    //     // this.setState({ userinfo: res.data.data });
    //   })
    //   .catch((res) => {
    //     console.log(res)
    //   })

    // // eslint-disable-next-line func-names
    // document.getElementById('account_nav').addEventListener('click', (e) => {
    //   if (e.target && e.target.nodeName === 'A') {
    //     this.closeNav()
    //   }
    // })
  }

  chatBoxHandler = () => {
    const { isChatOpen } = this.state
    this.setState({
      isChatOpen: !isChatOpen,
    })
  }

  closeNav = () => {
    this.setState({ isChatOpen: false })
  }

  render() {
    const { accountAddress, accountBalance } = this.context

    return (
      <>
        <Account
          accountAddress={accountAddress}
          accountBalance={accountBalance}
        />
      </>
    )
  }
}

Profile.contextType = AppContext
export default Profile
