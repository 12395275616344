import React from 'react'

export default function UserActivity(props) {
    return (
        <>
            <h5 className="title mt-5">Account Activity</h5>
            <div className="card mb-3 transaction_card">
                <div className="card-body">
                    <div className="row">
                        <div className="table-responsive">
                            <table className="table table-striped table-dark referral_table_tb">
                                <thead>
                                    <tr>
                                        <th scope="col">S.N</th>
                                        <th scope="col">Login IP</th>
                                        <th scope="col">Date and TIme</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>132.23.23.09</td>
                                        <td>12/01/2022</td>
                                        <td>Active</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
