import React, { Component } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
// import tron from '../../public/images/icons/tron_icon.png'
// import gambling from "../../public/images/icons/gambling_dark.svg";
// import coins from "../../public/images/icons/coins_dark.svg";
// import user from "../public/images/icons/user_dark.svg";
// import referral from "../public/images/icons/referral_dark.svg";
// import more from "../public/images/icons/more_dark.svg";
// import live from "../public/images/icons/live_dark.svg";
// import userDP from "../public/images/icons/avatar.svg";
// import token from "../public/images/icons/token.svg";
import './tokensale.css'
import RoadMap from './RoadMap'

export default class TokenSale extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  handelChange = (event) => {
    this.setState({ value: event.target.value })
  }

  render() {
    const now = 60
    const { value } = this.state

    return (
      <>
        <div className="main_container">
          <div className="token_banner">
          

            <div className="sale_content">
              <div className="container  text-center">
                <h1>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
                </h1>
                <p>
                  Nam in turpis eu libero porttitor convallis. Phasellus ex
                  ligula, pulvinar at felis vel, vestibulum elementum augue. In
                  et tortor felis. Duis ut molestie ipsum, id scelerisque
                  mauris. Cras a augue a magna commod Nam in turpis eu libero
                  porttitor convallis. Phasellus ex ligula, pulvinar at felis
                  vel, vestibulum elementum augue. In et tortor felis. Duis ut
                </p>
                <button className="btn btn-primary" type="button">
                  Token Sale
                </button>
              </div>
            </div>
          </div>
          <section
            className="bg-white section_padding about_section"
            id="about"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-7 margin-top-60">
                  <div className="heading margin-bottom-20">
                    <h4>Best Blockchain &amp; Better Than Any Blockchain</h4>
                  </div>
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga.
                  </p>
                  <p>
                    Cupiditate non provident, similique sunt in culpa qui
                    officia deserunt mollitia animi, id est laborum et dolorum
                    fuga.
                  </p>
                </div>
                <div className="col-md-5 text-right">
                  <img
                    src="https://cdn.pixabay.com/photo/2017/06/06/23/53/poker-2378914_1280.jpg"
                    alt=""
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="ib-icon">
                    {' '}
                    <i className="flaticon-smartphone" />{' '}
                  </div>
                  <div className="ib-info">
                    <h4 className="h6">Peer-to-Peer Transactions</h4>
                    <p>
                      Contrary to popular belief , Lorem Ipsum is not simply
                      random text. It has roots in a piece
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="ib-icon">
                    {' '}
                    <i className="flaticon-flat-world-map" />{' '}
                  </div>
                  <div className="ib-info">
                    <h4 className="h6">Borderless Payments</h4>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem not simply random accusantium
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="ib-icon">
                    {' '}
                    <i className="flaticon-secure-shield" />{' '}
                  </div>
                  <div className="ib-info">
                    <h4 className="h6">Fully Protection</h4>
                    <p>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="ib-icon">
                    {' '}
                    <i className="flaticon-credit-card" />{' '}
                  </div>
                  <div className="ib-info">
                    <h4 className="h6">Smart Money</h4>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="ib-icon">
                    {' '}
                    <i className="flaticon-wallet" />{' '}
                  </div>
                  <div className="ib-info">
                    <h4 className="h6">Secure Wallet</h4>
                    <p>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="ib-icon">
                    {' '}
                    <i className="flaticon-money" />{' '}
                  </div>
                  <div className="ib-info">
                    <h4 className="h6">Easy To buy &amp; Sell</h4>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="dark section_padding " id="token">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h3>Lorem ipsum dolor sit amet, consectetur</h3>
                  <p>
                    ICO Crypto token will be released on the basis of Ethereum
                    and Bitocin platform. It’s compatibility of the token with
                    third-party services wallets, exchanges etc, and provides
                    easy-to-use integration.
                  </p>

                  <ProgressBar now={now} label={`${now}%`} />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse ac lorem vel sapien bibendum dapibus. Etiam nunc
                    orci, lobortis eget tortor vitae, fringilla lacinia lorem.
                    Pellentesque consequat justo vel nibh accumsan,Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit. Suspendisse ac
                    lorem vel sapien bibendum dapibus. Etiam nunc orci, lobortis
                    eget tortor vitae, fringilla lacinia lorem. Pellentesque
                    consequat justo vel nibh accumsan,
                  </p>
                  {/* <div class="row">
                    <div class="col">
                      <h6> Now Distribution</h6>
                      <span>2,000,000</span>{" "}
                    </div>
                    <div class="col">
                      <h6> 99,910</h6>
                      <span>ETH Received</span>{" "}
                    </div>
                    <div class="col">
                      <h6> 99,910</h6>
                      <span>BTC Received</span>{" "}
                    </div>
                  </div> */}
                </div>

                <div className="col-lg-6 cal_col">
                  <h3>Calculate</h3>
                  <p className="lead">
                    Minimum purchase is 50 Coins tokens. Get a bonus from 5% to
                    25% on every token purchase
                  </p>
                  <div className="calculator_col">
                    <form className="d-flex align-items-center justify-content-center token_sale_form">
                      <div className="form-group">
                        <span>Amount You Pay</span>
                        <input
                          type="text"
                          value={value}
                          onChange={this.handelChange}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group tsp_h5">
                        <span>&nbsp;</span>
                        <h5>TSP =</h5>
                      </div>
                      <div className="form-group">
                        <span>Amount You Get</span>
                        <input
                          type="text"
                          value={value / 10}
                          className="form-control get_tsp"
                          readOnly
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-white section_padding roadmap_section">
            <div className="container">
              <RoadMap />
            </div>
          </section>
        </div>
      </>
    )
  }
}
