import React, { useContext, useEffect, useState } from 'react'
import {
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Modal,
  ProgressBar,
  Row,
  Table,
} from 'react-bootstrap'
import Countdown from 'react-countdown-now'
import moment from 'moment'
import coins from '../public/images/lotto_coin.png'
import axios from 'axios'
import SkeletonLoader from '../components/SkeletonLoader'
import { Link, useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import { AppContext } from '../context'
import LottoDividend from '../dynamicLotto/components/lottoDividend'
import { useAccount } from '../context/AccountProvider'

const tomorrow = moment(new Date()).add(2, 'days')
const timerTimestamp = new Date(tomorrow).getTime()

const settings = {
  className: 'center',
  centerMode: true,
  infinite: true,
  autoplay: true,
  dots: true,
  arrows: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        dots: false,
      },
    },
  ],
}

const dailyReturn = 0.0033 * 50

const allLottoList = [
  {
    id: 1,
    ticker: 'TRX',
    currency: 'Tron',
    name: 'Tron Mega Lotto',
    maxTickets: 60000000,
    ticketCost: 100,
    dividendToken: 'LOTTO',
  },
  {
    id: 2,
    currency: 'MTW',
    ticker: 'MTW',
    name: 'MTW Mega Lotto',
    maxTickets: 60000000,
    ticketCost: 1,
    dividendToken: 'WHL',
  },
  {
    id: 3,
    currency: 'TRX',
    ticker: 'PLAY',
    name: 'Play Game',
    maxTickets: 0,
    ticketCost: 0,
    dividendToken: 'PLAY',
  },
]

const StakeAndEarn = () => {
  const context = useContext(AppContext)
  const {
    setUpdateUnfreezeFlag,
    setUpdateTicketPurchase,

    gameWalt,
    setWinningBoxData,
    setTotalFrozen,
    setUserFreezeToken,
    setUpdateGameWallet,
    setUpdatedSweepToken,
    buyTicket,
    setReceive,

    setUnfreezeDice,
    isDealer,
    totalfrozen,
    receive,

    lottoModalOpen,
    lottoModalOpenHandler,
    lottoModalCloseHandler,
  } = context
  const {
    accountAddress,
    isUserNotloggedIn,
    onSubmitWalletBalance,
    walletBalance,
  } = useAccount()
  const history = useHistory()
  const [lottoPublicData, setLottoPublicData] = useState({})
  const [isPublicDataLoading, setIsPublicDataLoading] = useState(false)
  const [dividendtimer, setDividendtimer] = useState(0)
  const [showCalculate, setShowCalculate] = useState(false)
  const [tokenAmount, setTokenAmount] = useState(100)
  const [returnPayout, setReturnPayout] = useState({
    oneDay: tokenAmount * dailyReturn,
    oneWeek: tokenAmount * dailyReturn * 7,
    perMonth: tokenAmount * dailyReturn * 30,
    perQuarter: tokenAmount * dailyReturn * 30 * 3,
    perYear: tokenAmount * dailyReturn * 30 * 12,
  })
  const [returnPayoutUSD, setReturnPayoutUSD] = useState({
    oneDay: tokenAmount * dailyReturn,
    oneWeek: tokenAmount * dailyReturn * 7,
    perMonth: tokenAmount * dailyReturn * 30,
    perQuarter: tokenAmount * dailyReturn * 30 * 3,
    perYear: tokenAmount * dailyReturn * 30 * 12,
  })

  const handleCloseCalculate = () => setShowCalculate(false)
  const handleOpenCalculate = () => setShowCalculate(true)

  const handleAmountChange = (e) => {
    const { value } = e.target
    setTokenAmount(value)
    if (value) {
      setReturnPayout({
        oneDay: +value * dailyReturn,
        oneWeek: +value * dailyReturn * 7,
        perMonth: +value * dailyReturn * 30,
        perQuarter: +value * dailyReturn * 30 * 3,
        perYear: +value * dailyReturn * 30 * 12,
      })
      setReturnPayoutUSD({
        oneDay: +value * dailyReturn,
        oneWeek: +value * dailyReturn * 7,
        perMonth: +value * dailyReturn * 30,
        perQuarter: +value * dailyReturn * 30 * 3,
        perYear: +value * dailyReturn * 30 * 12,
      })
    }
  }

  const getPublicDividendDataHandler = async () => {
    setIsPublicDataLoading(true)
    //setIsRecieveBalanceLoading(true)
    const res = await axios.get(
      process.env.REACT_APP_AUTH + `/user/public-dividend/PLAY`,
    )
    setLottoPublicData(res.data.data.dividend_pools[0])
    setDividendtimer(res.data.data.dividend_pools[0].distributionTime * 1000)
    setIsPublicDataLoading(false)
  }

  useEffect(() => {
    getPublicDividendDataHandler()
  }, [])

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue
  }

  const lotteryData = allLottoList.find((p) => p.id == 3)

  return (
    <>
      <div className="stake_wrap">
        <Container>
          <div className="head stake_slider pb-0">
            <div className="container-fluid slider_container">
              <Slider {...settings}>
                {/* <div className="slide_item slide3" /> */}
                <Link to={'/dapp/lotto'}>
                  <div className="slide_item slide1" />
                </Link>
                <Link to={'/dapp/lotto'}>
                  <div className="slide_item slide2" />
                </Link>
              </Slider>
            </div>
          </div>
          <div className="strip">
            <Row>
              <Col md={3}>
                <h5>Total Staking pool</h5>
                <strong>
                  <p>
                    {isPublicDataLoading ? (
                      <SkeletonLoader width="100px" />
                    ) : (
                      lottoPublicData?.availableDividend?.toFixed(2) + 'TRX'
                    )}
                  </p>
                </strong>
              </Col>
              <Col md={3}>
                <h5>Total next Payout</h5>
                <strong>
                  <p>
                    {isPublicDataLoading ? (
                      <SkeletonLoader width="100px" />
                    ) : lottoPublicData?.availableDividend > 0 ? (
                      (lottoPublicData?.availableDividend * 0.95).toFixed(2) +
                      'TRX'
                    ) : (
                      '0 TRX'
                    )}
                  </p>
                </strong>
              </Col>
              <Col md={3}>
                <h5>Price distribution in</h5>
                <strong>
                  <Countdown date={dividendtimer} />
                </strong>
              </Col>
              <Col md={3} className="button_col_stake">
                <button
                  className="btn btn-primary "
                  onClick={lottoModalOpenHandler}
                >
                  Stake/Unstake
                </button>
              </Col>
            </Row>
          </div>
          {/* <div className="strip">
            <Row> */}
          {/* <Modal
        show={showCalculate}
        onHide={handleCloseCalculate}
        className="energy_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Estimated payout</Modal.Title>
        </Modal.Header>
        <Modal.Body> */}
          {/* <Col md={12} style={{ textAlign: 'left' }}>
                <Modal.Title className="mt-2">Estimated payout</Modal.Title>
                <p className="mt-4">
                  Calculate your profit from the PLAY tokens you have!
                </p>

                <p className="mt-2">
                  Enter the PLAY tokens number in the field and see the
                  estimated payouts in Tron. The more tickets you buy, the more
                  play token you get - the higher is staking rewards you get.
                </p>
              </Col>

              <Col md={6} style={{ textAlign: 'left' }}>
                <InputGroup className="mb-3 token_amount_group mt-3" autofocus>
                  <InputGroup.Text id="basic-addon1">
                    <img className="coin_img" src={coins} alt="" />
                  </InputGroup.Text>
                  <FormControl
                    value={tokenAmount}
                    onChange={handleAmountChange}
                    type="text"
                    autofocus={true}
                    placeholder="Play token amount"
                    className="w-50"
                  />
                </InputGroup>
              </Col>

              <Col md={12} style={{ textAlign: 'left' }}>
                <div class="mt-3 table-responsive">
                  <Table striped hover variant="dark">
                    <thead>
                      <tr>
                        <th>In a day</th>
                        <th>In a week</th>
                        <th>Per month</th>
                        <th>Per quarter</th>
                        <th>Per year</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{returnPayout.oneDay.toFixed(6)}TRX</td>
                        <td>{returnPayout.oneWeek.toFixed(6)}TRX</td>
                        <td>{returnPayout.perMonth.toFixed(6)}TRX</td>
                        <td>{returnPayout.perQuarter.toFixed(6)}TRX</td>
                        <td>{returnPayout.perYear.toFixed(6)}TRX</td>
                      </tr>
                    </tbody>
                  </Table> */}
          {/* <h6 className="mb-0 text-muted">Total in USD</h6>
            <Table striped hover variant="dark">
              <tbody>
                <tr>
                  <td>{returnPayoutUSD.oneDay.toFixed(6)}TRX</td>
                  <td>{returnPayoutUSD.oneWeek.toFixed(6)}TRX</td>
                  <td>{returnPayoutUSD.perMonth.toFixed(6)}TRX</td>
                  <td>{returnPayoutUSD.perQuarter.toFixed(6)}TRX</td>
                  <td>{returnPayoutUSD.perYear.toFixed(6)}TRX</td>
                </tr>
              </tbody>
            </Table> */}
          {/* </div>
              </Col>
              <Col md={3} style={{ textAlign: 'left' }}>
                <button
                  className="btn btn-primary btn-block btn-lg mt-4 mb-2"
                  onClick={() => history.push('/dapp/lotto')}
                >
                  Buy Ticket and Get Free Lotto
                </button>
              </Col> */}
          {/* </Modal.Body>
      </Modal> */}
          {/* </Row>
          </div> */}

          {/* <div className="strip">
            <Row>
              <Col md={12} style={{ textAlign: 'left' }}>
                <h4>Staking history</h4>

                <div class="mt-3 table-responsive">
                  <Table striped hover variant="dark">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Amount Distributed</th>
                        <th>Staked Tokens</th>
                        <th>Per 100 PLAY</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>16/08/2022</td>
                        <td>1200 TRX</td>
                        <td>120000 PLAY</td>
                        <td>120 TRX</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div> */}
        </Container>
      </div>
      <Modal
        show={lottoModalOpen}
        onHide={lottoModalCloseHandler}
        dialogClassName="dividend_modal "
      >
        <Modal.Header closeButton>
          <Modal.Title>{lotteryData?.dividendToken} Dividend</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LottoDividend lotteryData={lotteryData} fromStake />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default StakeAndEarn
