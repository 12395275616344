/* eslint-disable no-nested-ternary */
/* eslint-disable react/static-property-placement */

import React, { useContext, useEffect, useState } from 'react'

import TronWeb from 'tronweb'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import Modal from 'react-bootstrap/Modal'
import tron from '../../public/images/icons/tron_icon.png'
import {
  tokencontractAddress,
  contractAddress,
} from '../../utils/ContractAddress'

import { AppContext } from '../../context/index'
import { useAccount } from '../../context/AccountProvider'
import { useLottery } from '../../context/LotteryProvider'
import { freezeWHL } from '../Service'

const FreezeModal = (props) => {
  const { show, close, whlBalance } = props
  const { accountAddress, getUserBalance } = useAccount()
  const {
    freezeLottoTokens,
    onSubmitLottoTokens,
    onSubmitFreezeLottoTokens,
    onSubmitRecieveBalance,
    onSubmitTotalFrozenBalance,
    totalFrozenBalance,
  } = useLottery()
  const context = useContext(AppContext)
  const {
    setTotalFrozen,
    setUpdatedSweepToken,
    setUpdatedFrezeeAmount,
    setReceive,
  } = context
  const [reqFreezeToken, setReqFreezeToken] = useState(whlBalance)
  const [loading, setLoading] = useState(false)
  const [isBigValue, setIsBigValue] = useState(false)
  let dividend = 0
  // const [dividend, setDividend] = useState(0)

  const handleAll = () => {
    setReqFreezeToken(whlBalance)
  }
  const handleFreezeToken = (event) => {
    setReqFreezeToken(+event.target.value)

    if (event.target.value > whlBalance) {
      setIsBigValue(true)
    } else {
      setIsBigValue(false)
    }
  }

  const fetchDividentPoll = async () => {
    try {
      let contract = await window.tronWeb.contract().at(contractAddress)
      const ticketbal = await contract.dividendPool().call()
      const dividendValue = TronWeb.fromSun(parseInt(ticketbal, 10))
      dividend = dividendValue
      // setDividend(dividendValue)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const totalfrozenHandler = async () => {
    try {
      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      const totalfrozen = await tokencontract.frozenTotal().call()
      const total = +totalfrozen / 10 ** 8

      setTotalFrozen(total)
      onSubmitTotalFrozenBalance(total)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFreeze = async () => {
    const payload = {
      balance: reqFreezeToken,
      token: 'WHL',
    }

    const res = await freezeWHL(payload)
    if (res?.response) {
      return ToastsStore.error(
        res.response.data.message || 'Something went wrong',
      )
    }
    getUserBalance()
    close()
    ToastsStore.success('Frozen successfully')

    // if (sweepToken >= reqFreezeToken) {
    //   try {
    //     setLoading(true)
    //     const tokencontract = await window.tronWeb
    //       .contract()
    //       .at(tokencontractAddress)
    //     await tokencontract.freezeToken(reqFreezeToken * 10 ** 8).send()
    //     ToastsStore.success('Frozen successfully')

    //     axios
    //       .post(`tron/user/freeze-lotto`, {
    //         public_address: accountAddress,
    //       })
    //       .then(() => {})

    //     setTimeout(() => {
    //       sweepTokenHandler()
    //       freezeToken()
    //       receive()

    //       close()

    //       setLoading(false)
    //     }, 1000)
    //   } catch (error) {
    //     setLoading(false)
    //     ToastsStore.error('Transcation failed')
    //   }
    // } else {
    //   ToastsStore.error('Please enter valid amount')
    // }
  }

  const sweepTokenHandler = async () => {
    try {
      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      const sweep = await tokencontract.balanceOf(accountAddress).call()
      const sweeptoken = +sweep / 10 ** 8
      setUpdatedSweepToken(sweeptoken)
      onSubmitLottoTokens(sweeptoken)
      setReqFreezeToken(sweeptoken)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const freezeToken = async () => {
    try {
      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      const freeze = await tokencontract.frozenBalance(accountAddress).call()
      const freezetoken = +freeze / 10 ** 8
      setUpdatedFrezeeAmount(freezetoken)
      onSubmitFreezeLottoTokens(freezetoken)
    } catch (error) {}
  }

  const receive = async () => {
    try {
      const contract = await window.tronWeb.contract().at(contractAddress)
      const percentvalue = await contract.divDistributePercentage().call()
      const percent = +percentvalue
      const receivebal =
        (((+dividend * percent) / 100 / totalFrozenBalance) *
          freezeLottoTokens *
          10 ** 8) /
        10 ** 8

      setTimeout(() => {
        console.log(
          dividend,
          percent,
          totalFrozenBalance,
          freezeLottoTokens,
          'receivebal',
        )
      }, 1000)
      setReceive(receivebal)
      onSubmitRecieveBalance(receivebal)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchDividentPoll()
    totalfrozenHandler()
    sweepTokenHandler()
  }, [])

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={close}
          size="sm"
          dialogClassName="withdraw_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Freeze</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img
                      className="icon_img_trs"
                      height="30"
                      src={tron}
                      alt=""
                    />
                  </span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={reqFreezeToken}
                  onChange={handleFreezeToken}
                />
                <div
                  className="input-group-append"
                  aria-hidden
                  onClick={handleAll}
                >
                  <span className="input-group-text">
                    <a href="#!">All</a>
                  </span>
                </div>
                {isBigValue ? (
                  <small className="text-danger">
                    Input value can not be more than your available Lotto amount
                  </small>
                ) : null}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary 01"
              onClick={handleFreeze}
              disabled={whlBalance === 0}
            >
              {!loading ? 'Freeze' : <i className="fa fa-refresh fa-spin" />}
            </button>
          </Modal.Body>
        </Modal>

        <ToastsContainer store={ToastsStore} />
      </div>
    </>
  )
}
export default FreezeModal
