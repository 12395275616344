import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useSidebarToggle } from '../context/SidebarToggleProvider'

const FooterNavbar = () => {
  const { setIsOpen, isOpen } = useSidebarToggle()
  return (
    <>
      <div className="footer_navbar mobile_only">
        <Navbar bg="dark" variant="dark" fixed="bottom">
          <Nav>
            <a className="nav-link" href="/" onClick={() => setIsOpen(false)}>
              <i className="icon lobby_gray"></i>
              <p>All Games</p>
            </a>
            <NavLink
              className="nav-link"
              to="/dapp/lotto"
              onClick={() => setIsOpen(false)}
            >
              {' '}
              <i className="icon lotto"></i>
              <p>Lotto</p>
            </NavLink>
            {/* <NavLink
              activeClassName="active"
              className="nav-link"
              to="/chat"
              onClick={() => setIsOpen(false)}
            >
              {' '}
              <i className="icon chat"></i>
              <p>Chat</p>
            </NavLink> */}
            <a
              className="nav-link menu_link"
              href="#!"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              {' '}
              <i className="icon menu"></i>
              <p>Menu</p>
            </a>
          </Nav>
        </Navbar>
      </div>
    </>
  )
}

export default FooterNavbar
