import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'

import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import tron from '../public/images/icons/tron_icon.png'

import { AppContext } from '../context/index'
import {
  tokencontractAddress,
  tspcontractAddress,
} from '../utils/ContractAddress'
import { useAccount } from '../context/AccountProvider'
import { useTSPToken } from '../context/TSPProvider'
import { useLottery } from '../context/LotteryProvider'

let datalength = 20
const Dividend = () => {
  const context = useContext(AppContext)
  const {
    setUpdatedSweepToken,
    setUpdateTspSweepToken,
    tspfrezeeamount,
    frezeeamount,

    lottoModalOpenHandler,
  } = context
  const { accountAddress } = useAccount()
  const {
    onSubmitTSPToken,
    tspToken,
    onSbumitTSPUnfreezeToken,
    tspUnfreezeToken,
  } = useTSPToken()
  const {
    lottoTokens,
    freezeLottoTokens,
    onSubmitLottoTokens,
    onSubmitFreezeLottoTokens,
  } = useLottery()

  const [dividentdata, setDividentdata] = useState([])
  const [data, setData] = useState('')
  const [date, setDate] = useState([null, new Date()])
  const [Type, setType] = useState('lotto')
  const [show, setShow] = useState(true)
  const [isLottoSelected, setIsLottoSelected] = useState(false)
  const [isTspSelected, setIsTspSelected] = useState(false)
  const [totalLottoDividendSum, setTotalLottoDividendSum] = useState(0)
  const [totalTSPDividendSum, setTotalTSPDividendSum] = useState(0)
  const [sweepToken, setSweepToken] = useState(0)

  const handleChange = (e) => {
    setType(e.target.value)
  }

  useEffect(() => {
    onselectapiCall()
  }, [Type])

  const onselectapiCall = (loadmore = false) => {
    if (loadmore) {
      datalength += 20
    }

    if (Type === 'lotto') {
      setIsLottoSelected(true)
      setIsTspSelected(false)
    } else {
      setIsLottoSelected(false)
      setIsTspSelected(true)
    }

    axios
      .post(`tron/user/dividened`, {
        game: Type,
        publicAddress: accountAddress,
        date,
        dataLength: datalength,
      })
      .then((res) => {
        const resData = res.data.data

        setData(res.data.status)
        setDividentdata(resData)
        //  console.log(resData, 'd_resData')
        const totalSum = resData.reduce(
          (preValue, curValue) => preValue + curValue.amount,
          0,
        )

        if (Type === 'lotto') {
          setTotalLottoDividendSum(totalSum)
        } else {
          setTotalTSPDividendSum(totalSum)
        }

        // console.log(totalSum, 'totalSum')

        if (datalength > res.data.dat.length) {
          setShow(false)
        }
      })
      .catch((res) => {
        console.log(res, 'catch')
      })
  }

  const onDateChange = (date) => {
    setDate(date)
  }

  useEffect(() => {
    onselectapiCall()
  }, [date])

  const selectDividendHandler = (flag) => {
    if (flag === 'TSP') {
      setIsLottoSelected(false)
      setIsTspSelected(true)
    } else if (flag === 'LOTTO') {
      setIsLottoSelected(true)
      setIsTspSelected(false)
    }
  }

  const sweepTokenHanlder = async () => {
    try {
      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      const sweep = await tokencontract.balanceOf(accountAddress).call()
      const sweeptoken = +sweep / 10 ** 8

      // this.context.sweepToken = sweeptoken
      window.consoleClearHandler(
        sweeptoken,
        'ssssssssssssssssssssssssssssssssssssss',
      )
      setUpdatedSweepToken(sweeptoken)

      setSweepToken(sweeptoken)
      onSubmitLottoTokens(sweeptoken)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const freezeTokenHandler = async () => {
    try {
      const tokencontract = await window.tronWeb
        .contract()
        .at(tokencontractAddress)
      const freeze = await tokencontract.frozenBalance(accountAddress).call()
      const freezetoken = +freeze / 10 ** 8

      onSubmitFreezeLottoTokens(freezetoken)
    } catch (error) {}
  }

  const tspsweepTokenHandler = async () => {
    if (accountAddress) {
      try {
        let tokencontract = await window.tronWeb
          .contract()
          .at(tspcontractAddress)
        const sweep = await tokencontract.balanceOf(accountAddress).call()
        const sweeptoken = +sweep
        setUpdateTspSweepToken(sweeptoken)

        onSubmitTSPToken(sweeptoken)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const tspfreezeTokenHanlder = async () => {
    try {
      let tokencontract = await window.tronWeb.contract().at(tspcontractAddress)
      const freeze = await tokencontract.frozenBalance(accountAddress).call()
      const freezetoken = +freeze / 10 ** 8

      onSbumitTSPUnfreezeToken(freezetoken)
    } catch (error) {
      console.log(error, 'errorerrorerror')
    }
  }

  useEffect(() => {
    tspsweepTokenHandler()
    sweepTokenHanlder()
    tspfreezeTokenHanlder()
    freezeTokenHandler()
  }, [])

  console.log(isTspSelected, 'isTspSelected')

  return (
    <>
      <h5 className="title d-flex">
        {isTspSelected ? 'TSP Dividend' : null}
        {isLottoSelected ? 'LOTTO Dividend' : null}
        <div className="dropdown tsp_dd">
          {/* <button
        type="button"
        className="btn btn-secondary dropdown-toggle ml-3 dd_pp_btn"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {' '}
      </button> */}
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {!isTspSelected ? (
              <a
                className="dropdown-item"
                href="#!"
                onClick={() => selectDividendHandler('TSP')}
              >
                TSP Dividend
              </a>
            ) : null}
            {!isLottoSelected ? (
              <a
                className="dropdown-item"
                href="#!"
                onClick={() => selectDividendHandler('LOTTO')}
              >
                Lotto Dividend
              </a>
            ) : null}
          </div>
        </div>
      </h5>
      <div className="card account_card mb-3">
        {isTspSelected ? (
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 col-6 h_b_r">
                <div className="media">
                  <img className="avatar_dp mr-3" src={tron} alt="" />
                  <div className="media-body">
                    <p>
                      <small>Total dividend earned </small>
                    </p>
                    <h5 className="mt-0">
                      {(Math.round(totalTSPDividendSum * 100) / 100).toFixed(2)}{' '}
                      TRX
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6 tron_sweep_col text-center h_b_r">
                <div className="media">
                  <img className="avatar_dp mr-3" src={tron} alt="" />
                  <div className="media-body">
                    <p>
                      <small>TSP Available</small>
                    </p>
                    <h5 className="mt-0">
                      {(tspToken / 10 ** 8).toFixed(2)} TSP
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6 tron_sweep_col text-center h_b_r">
                <div className="media">
                  <img className="avatar_dp mr-3" src={tron} alt="" />
                  <div className="media-body">
                    <p>
                      <small>TSP Frozen</small>
                    </p>
                    <h5 className="mt-0">{tspUnfreezeToken.toFixed(2)} TSP</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {isLottoSelected ? (
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 col-6 h_b_r">
                <div className="media">
                  <img className="avatar_dp mr-3" src={tron} alt="" />
                  <div className="media-body">
                    <p>
                      <small>Total Lotto Dividend earned </small>
                    </p>

                    <h5 className="mt-0">
                      {' '}
                      {(Math.round(totalLottoDividendSum * 100) / 100).toFixed(
                        2,
                      )}{' '}
                      TRX
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6 tron_sweep_col text-center h_b_r">
                <div className="media">
                  <img className="avatar_dp mr-3" src={tron} alt="" />
                  <div className="media-body">
                    <p>
                      <small>LOTTO Available</small>
                    </p>
                    <h5 className="mt-0" onClick={lottoModalOpenHandler}>
                      {lottoTokens.toFixed(2)} LOTTO
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6 tron_sweep_col text-center h_b_r">
                <div className="media">
                  <img className="avatar_dp mr-3" src={tron} alt="" />
                  <div className="media-body">
                    <p>
                      <small>LOTTO Frozen</small>
                    </p>
                    <h5 className="mt-0">
                      {freezeLottoTokens.toFixed(2)} LOTTO
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <h5 className="title">Dividend History</h5>
      <div className="card mb-3 transaction_card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <DateRangePicker onChange={onDateChange} value={date} />
            </div>
            <div className="col-md-6">
              <select
                className="custom-select"
                value={Type}
                onChange={handleChange}
              >
                <option value="lotto">LOTTO</option>
                <option value="TSP">TSP</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className=" mb-3 transaction_history_card">
        <div className="card-body p-0">
          {data ? (
            <div>
              <table className="table table-striped table-dark mb-0">
                <thead>
                  <tr>
                    <th scope="col">S.N</th>
                    <th scope="col">Date</th>
                    <th scope="col">Amount</th>
                    <th scope="col">GameType</th>
                    {/* <th scope="col">Bet</th>
            <th scope="col">Multiplier</th>
            <th scope="col">Payout</th> */}
                  </tr>
                </thead>
                <tbody>
                  {dividentdata?.map((dividendData, index) => (
                    <tr key={dividendData.id}>
                      <td>{index + 1}</td>
                      <td>
                        {new Date(dividendData.created_at).toLocaleString()}
                      </td>
                      <td>{dividendData.amount.toFixed(2)} TRX</td>
                      <td>{dividendData.game}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {show ? (
                <div style={{ textAlign: 'center' }}>
                  <button
                    style={{
                      marginBottom: '10px',
                      marginTop: 10,
                    }}
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => onselectapiCall(true)}
                  >
                    Load more
                  </button>
                </div>
              ) : null}
            </div>
          ) : (
            <h4 className="no_h4">No dividend claims available</h4>
          )}
        </div>
      </div>
    </>
  )
}

export default Dividend
