/* eslint-disable no-underscore-dangle */
import React from 'react'
import axios from 'axios'
import boxIcon from '../public/images/icons/topwaggers.svg'

class WinningNumbers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      winningData: [],
      pastDate: new Date(),
      isFuturVisible: false,
      isLoading: true,
    }
  }

  componentDidMount() {
    const theDate = new Date()
    const todayFormat = theDate.toDateString()
    this.setState({
      topWaggersDate: todayFormat,
    })
    // const dateString = theDate.toGMTString();
    axios
      .get(`tron/user/potWinningNumber/${Date.now()}`)
      .then((res) => {
        this.setState({
          winningData: res.data.data,
          isLoading: false,
        })
      })
      .catch(() => {})
  }

  getPastWaggers = () => {
    const { pastDate } = this.state
    const prevDate = pastDate.setDate(pastDate.getDate() - 1)
    const theDate = new Date(prevDate)
    const dateString = theDate.toDateString()
    this.setState({
      topWaggersDate: dateString,
      winningData: [],
      isLoading: true,
    })

    axios
      .get(`tron/user/potWinningNumber/${prevDate}`)
      .then((res) => {
        this.setState({
          winningData: res.data.data,

          isFuturVisible: true,
          isLoading: false,
        })
      })
      .catch(() => {})
  }

  getFutureWaggers = () => {
    const { pastDate } = this.state
    const prevDate = pastDate.setDate(pastDate.getDate() + 1)
    const theDate = new Date(prevDate)
    const dateString = theDate.toDateString()
    this.setState({
      topWaggersDate: dateString,
      winningData: [],
      isLoading: true,
    })

    const currentDate = Date.now()
    const currtheDate = new Date(currentDate)
    const currentDateString = currtheDate.toDateString()

    const prevDateDate = new Date(prevDate)
    const prevDateString = prevDateDate.toDateString()

    if (currentDateString === prevDateString) {
      this.setState({
        isFuturVisible: false,
      })
    }
    axios
      .get(`tron/user/potWinningNumber/${prevDate}`)
      .then((res) => {
        this.setState({
          winningData: res.data.data,
          isLoading: false,
        })
      })
      .catch(() => {})
  }

  render() {
    const { topWaggersDate, isFuturVisible, winningData, isLoading } =
      this.state

    return (
      <>
        <div className="card-body">
          <div>
            <div className="top_heade_waggers">
              <div aria-hidden onClick={this.getPastWaggers}>
                <span>
                  <i className="mdi mdi-arrow-left" />
                </span>
              </div>
              <div>
                <strong>{topWaggersDate}</strong>
              </div>
              <div>
                {isFuturVisible ? (
                  <div aria-hidden onClick={this.getFutureWaggers}>
                    <span>
                      <i className="mdi mdi-arrow-right" />
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            {!isLoading ? (
              <>
                {winningData.length ? (
                  <div>
                    <table className="wager_table table">
                      <thead>
                        <tr>
                          <td>Pot Number</td>
                          <td>Winning Number</td>
                        </tr>
                      </thead>
                      <tbody>
                        {winningData.map((data) => (
                          <tr key={data.id}>
                            <td>
                              <img
                                className="pot_box_icon"
                                src={boxIcon}
                                alt=""
                              />{' '}
                              Pot {data._potNumber}
                            </td>
                            {data._potWinningNumber !== 0 ? (
                              <td>{data._potWinningNumber}</td>
                            ) : (
                              <td> - </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h4 className="no_top_waggers">No Data Available</h4>
                )}
              </>
            ) : (
              <h4 className="no_top_waggers">
                <span className="spinner">
                  <div className="bounce1" />
                  <div className="bounce2" />
                  <div className="bounce3" />
                </span>
              </h4>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default WinningNumbers
