import isEmpty from 'is-empty'
import React, { useState, useEffect } from 'react'
import { getAllTransaction } from '../components/Auth/Service'
import noData from '../public/images/icons/no_data.svg'


export default function AccountStatement() {
    const [statement, setStatement] = useState([])
    const getAllTransactionHandler = async (loadmore) => {
        const res = await getAllTransaction(loadmore)
        const { history: { user_transactions } } = res
        setStatement(user_transactions)
    }
    useEffect(() => {
        getAllTransactionHandler()
    }, [])
    return (
        <>
            <h5 className="title mt-5">Account Statement</h5>
            <div className="card mb-3 transaction_card">
                <div className="card-body">
                    <div className="row" style={{ justifyContent: "center" }}>
                        {
                            !isEmpty(statement) ?
                                <div className="table-responsive">
                                    <table className="table table-striped table-dark referral_table_tb">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.N</th>
                                                <th scope="col">Date and Time</th>
                                                <th scope="col">Token</th>
                                                <th scope="col">Deposit</th>
                                                <th scope="col">Withdraw</th>
                                                <th scope="col">Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                statement.map((item, i) => (
                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{new Date(
                                                            item.createdAt,
                                                        ).toLocaleString()}</td>
                                                        <td>{item.token}</td>
                                                        <td>{item.balance_update > 0 ? item.balance_update : "-"}</td>
                                                        <td>{item.balance_update < 0 ? item.balance_update : "-"}</td>
                                                        <td>{item.balance}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>

                                    </table>
                                    {
                                        statement.length >= 20 && statement.length < 200 ? <div style={{ textAlign: 'center' }}>
                                            <button
                                                style={{
                                                    marginBottom: 10,
                                                    marginTop: 10,
                                                }}
                                                type="button"
                                                className="btn btn-outline-primary btn-sm"
                                                onClick={() => getAllTransactionHandler(true)}
                                            >
                                                Load more
                                            </button>
                                        </div> : null
                                    }
                                </div>
                                :
                                <div className="no_data">
                                    <img src={noData} alt="no data" className="no_data_img" />
                                    <h4 className="no_h4">No record found!</h4>
                                </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

